import React from "react";
import { StyledTag as AntDTag } from "./style";

type Props = {
  color: string;
  children: string;
};

const Tag = ({ color, children }: Props) => {
  return <AntDTag color={color}>{children}</AntDTag>;
};

export default Tag;
