/** @format */

import * as React from "react";

const SmilyesIconResponsive = () => (
  <svg width={38} height={40} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M18.731 28.972c4.084 0 5.096-5.03 7.821-7.64 2.859-2.737 5.062-8.155 0-12.343C21.49 4.799 28.118 0 19.986 0 14.45 0 5.048 2.209 2.404 6.61 1.051 8.864 0 15.708 0 18.52c0 4.019 3.684 6.677 6.269 9.376 2.76 2.883 8.14 1.076 12.462 1.076z"
        fill="#E8F8EE"
      />
      <g
        stroke="#292D32"
        strokeWidth={2.016}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      >
        <path d="M8.169 16.031c0-4.04 1.627-5.655 5.695-5.655h4.881c4.068 0 5.695 1.616 5.695 5.655v5.117c-3.783.068-5.356 1.63-5.423 5.387h-5.153c-4.068 0-5.695-1.616-5.695-5.656" />
        <path d="M14.895 15.587a2.092 2.092 0 0 0-2.387.027m7.81-.027a2.092 2.092 0 0 0-2.386.027m-1.411 7.446h-3.146a.727.727 0 0 1-.732-.726c0-2.007 1.64-3.636 3.661-3.636.868 0 1.668.296 2.292.794m16.692 12.16c0 4.04-1.627 5.655-5.695 5.655h-4.881c-4.068 0-5.695-1.616-5.695-5.655v-5.117c.067-3.757 1.64-5.32 5.423-5.387h5.153c4.068 0 5.695 1.616 5.695 5.656" />
        <path d="M25.742 26.023a2.092 2.092 0 0 1-2.386-.027m7.81.027a2.092 2.092 0 0 1-2.386-.027m1.3 3.474c.408 0 .733.323.733.727 0 2.007-1.64 3.636-3.661 3.636-2.02 0-3.661-1.63-3.661-3.636 0-.404.325-.727.732-.727h5.858z" />
      </g>
    </g>
  </svg>
);

export default SmilyesIconResponsive;
