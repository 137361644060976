import * as React from "react";

function HelpIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width={22}
      height={21}
      viewBox="0 0 22 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{"icons8-help (1)"}</title>
      <g transform="translate(1.457 .5)" fill="none" fillRule="evenodd">
        <circle
          stroke="#344247"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          cx={10}
          cy={10}
          r={9.737}
        />
        <circle
          fill="#344247"
          fillRule="nonzero"
          cx={10}
          cy={15.263}
          r={1.053}
        />
        <path
          d="M10 11.842c0-2.33 2.368-1.579 2.368-4.474a2.369 2.369 0 00-4.736 0v.264"
          stroke="#344247"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default HelpIcon;
