/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { CustomHeader } from "common/Header";
import { PContainer, Container, Hr, ContnetContainer } from "../style";
import Step from "common/Step";
import DescriptionForm from "./description-form";
import { useForm } from "react-hook-form";
import DemandsForm from "./demands-form";
import { useStore } from "infrastructure/zustandStore/branch-list-store";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import PaymentForm from "./payment-form";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";
import { jobDetailsStore } from "infrastructure/zustandStore/job-details-store";
import { Helmet } from "react-helmet";
import storage from "infrastructure/utils/storage";

const { useEffect, useState } = React;

type Props = {
  name: string;
  value: null | string | any;
};

const EditEvaluationJob = () => {
  const { t } = useTranslation();
  let { id } = useParams();

  const [jobDetails, jobDetailsFn] = jobDetailsStore((state: any) => [
    state.jobDetails,
    state.jobDetailsFn,
  ]);

  const [editableData, setEditableData] = useState<any>();
  const { data: templates } = useQuery(
    ["templates"],
    () => fetchGetRequests("api/company/v1/evaluation-templates/active"),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const { data: configrationList } = useQuery(
    ["configrationList"],
    () => fetchGetRequests("api/configurations"),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const [branchListData, branchList] = useStore(
    (state: Record<string | number | symbol, any>) => [
      state.branchListData,
      state.branchList,
    ]
  );

  useEffect(() => {
    jobDetailsFn(id);
    branchList();
    const getDetails = async () => {
      const response = await axiosApiInstance.get(`api/company/v1/jobs/${id}`);
      setEditableData({
        evaluation_template_id: response?.data?.job?.template?.id,
        branch_id: [response?.data?.job?.branch?.id],
        name: response?.data?.job?.name,
        description: response?.data?.job?.description,
        instructions: response?.data?.job?.instructions,
        shoppers_count: response?.data?.job?.shoppersData.count,
        age_group_id: response?.data?.job?.age?.id,
        education_id: response?.data?.job?.education?.id,
        gender: response?.data?.job?.gender,
        experience_id: response?.data?.job?.experience?.id,
        nationality_id: response?.data?.job?.nationality?.id,
        skills: response?.data?.job?.skills,
        languages: response?.data?.job?.languages,
        extra_pay: response?.data?.job?.extra_pay,
        extra_item: response?.data?.job?.extraPay?.name,
        extra_item_price: response?.data?.job?.extraPay?.amount,
      });
    };
    getDetails();
  }, []);

  const [current, setCurrent] = useState<number>(0);
  const [editableResponse, setEditableResponse] = useState();
  const [paymentType, setPaymentType] = useState<string>("");

  const { handleSubmit, register, errors, formState, watch, setValue, reset } =
    useForm<{
      evaluationTemplateSelect: number;
      picker: Array<number>;
      evluationPlaceSelect: number;
      ageSelect: number;
      genderSelect: Array<number>;
      educationSelect: number;
      skillsSelect: Array<number>;
      experiencesSelect: number;
      languagesSelect: Array<number>;
      nationalitySelect: number;
      extraItemsSelect: number;
    }>({
      mode: "onBlur",
      criteriaMode: "all",
      defaultValues: {
        evaluationTemplateSelect: jobDetails.evaluation_template_id,
        picker: [],
        evluationPlaceSelect: 0,
        ageSelect: 0,
        genderSelect: [],
        educationSelect: 0,
        skillsSelect: [],
        experiencesSelect: 0,
        languagesSelect: [],
        nationalitySelect: 0,
        extraItemsSelect: 0,
      },
    });

  const next = () => {
    if (editableData.extra_pay === false) {
      delete editableData["extra_item"];
      delete editableData["extra_item_price"];
    }

    if (editableData.gender === "ذكر") {
      editableData["gender"] = "male";
    } else if (editableData.gender === "انثي") {
      editableData["gender"] = "female";
    } else {
      delete editableData["gender"];
    }

    if (current === 0) {
      setCurrent(current + 1);
    } else if (current === 1 && jobDetails) {
      axiosApiInstance
        .put(`api/company/v1/jobs/${id}`, editableData)
        .then((res) => {
          storage.setItem("jobs_data", JSON.stringify(res.data));
          setEditableResponse(res.data);
          setCurrent(current + 1);
        })
        .catch((err) => {
          showNotification(
            NotificationStatus.Error,
            t("error"),
            err.response.data.message
          );
        });
    }
  };

  const prev = () => {
    reset();
    setCurrent(current - 1);
  };

  const handleSelectOnChange = (value: number, item?: any, type?: string) => {
    const { title, defaultValue }: any = item;
    const list: any = { ...jobDetails };
    const editList = { ...editableData };

    if (type === "evaluationTemplateSelect") {
      if (list["name"] === "" && list["description"] === "") {
        list["name"] = defaultValue?.name;
        list["description"] = defaultValue?.description;
      } else {
        list["name"] = defaultValue?.name;
        list["description"] = defaultValue?.description;
      }
    }

    if (item && item.length > 0 && item instanceof Array) {
      const items: any = { ...item };
      const title = items[0].title;
      list[title] = value;
      editList[title] = value;
    } else if (title === "evaluation_template_id") {
      list["template"].name = value;
      list[title] = value;
    } else if (title === "branch_id") {
      list["branch"].name = value;
      list[title] = value;
    } else if (title === "education_id" && list["education"] !== null) {
      list["education"].id = value;
      list[title] = value;
    } else if (title === "experience_id" && list["experience"] !== null) {
      list["experience"].id = value;
      list[title] = value;
    } else if (title === "nationality_id" && list["nationality"] !== null) {
      list["nationality"].id = value;
      list[title] = value;
    } else {
      list[title] = value;
      editList[title] = value;
    }
    editList[title] = value;

    setEditableData(editList);
    jobDetailsStore.setState({ jobDetails: list });
  };

  const handleInputChange = (e: { target: Props }) => {
    let { name, value }: Props = e.target;
    let list = { ...jobDetails };
    const editList = { ...editableData };

    if (name === "extra_item") {
      list["extraPay"].name = value;
      list[name] = value;
    }
    if (name === "extra_item_price") {
      list["extraPay"].amount = parseInt(value);
      list[name] = parseInt(value);
    }
    if (name === "shoppers_count") {
      list["shoppersData"].count = parseInt(value);
      list[name] = parseInt(value);
      editList[name] = parseInt(value);
    }
    list[name] = value;
    editList[name] = value;
    setEditableData(editList);
    jobDetailsStore.setState({ jobDetails: list });
  };

  const handleRangePickerOnchange = (date: any, dateString: Array<string>) => {
    const list: any = { ...jobDetails };
    const editList = { ...editableData };

    list["start_time"] = list["startTime"];
    delete list["startTime"];
    list["start_time"] = dateString[0];
    list["end_time"] = list["endTime"];
    delete list["endTime"];
    list["end_time"] = dateString[1];
    editList["start_time"] = dateString[0];
    editList["end_time"] = dateString[1];

    setEditableData(editList);
    jobDetailsStore.setState({ jobDetails: list });
  };

  const handleOnChangeSwitchButton = (checked: boolean) => {
    const list: any = { ...jobDetails };
    const editList = { ...editableData };

    list["extra_pay"] = checked;
    editList["extra_pay"] = checked;

    setEditableData(editList);
    jobDetailsStore.setState({ jobDetails: list });
  };

  const steps = [
    {
      title: t("description"),
      content: (
        <DescriptionForm
          templates={templates?.response?.data.templates}
          handleSelectOnChange={handleSelectOnChange}
          handleInputChange={handleInputChange}
          template={jobDetails}
          handleSubmit={handleSubmit(next)}
          register={register}
          errors={errors}
          formState={formState}
          watch={watch}
          setValue={setValue}
        />
      ),
      description: t("job_description_span"),
    },
    {
      title: t("demands"),
      content: (
        <DemandsForm
          branchListData={branchListData}
          handleSelectOnChange={handleSelectOnChange}
          handleRangePickerOnchange={handleRangePickerOnchange}
          handleInputChange={handleInputChange}
          handleOnChangeSwitchButton={handleOnChangeSwitchButton}
          handleSubmit={handleSubmit(next)}
          register={register}
          errors={errors}
          formState={formState}
          watch={watch}
          setValue={setValue}
          current={current}
          prev={prev}
          data={jobDetails}
          configrationList={configrationList?.response.configuration}
        />
      ),
      description: t("demands_description_span"),
    },
    {
      title: t("payment"),
      content: (
        <PaymentForm
          res={editableResponse}
          current={current}
          prev={prev}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
        />
      ),
      description: t("payment_description_span"),
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> تعديل مهمة -تدري </title>
      </Helmet>
      <PContainer>
        <CustomHeader
          title={<Trans>add_evaluation_job</Trans>}
          path={"/jobs"}
          type="withoutLeftContainer"
        />
      </PContainer>

      <Container>
        <Step steps={steps} current={current} />
        <Hr />
        <ContnetContainer>{steps[current].content}</ContnetContainer>
      </Container>
    </>
  );
};

export default React.memo(EditEvaluationJob);
