/** @format */

import React from "react";
import {
  BranchRatingsSection,
  ChartLabels,
  LinePrpgressPercentage,
  LinePrpgressWrapper,
  PercentageLabels,
  PointLabel,
  PointsReviewWrapper,
  VerticalLine,
} from "./style";
import { Row, Col } from "antd";
import { Trans } from "react-i18next";
import { Progress } from "common/Progress";
import NounSmileEmoji from "assets/svg/noun-smile";
import NounNaturalEmoji from "assets/svg/noun-neutral";
import NounSmileGreen from "assets/svg/noun-smile-green";
import NounLaughEmoji from "assets/svg/noun-laugh";
const SummaryPointsReview = ({
  percentage,
  questionCategories,
  total_rating,
  mBot,
}: any) => {
  const percent = percentage;

  return (
    <PointsReviewWrapper mBot={mBot}>
      <Row>
        <Col span={11}>
          <ChartLabels>
            {total_rating ? (
              <Trans>overall_rating_branches</Trans>
            ) : (
              <Trans>branch_ratings</Trans>
            )}
          </ChartLabels>
          <BranchRatingsSection>
            {percent > 0 && percent < 49 ? (
              <Progress
                type="dashboard"
                percent={percent}
                strokeColor={"#ec8577"}
                size="default"
                format={() => <NounSmileEmoji />}
              />
            ) : percent >= 50 && percent <= 69 ? (
              <Progress
                type="dashboard"
                percent={percent}
                strokeColor={"#f5a425"}
                size="default"
                format={() => <NounNaturalEmoji />}
              />
            ) : percent >= 70 && percent <= 89 ? (
              <Progress
                type="dashboard"
                percent={percent}
                strokeColor={"#82ae04"}
                size="default"
                format={() => <NounSmileGreen />}
              />
            ) : (
              <Progress
                type="dashboard"
                percent={percent}
                strokeColor={"#37ae00"}
                size="default"
                format={() => <NounLaughEmoji />}
              />
            )}

            <PercentageLabels> {percent + "%"} </PercentageLabels>
            <PointLabel>
              <Trans>based_on_shoppers_answers</Trans>
            </PointLabel>
          </BranchRatingsSection>
        </Col>
        <Col span={2}>
          <VerticalLine />
        </Col>
        <Col span={11}>
          <ChartLabels>
            <Trans>focus_of_questions</Trans>
          </ChartLabels>
          {questionCategories?.map((el: any, index: number) => (
            <React.Fragment key={index}>
              {el.category.length === 0 ? (
                <ChartLabels>
                  <Trans>other</Trans>
                </ChartLabels>
              ) : (
                <ChartLabels>{el.category}</ChartLabels>
              )}
              <LinePrpgressWrapper>
                <Progress
                  key={index}
                  percent={el.percentage}
                  size="default"
                  strokeColor={el.percentage > 50 ? "#44c874" : "#ff4d4f"}
                  style={{ width: "80%", marginBottom: 0 }}
                  showInfo={false}
                />
                <LinePrpgressPercentage>
                  {el.percentage}%
                </LinePrpgressPercentage>
              </LinePrpgressWrapper>
            </React.Fragment>
          ))}
        </Col>
      </Row>
    </PointsReviewWrapper>
  );
};

export default SummaryPointsReview;
