/** @format */

import styled from "styled-components";
import ReactPlayer from "react-player/lazy";

//@ts-ignore
export const StyledPlayer = styled(ReactPlayer)`
  margin-bottom: 0px !important;
  height: 100% !important;
  width: unset !important;
  video {
    width: 300px !important;
    height: 300px !important;
    @media print {
      width: 100px;
      height: 100px;
    }
  }
  audio {
    width: 250px !important;
    height: 150px !important;
  }
`;
