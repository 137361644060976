/** @format */

import styled from "styled-components";
import { Tabs } from "antd";
import Colors from "../../infrastructure/utils/Colors";

type Props = {
  language?: string;
  className?: string;
};
//@ts-ignore
export const StyledTabs = styled(Tabs) <Props>`
  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
    display: none;
  }

  &.ant-tabs {
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.className === "mainTabs" ? "98%" : "100%")};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) =>
    props.className === "mainTabs" ? Colors.charcoal_grey : Colors.dark_mint};
    font-family: Famtree;
    font-size: ${(props) => (props.className === "mainTabs" ? "16px" : "14px")};
    font-weight: bold;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: ${(props) =>
    props.className !== "mainTabs" && Colors.dark_mint_2};
    @media (max-width: 767px) {
      width: 110px;
    }
  }

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    @media (max-width: 767px) {
      width: 110px;
    }
  }

  .ant-tabs-tab-btn {
    color: ${Colors.black};
    font-family: Famtree;
    font-size: ${(props) => (props.className === "mainTabs" ? "16px" : "14px")};
    font-weight: normal;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .ant-tabs-ink-bar {
    background-color: ${Colors.dark_mint};
  }

  .ant-tabs-nav-wrap {
    direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};
    flex: 0.98;
  }

  &.ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    width: ${(props) =>
    props.className === "changePassword" || props.className === "mainTabs"
      ? "unset"
      : "0px"};
  }

  &.ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    align-self: ${(props) => props.className === "changePassword" && "center"};
    width: ${(props) =>
    props.className === "changePassword"
      ? "100%"
      : props.className === "mainTabs"
        ? "96%"
        : "63%"};
    margin: 0 auto;
    margin-bottom: 20px;

    @media (max-width: 900px) {
      margin-bottom: unset;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: center;
  }

  &.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    margin: ${(props) => props.className === "changePassword" && "0 auto"};
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .ant-tabs-tab {
    margin: 0 0 0 32px;
    cursor: pointer;
    @media (max-width: 767px) {
      width: 80px;
      justify-content: center;
    }
  }

  .ant-tabs-content-holder {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

export const TapsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
