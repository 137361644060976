/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Input } from "common/Input";
import {
  Form,
  Label,
  SelectLabel,
  SelectWithSwitchContainer,
  SwitchLabel,
  SwitchBtnContainer,
  AddressLables,
  MapLabel,
  Container,
  ButtonContainer,
  P,
  MapTitle,
  SelectWrapper,
} from "./style";
import { BranchesFormProps } from "infrastructure/interfaces/branches-form.interface";
import { Error } from "../../style";
import { Select } from "common/Select";
import { SwitchBtn } from "common/Switch";
import { Button } from "common/Button";
import Modal from "common/Modal";
import MapContainer from "common/Map/index";
import { Select as AntDSelect } from "antd";
import ReactSelect from "common/ReactSelect";

const { Option } = AntDSelect;

const AddCompanyBranchForm: React.FC<BranchesFormProps> = ({
  errors,
  register,
  formState,
  categories,
  handleInputChange,
  handleOnChangeSwitchButton,
  data,
  handleSelectOnChange,
  handleSubmit,
  isModalVisible,
  showModal,
  handleCancel,
  handleOk,
  loading,
  countriesOptions,
  handleFetchCities,
  disableCity,
  citiesOptions,
  setValue,
}): JSX.Element => {
  const { i18n, t } = useTranslation();

  const { address } = data;

  return (
    <Container language={i18n.language === "ar" ? "ar" : "en"}>
      <Form onSubmit={handleSubmit} noValidate>
        <Label>
          <Trans>branch_name</Trans>
        </Label>
        <Input
          name="name"
          type="text"
          placeholder={i18n.language === "ar" ? "مثال :فرع حطين" : "Enter name"}
          dir="true"
          width={"formWidth"}
          errorName={errors.name}
          ref={register({ required: true, maxLength: 60 })}
          onChange={(e: { target: { name: string; value: string } }) =>
            handleInputChange(e)
          }
        />
        {errors?.name?.types?.required && (
          <Error>
            <Trans>name_required</Trans>
          </Error>
        )}
        {errors?.name?.types?.maxLength && (
          <Error>
            <Trans values={{ num: 60 }}>max_length</Trans>
          </Error>
        )}

        <Label>
          <Trans>shop_name</Trans>
        </Label>
        <Input
          name="shop_name"
          type="text"
          placeholder={
            i18n.language === "ar" ? "مثال : محل الاسطورة" : "Enter name"
          }
          dir="true"
          width={"formWidth"}
          errorName={errors.shop_name}
          ref={register({ required: true, maxLength: 60 })}
          onChange={(e: { target: { name: string; value: string } }) =>
            handleInputChange(e)
          }
        />
        {errors?.shop_name?.types?.required && (
          <Error>
            <Trans>name_required</Trans>
          </Error>
        )}
        {errors?.shop_name?.types?.maxLength && (
          <Error>
            <Trans values={{ num: 60 }}>max_length</Trans>
          </Error>
        )}

        <SelectWithSwitchContainer
          language={i18n.language === "ar" ? "ar" : "en"}
        >
          <SelectWrapper>
            <Label>
              <Trans>working_field</Trans>
            </Label>
            <Select
              defaultValue={
                <SelectLabel>
                  <Trans>select_working_field_default_value</Trans>
                </SelectLabel>
              }
              onChange={(value: number) => {
                handleSelectOnChange(value, "category_id");
                setValue("category", value);
              }}
              className="company"
              errorStyle={errors.category}
            >
              {categories &&
                categories.map((category) => {
                  return (
                    <Option
                      key={category.id}
                      value={category.id}
                      title={category.name}
                    >
                      <P language={i18n.language === "ar" ? "ar" : "en"}>
                        {category.name}
                      </P>
                    </Option>
                  );
                })}
            </Select>
            {errors.category && (
              <Error
                style={{
                  direction: i18n.language === "ar" ? "rtl" : "ltr",
                }}
              >
                <Trans>required_error_message</Trans>
              </Error>
            )}
          </SelectWrapper>

          <SwitchBtnContainer>
            <SwitchLabel>
              <Trans>is_website</Trans>
            </SwitchLabel>
            <SwitchBtn
              onChange={(checked: boolean) => {
                handleOnChangeSwitchButton(checked);
              }}
              defaultChecked={data.is_website}
              className={data.is_website}
            />
          </SwitchBtnContainer>
        </SelectWithSwitchContainer>

        {data.is_website ? (
          <>
            <Label>
              <Trans>website</Trans>
            </Label>
            <Input
              name="website"
              type="url"
              width={"formWidth"}
              errorName={errors.website}
              onChange={(e: { target: { name: string; value: string } }) =>
                handleInputChange(e)
              }
              ref={register({
                required: true,
                pattern:
                  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
              })}
            />
            {errors?.website?.types?.required && (
              <Error>
                <Trans>website_required</Trans>
              </Error>
            )}
            {errors?.website?.types?.pattern && (
              <Error>
                <Trans>website_pattern</Trans>
              </Error>
            )}
          </>
        ) : (
          <>
            {/* country */}
            <Label>
              <Trans>country</Trans>
            </Label>
            <ReactSelect
              className="country-select"
              options={countriesOptions}
              onChange={(option: { value: number; label: string }) => {
                handleFetchCities(option.value);
                handleSelectOnChange(option.value, "country_id");
                setValue("country", option.value);
              }}
              placeholder={t("select_country")}
              name={"country"}
              errorStyle={errors.country}
            />
            {errors.country && (
              <Error
                style={{
                  direction: i18n.language === "ar" ? "rtl" : "ltr",
                }}
              >
                <Trans>required_error_message</Trans>
              </Error>
            )}

            {/* city */}
            <Label>
              <Trans>city</Trans>
            </Label>
            <ReactSelect
              className="city-select"
              options={citiesOptions}
              onChange={(option: { value: number; label: string }) => {
                handleSelectOnChange(option.value, "city_id");
                setValue("city", option.value);
              }}
              placeholder={t("select_city")}
              isDisabled={disableCity}
              errorStyle={errors.city}
            />
            {errors.city && (
              <Error
                style={{
                  direction: i18n.language === "ar" ? "rtl" : "ltr",
                }}
              >
                <Trans>required_error_message</Trans>
              </Error>
            )}

            <AddressLables>
              <Label>
                <Trans>address</Trans>
              </Label>

              <MapLabel onClick={showModal}>
                <Trans>map_address</Trans>
              </MapLabel>
            </AddressLables>
            <Input
              name="address"
              type="text"
              dir="true"
              width={"formWidth"}
              errorName={!address && errors.address}
              ref={register({ required: true })}
              disabled
              value={address}
            />
            {!address && errors?.address?.types?.required && (
              <Error>
                <Trans>address_required</Trans>
              </Error>
            )}

            <Label>
              <Trans>address_description</Trans>
            </Label>
            <Input
              name="address_description"
              type="text"
              dir="true"
              width={"formWidth"}
              errorName={errors.address_description}
              onChange={(e: { target: { name: string; value: string } }) =>
                handleInputChange(e)
              }
            />
            <Label>
              <Trans>website</Trans>
            </Label>
            <Input
              name="website"
              type="url"
              dir="true"
              width={"formWidth"}
              onChange={(e: { target: { name: string; value: string } }) =>
                handleInputChange(e)
              }
              errorName={errors.website}
              ref={register({
                pattern:
                  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
              })}
            />
            {errors?.website?.types?.pattern && (
              <Error>
                <Trans>website_pattern</Trans>
              </Error>
            )}
          </>
        )}
      </Form>

      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        title={
          <MapTitle language={i18n.language === "ar" ? "ar" : "en"}>
            <Trans>map_title</Trans>
          </MapTitle>
        }
        text={<Trans>save</Trans>}
        onOk={handleOk}
        width={"companyBranch"}
      >
        <MapContainer />
      </Modal>

      <ButtonContainer>
        <Button
          disabled={formState.isSubmitting}
          size={"large"}
          onClick={handleSubmit}
          loading={loading}
        >
          <Trans>save</Trans>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default AddCompanyBranchForm;
