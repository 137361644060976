/** @format */

import React from "react";
import EmptyTemplate from "common/EmptyTemplate";
import { Container } from "./style";
import { Header, Title } from "../style";
import { useQuery } from "react-query";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";
import { Collapse } from "antd";
import Spinner from "common/LoadingIndecator";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const { Panel } = Collapse;

export const Help = () => {
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { data } = useQuery(
    ["help-questions"],
    () => fetchGetRequests("api/company/v1/help-questions"),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar" ? "مساعدة - تدرى" : "Tdree - Help"}
        </title>
      </Helmet>
      <Header language={i18n.language === "ar" ? "ar" : "en"}>
        <Title>
          <Trans>help</Trans>
        </Title>
      </Header>

      <EmptyTemplate
        className="help"
        style={{
          overflow: "scroll",
          margin: "0",
        }}
      >
        {!data ? (
          <Spinner size="large" />
        ) : (
          <Container
            style={{
              margin: "0px",
              padding: "20px",
            }}
            language={i18n.language === "ar" ? "ar" : "en"}
          >
            <Collapse
              expandIconPosition={
                isMobile || i18n.language === "en" ? "left" : "right"
              }
              style={{
                width: "100%",
                direction: "ltr",
              }}
            >
              {data?.response?.questions?.map(
                (question: {
                  title:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                  id: string | number;
                  answer:
                    | boolean
                    | React.ReactChild
                    | React.ReactFragment
                    | React.ReactPortal
                    | null
                    | undefined;
                }) => {
                  return (
                    question.title &&
                    question.answer && (
                      <Panel
                        style={{
                          fontFamily: "Famtree",
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          marginLeft: "30px",
                          textAlign: i18n.language === "ar" ? "right" : "left",
                        }}
                        header={question.title}
                        key={question.id}
                      >
                        <p
                          style={{
                            fontFamily: "Famtree",
                            fontSize: "12px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            marginBottom: "0",
                          }}
                        >
                          {question.answer}
                        </p>
                      </Panel>
                    )
                  );
                }
              )}
            </Collapse>
          </Container>
        )}
      </EmptyTemplate>
    </>
  );
};
