import React from "react";

function RatingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g stroke="#344247" strokeWidth="1.5" transform="translate(-1622 -296)">
          <g transform="translate(1415)">
            <g transform="translate(0 284)">
              <g transform="translate(208.457 12.5)">
                <path
                  strokeLinejoin="round"
                  d="M10 .263C4.623.263.263 4.623.263 9.999c0 1.72.45 3.332 1.232 4.734L.284 19.07a.526.526 0 00.648.648l4.338-1.21a9.689 9.689 0 004.73 1.23c5.377 0 9.737-4.36 9.737-9.738 0-5.377-4.36-9.736-9.737-9.736z"
                ></path>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.15789474 5.52631579L5 8.68421053 8.15789474 11.8421053"
                ></path>
                <path
                  strokeLinecap="round"
                  d="M5.526 8.684h4.21a4.21 4.21 0 014.211 4.21v1.053"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default RatingIcon;
