/** @format */

import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";

export const Error = styled.p`
  font-family: Famtree;
  color: ${Colors.darkish_pink};
  margin: 0;
  font-size: 13px;
  flex: 0;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  color: ${Colors.battleship_grey};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
`;

export const Bio = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: ${Colors.charcoal_grey_70};
`;

export const TdreeTitle = styled.div`
  font-family: Famtree;
  font-size: 48px;
  font-weight: normal;
  margin-top: 50px;
  margin-bottom: 0;
`;

export const Container = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 85%;
  position: relative;
  margin: ${(props: { language?: string }) =>
    props.language === "ar" ? "0px 0px 0px 15px" : "0px 15px 0px 0px"};
  @media (max-width: 800px) {
    margin: 0;
  }
`;

export const TablesWrapper = styled.div`
  width: 100%;
  @media (max-width: 800px) {
    margin-top: 17px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 50px;
  direction: ${(props: { language?: string }) =>
    props?.language === "ar" ? "rtl" : "ltr"};
  @media (max-width: 767px) {
    margin: 40px 15px 0 15px;
  }
`;

export const ColumnCell = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  color: ${Colors.charcoal_grey};
  word-break: break-all;
  white-space: nowrap;
`;
export const ColumnCellFixed = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  color: ${Colors.charcoal_grey};
  word-break: break-all;
`;
export const ColumnCellName = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  cursor: pointer;
  color: ${Colors.charcoal_grey};
  white-space: nowrap;
`;

export const MoreBtn = styled.a`
  font-family: Famtree;
  font-size: 36px;
  line-height: 0;
  margin-top: -10px;
  letter-spacing: -2px;
  cursor: pointer;
  color: ${Colors.charcoal_grey_38};
`;

export const Title = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  margin: 30px 0px;
  font-size: 20px;
  text-align: ${(props: { language?: string }) =>
    props.language === "ar" ? "right" : "left"};
  @media (max-width: 991px) {
    font-size: 18px;
    margin: 20px 10px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    margin: 20px 10px;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  .checkbox-value {
    font-family: Famtree;
    font-size: 14px;
    font-weight: normal;
    color: #344247;
    margin: 0 14px;
  }
`;

export const CheckboxLabel = styled.span`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #344247;
  margin: 0 14px;
`;

export const RateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  align-items: ${(props: { language?: string }) =>
    props.language === "ar" ? "flex-start" : "flex-end"};
  min-height: ${(props: { hieght?: string; language?: string }) =>
    props.hieght ? props.hieght : "auto"};
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const SectionFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const Span = styled.span`
  color: white;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
`;

export const NoREsultsMessage = styled.div`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 18px;
  font-weight: normal;
  margin: 18px 0 34px;
`;

export const NoResultText = styled.p`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 16px;
  font-weight: normal;
  margin: 20px 0;
`;
