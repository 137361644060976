/** @format */

import { create } from "zustand";

export const locationStore = create((set: any) => ({
  location: { lat: 30.0769892, long: 31.3451107 },
  address: "",
  district: "",
  city: "",
  country: "",
  locationFn: async () => {
    try {
      set({
        location: { lat: 30.0769892, long: 31.3451107 },
        address: "",
        district: "",
        city: "",
        country: "",
      });
    } catch (error) {}
  },
}));
