/** @format */

import * as React from "react";
import { SVGProps } from "react";

const AppStoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={30}
    {...props}
    style={{ marginLeft: "10px" }}
  >
    <g fill="#FFF">
      <path d="M4.376 27.463C5.361 28.752 6.842 30 8.546 30c.969 0 1.792-.48 2.677-.83a7.06 7.06 0 0 1 2.545-.48 6.012 6.012 0 0 1 2.443.504c.41.182.817.376 1.234.544.423.172.873.261 1.327.262a4.027 4.027 0 0 0 2.345-.794c1.998-1.44 3.478-3.97 4.527-6.185.105-.22.205-.444.306-.665.166-.37-.096-.837-.561-1.106-2.053-1.186-3.255-3.02-3.456-5.549-.2-2.53.735-4.526 2.612-5.976l.37-.32c.204-.177.093-.669-.293-1.053C23.202 6.881 21.3 6.039 19.308 6c-.387 0-.773.034-1.154.1-1.733.313-3.125 1.182-4.758 1.182-.495 0-.988-.081-1.46-.24C10.569 6.58 9.21 6 7.755 6c-.16 0-.322 0-.484.022-1.794.2-3.469 1.04-4.748 2.378C-.001 11.016-.358 15.08.27 18.513c.604 3.327 2.091 6.324 4.105 8.95z" />
      <path d="M12.462 5.998a.615.615 0 0 0 .099 0C17.437 5.154 17.97 1.658 18 .382a.362.362 0 0 0-.124-.277.422.422 0 0 0-.303-.104.584.584 0 0 0-.122.012C12.526 1.072 12.014 4.368 12 5.6c.002.11.053.215.14.29a.46.46 0 0 0 .322.108z" />
    </g>
  </svg>
);

export default AppStoreIcon;
