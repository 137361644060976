/** @format */

import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { axiosApiInstance } from "infrastructure/utils/api";
import { ChangePaswwordForm } from "./form";
import { FormProps } from "infrastructure/interfaces/forms.interface";
import { useNavigate } from "react-router-dom";

const { useState } = React;

export const ChangePassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleSubmit, register, errors, formState } = useForm({
    mode: "onBlur",
    criteriaMode: "all",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const onSubmit = (data: FormProps) => {
    setLoading(true);
    return axiosApiInstance
      .put("api/company/v1/profile/password", data)
      .then(() => {
        setLoading(false);
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
        navigate("/home");
      })
      .catch((error) => {
        setLoading(false);
        showNotification(
          NotificationStatus.Error,
          t("error"),
          error.response.data.message
        );
      });
  };

  return (
    <ChangePaswwordForm
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      register={register}
      formState={formState}
      loading={loading}
      togglePassowrdVisiblity={togglePasswordVisiblity}
      passwordShown={passwordShown}
    />
  );
};
