/** @format */

import React, { useState } from "react";
import {
  Info,
  ShopperInformationRow,
  ShoppersAddress,
  ShoppersTitle,
  ShopperHeader,
} from "./style";
import Avatar from "common/Avatar";
import { Trans, useTranslation } from "react-i18next";
import { timeStampToDate } from "infrastructure/helpers/timeStampToDate";
import { Rate } from "common/Rate";
import { Button } from "common/Button";
import { RatingWrapper } from "pages/CompanyBranch/style";
import {
  showNotification,
  NotificationStatus,
} from "infrastructure/helpers/showNotifications";
import { axiosApiInstance } from "infrastructure/utils/api";

const ShopperInfo = ({ shopperData, show_in_print, id }: any) => {
  const [shopperRate, setShopperRate] = useState<number>();
  const [isRated, setIsRated] = useState<boolean>(true);
  const { t } = useTranslation();

  const getShopperRate = (value: number) => {
    setShopperRate(value);
  };

  const handleEvaluationRateOnClick = () => {
    axiosApiInstance
      .post("api/company/v1/jobs/shopper/rate", {
        user_id: shopperData.id,
        job_id: id,
        rate: shopperRate,
      })
      .then(() => {
        setIsRated(false);
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          t("error"),
          err.response.data.message
        );
      });
  };

  return (
    <>
      <ShopperInformationRow show_in_print={show_in_print}>
        <ShopperHeader>
          <Avatar src={shopperData?.image} size={"default"} shape={"circle"} />
          <Info className="shopper-name">
            <ShoppersTitle>{shopperData?.name}</ShoppersTitle>
            <ShoppersAddress>
              {shopperData?.city + "," + shopperData?.country}
            </ShoppersAddress>
          </Info>
        </ShopperHeader>
        <Info>
          <ShoppersTitle>
            <Trans>start_time</Trans>
          </ShoppersTitle>
          <ShoppersAddress>
            {timeStampToDate(shopperData?.ratingDetails.start_time)}
          </ShoppersAddress>
        </Info>
        <Info>
          <ShoppersTitle>
            <Trans>end_time</Trans>
          </ShoppersTitle>
          <ShoppersAddress>
            {timeStampToDate(shopperData?.ratingDetails.end_time)}
          </ShoppersAddress>
        </Info>
      </ShopperInformationRow>
      {shopperData.status.id === 5 ? (
        shopperData.status.id === 5 || shopperData.status.id === 4 ? (
          <ShopperInformationRow show_in_print={show_in_print}>
            <RatingWrapper>
              <ShoppersTitle>
                <Trans>evaluation</Trans>
              </ShoppersTitle>
              {shopperData?.ratingDetails.shopperRating !== null ? (
                <Rate
                  count={5}
                  className="shopperDetails"
                  value={shopperData?.ratingDetails.shopperRating}
                  disabled
                />
              ) : (
                <Rate
                  count={5}
                  className="shopperDetails"
                  onChange={(value: number) => getShopperRate(value)}
                  value={
                    shopperRate === undefined
                      ? shopperData?.ratingDetails.shopperRating
                      : shopperRate
                  }
                />
              )}
            </RatingWrapper>
            {shopperData?.ratingDetails.shopperRating === null && isRated ? (
              <div>
                <Button size={"small"} onClick={handleEvaluationRateOnClick}>
                  <Trans>evaluation_now</Trans>
                </Button>
              </div>
            ) : (
              <></>
            )}
          </ShopperInformationRow>
        ) : (
          <Rate
            count={5}
            className="shopperDetails"
            onChange={(value: number) => getShopperRate(value)}
            value={
              shopperRate === undefined
                ? shopperData?.ratingDetails.shopperRating
                : shopperRate
            }
          />
        )
      ) : null}
    </>
  );
};

export default ShopperInfo;
