/** @format */

//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { Button } from 'common/Button';
import FormTemplate from 'common/FormTemplate';
import { Input } from 'common/Input';
import {
  Label,
  Form,
  SectionTitle,
  ButtonContainer,
  OptionValue,
  SupContainer,
  QuestionsHeadContainer,
  QuestionBodyContainer,
  SwitchLabel,
  OptionWrapper,
  QuestionTypeScale,
  MultiChoiceContainer,
  QuestionDropdownContainer,
  SelectWrapper,
  CheckboxWrapper,
  SliderWrapper,
  SelectLabel,
  WeightWrapper,
  WeightAndPointsText,
  InfoLabel,
  ChoiceOptionsWithPoints,
  DrawerBodyTitle,
  ExampleSpan,
  ScaleDetailsBox,
  OptionsAndPointsLabels,
  QuestionNumber,
} from '../style';
import { SwitchBtn } from 'common/Switch';
import { Checkbox } from 'common/Checkbox';
import { Select } from 'common/Select';
import DeleteIcon from 'assets/svg/icon-delete';
import {
  EvaluationFormProps,
  questionTypesProps,
  QuestionsProps,
} from 'infrastructure/interfaces/evaluationForm.interface';
import PlusBtnIcon from 'assets/svg/icon-add-question';
import { Error } from '../../style';
import { Select as AntDSelect } from 'antd';
import Creatable from 'react-select/creatable';
import { Slider } from 'common/Slider';
import InfoIcon from 'assets/svg/info-icon';
import Drawer from 'common/Drawer';
import styled from 'styled-components';
import { WrappedSelect } from '../wrapperSelect';
import Colors from 'infrastructure/utils/Colors';
const { Option } = AntDSelect;

const reorder = (
  list: Iterable<unknown> | ArrayLike<unknown>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const WeightForm: React.FC<EvaluationFormProps> = ({
  data,
  questionTypes,
  mockedArr,
  handleAddQuestionBlockOrMultiChoiceQuestion,
  handleOnChangeSwitchButton,
  handleInputChange,
  handleSelectOnChange,
  handleRemoveQuestionBlockOrMultiChoiceQuestionsItem,
  handleSubmit,
  errors,
  register,
  formState,
  loading,
  setEvaluationTemplates,
  onExtraImagesChange,
  onExtraVideosChange,
  handleQuestionCategoryChange,
  question_categories,
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [max, setMax] = useState(
    () => JSON.parse(localStorage.getItem('max')) || 0
  );

  const [, setMin] = useState(0);
  const [step, setStep] = useState(0);
  const [state, setState] = useState<any>(data?.questions);
  const [visible, setVisible] = useState(false);
  const [multiChoiceDrawerVisibilty, setMultiChoiceDrawerVisibilty] =
    useState(false);

  const [singleChoiceDrawerVisibilty, setSingleChoiceDrawerVisibilty] =
    useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState({ key: false });

  const showDrawer = () => {
    setVisible(true);
  };

  const showMultiChoiceDrawer = () => {
    setMultiChoiceDrawerVisibilty(true);
  };

  const showSingleChoiceDrawer = () => {
    setSingleChoiceDrawerVisibilty(true);
  };

  useEffect(() => {
    setState(data?.questions);
  }, [data?.questions, step]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(state, result.source.index, result.destination.index);

    setState(items);
    setEvaluationTemplates((prevState: any) => {
      return {
        ...prevState,
        questions: items,
      };
    });
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'rgba(48,48,48,0.04)',
      borderRadius: '10px',
      borderColor: 'rgba(48,48,48,0.04)',
      minHeight: '51px',
      fontFamily: 'Famtree',
      width: '100%',
    }),
  };

  const renderTotalPointsAndWeightPercentage = (item) => {
    if (
      item.question_type_id === 8 ||
      item.question_type_id === 2 ||
      item.question_type_id === 9
    )
      return (
        <WeightWrapper>
          <WeightAndPointsText>
            <Trans
              values={
                item.question_type_id === 2
                  ? {
                      count: item.total_points || 0,
                    }
                  : item.question_type_id === 9
                  ? {
                      count: item.total_points > 0 ? item.total_points : 0,
                    }
                  : item.question_type_id === 8
                  ? {
                      count: item.total_points || 0,
                    }
                  : 0
              }
            >
              total_points
            </Trans>
          </WeightAndPointsText>
          <WeightAndPointsText> {' - '}</WeightAndPointsText>
          <WeightAndPointsText>
            <Trans
              values={
                item.question_type_id === 2
                  ? {
                      count: item.weight_percentage || 0,
                    }
                  : item.question_type_id === 9
                  ? {
                      count: item.weight_percentage || 0,
                    }
                  : item.question_type_id === 8
                  ? {
                      count: item.weight_percentage || 0,
                    }
                  : 0
              }
            >
              weight
            </Trans>
          </WeightAndPointsText>
        </WeightWrapper>
      );
  };

  const buttonCircleStyle = {
    position: 'absolute',
    marginRight: '-65px',
    backgroundColor: Colors.red,
    color: 'white',
    borderColor: Colors.red,
    display: 'block',
    alignSelf: 'flex-start',
  };
  return (
    <Form onSubmit={handleSubmit} noValidate>
      <SectionTitle language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Trans>evaluation_items</Trans>
      </SectionTitle>
      {/* draggable implementation */}
      <div style={{ width: '100%' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {state?.length &&
                  state.map((value: QuestionsProps, index: number) => {
                    return (
                      <Draggable
                        key={value.id}
                        draggableId={value.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <SupContainer
                            key={value.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Button
                              shape={'circle'}
                              size={'large'}
                              name={`circle[${value.id}]`}
                              style={buttonCircleStyle}
                              onClick={() =>
                                handleAddQuestionBlockOrMultiChoiceQuestion(
                                  index
                                )
                              }
                            >
                              +
                            </Button>
                            <FormTemplate>
                              <QuestionsHeadContainer>
                                {renderTotalPointsAndWeightPercentage(value)}
                                <div>
                                  <QuestionNumber>{index + 1}</QuestionNumber>
                                </div>
                                <WeightWrapper>
                                  <SwitchLabel>
                                    <Trans>mandatory_switch_question</Trans>
                                  </SwitchLabel>

                                  <SwitchBtn
                                    style={
                                      value.required
                                        ? { backgroundColor: '#44c874' }
                                        : {
                                            backgroundColor:
                                              'rgba(52, 66, 71, 0.1)',
                                          }
                                    }
                                    onChange={(value) =>
                                      handleOnChangeSwitchButton(value, index)
                                    }
                                    defaultChecked={value.required}
                                  />
                                  {index === 0 ? (
                                    <></>
                                  ) : (
                                    <DeleteIcon
                                      onClick={() =>
                                        handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                                          value.id
                                        )
                                      }
                                    />
                                  )}
                                </WeightWrapper>
                              </QuestionsHeadContainer>
                              <QuestionBodyContainer>
                                {/* question  */}

                                <Input
                                  width='full_width'
                                  key={value.id}
                                  id='question'
                                  type='text'
                                  name={`question[${value.id}]`}
                                  placeholder={t('question_head')}
                                  dir='true'
                                  errorName={
                                    errors?.question &&
                                    errors.question[value.id]
                                  }
                                  onChange={(e: Event) =>
                                    handleInputChange(
                                      e,
                                      index,
                                      undefined,
                                      'form'
                                    )
                                  }
                                  ref={register({
                                    required: true,
                                    message: 'error message',
                                    maxLength: 120,
                                  })}
                                  defaultValue={value.question}
                                  className={`questionHead[${value.id}]`}
                                />
                                {errors.question &&
                                  errors.question[value.id]?.type && (
                                    <Error
                                      style={{
                                        direction:
                                          i18n.language === 'ar'
                                            ? 'rtl'
                                            : 'ltr',
                                      }}
                                    >
                                      <Trans>
                                        evaluation_template_question_required
                                      </Trans>
                                    </Error>
                                  )}
                                {errors.question &&
                                  errors.question[value.id]?.type && (
                                    <Error
                                      style={{
                                        direction:
                                          i18n.language === 'ar'
                                            ? 'rtl'
                                            : 'ltr',
                                      }}
                                    >
                                      <Trans values={{ count: 120 }}>
                                        maxlenght_error_message
                                      </Trans>
                                    </Error>
                                  )}
                                {/* end  question  */}
                                <QuestionDropdownContainer
                                  language={
                                    i18n.language === 'ar' ? 'ar' : 'en'
                                  }
                                >
                                  {/* create input tags  */}
                                  <SelectWrapper
                                    language={
                                      i18n.language === 'ar' ? 'ar' : 'en'
                                    }
                                  >
                                    <Label
                                      language={
                                        i18n.language === 'ar' ? 'ar' : 'en'
                                      }
                                    >
                                      <Trans>focus_of_question_optional</Trans>
                                    </Label>
                                    <WrappedSelect
                                      options={question_categories}
                                      className={`question-focus-select[${value.id}]`}
                                      name={`question-focus-select[${value.id}]`}
                                      handleOnChange={(item) => {
                                        setMenuIsOpen({ [value.id]: false });
                                        handleQuestionCategoryChange(
                                          item,
                                          index
                                        );
                                      }}
                                      styles={customStyles}
                                      value={value}
                                      menuIsOpen={menuIsOpen[value.id]}
                                      onFocus={() =>
                                        setMenuIsOpen({
                                          [value.id]: true,
                                        })
                                      }
                                      onBlur={() =>
                                        setMenuIsOpen({ [value.id]: false })
                                      }
                                      handleKeydown={() => {
                                        if (!menuIsOpen[value.id])
                                          setMenuIsOpen({ [value.id]: true });
                                      }}
                                    />
                                  </SelectWrapper>
                                  {/* question type select  */}
                                  <SelectWrapper
                                    language={
                                      i18n.language === 'ar' ? 'ar' : 'en'
                                    }
                                  >
                                    <Label
                                      language={
                                        i18n.language === 'ar' ? 'ar' : 'en'
                                      }
                                    >
                                      <Trans>select_default_value</Trans>
                                    </Label>
                                    <Select
                                      onChange={(value: number) =>
                                        handleSelectOnChange(value, index)
                                      }
                                      defaultValue={
                                        value.question_type_id ? (
                                          value.question_type_id
                                        ) : (
                                          <SelectLabel
                                            language={
                                              i18n.language === 'ar'
                                                ? 'ar'
                                                : 'en'
                                            }
                                          >
                                            <Trans>select_default_value</Trans>
                                          </SelectLabel>
                                        )
                                      }
                                      className='question-type-select'
                                    >
                                      {/* refactored   */}
                                      {questionTypes?.map(
                                        (
                                          type: questionTypesProps,
                                          i: number
                                        ) => {
                                          return mockedArr.map((value, i) => {
                                            if (value.name === type.name) {
                                              return (
                                                <Option
                                                  value={type.id}
                                                  title={type.name}
                                                  key={type.id}
                                                >
                                                  <OptionWrapper
                                                    className={type.name}
                                                  >
                                                    {value.icon}
                                                    <OptionValue>
                                                      {type.translation}
                                                    </OptionValue>
                                                  </OptionWrapper>
                                                </Option>
                                              );
                                            }
                                          });
                                        }
                                      )}
                                    </Select>
                                  </SelectWrapper>

                                  {/* end question type select  */}
                                </QuestionDropdownContainer>
                              </QuestionBodyContainer>

                              {/* single , multi choice  and scale questions */}
                              {value.question_type_id === 8 ||
                              value.question_type_id === 9 ? (
                                <>
                                  <OptionsAndPointsLabels
                                    language={
                                      i18n.language === 'ar' ? 'ar' : 'en'
                                    }
                                  >
                                    <Label
                                      language={
                                        i18n.language === 'ar' ? 'ar' : 'en'
                                      }
                                    >
                                      <Trans>options</Trans>
                                    </Label>
                                    <InfoLabel>
                                      <InfoIcon
                                        onClick={
                                          value.question_type_id === 8
                                            ? showMultiChoiceDrawer
                                            : showSingleChoiceDrawer
                                        }
                                      />
                                      <Trans>points</Trans>
                                    </InfoLabel>
                                    {value.question_type_id === 8 ? (
                                      <Drawer
                                        title='نظام النقاط'
                                        setVisible={
                                          setMultiChoiceDrawerVisibilty
                                        }
                                        visible={multiChoiceDrawerVisibilty}
                                      >
                                        <DrawerBodyTitle>
                                          <Trans>
                                            drawer_multi_choice_content
                                          </Trans>
                                        </DrawerBodyTitle>
                                        <ExampleSpan
                                          language={
                                            i18n.language === 'ar' ? 'ar' : 'en'
                                          }
                                        >
                                          <Trans>example</Trans>
                                        </ExampleSpan>
                                        <ScaleDetailsBox>
                                          <ExampleSpan
                                            language={
                                              i18n.language === 'ar'
                                                ? 'ar'
                                                : 'en'
                                            }
                                          >
                                            حدد مايدل على نظافة الفرع
                                          </ExampleSpan>
                                          <Label
                                            style={{ fontSize: '12px' }}
                                            language={
                                              i18n.language === 'ar'
                                                ? 'ar'
                                                : 'en'
                                            }
                                          >
                                            تم توفير المناديل – نقاط الإجابة =
                                            20
                                          </Label>
                                          <Label
                                            style={{ fontSize: '12px' }}
                                            language={
                                              i18n.language === 'ar'
                                                ? 'ar'
                                                : 'en'
                                            }
                                          >
                                            تم توفير المعقمات – نقاط الإجابة =
                                            20
                                          </Label>
                                          <Label
                                            style={{ fontSize: '12px' }}
                                            language={
                                              i18n.language === 'ar'
                                                ? 'ar'
                                                : 'en'
                                            }
                                          >
                                            الطاولات نظيفة – نقاط الإجابة = 20
                                          </Label>
                                          <Label
                                            style={{ fontSize: '12px' }}
                                            language={
                                              i18n.language === 'ar'
                                                ? 'ar'
                                                : 'en'
                                            }
                                          >
                                            <Trans>
                                              all_answers_not_applicable
                                            </Trans>
                                            – نقاط الإجابة = 0
                                          </Label>
                                        </ScaleDetailsBox>
                                      </Drawer>
                                    ) : (
                                      <Drawer
                                        title='نظام النقاط'
                                        setVisible={
                                          setSingleChoiceDrawerVisibilty
                                        }
                                        visible={singleChoiceDrawerVisibilty}
                                      >
                                        <DrawerBodyTitle>
                                          <Trans>
                                            drawer_single_choice_content
                                          </Trans>
                                        </DrawerBodyTitle>
                                        <ExampleSpan>
                                          <Trans>example</Trans>
                                        </ExampleSpan>
                                        <ScaleDetailsBox>
                                          <ExampleSpan>
                                            هل أبواب المدخل نظيفة؟
                                          </ExampleSpan>
                                          <Label
                                            style={{ fontSize: '12px' }}
                                            language={
                                              i18n.language === 'ar'
                                                ? 'ar'
                                                : 'en'
                                            }
                                          >
                                            نعم – نقاط الإجابة = 50
                                          </Label>
                                          <Label
                                            style={{ fontSize: '12px' }}
                                            language={
                                              i18n.language === 'ar'
                                                ? 'ar'
                                                : 'en'
                                            }
                                          >
                                            لا – نقاط الإجابة = 0
                                          </Label>
                                        </ScaleDetailsBox>
                                      </Drawer>
                                    )}
                                  </OptionsAndPointsLabels>

                                  <div style={{ width: '100%' }}>
                                    {value.options?.length &&
                                      value.options?.map(
                                        (item: any, i: number) => {
                                          return (
                                            <div key={item.id}>
                                              <MultiChoiceContainer>
                                                {i === 4 ? (
                                                  <DeleteIcon
                                                    onClick={() => {
                                                      handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                                                        index,
                                                        item.id,
                                                        'multiChoice'
                                                      );
                                                    }}
                                                    style={{
                                                      position: 'absolute',
                                                      top: '50%',
                                                      left: '30%',
                                                      transform:
                                                        'translateY(-50%)',
                                                    }}
                                                  />
                                                ) : i === 0 ? (
                                                  <PlusBtnIcon
                                                    id='plus_button'
                                                    onClick={() => {
                                                      handleAddQuestionBlockOrMultiChoiceQuestion(
                                                        index,
                                                        i,
                                                        'multiChoice'
                                                      );
                                                    }}
                                                    style={{
                                                      position: 'absolute',
                                                      top: '50%',
                                                      left: '30%',
                                                      transform:
                                                        'translateY(-50%)',
                                                    }}
                                                  />
                                                ) : (
                                                  <>
                                                    <PlusBtnIcon
                                                      onClick={() => {
                                                        handleAddQuestionBlockOrMultiChoiceQuestion(
                                                          index,
                                                          i,
                                                          'multiChoice'
                                                        );
                                                      }}
                                                      style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '34%',
                                                        transform:
                                                          'translateY(-50%)',
                                                      }}
                                                    />
                                                    <DeleteIcon
                                                      onClick={() => {
                                                        handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                                                          index,
                                                          item.id,
                                                          'multiChoice'
                                                        );
                                                      }}
                                                      style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '30%',
                                                        transform:
                                                          'translateY(-50%)',
                                                      }}
                                                    />
                                                  </>
                                                )}

                                                <ChoiceOptionsWithPoints>
                                                  <Input
                                                    id='options'
                                                    placeholder={t(
                                                      'option_head'
                                                    )}
                                                    name={`options[${i}]`}
                                                    style={{
                                                      width: '73%',
                                                      paddingLeft: '110px',
                                                    }}
                                                    type='text'
                                                    dir='true'
                                                    errorName={
                                                      errors?.options &&
                                                      errors.options[i]
                                                    }
                                                    onChange={(e: any) =>
                                                      handleInputChange(
                                                        e,
                                                        index,
                                                        i,
                                                        'form'
                                                      )
                                                    }
                                                    maxLength={200}
                                                    ref={register({
                                                      required: true,
                                                      maxLength: 200,
                                                    })}
                                                    defaultValue={
                                                      item.option ===
                                                      'جميع الاجابات لا تنطبق'
                                                        ? ''
                                                        : item.option
                                                    }
                                                  />
                                                  <Input
                                                    id='points'
                                                    placeholder={t('points')}
                                                    width='optionsWidth'
                                                    name={`points[${i + 1}]`}
                                                    type='number'
                                                    maxLength='3'
                                                    dir='true'
                                                    min='0'
                                                    errorName={
                                                      errors?.points &&
                                                      errors.points[i + 1]
                                                    }
                                                    onChange={(e: any) => {
                                                      handleInputChange(
                                                        e,
                                                        index,
                                                        i,
                                                        'form'
                                                      );
                                                    }}
                                                    ref={register({
                                                      required: true,
                                                      message: 'error message',
                                                      min: 0,
                                                    })}
                                                    onWheel={(e: any) =>
                                                      e.target.blur()
                                                    }
                                                    defaultValue={item.points}
                                                  />
                                                </ChoiceOptionsWithPoints>
                                              </MultiChoiceContainer>
                                              {errors.options &&
                                                errors?.options[i]?.type && (
                                                  <Error
                                                    style={{
                                                      direction:
                                                        i18n.language === 'ar'
                                                          ? 'rtl'
                                                          : 'ltr',
                                                    }}
                                                  >
                                                    <Trans>
                                                      evaluation_template_options_required
                                                    </Trans>
                                                  </Error>
                                                )}
                                              {errors.options &&
                                                errors.options[i]?.type && (
                                                  <Error
                                                    style={{
                                                      direction:
                                                        i18n.language === 'ar'
                                                          ? 'rtl'
                                                          : 'ltr',
                                                    }}
                                                  >
                                                    <Trans
                                                      values={{ count: 200 }}
                                                    >
                                                      maxlenght_error_message
                                                    </Trans>
                                                  </Error>
                                                )}
                                              {errors.points &&
                                                errors.points[i]?.type && (
                                                  <Error
                                                    style={{
                                                      direction:
                                                        i18n.language === 'ar'
                                                          ? 'rtl'
                                                          : 'ltr',
                                                    }}
                                                  >
                                                    <Trans>
                                                      points_required
                                                    </Trans>
                                                  </Error>
                                                )}
                                            </div>
                                          );
                                        }
                                      )}

                                    {value.question_type_id === 8 && (
                                      <ChoiceOptionsWithPoints>
                                        <Input
                                          id='questions-tip'
                                          name='questions-tip'
                                          type='text'
                                          dir='true'
                                          placeholder={t(
                                            'all_answers_not_applicable'
                                          )}
                                          disabled
                                          style={{
                                            cursor: 'not-allowed',
                                            width: '73%',
                                          }}
                                        />
                                        <Input
                                          id='static_choice_points'
                                          placeholder={0}
                                          width='optionsWidth'
                                          type='number'
                                          name={`static_choice_points[${value.id}]`}
                                          dir='true'
                                          disabled
                                          onWheel={(e: any) => e.target.blur()}
                                          style={{
                                            cursor: 'not-allowed',
                                          }}
                                        />
                                      </ChoiceOptionsWithPoints>
                                    )}

                                    {errors.static_choice_points &&
                                      errors.static_choice_points[value.id]
                                        ?.types?.required && (
                                        <Error>
                                          <Trans>points_required</Trans>
                                        </Error>
                                      )}
                                  </div>
                                </>
                              ) : value.question_type_id === 2 ? (
                                <SliderWrapper>
                                  <Slider value={max && 20} min={1} max={20} />
                                  <QuestionTypeScale>
                                    <Label>
                                      <Trans>slider_range</Trans>
                                    </Label>

                                    <Input
                                      id='min'
                                      name={`min[${value.id}]`}
                                      type='number'
                                      dir='true'
                                      maxLength='3'
                                      errorName={
                                        errors?.min && errors.min[value.id]
                                      }
                                      min='1'
                                      width={'optionsWidth'}
                                      onChange={(e2: any) => {
                                        setMin(parseInt(e2?.target.value));
                                        handleInputChange(
                                          e2,
                                          index,
                                          undefined,
                                          'form'
                                        );
                                      }}
                                      ref={register({
                                        required: true,
                                        min: 1,
                                      })}
                                      placeholder={t('min')}
                                      onWheel={(e: any) => e.target.blur()}
                                      defaultValue={
                                        value.scale?.min === 0
                                          ? ''
                                          : value.scale?.min
                                      }
                                    />

                                    <Input
                                      id='max'
                                      name={`max[${value.id}]`}
                                      type='number'
                                      dir='true'
                                      maxLength='3'
                                      min='1'
                                      width={'optionsWidth'}
                                      errorName={
                                        errors?.max && errors.max[value.id]
                                      }
                                      onChange={(e3: any) => {
                                        setMax(parseInt(e3?.target.value));
                                        localStorage.setItem(
                                          'max',
                                          parseInt(e3?.target.value)
                                        );
                                        handleInputChange(
                                          e3,
                                          index,
                                          undefined,
                                          'form'
                                        );
                                      }}
                                      placeholder={t('max')}
                                      ref={register({
                                        required: true,
                                        min: 1,
                                      })}
                                      onWheel={(e: any) => e.target.blur()}
                                      defaultValue={
                                        value.scale?.max === 0
                                          ? ''
                                          : value.scale?.max
                                      }
                                    />

                                    <Input
                                      id='step'
                                      name={`step[${value.id}]`}
                                      min='1'
                                      type='number'
                                      dir='true'
                                      width={'optionsWidth'}
                                      errorName={
                                        errors?.step && errors.step[value.id]
                                      }
                                      onChange={(e4: Event) => {
                                        setStep(parseInt(e4?.target.value));
                                        handleInputChange(
                                          e4,
                                          index,
                                          undefined,
                                          'form'
                                        );
                                      }}
                                      maxLength='3'
                                      ref={register({
                                        required: true,
                                        min: 1,
                                        validate: (value: string) => {
                                          return parseInt(value) < max;
                                        },
                                      })}
                                      placeholder={t('step')}
                                      onWheel={(e: any) => e.target.blur()}
                                      defaultValue={
                                        value.scale?.step === 0
                                          ? ''
                                          : value.scale?.step
                                      }
                                    />
                                  </QuestionTypeScale>

                                  <QuestionTypeScale>
                                    <InfoLabel>
                                      <InfoIcon onClick={showDrawer} />
                                      <Trans>points</Trans>
                                    </InfoLabel>
                                    <Input
                                      id='min_points'
                                      name={`min_points[${value.id}]`}
                                      type='number'
                                      maxLength='3'
                                      dir='true'
                                      min='0'
                                      width={'optionsWidth'}
                                      onChange={(e5: any) => {
                                        handleInputChange(
                                          e5,
                                          index,
                                          undefined,
                                          'form'
                                        );
                                      }}
                                      placeholder={t('min_points')}
                                      errorName={
                                        errors?.min_points &&
                                        errors.min_points[value.id]
                                      }
                                      ref={register({
                                        required: true,
                                        min: 0,
                                      })}
                                      onWheel={(e: any) => e.target.blur()}
                                      defaultValue={
                                        value.scale?.min_points === 0
                                          ? ''
                                          : value.scale?.min_points
                                      }
                                    />

                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <Input
                                      id='max_points'
                                      name={`max_points[${value.id}]`}
                                      type='number'
                                      dir='true'
                                      maxLength='3'
                                      min='0'
                                      width={'optionsWidth'}
                                      onChange={(e6: any) => {
                                        handleInputChange(
                                          e6,
                                          index,
                                          undefined,
                                          'form'
                                        );
                                      }}
                                      placeholder={t('max_points')}
                                      errorName={
                                        errors?.max_points &&
                                        errors.max_points[value.id]
                                      }
                                      ref={register({
                                        required: true,
                                        min: 0,
                                      })}
                                      onWheel={(e: any) => e.target.blur()}
                                      defaultValue={
                                        value.scale?.max_points === 0
                                          ? ''
                                          : value.scale?.max_points
                                      }
                                    />

                                    <Drawer
                                      title={`${
                                        i18n.language === 'ar'
                                          ? 'نظام النقاط'
                                          : 'Points system'
                                      }`}
                                      visible={visible}
                                      setVisible={setVisible}
                                    >
                                      <DrawerBodyTitle
                                        language={
                                          i18n.language === 'ar' ? 'ar' : 'en'
                                        }
                                      >
                                        <Trans>drawer_scale_content</Trans>
                                      </DrawerBodyTitle>
                                      <ExampleSpan
                                        language={
                                          i18n.language === 'ar' ? 'ar' : 'en'
                                        }
                                      >
                                        <Trans>example</Trans>
                                      </ExampleSpan>
                                      <ScaleDetailsBox
                                        language={
                                          i18n.language === 'ar' ? 'ar' : 'en'
                                        }
                                      >
                                        <ExampleSpan>
                                          <Trans>how-long-did-it-take</Trans>
                                        </ExampleSpan>
                                        <div
                                          style={{
                                            width: '109%',
                                            margin: '12px 25px 20px -48px',
                                          }}
                                        >
                                          <Slider />
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection:
                                              i18n.language === 'ar'
                                                ? 'row'
                                                : 'row-reverse',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <Label style={{ fontSize: '12px' }}>
                                            10
                                          </Label>
                                          <Label style={{ fontSize: '12px' }}>
                                            <Trans>scale_points</Trans> : 0
                                          </Label>
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection:
                                              i18n.language === 'ar'
                                                ? 'row'
                                                : 'row-reverse',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <Label style={{ fontSize: '12px' }}>
                                            100
                                          </Label>
                                          <Label style={{ fontSize: '12px' }}>
                                            <Trans>points</Trans>: 0
                                          </Label>
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection:
                                              i18n.language === 'ar'
                                                ? 'row'
                                                : 'row-reverse',
                                            justifyContent: 'space-between',
                                          }}
                                        >
                                          <Label style={{ fontSize: '12px' }}>
                                            <Trans>
                                              how-much-number-increases
                                            </Trans>
                                          </Label>
                                          <Label style={{ fontSize: '12px' }}>
                                            <Trans>step</Trans>: 1
                                          </Label>
                                        </div>
                                      </ScaleDetailsBox>
                                    </Drawer>
                                  </QuestionTypeScale>
                                </SliderWrapper>
                              ) : null}

                              {errors.min &&
                                errors?.min[value.id]?.types?.required && (
                                  <Error>
                                    <Trans>required_error_message</Trans>
                                  </Error>
                                )}
                              {errors.min &&
                                errors?.min[value.id]?.types?.min && (
                                  <Error>
                                    <Trans>zero_error_message</Trans>
                                  </Error>
                                )}
                              {errors.step &&
                                errors?.step[value.id]?.types?.validate && (
                                  <Error>
                                    <Trans>min_error_message</Trans>
                                  </Error>
                                )}
                              {/* end  single , multi choice  and scale questions */}

                              {value.question_type_id === 1 ||
                              value.question_type_id === 8 ||
                              value.question_type_id === 9 ? (
                                <>
                                  <CheckboxWrapper
                                    language={
                                      i18n.language === 'ar' ? 'ar' : 'en'
                                    }
                                  >
                                    <Checkbox
                                      onChange={(event) =>
                                        onExtraImagesChange(event, index)
                                      }
                                      checked={value.extra_images}
                                    />
                                    <span className='checkbox-value'>
                                      <Trans>want_extra_images</Trans>
                                    </span>
                                  </CheckboxWrapper>
                                  <CheckboxWrapper>
                                    <Checkbox
                                      onChange={(event) =>
                                        onExtraVideosChange(event, index)
                                      }
                                      checked={value.extra_videos}
                                    />
                                    <span className='checkbox-value'>
                                      <Trans>want_extra_videos</Trans>
                                    </span>
                                  </CheckboxWrapper>
                                </>
                              ) : (
                                <></>
                              )}
                            </FormTemplate>
                          </SupContainer>
                        )}
                      </Draggable>
                    );
                  })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* end draggable implementation */}
      </div>
      <ButtonContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Button
          disabled={formState.isSubmitting}
          size={'large'}
          onClick={handleSubmit}
          loading={loading}
          className='wieghtForm-save-button'
        >
          <Trans>save</Trans>
        </Button>
      </ButtonContainer>
    </Form>
  );
};
export default React.memo(WeightForm);

export const StyledCreatable = styled(Creatable)`
  width: 100%;
`;
