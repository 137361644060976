import * as React from "react";

function IconNoForms(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={97}
      height={96}
      viewBox="0 0 97 96"
    >
      <g
        stroke="#344247"
        strokeWidth={2.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={0.38}
      >
        <path d="M13.206 1.412h70.588c6.237 0 11.294 5.057 11.294 11.294v70.588c0 6.237-5.057 11.294-11.294 11.294H13.206c-6.237 0-11.294-5.057-11.294-11.294V12.706c0-6.237 5.057-11.294 11.294-11.294z" />
        <path d="M44.265 18.353h33.882v14.118H44.265zM27.324 24h-8.471m25.412 22.588h33.882v14.118H44.265zm33.882 31.059H32.971m-5.647-25.412h-8.471" />
      </g>
    </svg>
  );
}

export default IconNoForms;
