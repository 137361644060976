/** @format */

import * as React from "react";
import styled from "styled-components";

function PlusBtnIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    //@ts-ignore
    <SVG
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={21}
      viewBox="0 0 21 21"
    >
      <g
        transform="translate(1 1)"
        stroke="#344247"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity={0.54}
      >
        <circle cx={9.5} cy={9.5} r={9.25} />
        <path d="M9.5 5.25v8.5M5.25 9.5h8.5" />
      </g>
    </SVG>
  );
}

export default PlusBtnIcon;

export const SVG = styled.svg`
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translateY(-50%);

  @media (max-width: 800px) {
    left: 33%;
  }
`;
