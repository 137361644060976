import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";

export const Pargraph = styled.div`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 18px;
  font-weight: normal;
  margin: 18px 0 34px;
`;

export const HeaderSubtitle = styled.div`
  color: #344247;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 30px 0;
  text-align: ${(props: { language?: string }) =>
    props.language === "ar" ? "right" : "left"};
`;

export const Span = styled.span`
  color: white;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
`;

export const InputHint = styled.p`
  color: #a8a4a4;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 2px;
`;

export const ColumnCell = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  color: ${Colors.charcoal_grey};
`;

export const ColumnCellName = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  cursor: pointer;
  color: ${Colors.charcoal_grey};
`;

export const MoreBtn = styled.div`
  font-family: Famtree;
  font-size: 36px;
  line-height: 0;
  margin-top: -10px;
  letter-spacing: -2px;
  cursor: pointer;
`;

export const Title = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 29px 0px 15px 0px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 70%;
  border: 1px solid #f1e9e9;
  padding: 2.5% 2%;
  border-radius: 15px;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const Label = styled.label`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  margin-top: 30px;
`;

export const InputWrapper = styled.div``;

export const Hr = styled.hr`
  background-color: rgb(221, 221, 221);
  height: 0.7px;
  border: 0;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const Error = styled.p`
  font-family: Famtree;
  color: ${Colors.darkish_pink};
  margin: 0;
  font-size: 13px;
  flex: 0;
  margin-bottom: 20px;
`;

export const ModalHeader = styled.p`
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: row-reverse;
  @media (max-width: 767px) {
    width: 100%;
    button {
      width: 25%;
    }
  }
`;
