/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import Nav from "../Nav/Nav";
import ResponsiveHeader from "common/ResponsiveHeader";
import {
  ARContainer,
  ENContainer,
  ENSubContainer,
  ARSubContainer,
} from "./style";
import { useMediaQuery } from "react-responsive";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = (props: LayoutProps) => {
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return i18n.language === "ar" ? (
    <ARContainer>
      {isMobile ? (
        <ARSubContainer>
          <ResponsiveHeader />
          {props.children}
        </ARSubContainer>
      ) : (
        <>
          <Nav />
          <ARSubContainer>{props.children}</ARSubContainer>
        </>
      )}
    </ARContainer>
  ) : (
    <ENContainer>
      {isMobile ? (
        <ENSubContainer>
          <ResponsiveHeader />
          {props.children}
        </ENSubContainer>
      ) : (
        <>
          <Nav />
          <ENSubContainer>{props.children}</ENSubContainer>
        </>
      )}
    </ENContainer>
  );
};

export default Layout;
