/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  RightContainer,
  Item,
  ItemLink,
  Li,
  List,
  Arrow,
  Container,
  Span,
  StyledAvatar as AntDAvatar,
  IconWithLabel,
} from "./style";
import { useLocation } from "react-router-dom";
import { Hr } from "../../pages/EvaluationTemplate/EvaluationDetails/style";
import HomeIcon from "../../assets/svg/icon-home";
import BackIcon from "../../assets/svg/icon-back";
import FormsIcon from "../../assets/svg/icon-forms";
import MapIcon from "../../assets/svg/icon-map";
import JobsIcon from "../../assets/svg/icon-jobs";
import RatingIcon from "../../assets/svg/icon-rating";
import HelpIcon from "../../assets/svg/icon-help";
import InvoicesIcon from "../../assets/svg/icon-invoice";
import { UserOutlined } from "@ant-design/icons";
import WalletIcon from "assets/svg/wallet";
import LanguageIcon from "assets/svg/icon-languages";
import { Button } from "antd";
import ArrowNavLeft from "assets/svg/arrow-nav-left";
import Logout from "components/Logout";
import ManagersIcon from "assets/svg/managers-icon";
import ContactUsIcon from "assets/svg/contact-icon";
import TermsIcon from "assets/svg/terms-icon";
import storage from "infrastructure/utils/storage";

const Nav = () => {
  let location = useLocation();
  const { i18n } = useTranslation();

  function changeLanguage(lng: string) {
    i18n.changeLanguage(lng);
  }

  const userDataFromStorage: string | null | any = storage.getItem("user_data");

  const parsedUserData = userDataFromStorage && JSON.parse(userDataFromStorage);
  const userImage = storage.getItem("user_image");

  return (
    <RightContainer language={i18n.language === "ar" ? "ar" : "en"}>
      <Container>
        <List>
          <ItemLink
            to={"/home"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/home"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <HomeIcon />
                </Item>
                <Span>
                  <Trans>home</Trans>
                </Span>
              </IconWithLabel>
            </Li>
          </ItemLink>

          <ItemLink
            to={"/branches"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/branches"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <MapIcon />
                </Item>
                <Span>
                  <Trans>branches</Trans>
                </Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>

          <ItemLink
            to={"/evaluation-templates"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/evaluation-templates"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <FormsIcon />
                </Item>
                <Span>
                  <Trans>evaluation_templates</Trans>
                </Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>

          <ItemLink
            id="jobs"
            to={"/jobs"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/jobs"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <JobsIcon />
                </Item>
                <Span>
                  <Trans>jobs</Trans>
                </Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>

          <ItemLink
            to={"/ratings"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/ratings"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <RatingIcon />
                </Item>
                <Span>
                  <Trans>evaluations</Trans>
                </Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>

          <ItemLink
            to={"/invoices"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/invoices"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <InvoicesIcon />
                </Item>
                <Span>
                  <Trans>balance_consumptions</Trans>
                </Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>

          <ItemLink
            to={"/wallet"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/wallet"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <WalletIcon />
                </Item>
                <Span>
                  <Trans>wallet</Trans>
                </Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>

          <ItemLink
            to={"/help"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/help"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <HelpIcon />
                </Item>
                <Span>
                  <Trans>help</Trans>
                </Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>

          <ItemLink
            to={"/termsAndConditions"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/termsAndConditions"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <TermsIcon />
                </Item>
                <Span>
                  <Trans>terms_and_conditions</Trans>
                </Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>

          <ItemLink
            to={"/contactUs"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/contactUs"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <ContactUsIcon />
                </Item>
                <Span>
                  <Trans>contact_us</Trans>
                </Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>

          <ItemLink
            to={"/managers"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/managers"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                <Item>
                  <ManagersIcon />
                </Item>
                <Span>
                  <Trans>managers</Trans>
                </Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>

          <Hr />
          <ItemLink
            to={"/profile"}
            className={({ isActive, isPending }) =>
              isPending ? "pending" : isActive ? "active" : ""
            }
            style={({ isActive }) => {
              return {
                fontWeight: isActive ? "bold" : "",
                color: isActive ? "#44c874" : "",
              };
            }}
          >
            <Li
              style={{
                backgroundColor:
                  location.pathname === "/profile"
                    ? "rgba(68, 200, 116, 0.12)"
                    : "",
              }}
            >
              <IconWithLabel>
                {userImage ? (
                  <Item>
                    <AntDAvatar src={userImage} size={"small"} shape="circle" />
                  </Item>
                ) : (
                  <Item>
                    <UserOutlined />
                  </Item>
                )}

                <Span>{parsedUserData?.name}</Span>
              </IconWithLabel>
              <Arrow>
                {i18n.language === "ar" ? <BackIcon /> : <ArrowNavLeft />}
              </Arrow>
            </Li>
          </ItemLink>
          <Hr />

          {i18n.language === "en" ? (
            <IconWithLabel
              style={{
                marginRight: "26px",
                justifyContent: "flex-start",
                fontFamily: "Famtree",
                fontSize: "14px",
                color: "#344247",
              }}
            >
              <LanguageIcon />
              <Button onClick={() => changeLanguage("ar")} type="text">
                العربية
              </Button>
            </IconWithLabel>
          ) : (
            <IconWithLabel
              style={{
                marginRight: "10px",
                justifyContent: "flex-start",
                fontFamily: "Famtree",
                fontSize: "14px",
                color: "#344247",
              }}
            >
              <LanguageIcon />
              <Button onClick={() => changeLanguage("en")} type="text">
                English
              </Button>
            </IconWithLabel>
          )}

          <Hr />
          <Logout display={true} />
          <Hr />
        </List>
      </Container>
    </RightContainer>
  );
};

export default Nav;
