import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "common/Button";
import { Input } from "common/Input";
import { Error, Label } from "../../style";
import {
  ButtonContainer,
  Form,
  InputsContainer,
  TitleContainer,
  Title,
} from "../style";
import { FormProps } from "infrastructure/interfaces/forms.interface";
import VisibleIcon from "assets/svg/icon-visible";
import InvisibleIcon from "assets/svg/icon-invisible";

const { useRef } = React;

export const ResetPasswordForm: React.FC<FormProps> = ({
  errors,
  register,
  formState,
  onSubmit,
  watch,
  loading,
  passwordShown,
  togglePassowrdVisiblity,
}): JSX.Element => {
  const password = useRef({});

  password.current = watch("password", "");
  const { i18n } = useTranslation();

  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
        marginTop: "120px",
      }}
    >
      {" "}
      <Form onSubmit={onSubmit} noValidate>
        <TitleContainer>
          <Title>
            <Trans>reset_password</Trans>
          </Title>
        </TitleContainer>
        <InputsContainer language={i18n.language === "ar" ? "ar" : "en"}>
          <Label>
            <Trans>email</Trans>
          </Label>
          <Input
            name="email"
            type="text"
            login
            ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
            width="login"
            errorName={errors.email}
          />
          {errors?.email?.types?.required && (
            <Error>
              <Trans>email_required</Trans>
            </Error>
          )}
          {errors?.email?.types?.pattern && (
            <Error>
              <Trans>patternـisـnotـcorrect</Trans>
            </Error>
          )}
        </InputsContainer>

        <InputsContainer language={i18n.language === "ar" ? "ar" : "en"}>
          <Label>
            <Trans>password</Trans>
          </Label>
          <Input
            name="password"
            type={passwordShown ? "text" : "password"}
            login
            ref={register({ required: true, minLength: 8 })}
            width="login"
            errorName={errors.password}
          />

          {errors?.password?.types?.required && (
            <Error>
              <Trans>password_required</Trans>
            </Error>
          )}
          {errors?.password?.types?.minLength && (
            <Error>
              <Trans>passwordـminLength_8</Trans>
            </Error>
          )}

          {passwordShown ? (
            <VisibleIcon onClick={togglePassowrdVisiblity} />
          ) : (
            <InvisibleIcon onClick={togglePassowrdVisiblity} />
          )}
        </InputsContainer>

        <InputsContainer language={i18n.language === "ar" ? "ar" : "en"}>
          <Label>
            <Trans>confirm_password</Trans>
          </Label>
          <Input
            name="confirm_password"
            type={passwordShown ? "text" : "password"}
            errorName={errors.confirm_password}
            ref={register({
              required: true,
              validate: (value: string) =>
                value === password.current || "The passwords do not match",
            })}
            width="login"
          />
          {errors?.confirm_password?.types?.required && (
            <Error>
              <Trans>password_required</Trans>
            </Error>
          )}
          {errors.confirm_password && (
            <Error>
              <Trans>the_passwords_do_not_match</Trans>
            </Error>
          )}

          {passwordShown ? (
            <VisibleIcon onClick={togglePassowrdVisiblity} />
          ) : (
            <InvisibleIcon onClick={togglePassowrdVisiblity} />
          )}
        </InputsContainer>
        <InputsContainer language={i18n.language === "ar" ? "ar" : "en"}>
          <ButtonContainer>
            <Button
              size={"large"}
              disabled={formState.isSubmitting}
              onClick={onSubmit}
              loading={loading}
            >
              <Trans>update</Trans>
            </Button>
          </ButtonContainer>
        </InputsContainer>
      </Form>
    </div>
  );
};
