/** @format */

import React from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { axiosApiInstance } from "infrastructure/utils/api";
import { LogoutWrapper } from "./style";
import storage from "infrastructure/utils/storage";

type Props = {
  display?: boolean;
};

const Logout = ({ display }: Props) => {
  const navigate = useNavigate();

  return (
    <LogoutWrapper
      onClick={() => {
        axiosApiInstance.post("api/company/logout").then(() => {
          storage.clearItem("access_token");
          storage.clearItem("user_data");
          storage.clearItem("user_image");

          navigate("/");
        });
      }}
      display={display}
    >
      <Trans>logout</Trans>
    </LogoutWrapper>
  );
};

export default Logout;
