/** @format */

import * as React from "react";

const StarIconResponsive = () => (
  <svg width={33} height={33} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M13.094 24.806c3.22 0 5.852-3.164 8-5.236 2.255-2.173 8.26-9.391 4.27-12.717C21.371 3.527 20.97 0 14.56 0 10.194 0 0 4.11 0 8.103c0 3.578 3.263 5.5 3.736 7.689.77 3.57.492 4.752 2.53 6.895 2.177 2.29 3.42 2.12 6.828 2.12z"
        fill="#E8F8EE"
      />
      <g
        stroke="#292D32"
        strokeDasharray="0,0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.016}
      >
        <path d="M29.315 16.376c1.609-1.609 1.087-3.231-1.155-3.616l-3.506-.578c-.591-.096-1.292-.618-1.554-1.155L21.162 7.15c-1.045-2.104-2.764-2.104-3.81 0l-1.938 3.877c-.261.537-.963 1.045-1.554 1.155l-3.506.578c-2.241.371-2.764 1.994-1.155 3.616l2.736 2.736c.454.454.715 1.348.564 1.994l-.784 3.383c-.618 2.667.812 3.712 3.163 2.31l3.286-1.953c.591-.357 1.581-.357 2.173 0l3.286 1.953c2.351 1.388 3.781.357 3.162-2.31l-.783-3.383M11 6.875H2.75m4.125 19.25H2.75M4.125 16.5H2.75" />
      </g>
    </g>
  </svg>
);

export default StarIconResponsive;
