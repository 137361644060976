/** @format */

import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { Button } from "common/Button";
import FormTemplate from "common/FormTemplate";
import { Input } from "common/Input";
import {
  Label,
  Form,
  SectionTitle,
  ButtonContainer,
  OptionValue,
  SupContainer,
  QuestionsHeadContainer,
  QuestionBodyContainer,
  SwitchLabel,
  OptionWrapper,
  QuestionTypeScale,
  MultiChoiceContainer,
  QuestionDropdownContainer,
  SelectWrapper,
  CheckboxWrapper,
  SliderWrapper,
  SelectLabel,
  WeightWrapper,
  QuestionNumber,
} from "../style";
import { SwitchBtn } from "common/Switch";
import { Checkbox } from "common/Checkbox";
import { Select } from "common/Select";
import DeleteIcon from "assets/svg/icon-delete";
import {
  EvaluationFormProps,
  questionTypesProps,
  QuestionsProps,
} from "infrastructure/interfaces/evaluationForm.interface";
import PlusBtnIcon from "assets/svg/icon-add-question";
import { Error } from "../../style";
import { Select as AntDSelect } from "antd";
import { WrappedSelect } from "../wrapperSelect";
import { Slider } from "common/Slider";
import Colors from "infrastructure/utils/Colors";

const { Option } = AntDSelect;
const reorder = (
  list: Iterable<unknown> | ArrayLike<unknown>,
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "rgba(48,48,48,0.04)",
    borderRadius: "10px",
    borderColor: "rgba(48,48,48,0.04)",
    minHeight: "51px",
    fontFamily: "Famtree",
  }),
};

const CreateEvaluationTemplateForm: React.FC<EvaluationFormProps> = ({
  data,
  questionTypes,
  mockedArr,
  handleAddQuestionBlockOrMultiChoiceQuestion,
  handleOnChangeSwitchButton,
  handleInputChange,
  handleSelectOnChange,
  handleRemoveQuestionBlockOrMultiChoiceQuestionsItem,
  handleSubmit,
  errors,
  register,
  formState,
  loading,
  setEvaluationTemplates,
  onExtraImagesChange,
  onExtraVideosChange,
  handleQuestionCategoryChange,
  question_categories,
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const [max, setMax] = useState(0);
  const [, setMin] = useState(0);
  const [state, setState] = useState<any>(data?.questions);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    setState(data?.questions);
  }, [data?.questions]);

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(state, result.source.index, result.destination.index);

    setState(items);
    setEvaluationTemplates((prevState: any) => {
      return {
        ...prevState,
        questions: items,
      };
    });
  };

  const buttonCircleStyle = {
    position: "absolute",
    marginRight: "-65px",
    backgroundColor: Colors.red,
    color: "white",
    borderColor: Colors.red,
    display: "block",
    alignSelf: "flex-start",
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <SectionTitle language={i18n.language === "ar" ? "ar" : "en"}>
        <Trans>evaluation_items</Trans>
      </SectionTitle>

      <div style={{ margin: "0 auto", width: "100%" }}>
        {/* draggable implementation */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {state?.length &&
                  state.map((value: QuestionsProps, index: number) => (
                    <Draggable
                      key={value.id}
                      draggableId={value.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <SupContainer
                          key={value.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Button
                            shape={"circle"}
                            size={"large"}
                            style={buttonCircleStyle}
                            onClick={() =>
                              handleAddQuestionBlockOrMultiChoiceQuestion(index)
                            }
                          >
                            +
                          </Button>
                          <FormTemplate>
                            <QuestionsHeadContainer>
                              <div>
                                <QuestionNumber>{index + 1}</QuestionNumber>
                              </div>
                              <div></div>
                              <WeightWrapper>
                                <SwitchLabel>
                                  <Trans>mandatory_switch_question</Trans>
                                </SwitchLabel>
                                <SwitchBtn
                                  className="switch-btn"
                                  style={
                                    value.required
                                      ? { backgroundColor: "#44c874" }
                                      : {
                                          backgroundColor:
                                            "rgba(52, 66, 71, 0.1)",
                                        }
                                  }
                                  onChange={(value) =>
                                    handleOnChangeSwitchButton(value, index)
                                  }
                                  defaultChecked={value.required}
                                />

                                {index === 0 ? (
                                  <></>
                                ) : (
                                  <DeleteIcon
                                    onClick={() =>
                                      handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                                        value.id
                                      )
                                    }
                                  />
                                )}
                              </WeightWrapper>
                            </QuestionsHeadContainer>
                            <QuestionBodyContainer>
                              {/* question  */}
                              <Input
                                key={value.id}
                                id="question"
                                name={`question[${value.id}]`}
                                type="text"
                                style={{ width: "100%" }}
                                placeholder={t("question_head")}
                                dir="true"
                                errorName={
                                  errors?.question && errors.question[value.id]
                                }
                                onChange={(e: Event) =>
                                  handleInputChange(e, index, undefined, "form")
                                }
                                ref={register({
                                  required: true,
                                  message: "error message",
                                  maxLength: 120,
                                })}
                                defaultValue={value.question}
                                className={`questionHead[${value.id}]`}
                              />
                              {errors.question &&
                                errors.question[value.id]?.type && (
                                  <Error
                                    style={{
                                      direction:
                                        i18n.language === "ar" ? "rtl" : "ltr",
                                    }}
                                  >
                                    <Trans>
                                      evaluation_template_question_required
                                    </Trans>
                                  </Error>
                                )}
                              {errors.question &&
                                errors.question[value.id]?.type && (
                                  <Error
                                    style={{
                                      direction:
                                        i18n.language === "ar" ? "rtl" : "ltr",
                                    }}
                                  >
                                    <Trans values={{ count: 120 }}>
                                      maxlenght_error_message
                                    </Trans>
                                  </Error>
                                )}
                              {/* end  question  */}
                              <QuestionDropdownContainer
                                language={i18n.language === "ar" ? "ar" : "en"}
                              >
                                <SelectWrapper
                                  language={
                                    i18n.language === "ar" ? "ar" : "en"
                                  }
                                >
                                  <Label
                                    language={
                                      i18n.language === "ar" ? "ar" : "en"
                                    }
                                  >
                                    <Trans>focus_of_question_optional</Trans>
                                  </Label>

                                  <WrappedSelect
                                    options={question_categories}
                                    handleOnChange={(value: any) => {
                                      setMenuIsOpen(false);
                                      handleQuestionCategoryChange(
                                        value,
                                        index
                                      );
                                    }}
                                    styles={customStyles}
                                    value={value}
                                    menuIsOpen={menuIsOpen}
                                    onFocus={() => setMenuIsOpen(true)}
                                    onBlur={() => setMenuIsOpen(false)}
                                    handleKeydown={() => {
                                      if (!menuIsOpen) setMenuIsOpen(true);
                                    }}
                                  />
                                </SelectWrapper>
                                <SelectWrapper
                                  language={
                                    i18n.language === "ar" ? "ar" : "en"
                                  }
                                >
                                  <Label
                                    language={
                                      i18n.language === "ar" ? "ar" : "en"
                                    }
                                  >
                                    <Trans>select_default_value</Trans>
                                  </Label>
                                  <Select
                                    onChange={(value: number) =>
                                      handleSelectOnChange(value, index)
                                    }
                                    defaultValue={
                                      value.question_type_id ? (
                                        value.question_type_id
                                      ) : (
                                        <SelectLabel
                                          language={
                                            i18n.language === "ar" ? "ar" : "en"
                                          }
                                        >
                                          <Trans>select_default_value</Trans>
                                        </SelectLabel>
                                      )
                                    }
                                    className="question-type-select"
                                  >
                                    {questionTypes?.map(
                                      (type: questionTypesProps, i: number) => {
                                        return mockedArr.map((value, i) => {
                                          if (value.name === type.name) {
                                            return (
                                              <Option
                                                value={type.id}
                                                title={type.name}
                                                key={type.id}
                                              >
                                                <OptionWrapper
                                                  className={type.name}
                                                >
                                                  {value.icon}
                                                  <OptionValue>
                                                    {type.translation}
                                                  </OptionValue>
                                                </OptionWrapper>
                                              </Option>
                                            );
                                          }
                                        });
                                      }
                                    )}
                                  </Select>
                                </SelectWrapper>
                              </QuestionDropdownContainer>
                            </QuestionBodyContainer>

                            {value.question_type_id === 8 ||
                            value.question_type_id === 9 ? (
                              <>
                                {value.options?.length &&
                                  value.options?.map((item: any, i: number) => {
                                    return (
                                      <div
                                        key={item.id}
                                        style={{ width: "100%" }}
                                      >
                                        <MultiChoiceContainer>
                                          {i === 4 ? (
                                            <DeleteIcon
                                              onClick={() =>
                                                handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                                                  index,
                                                  item.id,
                                                  "multiChoice"
                                                )
                                              }
                                              style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "23px",
                                                transform: "translateY(-50%)",
                                              }}
                                            />
                                          ) : i === 0 ? (
                                            <PlusBtnIcon
                                              id="plus_button"
                                              onClick={() =>
                                                handleAddQuestionBlockOrMultiChoiceQuestion(
                                                  index,
                                                  i,
                                                  "multiChoice"
                                                )
                                              }
                                              style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "23px",
                                                transform: "translateY(-50%)",
                                              }}
                                            />
                                          ) : (
                                            <>
                                              <PlusBtnIcon
                                                id="plus_button"
                                                onClick={() =>
                                                  handleAddQuestionBlockOrMultiChoiceQuestion(
                                                    index,
                                                    i,
                                                    "multiChoice"
                                                  )
                                                }
                                                style={{
                                                  position: "absolute",
                                                  top: "50%",
                                                  left: "56px",
                                                  transform: "translateY(-50%)",
                                                }}
                                              />
                                              <DeleteIcon
                                                onClick={() => {
                                                  handleRemoveQuestionBlockOrMultiChoiceQuestionsItem(
                                                    index,
                                                    item.id,
                                                    "multiChoice"
                                                  );
                                                }}
                                                style={{
                                                  position: "absolute",
                                                  top: "50%",
                                                  left: "23px",
                                                  transform: "translateY(-50%)",
                                                }}
                                              />
                                            </>
                                          )}
                                          <Input
                                            id="options"
                                            placeholder={t("option_head")}
                                            name={`options[${item.id}]`}
                                            type="text"
                                            errorName={
                                              errors?.options &&
                                              errors.options[item.id]
                                            }
                                            onChange={(e: any) =>
                                              handleInputChange(
                                                e,
                                                index,
                                                i,
                                                "form"
                                              )
                                            }
                                            ref={register({
                                              required: true,
                                              maxLength: 200,
                                            })}
                                            defaultValue={item.option}
                                          />
                                        </MultiChoiceContainer>
                                        {errors.options &&
                                          errors?.options[item.id]?.types
                                            ?.required && (
                                            <Error
                                              style={{
                                                direction:
                                                  i18n.language === "ar"
                                                    ? "rtl"
                                                    : "ltr",
                                              }}
                                            >
                                              <Trans>
                                                evaluation_template_options_required
                                              </Trans>
                                            </Error>
                                          )}
                                        {errors.options &&
                                          errors.options[item.id]?.types
                                            ?.maxLength && (
                                            <Error
                                              style={{
                                                direction:
                                                  i18n.language === "ar"
                                                    ? "rtl"
                                                    : "ltr",
                                              }}
                                            >
                                              <Trans values={{ count: 200 }}>
                                                maxlenght_error_message
                                              </Trans>
                                            </Error>
                                          )}
                                      </div>
                                    );
                                  })}
                                {value.question_type_id === 8 && (
                                  <Input
                                    id="questions-tip"
                                    name="questions-tip"
                                    type="text"
                                    dir="true"
                                    placeholder={t(
                                      "all_answers_not_applicable"
                                    )}
                                    disabled
                                    style={{ cursor: "not-allowed" }}
                                  />
                                )}
                              </>
                            ) : value.question_type_id === 2 ? (
                              <SliderWrapper>
                                <Slider value={max && 20} min={1} max={20} />
                                <QuestionTypeScale>
                                  <Label>
                                    <Trans>slider_range</Trans>
                                  </Label>

                                  <Input
                                    id="min"
                                    name={`min[${value.id}]`}
                                    type="number"
                                    dir="true"
                                    maxLength="3"
                                    errorName={
                                      errors?.min && errors.min[value.id]
                                    }
                                    width={"optionsWidth"}
                                    onChange={(e2: any) => {
                                      setMin(parseInt(e2?.target.value));
                                      handleInputChange(
                                        e2,
                                        index,
                                        undefined,
                                        "form"
                                      );
                                    }}
                                    ref={register({
                                      required: true,
                                      min: 1,
                                    })}
                                    placeholder={t("min")}
                                    onWheel={(e: any) => e.target.blur()}
                                    defaultValue={value.scale?.min}
                                  />

                                  <Input
                                    id="max"
                                    name={`max[${value.id}]`}
                                    type="number"
                                    dir="true"
                                    maxLength="3"
                                    width={"optionsWidth"}
                                    errorName={
                                      errors?.max && errors.max[value.id]
                                    }
                                    onChange={(e3: any) => {
                                      setMax(parseInt(e3?.target.value));
                                      handleInputChange(
                                        e3,
                                        index,
                                        undefined,
                                        "form"
                                      );
                                    }}
                                    placeholder={t("max")}
                                    ref={register({
                                      required: true,
                                      min: 1,
                                    })}
                                    onWheel={(e: any) => e.target.blur()}
                                    defaultValue={value.scale?.max}
                                  />

                                  <Input
                                    id="step"
                                    name={`step[${value.id}]`}
                                    type="number"
                                    dir="true"
                                    width={"optionsWidth"}
                                    errorName={
                                      errors?.step && errors.step[value.id]
                                    }
                                    onChange={(e4: Event) =>
                                      handleInputChange(
                                        e4,
                                        index,
                                        undefined,
                                        "form"
                                      )
                                    }
                                    maxLength="3"
                                    ref={register({
                                      required: true,
                                      min: 1,
                                      validate: (value: string) => {
                                        return parseInt(value) < max;
                                      },
                                    })}
                                    placeholder={t("step")}
                                    onWheel={(e: any) => e.target.blur()}
                                    defaultValue={value.scale?.step}
                                  />
                                </QuestionTypeScale>
                              </SliderWrapper>
                            ) : null}
                            {errors.min &&
                              errors?.min[value.id]?.types?.required && (
                                <Error>
                                  <Trans>required_error_message</Trans>
                                </Error>
                              )}
                            {errors.min &&
                              errors?.min[value.id]?.types?.min && (
                                <Error>
                                  <Trans>zero_error_message</Trans>
                                </Error>
                              )}
                            {errors.step &&
                              errors?.step[value.id]?.types?.validate && (
                                <Error>
                                  <Trans>min_error_message</Trans>
                                </Error>
                              )}

                            {value.question_type_id === 1 ||
                            value.question_type_id === 8 ||
                            value.question_type_id === 9 ? (
                              <>
                                <CheckboxWrapper>
                                  <Checkbox
                                    onChange={(event) =>
                                      onExtraImagesChange(event, index)
                                    }
                                    checked={value.extra_images}
                                  />
                                  <span className="checkbox-value">
                                    <Trans>want_extra_images</Trans>
                                  </span>
                                </CheckboxWrapper>
                                <CheckboxWrapper>
                                  <Checkbox
                                    onChange={(event) =>
                                      onExtraVideosChange(event, index)
                                    }
                                    checked={value.extra_videos}
                                  />
                                  <span className="checkbox-value">
                                    <Trans>want_extra_videos</Trans>
                                  </span>
                                </CheckboxWrapper>
                              </>
                            ) : (
                              <></>
                            )}
                          </FormTemplate>
                        </SupContainer>
                      )}
                    </Draggable>
                  ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {/* end draggable implementation */}
      </div>
      <ButtonContainer language={i18n.language === "ar" ? "ar" : "en"}>
        <Button
          disabled={formState.isSubmitting}
          size={"large"}
          onClick={handleSubmit}
          loading={loading}
        >
          <Trans>save</Trans>
        </Button>
      </ButtonContainer>
    </Form>
  );
};
export default React.memo(CreateEvaluationTemplateForm);
