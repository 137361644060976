/** @format */

import * as React from "react";
import { SVGProps } from "react";

const InstgramResponsiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <a
    href="https://www.instagram.com/TdreeApp/"
    target="_blank"
    rel="noreferrer"
  >
    <svg width={22} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.17 1.667c-3.11 0-4.966 1.808-4.966 4.841v6.975c0 3.042 1.855 4.85 4.967 4.85h7.155c3.112 0 4.967-1.808 4.967-4.841V6.508c.009-3.033-1.846-4.841-4.958-4.841H7.171zM7.437 10c0-1.783 1.487-3.233 3.317-3.233S14.07 8.217 14.07 10c0 1.783-1.488 3.233-3.317 3.233-1.83 0-3.317-1.45-3.317-3.233zm8.198-3.992a.99.99 0 0 1-.282.175.858.858 0 0 1-.932-.175.96.96 0 0 1-.18-.275.812.812 0 0 1-.067-.316c0-.109.025-.217.068-.317a.858.858 0 0 1 .18-.275.868.868 0 0 1 .931-.175.654.654 0 0 1 .154.075c.043.025.086.067.128.1a.858.858 0 0 1 .18.275c.043.1.068.208.068.317a.812.812 0 0 1-.068.316.96.96 0 0 1-.18.275z"
        fillRule="evenodd"
      />
    </svg>
  </a>
);

export default InstgramResponsiveIcon;
