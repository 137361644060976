/** @format */

import React, { ReactNode } from "react";
import { StyledCarousel } from "./style";

export type Props = {
  children: ReactNode;
  responsive: any;
  className: any;
};

export const CarouselComponent = ({
  children,
  responsive,
  className,
}: Props) => {
  return (
    <StyledCarousel
      responsive={responsive}
      className={className}
      data-testid="carousel"
    >
      {children}
    </StyledCarousel>
  );
};

CarouselComponent.defaultProps = {
  children: <div></div>,
};
