/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "common/Button";
import { Input } from "common/Input";
import { Error, Label } from "../../style";
import { ButtonContainer, Form, InputsContainer } from "./style";
import { FormProps } from "infrastructure/interfaces/forms.interface";

export const UserForm: React.FC<FormProps> = ({
  errors,
  register,
  formState,
  onSubmit,
  loading,
}): JSX.Element => {
  const { i18n } = useTranslation();
  return (
    <div
      style={
        i18n.language === "en"
          ? { direction: "ltr", margin: "5% auto", width: "41%" }
          : { direction: "rtl", margin: "5% auto", width: "50%" }
      }
    >
      <Form
        onSubmit={onSubmit}
        noValidate
        profile
        lang={i18n.language === "ar" ? "ar" : "en"}
      >
        <InputsContainer>
          <Label lang={i18n.language === "ar" ? "ar" : "en"}>
            <Trans>name</Trans>
          </Label>
          <Input
            name="name"
            type="text"
            dir="true"
            ref={register({ required: true })}
            width="100"
            errorName={errors.name}
          />
          {errors?.name?.types?.required && (
            <Error>
              <Trans>required_error_message</Trans>
            </Error>
          )}
        </InputsContainer>
        <InputsContainer>
          <Label lang={i18n.language === "ar" ? "ar" : "en"}>
            <Trans>mobileNum</Trans>
          </Label>
          <Input
            name="mobile"
            type="text"
            dir="true"
            login
            ref={register({ required: true })}
            width="100"
            errorName={errors.mobile}
          />
          {errors?.mobile?.types?.required && (
            <Error>
              <Trans>required_error_message</Trans>
            </Error>
          )}
        </InputsContainer>
        <InputsContainer>
          <Label lang={i18n.language === "ar" ? "ar" : "en"}>
            <Trans>work</Trans>
          </Label>
          <Input
            name="work"
            type="text"
            dir="true"
            login
            ref={register({ required: true })}
            width="100"
            errorName={errors.work}
          />
          {errors?.work?.types?.required && (
            <Error>
              <Trans>required_error_message</Trans>
            </Error>
          )}
        </InputsContainer>
        <ButtonContainer lang={i18n.language === "ar" ? "ar" : "en"}>
          <Button
            size={"large"}
            disabled={formState.isSubmitting}
            onClick={onSubmit}
            loading={loading}
          >
            <Trans>send</Trans>
          </Button>
        </ButtonContainer>
      </Form>
    </div>
  );
};
