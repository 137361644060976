/** @format */

import * as React from "react";
import { SVGProps } from "react";

const NounLaughEmoji = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 0C4.935 0 0 4.934 0 11c0 6.065 4.935 11 11 11s11-4.934 11-11c0-6.065-4.935-11-11-11zm0 20.693c-5.345 0-9.693-4.348-9.693-9.693 0-5.344 4.349-9.693 9.693-9.693 5.345 0 9.693 4.348 9.693 9.693 0 5.344-4.349 9.693-9.693 9.693zm5.398-8.573H5.601a.523.523 0 0 0-.523.523c0 3.88 2.98 5.91 5.922 5.91 2.943 0 5.922-2.03 5.922-5.91a.523.523 0 0 0-.523-.523zM11 17.508c-2.223 0-4.605-1.363-4.854-4.342h9.708c-.25 2.979-2.632 4.342-4.854 4.342zM6.685 8.952c.215-.215.5-.333.804-.333.304 0 .59.118.804.333a.52.52 0 0 0 .74 0 .523.523 0 0 0 0-.74 2.168 2.168 0 0 0-1.544-.639c-.582 0-1.13.227-1.543.64a.523.523 0 1 0 .74.74zm6.946 0c.215-.215.5-.333.804-.333.303 0 .589.118.804.333a.52.52 0 0 0 .74 0 .523.523 0 0 0 0-.74 2.168 2.168 0 0 0-1.544-.639c-.583 0-1.131.227-1.544.64a.523.523 0 1 0 .74.74z"
      fill="#37AE00"
    />
  </svg>
);

export default NounLaughEmoji;
