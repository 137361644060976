/** @format */

import React from "react";
import ScaleIcon from "assets/svg/icon-scale";
import FileIcon from "assets/svg/icon-file";
import ShortAnswerIcon from "assets/svg/icon-shot-answer";
import DateIcon from "assets/svg/icon-date";
import TimeIcon from "assets/svg/icon-time";
import SingleChoiceIcon from "assets/svg/icon-single-choice";
import MultiChoiceIcon from "assets/svg/icon-multiple-choice";
import { questionTypesProps } from "infrastructure/interfaces/evaluationForm.interface";

export const mockedArr: Array<questionTypesProps> = [
  { id: 1, name: "text", icon: <ShortAnswerIcon /> },
  { id: 2, name: "scale", icon: <ScaleIcon /> },
  { id: 3, name: "file", icon: <FileIcon /> },
  { id: 4, name: "date", icon: <DateIcon /> },
  { id: 5, name: "time", icon: <TimeIcon /> },

  {
    id: 9,
    name: "multiple_choice",
    icon: <MultiChoiceIcon />,
  },
  {
    id: 10,
    name: "single_choice",
    icon: <SingleChoiceIcon />,
  },
  {
    id: 6,
    name: "audio",
    icon: <i className="fa fa-microphone-slash" style={{ fontSize: "20px" }} />,
  },
  {
    id: 7,
    name: "video",
    icon: <i className="fa fa-youtube" style={{ fontSize: "20px" }} />,
  },
  {
    id: 8,
    name: "image",
    icon: <i className="fa fa-image" style={{ fontSize: "20px" }} />,
  },
];
