/** @format */

import { create } from "zustand";
import { axiosApiInstance } from "infrastructure/utils/api";

export const useStore = create((set: any) => ({
  branchListData: [],
  paginationInfo: [],
  loading: true,
  branchList: async () => {
    try {
      const response = await axiosApiInstance.get("api/company/v1/branches");
      set({
        branchListData: response.data.data.branches,
        loading: false,
        paginationInfo: response.data.data.pagination,
      });
    } catch (error) {}
  },
}));
