import * as React from "react";

function FileIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={20}
      viewBox="0 0 17 20"
    >
      <path
        d="M9.25 5.75v-5h-6.5a1.5 1.5 0 00-1.5 1.5v15.5a1.5 1.5 0 001.5 1.5h11.5a1.5 1.5 0 001.5-1.5V7.25h-5a1.5 1.5 0 01-1.5-1.5zm6.5 1.5L9.25.75"
        stroke="#C998B9"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FileIcon;
