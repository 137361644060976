/** @format */

import React from "react";
import { StyledSlider } from "./style";

type Props = {
  defaultValue?: number | [number, number] | any;
  disabled?: boolean;
  min: number;
  max: number;
  value: number | undefined;
  style?: any;
};
export const Slider = ({
  defaultValue,
  disabled,
  min,
  max,
  value,
  style,
}: Props) => {
  return (
    <StyledSlider
      defaultValue={defaultValue}
      disabled={disabled}
      min={min}
      max={max}
      value={value}
      reverse
      tipFormatter={null}
      trackStyle={style}
    />
  );
};
