/** @format */

import { Button } from 'common/Button';
import { Input } from 'common/Input';
import { FormProps } from 'infrastructure/interfaces/forms.interface';
import { Error } from 'pages/style';
import { Img } from 'pages/User/style';
import {
  InputsContainer,
  Label,
  WalletHeader,
  HeaderTitle,
  ActionContainer,
} from '../../style';
import { Form, ImageContainer } from 'pages/Wallet/style';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import InfoCircleIcon from 'assets/svg/info-circle';
import EditIcon from 'assets/svg/edit-icon';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';

const ChargeWithBankAccountForm: React.FC<FormProps> = ({
  errors,
  register,
  formState,
  onSubmit,
  handleImageUploading,
  requestedImgUrl,
  showImage,
  loading,
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [amount, setAmount] = useState(0);

  const onAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const { data: configrationList } = useQuery(
    ['configrationList'],
    () => fetchGetRequests('api/configurations'),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  return (
    <Form onSubmit={onSubmit} noValidate>
      <WalletHeader language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <HeaderTitle>
          <Trans>transfer_info</Trans>
        </HeaderTitle>
        <TransferInfoBox>
          <Label>
            <Trans>company_name</Trans>
          </Label>
          :
          <Text>
            <Trans>company_name_in_bank</Trans>
          </Text>
        </TransferInfoBox>
        <TransferInfoBox>
          <Label>
            <Trans>bank_name</Trans>
          </Label>
          :
          <Text>
            <Trans>albilad_bank_name</Trans>
          </Text>
        </TransferInfoBox>
        <TransferInfoBox style={{ marginBottom: '24px' }}>
          <Label>
            <Trans>iban</Trans>
          </Label>
          :<Text> SA1715000446123703880008</Text>
        </TransferInfoBox>
      </WalletHeader>
      <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Box language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <InfoCircleIcon style={{ margin: '0 2px' }} />
          <Text>
            <Trans>wallet_charge_time</Trans>
          </Text>
        </Box>
      </InputsContainer>

      {amount > 0 && (
        <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Box language={i18n.language === 'ar' ? 'ar' : 'en'}>
            <InfoCircleIcon style={{ margin: '0 5px' }} />
            <Text>
              <Trans
                values={{
                  amount: Number(
                    (
                      amount /
                      (1 +
                        configrationList?.response?.configuration.settings
                          .vat_percentage /
                          100)
                    ).toFixed(2)
                  ),
                  realAmount: amount,
                  vatPercentage:
                    configrationList?.response?.configuration.settings
                      .vat_percentage,
                  percentage: Number(
                    (
                      amount -
                      amount /
                        (1 +
                          configrationList?.response?.configuration.settings
                            .vat_percentage /
                            100)
                    ).toFixed(2)
                  ),
                }}
              >
                message_alert
              </Trans>{' '}
            </Text>
          </Box>
        </InputsContainer>
      )}

      <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>sender_name</Trans>
        </Label>
        <Input
          name='sender_name'
          width='full_width'
          type='text'
          errorName={errors.sender_name}
          ref={register({ required: true, pattern: /^[^0-9]+$/ })}
        />
        {errors?.sender_name?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}
        {errors?.sender_name?.types?.pattern && (
          <Error>
            <Trans>characters_only</Trans>
          </Error>
        )}
      </InputsContainer>
      <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>bank_name</Trans>
        </Label>
        <Input
          name='bank_name'
          width='full_width'
          type='text'
          errorName={errors.bank_name}
          ref={register({
            required: true,
            pattern: /^[^0-9]+$/,
          })}
        />
        {errors?.bank_name?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}
        {errors?.bank_name?.types?.pattern && (
          <Error>
            <Trans>characters_only</Trans>
          </Error>
        )}
      </InputsContainer>
      <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>transfer_amount</Trans>
        </Label>
        <Input
          name='amount'
          width='full_width'
          type='number'
          maxLength='15'
          errorName={errors.amount}
          ref={register({
            required: true,
            pattern: /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/,
            // max: 1000000,
            min: 1,
          })}
          placeholder={t('sar')}
          min={1}
          // max={1000000}
          onWheel={(e: any) => e.target.blur()}
          onChange={(e) => onAmountChange(e)}
        />
        {errors?.amount?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}
        {errors?.amount?.types?.pattern && (
          <Error>
            <Trans>negative_values</Trans>
          </Error>
        )}
        {/* {errors?.amount?.types?.max && (
          <Error>
            <Trans>maximum-30000</Trans>
          </Error>
        )} */}
        {errors?.amount?.types?.min && (
          <Error>
            <Trans>minimum_1</Trans>
          </Error>
        )}
      </InputsContainer>
      <ImageContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>transfer_picture</Trans>
        </Label>
        {loading && (
          <ImgLoadingContainer>
            <LoadingOutlined />
          </ImgLoadingContainer>
        )}

        <label htmlFor='file' style={{ width: '100%' }}>
          {showImage ? (
            <div style={{ position: 'relative' }}>
              <Img src={requestedImgUrl.fullUrl} alt='' />
              <EditIcon register />
            </div>
          ) : i18n.language === 'ar' ? (
            <LabelImag>
              <Input
                type='file'
                id='file'
                accept='.png, .jpg, .jpeg'
                name='image_url'
                style={{ display: 'none' }}
                errorName={errors.image_url}
                ref={register({ required: true })}
                onChange={(e: any) => handleImageUploading(e)}
              />
            </LabelImag>
          ) : (
            <LabelImagEng>
              <Input
                type='file'
                id='file'
                accept='.png, .jpg, .jpeg'
                name='image_url'
                style={{ display: 'none' }}
                errorName={errors.image_url}
                ref={register({ required: true })}
                onChange={(e: any) => handleImageUploading(e)}
              />
            </LabelImagEng>
          )}
        </label>
        <Input
          type='file'
          id='file'
          accept='.png, .jpg, .jpeg'
          name='image_url'
          style={{ display: 'none' }}
          onChange={(e: any) => handleImageUploading(e)}
        />
      </ImageContainer>
      <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
        {errors?.image_url?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}
      </InputsContainer>
      <ActionContainer>
        <Button
          disabled={formState?.isSubmitting}
          size='large'
          onClick={() => navigate(`/wallet`)}
        >
          <Trans>cancel</Trans>
        </Button>
        <Button
          id='bank-submit'
          disabled={formState?.isSubmitting}
          size='large'
          onClick={onSubmit}
          style={{ marginLeft: '10px' }}
        >
          <Trans>charge</Trans>
        </Button>
      </ActionContainer>
    </Form>
  );
};

export default ChargeWithBankAccountForm;

export const InputImage = styled.input`
  opacity: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

export const LabelImag = styled.label`
  width: 24px;
  height: 50px;
  padding: 13px 84px;
  display: block;
  cursor: pointer;
  border-radius: 10px;
  border: solid 1px #44c874;
  border-style: dashed;
  margin-left: 0;
  color: #44c874;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #44c874;
  position: relative;

  ::before {
    content: 'ارفاق الصورة';
    position: absolute;
    top: 55.5%;
    left: 48%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 700px) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const LabelImagEng = styled.label`
  width: 24px;
  height: 50px;
  padding: 13px 84px;
  display: block;
  cursor: pointer;
  border-radius: 10px;
  border: solid 1px #44c874;
  border-style: dashed;
  margin-left: 0;
  color: #44c874;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #44c874;
  position: relative;

  ::before {
    content: 'Attach Image';
    position: absolute;
    top: 55.5%;
    left: 48%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
`;

export const ImgLoadingContainer = styled.div`
  top: ${(props: { edit?: boolean }) => (props.edit ? '70%' : '30%')};
  position: absolute;
  left: ${(props: { edit?: boolean }) => (props.edit ? '44%' : '37%')};
`;

export const Box = styled.div`
  width: 100%;

  text-align: right;
  direction: ${(props: { language?: string }) =>
    props.language === 'ar' ? 'rtl' : 'ltr'};
  padding: 12px 16px 12px 8px;
  border-radius: 4px;
  background-color: #e6fafd;
  margin-bottom: 24px;
  display: flex;
`;

export const Text = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #344247;
  margin-left: 5px;
`;

export const TransferInfoBox = styled.div`
  display: flex;
`;
