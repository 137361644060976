/** @format */

import React from 'react';
import EmptyTemplate from 'common/EmptyTemplate';
import { useQuery } from 'react-query';
import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { Header, Title } from '../style';
import Spinner from 'common/LoadingIndecator';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = ({ page }: any) => {
  const { i18n } = useTranslation();

  const { data } = useQuery(
    ['termsAndConditions'],
    () => fetchGetRequests('api/pages/terms-conditions-companies'),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {i18n.language === 'ar'
            ? 'الشروط والأحكام - تدري'
            : 'Terms and condition - Tdree'}
        </title>
      </Helmet>
      {page ? (
        <></>
      ) : (
        <Header language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Title>{data?.response?.page?.name}</Title>
        </Header>
      )}

      <EmptyTemplate
        className='help'
        style={{
          overflow: 'scroll',
          padding: '20px',
          height: '84vh',
        }}
      >
        {!data ? (
          <Spinner size='large' />
        ) : (
          <div
            style={{
              direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
              fontFamily: 'Famtree',
            }}
            dangerouslySetInnerHTML={{ __html: data?.response?.page?.text }}
          />
        )}
      </EmptyTemplate>
    </>
  );
};

export default TermsAndConditions;
