/** @format */

import React from "react";
import {
  PdfCover,
  FlexWrapper,
  LogoWrapper,
  Span,
  LogoTitle,
  Description,
  Item,
  Label,
  Header,
  FlexStart,
} from "./style";
import Avatar from "common/Avatar";
import Logo from "assets/svg/logo";
import { Trans } from "react-i18next";
import SummaryPointsReview from "components/Summary/summaryPointsReview";
import ShopperInfo from "components/Summary/ShopperInfo";
import { PDFProps } from "infrastructure/interfaces/pdf.interface";
import { BranchesList } from "pages/Home/Details/style";
import moment from "moment";
import storage from "infrastructure/utils/storage";

const PDFCover = ({
  template_name,
  description,
  shoppers_number,
  questions_number,
  focus_of_evaluation,
  percentage,
  questionCategories,
  show_summary,
  mBot,
  show_shopper_info,
  shopperData,
  branchesList,
  branch_name,
}: PDFProps) => {
  const userImage = storage.getItem("user_image");
  return (
    <PdfCover>
      {/* header  */}
      <Header>
        <Avatar
          src={userImage}
          size={"large"}
          shape="circle"
          style={{ width: "100px" }}
        />
        <LogoWrapper>
          <LogoTitle>
            <Trans>tdree</Trans>
          </LogoTitle>
          <Logo />
        </LogoWrapper>
      </Header>
      {/* template  */}
      <FlexWrapper>
        <Item>
          <Span>{template_name}</Span>
          <Description>{description}</Description>
        </Item>
      </FlexWrapper>
      <FlexStart>
        {/* branchs  */}
        {branchesList?.length && (
          <FlexWrapper>
            <Item>
              <Label>
                <Trans>branches</Trans>
              </Label>
              <BranchesList>
                {branchesList.map((branch: string) => (
                  <li>{branch}</li>
                ))}
              </BranchesList>
            </Item>
          </FlexWrapper>
        )}
        {/* branch */}
        {branch_name && (
          <FlexWrapper>
            <Item>
              <Label>
                <Trans>branch</Trans>
              </Label>
              {branch_name}
            </Item>
          </FlexWrapper>
        )}
        {/* date  */}
        <FlexWrapper>
          <Item>
            <Label>
              <Trans>report_date</Trans>
            </Label>
            <Description>
              {moment().format("YYYY/MM/DD hh:mm:ss A")}
            </Description>
          </Item>
        </FlexWrapper>
      </FlexStart>
      {/* count  */}
      <FlexWrapper>
        {shoppers_number && (
          <Item>
            <Label>
              <Trans>shoppers_number</Trans>
            </Label>
            <Description>{shoppers_number}</Description>
          </Item>
        )}
        <Item>
          <Label>
            <Trans>questions_number</Trans>
          </Label>
          <Description>{questions_number}</Description>
        </Item>
      </FlexWrapper>
      {/* category  */}
      <Item>
        <Label>
          <Trans>focus_of_evaluation</Trans>
        </Label>
        <Description>{focus_of_evaluation}</Description>
      </Item>
      {/* summary  */}
      {show_summary && (
        <SummaryPointsReview
          percentage={percentage}
          questionCategories={questionCategories}
          mBot={mBot}
        />
      )}
      {show_shopper_info && (
        <ShopperInfo shopperData={shopperData} show_in_print={true} />
      )}
    </PdfCover>
  );
};

export default PDFCover;
