/** @format */

import React from "react";
import { Checkbox as AntdCheckbox } from "antd";

export type Props = {
  onChange?: (value?: any) => void | any;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
};

export const Checkbox: React.FC<Props> = ({ onChange, checked, disabled }) => {
  return (
    <AntdCheckbox
      data-testid="checkbox"
      onChange={onChange}
      disabled={disabled}
      {...(checked ? { checked } : null)}
    />
  );
};
