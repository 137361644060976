/** @format */

import * as React from "react";

const ArrowLeft = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <svg
    width={27}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{
      marginRight: "10px",
    }}
  >
    <path
      d="M14.57 5.93 8.5 12l6.07 6.07M25.5 12H8.67"
      stroke="#43C773"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0,0"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowLeft;
