import * as React from "react";
import { SVGProps } from "react";

const TopResult = (props: SVGProps<SVGSVGElement>) => (
  <svg width={148} height={45} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(.89 .04)" fill="none">
      <path
        fill="#43C773"
        d="M29 0h7.57v1H29zM1 11.34H0C.005 5.099 5.049.033 11.29 0h13.6v1h-13.6C5.601 1.033 1.005 5.651 1 11.34zm100.21 32.14h20.01v1h-20.01z"
      />
      <rect
        fill="#43C773"
        x={3.46}
        y={4.69}
        width={133.73}
        height={35.1}
        rx={7.83}
      />
      <path fill="#43C773" d="m133.56 25.24-8.4 14.55h21z" />
      <path
        fill="#FFF"
        d="m54.35 16.94 1.72 3.49 3.85.56-2.79 2.71.66 3.83-3.44-1.81-3.44 1.81.66-3.83-2.79-2.71 3.85-.56zm15.62 0 1.72 3.49 3.85.56-2.79 2.71.66 3.83-3.44-1.81-3.44 1.81.66-3.83-2.79-2.71 3.85-.56zm16 0 1.72 3.49 3.85.56-2.79 2.71.66 3.83-3.44-1.81-3.44 1.81.66-3.83-2.79-2.71 3.85-.56zm16 0 1.72 3.49 3.85.56-2.79 2.71.66 3.83-3.44-1.81-3.44 1.81.66-3.83-2.79-2.71 3.85-.56zm16 0 1.72 3.49 3.85.56-2.79 2.71.66 3.83-3.44-1.81-3.44 1.81.66-3.83-2.79-2.71 3.85-.56z"
      />
      <circle fill="#FFF" opacity={0.2} cx={27.85} cy={22.24} r={12.01} />
      <path
        d="M37.77 22.24a9.91 9.91 0 0 1-18.63 4.72 9.76 9.76 0 0 1-1.2-4.72 9.92 9.92 0 1 1 19.83 0z"
        fill="#FFF"
      />
      <path
        d="M36.57 26.96a9.91 9.91 0 0 1-17.43 0 15.7 15.7 0 0 1 17.43 0z"
        fill="#43C773"
      />
      <path
        d="M32.27 18.85a4.42 4.42 0 1 1-4.42-4.42 4.41 4.41 0 0 1 4.42 4.42z"
        fill="#B55B52"
      />
    </g>
  </svg>
);

export default TopResult;
