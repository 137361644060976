/** @format */

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border-radius: 14px;
  border: solid 0.7px #dddddd;
  display: flex;
  flex-direction: column;
  padding: 20px 22px 20px 22px;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    width: 94% !important;
    margin: 60px 0px 30px 0px;
    padding: 8px;
  }
`;
