/** @format */

import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { EmptyCard } from "./style";

export type Props = {
  children: ReactNode;
  className?: string;
  style?: any;
};

const EmptyTemplate: React.FC<Props> = ({ children, className, style }) => {
  const { i18n } = useTranslation();
  return (
    <EmptyCard
      style={style}
      className={className}
      language={i18n.language === "ar" ? "ar" : "en"}
      data-testid="EmptyTemplate-testid"
    >
      {children}
    </EmptyCard>
  );
};

export default EmptyTemplate;
