import * as React from "react";
import { SVGProps } from "react";

const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={50} height={50} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect fill="#43C773" width={50} height={50} rx={25} />
      <g transform="translate(15.31 13.81)" stroke="#FFF" strokeWidth={1.8}>
        <circle cx={9.611} cy={9.611} r={2.79} />
        <path d="M19.222 9.611A9.611 9.611 0 0 0 0 9.611c0 2.382.871 4.557 2.306 6.236 0 0 4.561 5.186 6.024 6.581a1.852 1.852 0 0 0 2.56 0c1.705-1.625 6.025-6.581 6.025-6.581a9.56 9.56 0 0 0 2.307-6.236z" />
      </g>
    </g>
  </svg>
);

export default LocationIcon;
