/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "common/Table";
import {
  ColumnCell,
  MoreBtn,
  ColumnCellName,
  Title,
  HeaderSubtitle,
} from "./style";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "common/Button";
import { Dropdown } from "common/Dropdown";
import { Menu, MenuProps, Space, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { useQuery, useQueryClient } from "react-query";
import { fetchGetRequestsWithPagination } from "infrastructure/helpers/fetchServerRequests";
import { Header } from "../style";
import DeleteIcon from "assets/svg/icon-delete";
import { Container, TablesWrapper } from "pages/style";
import { ButtonsContainer } from "common/Table/style";
import SearchInput from "common/Search";
import { SwitchBtn } from "common/Switch";
import { Helmet } from "react-helmet";
import { StyledP, Container as MenuContainer } from "common/Menu/style";
import Modal from "common/Modal";
import styled from "styled-components";
import EditIcon from "assets/svg/edit-icon";

const { Link } = Typography;

const Managers = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState<any>({
    id: false,
  });
  const [page, SetPage] = useState<number>(1);
  const [, setChecked] = useState<boolean>(true);

  const [record, setRecord] = useState<{ status: { id: number }; id: number }>({
    status: { id: 0 },
    id: 0,
  });

  const { data } = useQuery(["managersListData", page, searchInputValue], () =>
    fetchGetRequestsWithPagination(
      `api/company/v1/managers?page=${page}&search=${searchInputValue}`
    )
  );

  const handleOnSwitchManager = (checked: boolean, value: any) => {
    axiosApiInstance
      .put(`api/company/v1/managers/${value.id} `, {
        active: checked,
      })
      .then(() => {
        queryClient.invalidateQueries("managersListData");
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          " ",
          err.response.data.message
        );
      });
  };

  const showModal = (id: number) => {
    setIsModalVisible({ [id]: true });
  };

  const handleCancel = (id: number) => {
    setIsModalVisible({ [id]: false });
  };

  const handleDeleteManager = (id: number) => {
    axiosApiInstance
      .delete(`api/company/v1/managers/${id}`)
      .then(() => {
        queryClient.invalidateQueries("managersListData");
        setIsModalVisible({ [id]: false });
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          " ",
          err.response.data.message
        );
      });
  };

  const handleSearchInputChange = (e: any) => {
    setSearchInputValue(e.target?.value);
  };

  const handlePagination = (page: number) => {
    SetPage(page);
  };

  const handleMenuClick = (
    record: React.SetStateAction<{ status: { id: number }; id: number }>
  ) => {
    setRecord(record);
  };

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: (
        <StyledMenu language={i18n.language === "ar" ? "ar" : "en"}>
          <MenuContainer
            onClick={() => navigate(`edit/${record.id}`)}
            language={i18n.language === "ar" ? "ar" : "en"}
          >
            {i18n.language === "ar" ? (
              <>
                <EditIcon />
                <a>
                  <Trans>edit</Trans>
                </a>
              </>
            ) : (
              <>
                <a>
                  <Trans>edit</Trans>
                </a>
                <EditIcon />
              </>
            )}
          </MenuContainer>
        </StyledMenu>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <MenuContainer
            onClick={() => showModal(record.id)}
            language={i18n.language === "ar" ? "ar" : "en"}
          >
            {i18n.language === "ar" ? (
              <>
                <DeleteIcon />
                <a>
                  <Trans>delete</Trans>
                </a>
              </>
            ) : (
              <>
                <a>
                  <Trans>delete</Trans>
                </a>
                <DeleteIcon />
              </>
            )}
          </MenuContainer>
        </>
      ),
    },
  ];

  const columns: ColumnsType<object> | undefined = [
    {
      title: <Trans>name</Trans>,
      key: "name",
      render: (record: any) => {
        return (
          <ColumnCellName onClick={() => navigate(`${record.id}`)}>
            {record.name}
          </ColumnCellName>
        );
      },
    },
    {
      title: <Trans>email</Trans>,
      dataIndex: "email",
      key: "email",
      render: (email: string) => {
        return <ColumnCellName>{email}</ColumnCellName>;
      },
    },
    {
      title: <Trans>phone_number</Trans>,
      key: "mobile",
      dataIndex: "mobile",
      render: (mobile: number) => <ColumnCell>{mobile}</ColumnCell>,
    },
    {
      title: t("on/off"),
      dataIndex: "active",
      render: (record: any, value: any, index: any) => (
        <SwitchBtn
          onChange={(checked: boolean) => {
            setChecked(checked);
            handleOnSwitchManager(checked, value);
          }}
          defaultChecked={record}
        />
      ),
    },
    {
      title: " ",
      render: (record) => (
        <Dropdown items={{ items }} onClick={() => handleMenuClick(record)}>
          <MoreBtn>...</MoreBtn>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar" ? "المديرين - تدري " : " Managers - Tdree"}
        </title>
      </Helmet>
      <Header language={i18n.language === "ar" ? "ar" : "en"}>
        <Title>
          <Trans>managers</Trans>
        </Title>
      </Header>
      {
        <Container language={i18n.language === "ar" ? "ar" : "en"}>
          <ButtonsContainer language={i18n.language === "ar" ? "ar" : "en"}>
            <SearchInput
              handleChange={(e: any) => handleSearchInputChange(e)}
              placeholder={t("search_placeholder")}
            />
            <Button size={"large"} onClick={() => navigate(`/managers/create`)}>
              {t("new_manager")}
            </Button>
          </ButtonsContainer>
          <div style={{ direction: "rtl", width: "95%" }}>
            <HeaderSubtitle language={i18n.language === "ar" ? "ar" : "en"}>
              <Trans>create_team_subtitle</Trans>
            </HeaderSubtitle>

            <HeaderSubtitle language={i18n.language === "ar" ? "ar" : "en"}>
              <Space
                size={10}
                style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
              >
                <Trans>download_app</Trans>
                <Link
                  href="https://apps.apple.com/eg/app/%D8%AA%D8%AF%D8%B1%D9%8A-%D8%B4%D8%B1%D9%83%D8%A7%D8%AA/id1642523980"
                  target="_blank"
                  style={{ textDecoration: "underline", color: "#344247" }}
                >
                  IOS
                </Link>

                <Link
                  href="https://play.google.com/store/apps/details?id=com.tdreedashboard"
                  target="_blank"
                  style={{ textDecoration: "underline", color: "#344247" }}
                >
                  Android
                </Link>
              </Space>
            </HeaderSubtitle>
          </div>
          <TablesWrapper>
            <CustomTable
              columns={columns}
              data={data?.managers}
              loading={data?.managers ? false : true}
              pagination={{
                position: [
                  i18n.language === "ar" ? "bottomRight" : "bottomLeft",
                ],
                total: data?.pagination.total,
                defaultCurrent: data?.pagination.current_page || 1,
                onChange: (page: number) => handlePagination(page),
              }}
            />
          </TablesWrapper>
        </Container>
      }

      <Modal
        title={<StyledP>{<Trans>delete_manager</Trans>}</StyledP>}
        visible={isModalVisible[record.id]}
        onOk={() => handleDeleteManager(record.id)}
        onCancel={() => handleCancel(record.id)}
      >
        <Trans>branch_modal_delete_manager_message</Trans>
      </Modal>
    </>
  );
};

export default Managers;

//@ts-ignore
const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    width: 140px !important;
    box-shadow: 3px 3px 5px 6px #ccc;
    direction: ${(props: { language?: string }) =>
      props.language === "ar" ? "ltr" : "rtl"};
    margin-top: 15px;
  }
`;
