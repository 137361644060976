/** @format */

import styled from "styled-components";
import { Avatar } from "antd";

export const StyledAvatar = styled(Avatar)`
  &.ant-avatar-lg {
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }
`;
