/** @format */

import React, { FC, ReactNode } from "react";
import { StyledDrawer } from "./style";

export type Props = {
  open: boolean;
  title: string;
  setVisible: (value: boolean) => void;
  children: ReactNode;
};

const Drawer: FC<Props> = ({ children, open, setVisible, title }) => {
  const onClose = () => {
    setVisible(false);
  };

  return (
    <StyledDrawer
      title={title}
      placement={"left"}
      onClose={onClose}
      open={open}
      data-testid="myDrawer"
    >
      {children}
    </StyledDrawer>
  );
};

export default Drawer;
