import Colors from "infrastructure/utils/Colors";
import styled from "styled-components";
import { Typography } from "antd";
import img from "assets/svg/img.webp";
import { Form } from "antd";
import { Button } from "common/Button";
const { Text, Title } = Typography;

type Props = {
  width?: boolean;
  font?: boolean;
  row?: boolean;
  isMobile?: boolean;
};

export const PageWrapper = styled.section`
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const Section = styled.section`
  width: ${(props) => (props.isMobile ? "90%" : "80%")};
  display: flex;
  flex-direction: ${(props: Props) => (props.row ? "row-reverse" : "column")};
  margin: ${(props: Props) => (props.isMobile ? "24px auto" : "80px auto")};
  justify-content: space-around;
`;

export const SectionTitle = styled.h1`
  font-family: Famtree;
  font-size: ${(props: { isMobile: boolean }) =>
    props.isMobile ? "15px" : "40px"};
  color: #344247;
`;

export const TitleSpan = styled.span`
  font-size: ${(props: { font?: boolean }) => props.font && "28px"};
  color: #43c773;
  font-weight: bold;
  font-family: Famtree;
`;

export const SectionParghraph = styled.p`
  font-family: Famtree;
  font-size: ${(props: Props) =>
    props.font ? "32px" : props.isMobile ? "11px" : "15px"};
  color: ${Colors.charcoal_grey};
  width: ${(props: Props) => (props.width ? "212px" : "unset")};
  margin: ${(props: Props) =>
    props.width
      ? "24px 0 0"
      : props.font
        ? "12px 0 0 0"
        : props.isMobile
          ? "12px 0 12px 1px"
          : "25px 2px 12px 0"};
  font-weight: ${(props: Props) => props.font && "800"};
`;

export const StoreBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Famtree;
  width: 50%;
`;

export const SectionParghraphResponsive = styled.p`
  margin: 10px 0 0;
  font-family: Famtree;
  font-size: ${(props: Props) => (props.font ? "15px" : "11px")};
  font-weight: ${(props: Props) => (props.font ? "800" : "normal")};
  color: #344247;
`;

export const IconsWithContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props: Props) => (props.isMobile ? "flex-end" : "center")};
`;

export const VideoWrapper = styled.div`
  width: 80%;
  height: 360px;
  margin: 0 auto;
  margin-bottom: 42px;
`;

export const VideoWrapperResponsive = styled.div`
  width: 90%;
  height: 200px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

export const TdreeSection = styled.section`
  width: 100%;
  margin: 0px auto;
  padding: 69px 0px;
  object-fit: contain;
  border-radius: 2px;
  background-image: linear-gradient(
    rgb(247, 247, 247),
    rgba(244, 244, 244, 0.64) 72%,
    rgba(240, 240, 240, 0.49)
  );
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: ${(props: { registerSection?: boolean }) =>
    props.registerSection ? "center" : "space-between"};
`;

export const TdreeSectionResponsive = styled.section`
  padding: 44px 12px 19px;

  object-fit: contain;
  border-radius: 2px;
  background-image: linear-gradient(to bottom, #f7f7f7, #fafafa);
  display: flex;
  flex-direction: column;
`;

export const TdreeTitleSpan = styled.p`
  font-size: ${(props: Props) => (props.isMobile ? "13px" : "28px")};
  font-weight: bold;
  font-family: Famtree;
  color: #43c773;
  margin-top: ${(props: Props) => props.isMobile && "19px"};
`;

export const RectangleContainer = styled.div`
  width: 1020px;
  padding: 0px 50px 0px 0px;
  object-fit: contain;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 11px 0px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row-reverse;
`;

export const StyledText = styled(Text)`
  font-family: Famtree;
  font-size: 32px;
  font-weight: bold;
  color: rgb(52, 66, 71);
  width: 410px;
  text-align: center;
  margin: 138px 0px 28px 50px;
`;

export const StyledTextResponsive = styled(Text)`
  width: 410px;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: #344247;
  width: 205px;
  text-align: center;
  margin: 14px 73px 9px;
`;

export const StyledLink = styled.a`
  color: rgb(67, 199, 115) !important;
  word-break: break-all;
  font-size: 16px;
  :hover {
    color: ${Colors.dark_mint};
  }
  margin-top: 28px;
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  -webkit-box-pack: center;
  justify-content: center;
`;

export const Footer = styled.div`
  padding: 28px 210px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FooterCopyWrites = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-family: Famtree;
`;

export const FooterResponsiveCopyWrites = styled.p`
  font-size: 10px;
  font-weight: normal;
  font-family: Famtree;
  opacity: 0.8;
  text-align:center;
  margin:10px 0;
`;

export const FooterContactUs = styled.p`
  font-size: ${(props: Props) => (props.isMobile ? "12px" : "18px")};
  font-family: Famtree;
  color: #344247;
  direction: ltr;
`;

export const WebMockupImageWrapperResponsive = styled.div`
  position: relative;
  left: 1em;
`;

export const WebMockupImageWrapper = styled.div`
  position: relative;
  left: 2.3em;
  width: 656px;
`;

export const MockupMobileAndSocialLinksWrapperResponsive = styled.div`
  display: flex;
  flex-direction: row;
  text-align: end;
  margin-top: 18px;
`;

export const DownloadTdreeText = styled.p`
  margin: 56px 0 20px 32px;
  font-family: Famtree;
  font-size: 11px;
  color: #344247;
`;

export const RegisterNowSectionResponsive = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterResponsive = styled.div`
  width: 100%;
  height: 155px;
  padding: 15px 15px;
  border-radius: 2px;
  background-color: #fff;
  text-align: end;
`;

export const Banner = styled.div`
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  height: 926px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;

  @media (max-width: 850px) {
    height: 500px;
  }
`;

export const StyledForm = styled(Form)`
  &.ant-form-vertical .ant-form-item-row {
    align-items: flex-start;
  }
  .ant-form-item-explain-error {
    padding-bottom: 18px;
  }
`;

export const StyledTitle = styled(Title)`
  &.ant-typography,
  div.ant-typography-h1,
  div.ant-typography-h1 > textarea,
  .ant-typography h1 {
    font-family: Famtree;
    color: #ffffff;
    font-size: 40px;
    word-break: break-all;

    line-height: 10px;

    @media (max-width: 850px) {
      font-size: 18px;
    }

    @media (max-width: 300px) {
      font-size: 14px;
    }
  }
`;

export const StyledButton = styled(Button)`
  align-self: center;
  @media (max-width: 991px) {
    width: 50%;
    align-self: center;
  }

 
  @media (max-width: 767px) {
    width: 70%;
    }
`;

export const OptionValue = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin-bottom: 0px;
  text-align: ${(props: { language?: string }) =>
    props.language === "ar" ? "end" : "start"};

  :hover {
    color: ${Colors.charcoal_grey};
  }
`;
