/** @format */

import { create } from "zustand";
import { axiosApiInstance } from "infrastructure/utils/api";

export const jobDetailsStore = create((set: any) => ({
  jobDetails: {},
  loading: true,
  statusCode: "",
  jobDetailsFn: async (id: number) => {
    try {
      const response = await axiosApiInstance.get(`api/company/v1/jobs/${id}`);
      set({ jobDetails: response.data.job, loading: false });
    } catch (error) {
      set({ loading: false, statusCode: error.response.data.statusCode });
    }
  },
}));
