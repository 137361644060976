/** @format */

import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import { Title, Header } from 'pages/style';
import { useQuery } from 'react-query';
import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import Spinner from 'common/LoadingIndecator';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';
import {
  DashboardBranchesEmptyState,
  DashboardEvaluationsEmptyState,
  DashboardJobsEmptyState,
} from 'components/DashboardEmptyStates';
import LiveIcon from 'assets/svg/live-ic';
import {
  BranchRate,
  Job,
  VerticalBar,
  JobsCount,
  BranchesCount,
  AddEvaluationTemplate,
} from 'components/Dashboard';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'common/ReactSelect';
import { DatePicker } from 'antd';
import MostVisitedIcon from 'assets/svg/most-visited-icon';
import LeastVisitedIcon from 'assets/svg/least-visited-icon';
import LowResult from 'assets/svg/low-result-icon';
import TopResult from 'assets/svg/top-result-icon';
import { timeStampToDate } from 'infrastructure/helpers/timeStampToDate';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'common/Button';
import {
  BoldText,
  DateFilter,
  DetailsButtonWrapper,
  FilterDetailsInfo,
  FiltrationHeader,
  Hint,
  HomeWrapper,
  LastUpdate,
  NormalText,
  OnBoarding,
  Pargraph,
  SelectFilter,
  StyledAlert,
  Wrapper,
} from './style';
import storage from 'infrastructure/utils/storage';

const { RangePicker } = DatePicker;

export const Home = () => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const newUser = storage.getItem('user_data') || '';
  const [selectedBranch, setSelectedBranch] = useState<any>([]);
  const [selectedManagers, setSelectedManagers] = useState<any>([]);

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isBetween = useMediaQuery({ minWidth: 992, maxWidth: 1223 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 800 });

  const renderResponsiveCol = (type: string) => {
    if (newUser && JSON.parse(newUser).newUser) {
      return 12;
    } else if (isMobile) {
      return 24;
    } else if (isDesktopOrLaptop && type === 'branchs') {
      return 8;
    } else if ((isTablet || isBetween) && type === 'branchs') {
      return 12;
    } else if (isDesktopOrLaptop && type === 'jobs') {
      return 8;
    } else if ((isTablet || isBetween) && type === 'jobs') {
      return 12;
    } else if (
      data?.response?.dashboard?.evaluationTemplates?.count === 0 &&
      type === 'branches_rate'
    ) {
      return 12;
    } else if (isDesktopOrLaptop && type === 'branches_rate') {
      return 8;
    } else if (
      (isTablet || isBetween || isMobile) &&
      type === 'branches_rate'
    ) {
      return 24;
    }
  };

  const handleFilterParams = () => {
    let params: any = {
      template: selectedTemplate,
      from: selectedDate ? selectedDate[0] : null,
      to: selectedDate ? selectedDate[1] : null,
    };

    let newObj: any = {};
    Object.keys(params).forEach((item: any) => {
      if (params[item]) {
        newObj[item] =
          typeof params[item] === 'string' ? params[item] : params[item].value;
      }
    });

    const branchParams =
      selectedBranch &&
      selectedBranch.map((item: any) => item.value).join('&branch[]=');

    const managerParams =
      selectedManagers &&
      selectedManagers.map((item: any) => item.value).join('&manager[]=');

    const queryString = new URLSearchParams(newObj).toString();

    return selectedBranch.length &&
      selectedManagers.length &&
      queryString.length
      ? `branch[]=${branchParams}&manager[]=${managerParams}&${queryString}`
      : selectedBranch.length && selectedManagers.length
      ? `branch[]=${branchParams}&manager[]=${managerParams}`
      : selectedBranch.length && !selectedManagers.length
      ? `branch[]=${branchParams}&${queryString}`
      : selectedManagers.length
      ? `manager[]=${managerParams}&${queryString}`
      : `${queryString}`;
  };

  // templates
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const { data: templates } = useQuery(
    ['templates'],
    () => fetchGetRequests('api/company/v1/evaluation-templates/list'),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );
  const templatesOptions = templates?.response?.data?.templates.length
    ? templates?.response?.data?.templates.map((branch: any) => {
        return {
          value: branch.id,
          label: branch.name,
        };
      })
    : [];

  // dashboard filter action
  const { data, refetch, status } = useQuery(
    ['HomeDashboard'],
    () => fetchGetRequests(`api/company/v1/dashboard?${handleFilterParams()}`),
    {
      keepPreviousData: false,
      staleTime: 10000,
    }
  );

  // branches
  useEffect(() => {
    refetch();
  }, [selectedBranch, selectedDate, selectedTemplate, selectedManagers]);

  const managerParams =
    selectedManagers &&
    selectedManagers.map((item: any) => item.value).join('&manager_id[]=');

  const { data: branches }: any = useQuery(
    ['branchesListData', selectedManagers],
    () =>
      fetchGetRequests(
        selectedManagers.length
          ? `api/company/v1/branches?manager_id[]=${managerParams}`
          : 'api/company/v1/branches'
      ),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const { data: managers }: any = useQuery(
    ['managersListData', selectedTemplate],
    () =>
      fetchGetRequests(
        selectedTemplate
          ? `api/company/v1/managers?limit=1000&template_id=${selectedTemplate?.value}`
          : `api/company/v1/managers?limit=1000`
      ),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const managersOptions = managers?.response?.data?.managers.length
    ? managers?.response?.data?.managers.map((manager: any) => {
        return {
          value: manager.id,
          label: manager.name,
        };
      })
    : [];

  const branchesOptions = branches?.response?.data?.branches.length
    ? branches?.response?.data?.branches.map((branch: any) => {
        return {
          value: branch.id,
          label: branch.name,
        };
      })
    : [];

  const selectCustomStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: '#fff',
      borderRadius: '8px',
      borderColor: '#e8e8e8',
      minHeight: '50px',
      margin: '0',
      cursor: 'pointer',
      boxShadow: 'none',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
  };

  if (!data) {
    return <Spinner size='large' />;
  }

  const onRedirectSummary = () => {
    if (status) {
      window.open(
        `${window.location.origin}/home/summary/${handleFilterParams()}`
      );
    }
  };

  const SHOW_SUMMARY =
    selectedTemplate &&
    selectedBranch &&
    selectedBranch.length > 0 &&
    selectedDate &&
    selectedDate.length &&
    selectedDate[0].length > 0;

  return (
    <HomeWrapper>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {i18n.language === 'ar' ? 'الرئيسية - تدري' : 'Home - Tdree'}
        </title>
      </Helmet>
      <Header language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Title>
          <Trans>home</Trans>
        </Title>
      </Header>
      <>
        {newUser && JSON.parse(newUser).newUser && (
          <OnBoarding language={i18n.language === 'ar' ? 'ar' : 'en'}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* empty branches  */}
              <Col span={8} className='gutter-row'>
                <DashboardBranchesEmptyState />
              </Col>
              {/* empty templates  */}
              <Col span={8} className='gutter-row'>
                <DashboardEvaluationsEmptyState />
              </Col>
              {/* empty jobs  */}
              <Col span={8} className='gutter-row'>
                <DashboardJobsEmptyState />
              </Col>
            </Row>
          </OnBoarding>
        )}
        {!JSON.parse(newUser)?.newUser && (
          <>
            <FiltrationHeader>
              {/* date filter  */}
              <DateFilter>
                {/* @ts-ignore  */}
                <RangePicker
                  size='middle'
                  style={{
                    height: '48px',
                    borderRadius: '8px',
                    direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                  }}
                  placeholder={
                    i18n.language === 'ar'
                      ? ['بدء', 'انتهاء']
                      : ['Start', 'End']
                  }
                  separator={<>-</>}
                  // @ts-ignore
                  onChange={(date: any, dateString: string) =>
                    setSelectedDate(dateString)
                  }
                  format='DD-MM-YYYY'
                />
              </DateFilter>
              {/* branches filter */}
              <SelectFilter>
                <ReactSelect
                  className='branches-select'
                  options={branchesOptions}
                  onChange={(item: any) => {
                    setSelectedBranch(item);
                  }}
                  placeholder={t('all_branches')}
                  customStyles={selectCustomStyles}
                  isSearchable={true}
                  isClearable={true}
                  isMulti
                  closeMenuOnSelect={false}
                />
              </SelectFilter>
              <SelectFilter>
                <ReactSelect
                  className='managers-select'
                  options={managersOptions}
                  onChange={(item: any) => {
                    setSelectedManagers(item);
                  }}
                  placeholder={t('branch_manager')}
                  customStyles={selectCustomStyles}
                  isSearchable={true}
                  isClearable={true}
                  isMulti
                  closeMenuOnSelect={false}
                />
              </SelectFilter>
              {/* templates filter */}
              <SelectFilter>
                <ReactSelect
                  options={templatesOptions}
                  onChange={(item: any) => {
                    setSelectedTemplate(item);
                  }}
                  placeholder={t('evaluation_template')}
                  customStyles={selectCustomStyles}
                  isSearchable={true}
                  isClearable={true}
                />
              </SelectFilter>
            </FiltrationHeader>
            <Hint language={i18n.language === 'ar' ? 'ar' : 'en'}>
              <Trans>summary_filter_hint</Trans>
            </Hint>
            {/* details btn */}
            {SHOW_SUMMARY && (
              <DetailsButtonWrapper>
                <Button
                  type='default'
                  size='middle'
                  onClick={onRedirectSummary}
                >
                  <Trans>view_summary</Trans>
                </Button>
                <FilterDetailsInfo
                  language={i18n.language === 'ar' ? 'ar' : 'en'}
                >
                  <NormalText>
                    <Trans>summary_template_info</Trans>
                  </NormalText>
                  <BoldText>{selectedTemplate?.label}</BoldText>
                  {selectedDate && (
                    <NormalText>
                      <Trans
                        values={{
                          from: selectedDate[0],
                          to: selectedDate[1],
                        }}
                      >
                        period_from_to
                      </Trans>
                    </NormalText>
                  )}
                  {selectedBranch.length > 0 && (
                    <NormalText>
                      <Trans values={{ branch: selectedBranch?.label }}>
                        at_selected_branchs
                      </Trans>
                    </NormalText>
                  )}
                  {selectedManagers.length > 0 && (
                    <NormalText>
                      <Trans values={{ branch: selectedManagers?.label }}>
                        at_selected_managers
                      </Trans>
                    </NormalText>
                  )}
                </FilterDetailsInfo>
              </DetailsButtonWrapper>
            )}
          </>
        )}
        <Wrapper language={i18n.language === 'ar' ? 'ar' : 'en'}>
          {data?.response?.dashboard?.shoppersCount > 0 && (
            <StyledAlert
              message={
                i18n.language === 'ar'
                  ? `${data?.response.dashboard.shoppersCount} متسوق سري يقومون بأنجاز مهامك الآن`
                  : `${data?.response.dashboard.shoppersCount} secret shoppers doing your tasks now`
              }
              type='info'
              showIcon
              closable
              style={
                i18n.language === 'ar'
                  ? { direction: 'rtl' }
                  : { direction: 'ltr' }
              }
              icon={<LiveIcon />}
            />
          )}
          <Row gutter={[18, 18]}>
            {/* branches  */}
            <Col span={renderResponsiveCol('branchs')} className='gutter-row'>
              <BranchesCount branches={data?.response?.dashboard?.branches} />
              <BranchRate
                data={data?.response?.dashboard?.branches?.mostVisited}
                icon={<MostVisitedIcon />}
                title='most_visited_branches'
                type='visit'
              />
              <BranchRate
                data={data?.response?.dashboard?.branches?.leastVisited}
                icon={<LeastVisitedIcon />}
                title='least_visited_branches'
                type='visit'
              />
            </Col>
            {/* jobs  */}
            <Col span={renderResponsiveCol('jobs')} className='gutter-row'>
              <JobsCount job={data?.response?.dashboard?.visits} />
              <VerticalBar
                title='jobs_count'
                data={data?.response?.dashboard?.jobs?.monthCount}
                count={data?.response?.dashboard?.jobs?.count}
                width='100%'
              />
              <Job jobs={data?.response?.dashboard?.visits} />
            </Col>
            {/* branches rate */}
            {!JSON.parse(newUser)?.newUser && (
              <Col
                span={renderResponsiveCol('branches_rate')}
                className='gutter-row'
              >
                {data?.response?.dashboard?.resultAverage ? (
                  <>
                    <VerticalBar
                      title={'result_rate'}
                      count={data?.response?.dashboard?.branches?.count}
                      data={
                        data?.response?.dashboard?.resultAverage
                          ?.branchResultsByMonth
                      }
                      width='100%'
                    />
                    <BranchRate
                      data={
                        data?.response?.dashboard?.resultAverage?.topBranches
                      }
                      icon={<TopResult />}
                      title='top_branches_results'
                      type='result'
                    />
                    <BranchRate
                      data={
                        data?.response?.dashboard?.resultAverage?.leastBranches
                      }
                      icon={<LowResult />}
                      title='least_branches_results'
                      type='result'
                    />
                  </>
                ) : (
                  <AddEvaluationTemplate />
                )}
              </Col>
            )}
          </Row>
        </Wrapper>
      </>
      <LastUpdate>
        <Pargraph>
          {timeStampToDate(data?.response?.dashboard?.last_updated_at)}
        </Pargraph>
        <Pargraph>
          <Trans>last_update</Trans>
        </Pargraph>
      </LastUpdate>
    </HomeWrapper>
  );
};
