/** @format */

import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { StyledTextArea } from "./style";

const TextArea = forwardRef<HTMLInputElement, any>((props: any, ref) => {
  const { i18n } = useTranslation();
  return (
    <StyledTextArea
      {...props}
      ref={ref}
      dir="auto"
      language={i18n.language === "ar" ? "ar" : "en"}
    />
  );
});

export default TextArea;
