import * as React from "react";

function TimeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={21}
      viewBox="0 0 21 21"
    >
      <g
        transform="translate(1.028 1)"
        stroke="#FFAF00"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      >
        <circle cx={9.5} cy={9.5} r={9.25} />
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.75 4.25v6h4" />
      </g>
    </svg>
  );
}

export default TimeIcon;
