import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";

export const BillContainer = styled.div`
  width: 550px;
  margin: 0 auto;
  padding: 40px 36px 0px 35px;
  border-radius: 16px;
  box-shadow: 0 0 30px 0 #d3cdcd;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid white;
  position: relative;
  background: white;
  direction: rtl;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const QRWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  background-color: #f5f4f3;
  padding: 8px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type Props = {
  fontSize?: string;
  fontWeight?: string;
};
export const Pargraph = styled.p<Props>`
  font-family: Famtree;
  margin: 0;
  font-weight: ${(props: any) =>
    props.fontWeight ? props.fontWeight : "normal"};
  color: ${(props: { color?: string }) =>
    props.color ? props.color : Colors.charcoal_grey_54};
  font-size: ${(props: any) => (props.fontSize ? props.fontSize : "14px")};
  margin-bottom: 8px;
`;

export const LogoWrappper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const CompanyName = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Th = styled.th`
  font-size: 9px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  padding: 5px;
  background-color: #5eaf63;
  color: #fff;
  white-space: wrap;
`;

export const Td = styled.td`
  font-size: 8px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  text-align: center;
`;

export const SmallPargraph = styled.p`
  font-size: 8px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  text-align: right;
`;

export const Tr = styled.tr`
  border-bottom: 1px solid #dddddd;
`;

export const Space = styled.span`
  margin: 5px 0;
`;

export const Hr = styled.div`
  width: 95%;
  background-color: rgb(221, 221, 221);
  height: 0.5px;
`;

export const BillPayments = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top: 25px;
`;

export const Table = styled.table`
  width: 100%;
  font-family: Famtree;

  border-collapse: collapse;
`;
export const Payments = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: end;
  margin: 15px 25px 35px 25px;
`;

export const Payment = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
`;

export const Footer = styled.div`
  width: 100%;
  bottom: 10px;
  right: 25px;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
`;

export const RightContainer = styled.div`
  width: 40%;
  margin-top: 10px;
`;

export const LeftContainer = styled.div`
  margin-top: 30px;
`;

export const Bill = styled.div`
  display: none;
`;

export const SAR = styled.span`
  text-align: left;
`;

export const PromoPargraph = styled.p`
  font-size: 8px;
  font-weight: normal;
  color: ${Colors.lipstick};
  font-family: Famtree;
  text-align: right;
`;

export const AmountValue = styled.span`
  margin: 0 3px;
`;
