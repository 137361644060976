/** @format */

import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { StyledTabs as AntDTabs, TapsWrapper } from "./style";

const { TabPane } = AntDTabs;

type Props = {
  tabOneTitle?: ReactNode;
  tabTwoTitle?: ReactNode;
  tabThreeTitle?: ReactNode;
  tabOneContent?: ReactElement;
  tabTwoContent?: ReactElement;
  tabThreeContent?: ReactElement;
  type?: "line" | "card" | "editable-card" | undefined;
  centered?: boolean;
  className?: string | undefined | any;
  language?: string;
  onTabClick?: () => void;
  defaultActiveKey?: string;
};

const Tabs = ({
  tabOneTitle,
  tabTwoTitle,
  tabThreeTitle,
  tabOneContent,
  tabTwoContent,
  tabThreeContent,
  type,
  centered,
  className,
  onTabClick,
  defaultActiveKey,
}: Props) => {
  const { i18n } = useTranslation();
  return type === "card" ? (
    <TapsWrapper>
      <AntDTabs
        defaultActiveKey={defaultActiveKey}
        centered={centered}
        type={type}
        language={i18n.language === "ar" ? "ar" : "en"}
        onTabClick={onTabClick}
        className={className}
      >
        <TabPane tab={tabOneTitle} key="1">
          {tabOneContent}
        </TabPane>
        <TabPane tab={tabTwoTitle} key="2">
          {tabTwoContent}
        </TabPane>
        {tabThreeContent && (
          <TabPane tab={tabThreeTitle} key="3">
            {tabThreeContent}
          </TabPane>
        )}
      </AntDTabs>
    </TapsWrapper>
  ) : (
    <AntDTabs
      defaultActiveKey="1"
      centered={centered}
      type={type}
      language={i18n.language === "ar" ? "ar" : "en"}
      onTabClick={onTabClick}
      className={className}
    >
      <TabPane tab={tabOneTitle} key="1">
        {tabOneContent}
      </TabPane>
      <TabPane tab={tabTwoTitle} key="2">
        {tabTwoContent}
      </TabPane>
      <TabPane tab={tabThreeTitle} key="3">
        {tabThreeContent}
      </TabPane>
    </AntDTabs>
  );
};

export default React.memo(Tabs);
