import React from "react";

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip);

const PieChart = ({ data }: any) => {
  return (
    <Pie
      data={data}
      options={{
        responsive: true,
        interaction: {
          mode: "nearest",
        },
        plugins: {
          legend: {
            position: "top",
            display: false,
            labels: {
              font: {
                size: 13,
                family: "Famtree",
                weight: "normal",
              },
              color: "#344247",
              textAlign: "left",
            },
          },
          tooltip: {
            intersect: true,
            yAlign: "top",
            displayColors: true,
          },
        },
      }}
    />
  );
};

export default PieChart;
