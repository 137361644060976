import React from "react";

function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      viewBox="0 0 6 10"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g stroke="#D9D9D7" strokeWidth="1.5" transform="translate(-1430 -169)">
          <g transform="translate(1415)">
            <g transform="translate(16 170)">
              <path d="M3.9 0.1L0 4 3.9 7.9"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BackIcon;
