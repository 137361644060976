/** @format */

import * as React from "react";
import { SVGProps } from "react";

const LogoWithTdree = (props: SVGProps<SVGSVGElement>) => (
  <svg width={75} height={28} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <text
        fontFamily="Famtree-Regular, Famtree"
        fontSize={20.407}
        color="black"
        fill="#344247"
      >
        <tspan x={0} y={20} color="black">
          {"\u062A\u062F\u0631\u064A"}
        </tspan>
      </text>
      <path
        d="M62.848 2.126c5.636 0 10.206 4.52 10.206 10.097a9.99 9.99 0 0 1-2.289 6.371l.03.032 3.7 4.065a1.935 1.935 0 0 1-.144 2.747 1.976 1.976 0 0 1-2.776-.146l-3.7-4.065a1.958 1.958 0 0 1-.121-.147 10.258 10.258 0 0 1-4.906 1.24c-5.637 0-10.206-4.52-10.206-10.097s4.57-10.097 10.206-10.097z"
        fill="#44C874"
      />
      <path
        d="M62.848 7.503c2.635 0 4.77 2.113 4.77 4.72 0 2.606-2.135 4.72-4.77 4.72s-4.77-2.114-4.77-4.72c0-.59.109-1.156.309-1.676a1.974 1.974 0 0 0 2.72.088l.015-.014c.79-.719.848-1.942.129-2.733l-.078-.086a4.806 4.806 0 0 1 1.675-.299z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default LogoWithTdree;
