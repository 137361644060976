/** @format */

import * as React from "react";
import { SVGProps } from "react";

const FileUploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={38} height={38} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#F2F4F7" cx={19} cy={19} r={19} />
      <path
        d="M22.76 12h-5.256a.43.43 0 0 0-.309.13l-3.066 3.066a.43.43 0 0 0-.129.308v8.761c0 .966.786 1.752 1.752 1.752h7.009c.966 0 1.752-.786 1.752-1.752V13.752c0-.966-.786-1.752-1.752-1.752zm.877 12.265a.876.876 0 0 1-.876.876h-7.009a.876.876 0 0 1-.876-.876v-8.322h2.628a.44.44 0 0 0 .438-.438v-2.629h4.819c.484 0 .876.392.876.876v10.513z"
        stroke="#344247"
        strokeWidth={0.2}
        fill="#344247"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default FileUploadIcon;
