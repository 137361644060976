/** @format */

import * as React from "react";
import { SVGProps } from "react";

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={17} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.417 8.5c0-3.903 3.18-7.083 7.083-7.083s7.083 3.18 7.083 7.083-3.18 7.083-7.083 7.083-7.083-3.18-7.083-7.083zM8.5 11.865c.29 0 .531-.241.531-.532V7.792c0-.29-.24-.532-.531-.532a.535.535 0 0 0-.531.532v3.541c0 .29.24.532.531.532zm.503-6.701a.818.818 0 0 0-.234-.149.705.705 0 0 0-.269-.057.705.705 0 0 0-.27.057.818.818 0 0 0-.233.149.731.731 0 0 0-.149.234.705.705 0 0 0-.056.269c0 .092.02.184.056.269a.818.818 0 0 0 .15.234c.07.063.148.113.233.148.17.071.368.071.538 0a.818.818 0 0 0 .234-.148.818.818 0 0 0 .149-.234.705.705 0 0 0 .056-.27.705.705 0 0 0-.056-.268.731.731 0 0 0-.15-.234z"
      fill="#344247"
      fillRule="evenodd"
    />
  </svg>
);

export default InfoIcon;
