/** @format */

import React from 'react';
import { LeftContainer, RightContainer, StyledContainer } from '../style';
import { ResetPasswordForm } from './form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { axiosApiInstance } from 'infrastructure/utils/api';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import { useParams, useNavigate } from 'react-router-dom';
import TadreeComponent from 'assets/svg/tdree-component';
import { useMediaQuery } from 'react-responsive';

const { useState } = React;

export const ResetPassowrd = () => {
  const { t } = useTranslation();
  const token: any = useParams();
  const isMobile = useMediaQuery({ maxWidth: 800 });

  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState(false);

  const { handleSubmit, register, errors, formState, watch } = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
  });
  const [isLoading, setIsloading] = useState<boolean>(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const onSubmit = (e: {
    email: any;
    password: any;
    confirm_password: any;
  }) => {
    setIsloading(true);

    axiosApiInstance
      .post('api/company/reset-password', {
        email: e.email,
        password: e.password,
        password_confirmation: e.confirm_password,
        token: token[0],
      })
      .then(() => {
        setIsloading(false);

        showNotification(
          NotificationStatus.Success,
          t('done'),
          t('done_message')
        );
        navigate('/login');
      })
      .catch((error) => {
        setIsloading(false);

        showNotification(
          NotificationStatus.Error,
          t('error'),
          error?.response.data.message
        );
      });
  };

  return (
    <StyledContainer>
      <LeftContainer>
        <ResetPasswordForm
          onSubmit={handleSubmit(onSubmit)}
          errors={errors}
          register={register}
          watch={watch}
          formState={formState}
          loading={isLoading}
          togglePassowrdVisiblity={togglePasswordVisiblity}
          passwordShown={passwordShown}
        />
      </LeftContainer>

      {!isMobile && (
        <RightContainer>
          <TadreeComponent />
        </RightContainer>
      )}
    </StyledContainer>
  );
};
