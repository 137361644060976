/** @format */

import React from "react";
import { Navigate } from "react-router-dom";
import Layout from "common/Layout";
import storage from "infrastructure/utils/storage";

export const ProtectedRoutes = ({
  redirectPath = "/",
  children,
  name,
}: any) => {
  const isAuthed = !!storage.getItem("access_token");

  if (!isAuthed) {
    return <Navigate to={redirectPath} replace />;
  }

  return name ? children : <Layout>{children}</Layout>;
};
