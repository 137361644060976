import * as React from "react";
import { SVGProps } from "react";

const EvaluationTemplateEmptyLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width={170}
    height={100}
    viewBox="0 0 830.567 711.727"
    {...props}
  >
    <path
      fill="#e6e6e6"
      d="M68.348 169.893H455v126.931h-14.646v33.197l-33.197-33.197H68.348V169.893z"
    />
    <path fill="#fff" d="M79.088 182.253H444.26v101.545H79.088z" />
    <path fill="#43c773" d="M108.453 211.9h175.821v5.336H108.453z" />
    <path
      fill="#e6e6e6"
      d="M108.453 230.358h306.441v5.336H108.453zM108.453 248.816h306.141v5.336H108.453zM386.652 339.785H0v126.932h14.646v33.197l33.197-33.197h338.809V339.785z"
    />
    <path fill="#fff" d="M10.74 352.146h365.172v101.545H10.74z" />
    <path fill="#43c773" d="M43.035 382.769h175.821v5.336H43.035z" />
    <path
      fill="#e6e6e6"
      d="M43.035 401.227h306.441v5.336H43.035zM43.035 419.685h306.141v5.336H43.035zM386.652 0H0v126.931h14.646v33.198l33.197-33.198h338.809V0z"
    />
    <path fill="#fff" d="M10.74 12.361h365.172v101.545H10.74z" />
    <path fill="#43c773" d="M43.035 42.984h175.821v5.336H43.035z" />
    <path
      fill="#e6e6e6"
      d="M43.035 61.442h306.441v5.336H43.035zM43.035 79.9h306.141v5.336H43.035z"
    />
    <path
      fill="#ffb8b8"
      d="m679.314 695.51-15.913-.001-7.571-61.383 23.487.002-.003 61.382z"
    />
    <path
      d="m683.373 710.936-51.313-.002v-.65a19.974 19.974 0 0 1 19.972-19.971h31.342Z"
      fill="#2f2e41"
    />
    <path
      fill="#ffb8b8"
      d="m656.939 649.359-14.808 5.827-29.523-54.346 21.856-8.6 22.475 57.119z"
    />
    <path
      d="m666.365 662.227-47.75 18.79-.237-.605a19.974 19.974 0 0 1 11.271-25.898h.001l29.164-11.476Z"
      fill="#2f2e41"
    />
    <path
      d="m652.09 675.565 5.066-186.225-22.548 79.833 16.292 55.393-27.029 22.975-27.552-87.905-.049-.155 27.385-144.747 80.99-3.918.58-.03.093.573c.078.482 7.732 48.542 1.295 83.298-6.411 34.623-21.904 181.496-22.06 182.976l-.066.632Z"
      fill="#2f2e41"
    />
    <path
      d="m627.85 426.344-.457-.182 15.03-137.885.439-.11c15.275-3.819 35.855-3.485 46.764-2.947a7.205 7.205 0 0 1 6.748 6.093l18.07 119.012-.149.213c-12.493 17.846-31.581 22.697-48.649 22.697a115.2 115.2 0 0 1-37.796-6.89Z"
      fill="#43c773"
    />
    <path
      d="m699.426 466.628-.543-.05-9.6-181.714 29.17 6.588a13.173 13.173 0 0 1 10.126 14.504l-3.783 30.205 1.297 53.139c.903 3.264 15.673 57.335 7.467 71.424a6.544 6.544 0 0 1-4.063 3.292 101.773 101.773 0 0 1-24.057 2.815c-3.683-.001-5.958-.198-6.014-.203ZM613.523 467.86a10.32 10.32 0 0 1-5.78-6.866c-1.901-7.653 4.516-17.277 5.442-18.613l7.762-73.733-11.545-68.115a8.681 8.681 0 0 1 7.597-10.079l32.884-3.667-1.67 70.072c14.114 47.705-16.622 116.489-16.934 117.179l-.173.381h-.418c-.166 0-4.197-.075-17.165-6.559Z"
      fill="#3f3d56"
    />
    <circle
      cx={850.518}
      cy={335.048}
      r={31.882}
      transform="rotate(-28.663 573.929 649.483)"
      fill="#ffb8b8"
    />
    <path
      d="M702.308 433.742a12.172 12.172 0 0 1 12.192-14.131l11.035-25.532L742.687 397l-16.09 35.838a12.238 12.238 0 0 1-24.289.904Z"
      fill="#ffb8b8"
    />
    <path
      d="m717.846 410.347 9.113-54.677-15.566-25.943 3.965-37.012.883-.22a13.251 13.251 0 0 1 15.112 7.104l25.428 53.066.101.212-18.468 72.554Z"
      fill="#3f3d56"
    />
    <path
      d="M686.698 266.62c.728.212 3.788-3.244 7.138-7.386a38.472 38.472 0 0 0 8.02-31.252c-.047-.247-.093-.481-.14-.701-1.109-5.28-6.146-10.144-11.424-9.026.398-4.678-2.752-9.084-6.746-11.553s-8.724-3.384-13.338-4.255c-5.263-.993-10.592-1.99-15.938-1.652a31.108 31.108 0 0 0-14.533 4.642 30.683 30.683 0 0 0-12.886 33.15c6.717-7.628 14.678-15.925 26.183-12.619a21.11 21.11 0 0 1 7.16 3.744c6.992 5.493 10.507 13.58 13.908 21.484 1.699-4.325 8-5.953 11.58-2.99 2.299 1.9 3.245 5.061 3.158 8.042a29.615 29.615 0 0 1-2.042 8.675M682.586 385.727h-1.5v-5.9a6.606 6.606 0 0 0-6.598-6.6h-18.803a6.606 6.606 0 0 0-6.599 6.6v40.802a6.606 6.606 0 0 0 6.6 6.598h18.802a6.606 6.606 0 0 0 6.598-6.598v-27.902h1.5Z"
      fill="#2f2e41"
    />
    <circle cx={665.586} cy={392.727} r={4} fill="#fff" />
    <path
      d="M668.85 408.666a12.172 12.172 0 0 0-18.059-4.713l-23.578-14.756-12.461 12.142 33.59 20.37a12.238 12.238 0 0 0 20.509-13.043Z"
      fill="#ffb8b8"
    />
    <path
      d="m584.519 380.557 21.575-81.505a8.724 8.724 0 0 1 9.945-6.32l7.418 1.348 7.905 43.473-16.076 36.643 29.079 17.458-4.09 32.711ZM829.567 711.727h-381a1 1 0 0 1 0-2h381a1 1 0 0 1 0 2Z"
      fill="#3f3d56"
    />
  </svg>
);

export default EvaluationTemplateEmptyLogo;
