/** @format */

import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { timeStampToDate } from "infrastructure/helpers/timeStampToDate";
import { Hr } from "../../CompanyBranch/BranchDetails/style";
import { ShoppersDataContainer, Question } from "../style";
import { useParams } from "react-router-dom";
import { QuestionContainer } from "pages/style";
import { mockedArr } from "infrastructure/helpers/questionTypeList";
import InfiniteScroll from "react-infinite-scroll-component";
import { Empty } from "common/Empty";
import { CarouselComponent } from "common/Carousel";
import { SmallCard } from "common/Card";
import PrintPDF from "components/ReactPdf";
import PdfCoverr from "components/ReactPdf/cover";
import EvaluationCategory from "components/Summary/EvaluationCategroy";
import QuestionAnswer from "components/Summary/QuestionAnswer";
import ExtraQuestions from "components/Summary/ExtraQuestions";
import NotAnswredQuestions from "components/Summary/NotAnswredQuestions";
import ShopperInfo from "components/Summary/ShopperInfo";
import { useQuery } from "react-query";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";
import { useStore } from "infrastructure/zustandStore/evaluation-template-store";
import { jobSummaryUseStore } from "infrastructure/zustandStore/job-summary-store";
import Spinner from "common/LoadingIndecator";
import { shoppersUseStore } from "infrastructure/zustandStore/shoppers-store";
import { List as AntDList, Skeleton } from "antd";
import { axiosApiInstance } from "infrastructure/utils/api";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const ShoppersDetails = ({
  branch,
  startTime,
  endTime,
  shoppersCount,
}: {
  branch: { name: string };
  startTime: string;
  endTime: string;
  shoppersCount: number;
}) => {
  let { id } = useParams();
  const [shopperData, setShopperData] = useState<any>();
  const [shopperIds, setShopperIds] = useState<Array<number>>([]);
  // eslint-disable-next-line
  const [shoppersQuestions, setShoppersQuestions] = useState<any>([]);
  const [selectedShopper, setSelectedShoper] = useState<any>(null);

  const [questionLoading, setQuestionLoading] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<any>(null);

  const { i18n } = useTranslation();

  const { data, isLoading } = useQuery("shoppers", () =>
    fetchGetRequests(`api/company/v2/jobs/${id}/shoppers`)
  );

  const [shoppers, setShoppers, loading, setIsLoading] = shoppersUseStore(
    (state: any) => [
      state.shoppers,
      state.setShoppers,
      state.loading,
      state.setIsLoading,
    ]
  );

  useEffect(() => {
    if (data) {
      setShoppers(
        data?.response.data.shoppers.filter((item: any) => item.status.id !== 4)
      );
      setIsLoading(isLoading);
    }
  }, [data, isLoading, shopperData?.id]);

  const getShopperId = (item: any) => {
    setSelectedShoper(item.id);

    setShopperData(item);
  };

  const [template] = useStore((state: any) => [state.template]);

  const [jobSummaryData] = jobSummaryUseStore((state: any) => [
    state.jobSummaryData,
  ]);

  const handleShopperQuestions = (shopperId) => {
    return fetchGetRequests(
      `api/company/v2/jobs/${id}/shoppers/${shopperId}/questions`
    ).then((res) => {
      setShoppersQuestions(res.response.data.questions);
    });
  };

  const loadMoreData = async () => {
    if (questionLoading) {
      return;
    }
    setQuestionLoading(true);
    try {
      const response = await axiosApiInstance.get(
        `api/company/v2/jobs/${id}/shoppers/${selectedShopper}/questions/?page=${page}`
      );

      setItems((prevItems) => [...prevItems, ...response.data.data.questions]);
      setPagination(response.data.data.pagination);
      setQuestionLoading(false);

      if (page <= response.data.data.pagination.total_pages) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
    } finally {
      setQuestionLoading(false);
    }
  };

  useEffect(() => {
    if (selectedShopper) {
      loadMoreData();
    }
  }, [selectedShopper]);

  // eslint-disable-next-line
  let scale;

  items.length &&
    items.map((question: any, index: number) => {
      scale = {
        min: question.scale?.min,
        step: question.scale?.step,
        max: question.scale?.max,
      };

      function move(input: any[], from: any, to: any) {
        let numberOfDeletedElm = 1;

        const elm = input.splice(from, numberOfDeletedElm)[0];

        numberOfDeletedElm = 0;

        input.splice(to, numberOfDeletedElm, elm);
      }

      if (question?.options) {
        const lastElement = question?.options[question?.options?.length - 1];

        const lastIndex = question?.options.findIndex(
          (el) => el === lastElement
        );
        const indexOf = question?.options.findIndex((object) => {
          return object.option === "جميع الاجابات لا تنطبق";
        });
        move(question?.options, indexOf, lastIndex);
      }
    });

  const renderShopperData = () => {
    if (shopperData) {
      return (
        <InfiniteScroll
          style={{ height: "100%", overflowX: "hidden" }}
          dataLength={items?.length || 0}
          next={loadMoreData}
          hasMore={pagination?.has_more || false}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          scrollableTarget="scrollableDiv"
        >
          <PrintPDF>
            <PdfCoverr
              template_name={template?.data?.name}
              description={template?.data?.description}
              branch_name={branch?.name}
              start_time={timeStampToDate(startTime)}
              end_time={timeStampToDate(endTime)}
              shoppers_number={shoppersCount}
              questions_number={template?.data?.questions?.length}
              focus_of_evaluation={template?.data?.categories.map(
                (category: any, index: any) => (
                  <EvaluationCategory key={index} category={category} />
                )
              )}
              summary_point_type={template?.data?.type === "points"}
              show_summary={true}
              show_shopper_info={true}
              percentage={jobSummaryData?.job?.branchRate?.percentage}
              questionCategories={
                jobSummaryData?.job?.jobSummary?.questionCategories
              }
              pageHeight="100vh"
              shopperData={shopperData}
            />
            <ShopperInfo
              show_in_print={false}
              shopperData={shopperData}
              id={id}
            />
            <ShoppersDataContainer
              language={i18n.language === "ar" ? "ar" : "en"}
            >
              <AntDList
                dataSource={items}
                grid={{
                  gutter: 8,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1,
                  xxl: 1,
                }}
                renderItem={(item: any, index: number) => (
                  <AntDList.Item key={index}>
                    <div className="page-break" key={index}>
                      <QuestionContainer
                        language={i18n.language === "ar" ? "ar" : "en"}
                      >
                        {item.question.category && (
                          <EvaluationCategory
                            category={item.question.category}
                          />
                        )}
                        <QuestionAnswer
                          type={template?.data?.type}
                          question={item?.question}
                          language={i18n.language === "ar" ? "ar" : "en"}
                          index={index}
                          mockedArr={mockedArr}
                        />
                        <NotAnswredQuestions
                          question={item}
                          language={i18n.language === "ar" ? "ar" : "en"}
                        />
                        <ExtraQuestions
                          question={item.question}
                          language={i18n.language === "ar" ? "ar" : "en"}
                          index={index}
                          type="details"
                        />
                      </QuestionContainer>
                      <Hr />
                    </div>
                  </AntDList.Item>
                )}
              />
            </ShoppersDataContainer>
          </PrintPDF>
        </InfiniteScroll>
      );
    } else {
      return (
        <ShoppersDataContainer>
          <Empty
            description={
              <Question>
                <Trans>no_data</Trans>
              </Question>
            }
          />
        </ShoppersDataContainer>
      );
    }
  };

  if (loading) return <Spinner size={"large"} tip={"Loading..."} />;

  return shoppers?.data?.length > 0 ? (
    <>
      <CarouselComponent
        responsive={responsive}
        className={
          shoppers.data.length === 1 || shoppers.data.length === 2
            ? "rtl"
            : null
        }
      >
        {shoppers.data.map((item: any) => {
          return (
            <SmallCard
              key={item.id}
              title={item?.name}
              address={item.city + "," + item.country}
              status={item.status}
              rating={item.rating}
              image={item?.image}
              jobId={item.JobId}
              isActive={shopperIds.includes(item.id)}
              onClick={() => {
                getShopperId(item);
                setShopperIds((prev: any) => {
                  const found = prev.findIndex(
                    (value: number) => item.id === value
                  );
                  if (found !== -1) {
                    return prev.filter((value: number) => item.id !== value);
                  }
                  return [item.id];
                });
                handleShopperQuestions(item.id);
              }}
              className="shopperDetails"
            />
          );
        })}
      </CarouselComponent>

      {renderShopperData()}
    </>
  ) : (
    <Empty
      description={
        <Question>
          <Trans>no_shoppers_data</Trans>
        </Question>
      }
    />
  );
};

export default React.memo(ShoppersDetails);
