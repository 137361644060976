/** @format */

import { Progress } from "antd";
import styled from "styled-components";

export const StyledProgress = styled(Progress)`
  &.ant-progress-line {
    direction: rtl;
  }
  &.ant-progress-circle .ant-progress-inner {
    width: 200px !important;
    height: 200px !important;
    @media (max-width: 767px) {
      width: 150px !important;
      height: 150px !important;
    }
    @media print {
      width: 150px !important;
      height: 150px !important;
    }
  }
  .ant-progress-text {
    top: 33%;
  }
`;
