/** @format */

import React, { useEffect } from "react";
import EmptyTemplate from "common/EmptyTemplate";
import { Header, Title } from "../style";
import Colors from "infrastructure/utils/Colors";
import styled from "styled-components";
import { Input } from "common/Input";
import { useState } from "react";
import TextArea from "common/TextArea";
import { ButtonContainer } from "pages/User/style";
import { Button } from "common/Button";
import { Trans, useTranslation } from "react-i18next";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  showNotification,
  NotificationStatus,
} from "infrastructure/helpers/showNotifications";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";

type Props = {
  name: string;
  value: null | string | any;
};

const ContactUs = () => {
  const { t, i18n } = useTranslation();
  const { handleSubmit, formState, reset, register, setValue, errors } =
    useForm<{
      message: string;
      email: string;
      name: string;
      from: string;
      defaultValues: any;
    }>({
      mode: "onBlur",
      criteriaMode: "all",
      defaultValues: {
        message: "",
      },
    });

  const [data, setData] = useState<{
    name?: string;
    email?: string;
    message: string;
    from?: string;
  }>({
    name: "",
    email: "",
    from: "companies",
    message: "",
  });

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e: { target: Props }) => {
    const { name, value }: Props = e.target;
    const updatedData: any = { ...data };
    updatedData[name] = value;
    setData(updatedData);
  };

  const handleSendFormRequest = () => {
    setLoading(true);
    axiosApiInstance
      .post("api/contact-us", data)
      .then(() => {
        setLoading(false);
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
        reset({
          name: "",
          email: "",
          from: "companies",
        });

        setData({
          message: "",
        });
      })
      .catch((err) => {
        setLoading(false);
        showNotification(
          NotificationStatus.Error,
          t("err"),
          err?.response?.data.message
        );
      });
  };

  useEffect(() => {
    register("message", {
      validate: (value: string) => value || "This is required.",
    });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar" ? "تواصل معنا -تدري" : "Contact us - Tdree"}
        </title>
      </Helmet>
      <Header language={i18n.language === "ar" ? "ar" : "en"}>
        <Title>
          <Trans>contact-us</Trans>
        </Title>
      </Header>

      <EmptyTemplate className="help">
        <Container>
          <ContactUsForm language={i18n.language === "ar" ? "ar" : "en"}>
            <P>
              <Trans>contact-info</Trans>
            </P>
            <Ul>
              <Li>
                <I className="fa fa-tint" />
                <span id="ctl00_ctl54_g_b89391f9_0959_48f2_b83d_ba4426adecd4_lblAddress">
                  <Trans>address</Trans>
                </span>
                <span className="li-divider">:</span>
                <Trans>company-address</Trans>
              </Li>
              <Li>
                <I className="fa fa-mobile" />
                <span id="ctl00_ctl54_g_b89391f9_0959_48f2_b83d_ba4426adecd4_lblPhone">
                  <Trans>business_mobile</Trans>
                </span>
                <span className="li-divider">:</span>
                <span className="en-family">
                  {i18n.language === "ar" ? "966501325644+" : "+966501325644"}
                </span>
              </Li>
              <Li>
                <span>
                  <I className="fa fa-fax" />
                  <span
                    id="ctl00_ctl54_g_b89391f9_0959_48f2_b83d_ba4426adecd4_lblFax"
                    style={{ fontFamily: "Famtree" }}
                  >
                    <Trans>website</Trans>
                  </span>
                  <span className="li-divider">:</span>
                </span>
                <a className="en-family" href="https://tdree.com">
                  https://tdree.com
                </a>
              </Li>
              <li>
                <I className="fa fa-envelope" />
                <span
                  id="ctl00_ctl54_g_b89391f9_0959_48f2_b83d_ba4426adecd4_lblEmail"
                  style={{ fontFamily: "Famtree" }}
                >
                  <Trans>email</Trans>
                </span>
                <span className="li-divider">:</span>
                <span className="en-family">
                  <a href="mailto:info@mohesr.gov.eg"> support@tdree.com</a>
                </span>
              </li>
            </Ul>
          </ContactUsForm>

          <FormBox
            onSubmit={handleSubmit(handleSendFormRequest)}
            language={i18n.language === "ar" ? "ar" : "en"}
          >
            <P>
              <Trans>contact-us</Trans>
            </P>
            <InputsContainer>
              <Input
                placeholder={t("email")}
                width="login"
                type="text"
                name="email"
                onChange={(e: any) => handleInputChange(e)}
                errorName={errors.email}
                ref={register({
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors.email && (
                <Error language={i18n.language === "ar" ? "ar" : "en"}>
                  <Trans>required_error_message</Trans>
                </Error>
              )}
              {errors?.email?.types?.pattern && (
                <Error language={i18n.language === "ar" ? "ar" : "en"}>
                  <Trans>patternـisـnotـcorrect</Trans>
                </Error>
              )}
              <Input
                placeholder={t("name")}
                width="login"
                type="text"
                name="name"
                onChange={(e: any) => handleInputChange(e)}
                errorName={errors.name}
                ref={register({
                  required: true,
                })}
              />
              {errors.name && (
                <Error language={i18n.language === "ar" ? "ar" : "en"}>
                  <Trans>required_error_message</Trans>
                </Error>
              )}
            </InputsContainer>
            <TextArea
              name="message"
              onChange={(e: { target: any }) => {
                handleInputChange(e);
                setValue("message", e);
              }}
              placeholder={t("message")}
              value={data.message}
              inputRef={register({ required: true })}
              errorName={errors.message}
              height="100px"
            />
            {errors.message && (
              <Error language={i18n.language === "ar" ? "ar" : "en"}>
                <Trans>required_error_message</Trans>
              </Error>
            )}
            <ButtonContainer>
              <Button
                type="primary"
                onClick={handleSubmit(handleSendFormRequest)}
                htmlType="submit"
                disabled={formState.isSubmitting}
                loading={loading}
              >
                <Trans>send</Trans>
              </Button>
            </ButtonContainer>
          </FormBox>
        </Container>
      </EmptyTemplate>
    </>
  );
};

export default ContactUs;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const FormBox = styled.div`
  float: right;
  background-color: #fff;
  padding: 25px;
  border: 1px solid #ecebeb;
  diplay: flex;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const P = styled.h3`
  font-family: Famtree;
  font-weight: normal;
  font-size: 25px;
`;

const ContactUsForm = styled.div`
  float: right;
  background-color: #fff;
  padding: 25px;
  border: 1px solid #ecebeb;
  height: 300px;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};

  margin-bottom: 30px;
`;

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const Li = styled.li`
  width: 100%;
  margin-bottom: 15px;
  .li-divider {
    margin: 0 2px;
  }
`;

const I = styled.i`
  color: ${Colors.dark_mint};
  width: 30px;
  padding-top: 2px;
  font-size: 18px;
  text-align: center;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Error = styled.p`
  font-family: Famtree;
  color: ${Colors.darkish_pink};
  margin: 0;
  font-size: 13px;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  flex: 0;
  margin-bottom: 20px;
`;
