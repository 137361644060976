/** @format */

import React from "react";
import LogoWithTdree from "assets/svg/logo with tdree";
import MenuIcon from "assets/svg/menu-icon";
import { Header } from "./styles";
import { MenuProps } from "antd";
import { Dropdown } from "common/Dropdown";
import MobileNavMenu from "../MobileNavMenu/Nav";

const ResponsiveHeader = () => {
  const items: MenuProps["items"] = [
    {
      key: 1,
      label: <MobileNavMenu />,
    },
  ];
  return (
    <Header>
      <Dropdown items={{ items }}>
        <MenuIcon />
      </Dropdown>
      <LogoWithTdree />
    </Header>
  );
};

export default ResponsiveHeader;
