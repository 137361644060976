/** @format */

import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CustomHeader } from "common/Header";
import { useForm } from "react-hook-form";
import { useStore } from "infrastructure/zustandStore/categories-store";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { useNavigate, useParams } from "react-router-dom";
import shallow from "zustand/shallow";
import { useBranchCompanyDetailsStore } from "infrastructure/zustandStore/branch-details-store";
import Form from "./form";
import Spinner from "common/LoadingIndecator";
import { locationStore } from "infrastructure/zustandStore/location-store";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";

const EditCompanyBranch = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const { handleSubmit, register, errors, formState, reset } = useForm({
    mode: "onBlur",
    criteriaMode: "all",
  });

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const [categories, categoriesList] = useStore(
    (state: Record<string | number | symbol, any>) => [
      state.categories,
      state.categoriesList,
    ],
    shallow
  );

  const [branchDetailsData, branchDetails, loading] =
    useBranchCompanyDetailsStore(
      (state: Record<string | number | symbol, any>) => [
        state.branchDetailsData,
        state.branchDetails,
        state.loading,
      ],
      shallow
    );

  const location: { lat: string; long: string } = locationStore(
    (state: Record<string | number | symbol, any>) => state.location
  );
  const address: string = locationStore(
    (state: Record<string | number | symbol, any>) => state.address
  );
  const locationFn: () => void = locationStore(
    (state: Record<string | number | symbol, any>) => state.locationFn
  );
  const district: string = locationStore(
    (state: Record<string | number | symbol, any>) => state.district
  );
  const city: string = locationStore(
    (state: Record<string | number | symbol, any>) => state.city
  );
  const country: string = locationStore(
    (state: Record<string | number | symbol, any>) => state.country
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    categoriesList();
    branchDetails(id);
    locationFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    const list: any = { ...branchDetailsData };
    list["lat"] = location?.lat.toString();
    list["long"] = location?.long.toString();
    list["address"] = address;
    list["district"] = district;
    list["city"] = city;
    list["country"] = country;
    useBranchCompanyDetailsStore.setState({ branchDetailsData: list });
    setIsModalVisible(false);
  };

  const handleInputChange = (e: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = e.target;
    let list = { ...branchDetailsData };
    list[name] = value;
    useBranchCompanyDetailsStore.setState({ branchDetailsData: list });
  };

  const handleOnChangeSwitchButton = (checked: boolean) => {
    const list: any = { ...branchDetailsData };
    list["is_website"] = checked;
    useBranchCompanyDetailsStore.setState({ branchDetailsData: list });
  };

  const handleSelectOnChange = (
    value: number,
    obj_key: string,
    name: { title: string }
  ) => {
    const list: any = { ...branchDetailsData };
    list[obj_key] =
      obj_key === "category" ? { id: value, name: name.title } : value;
    useBranchCompanyDetailsStore.setState({ branchDetailsData: list });
  };

  const onSubmit = () => {
    const newData = Object.assign({}, branchDetailsData);
    let parsedData = newData && JSON.parse(JSON.stringify(newData));
    parsedData["category_id"] = parsedData["category"];
    parsedData.category_id = parsedData.category_id.id;
    delete parsedData.category;
    setIsLoading(true);
    axiosApiInstance
      .put(`api/company/v1/branches/${id}`, parsedData)
      .then(() => {
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("update_branch_sucess")
        );
        navigate("/branches");
        setIsLoading(false);
        reset();
      })
      .catch((err) => {
        setIsLoading(false);
        showNotification(
          NotificationStatus.Error,
          " ",
          err.response.data.message
        );
      });
  };

  useEffect(() => {
    useBranchCompanyDetailsStore.setState({ loading: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (branchDetailsData?.country_id) {
      handleFetchCities(branchDetailsData?.country_id);
    }
  }, [branchDetailsData?.country_id]);

  // fetch countries
  const { data: countries } = useQuery(
    ["Countries"],
    () => fetchGetRequests(`api/countries`),
    {
      keepPreviousData: false,
    }
  );

  const countriesOptions = countries
    ? countries?.response?.countries?.map((country: any) => {
        return {
          value: country.id,
          label: country.name,
        };
      })
    : [];

  // featch cities
  const [citiesOptions, setCitiesOptions] = useState<any>([]);
  const [disableCity, setDisableCity] = useState<boolean>(true);

  const handleFetchCities = (id: number) => {
    axiosApiInstance
      .get(`api/countries/${id}/cities`)
      .then((res) => {
        setDisableCity(false);
        const options = res?.data?.cities
          ?.map((city: any) => {
            if (city.name !== null) {
              return {
                value: city.id,
                label: city.name,
              };
            }
          })
          .filter(Boolean);
        setCitiesOptions(options);
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          t("Error"),
          err.response.data.message
        );
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> تعديل فرع -تدري </title>
      </Helmet>
      <CustomHeader
        title={<Trans>edit_company_branch</Trans>}
        path={"/branches"}
        type="withoutLeftContainer"
      />

      {loading ? (
        <Spinner size={"large"} tip={"Loading..."} />
      ) : (
        <Form
          handleSubmit={handleSubmit(onSubmit)}
          errors={errors}
          register={register}
          formState={formState}
          categories={categories}
          handleInputChange={handleInputChange}
          handleOnChangeSwitchButton={handleOnChangeSwitchButton}
          data={branchDetailsData}
          handleSelectOnChange={handleSelectOnChange}
          handleCancel={handleCancel}
          handleOk={handleOk}
          showModal={showModal}
          isModalVisible={isModalVisible}
          address={address}
          loading={isLoading}
          countriesOptions={countriesOptions}
          citiesOptions={citiesOptions}
          handleFetchCities={handleFetchCities}
          disableCity={disableCity}
        />
      )}
    </>
  );
};

export default EditCompanyBranch;
