/** @format */

import * as React from "react";
import { SVGProps } from "react";

const LinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <a
    href="https://www.linkedin.com/company/tdree-app"
    target="_blank"
    rel="noreferrer"
  >
    <svg width={27} height={27} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M21.375 0H5.625A5.626 5.626 0 0 0 0 5.625v15.75A5.626 5.626 0 0 0 5.625 27h15.75A5.625 5.625 0 0 0 27 21.375V5.625A5.625 5.625 0 0 0 21.375 0zM9 21.375H5.625V9H9v12.375zM7.312 7.573A1.977 1.977 0 0 1 5.345 5.59c0-1.096.882-1.985 1.968-1.985 1.087 0 1.97.89 1.97 1.985a1.977 1.977 0 0 1-1.97 1.984zM22.5 21.375h-3.375V15.07c0-3.789-4.5-3.502-4.5 0v6.305H11.25V9h3.375v1.986c1.57-2.91 7.875-3.125 7.875 2.785v7.604z" />
    </svg>
  </a>
);

export default LinkedinIcon;
