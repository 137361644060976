/** @format */

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'common/Button';
import { Input } from 'common/Input';
import { Error, Label } from 'pages/style';
import { FormProps } from 'infrastructure/interfaces/forms.interface';
import { Form, InputsContainer } from 'pages/User/style';
import VisibleIcon from 'assets/svg/icon-visible';
import InvisibleIcon from 'assets/svg/icon-invisible';

export const ChangePaswwordForm: React.FC<FormProps> = ({
  errors,
  register,
  formState,
  onSubmit,
  loading,
  passwordShown,
  togglePassowrdVisiblity,
}) => {
  const { i18n } = useTranslation();

  return (
    <Form style={{ marginTop: '20px' }} onSubmit={onSubmit} noValidate={false}>
      <InputsContainer profile language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>current_password</Trans>
        </Label>
        <Input
          name='current_password'
          type={passwordShown ? 'text' : 'password'}
          ref={register({ required: true })}
          errorName={errors.current_password}
          profile
        />
        {errors?.current_password?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}

        {passwordShown ? (
          <VisibleIcon
            onClick={togglePassowrdVisiblity}
            lang={i18n.language === 'ar' ? 'ar' : 'en'}
          />
        ) : (
          <InvisibleIcon
            onClick={togglePassowrdVisiblity}
            lang={i18n.language === 'ar' ? 'ar' : 'en'}
          />
        )}
      </InputsContainer>
      <InputsContainer profile language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>new_password</Trans>
        </Label>
        <Input
          name='new_password'
          type={passwordShown ? 'text' : 'password'}
          ref={register({ required: true, minLength: 8 })}
          errorName={errors.new_password}
          profile
        />
        {errors?.new_password?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}
        {errors?.new_password?.types?.minLength && (
          <Error>
            <Trans>passwordـminLength_8</Trans>
          </Error>
        )}

        {passwordShown ? (
          <VisibleIcon
            onClick={togglePassowrdVisiblity}
            lang={i18n.language === 'ar' ? 'ar' : 'en'}
          />
        ) : (
          <InvisibleIcon
            onClick={togglePassowrdVisiblity}
            lang={i18n.language === 'ar' ? 'ar' : 'en'}
          />
        )}
      </InputsContainer>

      <InputsContainer profile language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>new_password_confirmation</Trans>
        </Label>
        <Input
          name='confirm_password'
          type={passwordShown ? 'text' : 'password'}
          ref={register({ required: true, minLength: 8 })}
          errorName={errors.confirm_password}
          profile
        />
        {errors?.confirm_password?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}
        {errors?.confirm_password?.types?.minLength && (
          <Error>
            <Trans>passwordـminLength_8</Trans>
          </Error>
        )}

        {passwordShown ? (
          <VisibleIcon
            onClick={togglePassowrdVisiblity}
            lang={i18n.language === 'ar' ? 'ar' : 'en'}
          />
        ) : (
          <InvisibleIcon
            onClick={togglePassowrdVisiblity}
            lang={i18n.language === 'ar' ? 'ar' : 'en'}
          />
        )}
      </InputsContainer>

      <InputsContainer profile language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Button
          disabled={formState.isSubmitting}
          size='large'
          onClick={onSubmit}
          loading={loading}
          style={{ alignSelf: 'flex-start' }}
        >
          <Trans>save</Trans>
        </Button>
      </InputsContainer>
    </Form>
  );
};
