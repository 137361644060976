/** @format */

import * as React from "react";
import { SVGProps } from "react";

const RedIconDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={13}
    height={14}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    cursor="pointer"
  >
    <g stroke="#FF2B29" strokeWidth={1.2} fill="none" fillRule="evenodd">
      <path d="M8.46 2.946v-.162a1.784 1.784 0 1 0-3.568 0v.162" />
      <path
        strokeLinecap="round"
        d="M1 2.946h11.351m-6.81 2.595v4.864m2.27-4.864v4.864M2.135 2.946l.86 8.882A1.297 1.297 0 0 0 4.285 13h4.78c.668 0 1.227-.507 1.291-1.172l.86-8.882"
      />
    </g>
  </svg>
);

export default RedIconDelete;
