/** @format */

import React from "react";
import { EditProfileForm } from "./form";
import { useForm } from "react-hook-form";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStore } from "infrastructure/zustandStore/profile-store";
import Spinner from "common/LoadingIndecator";
import storage from "infrastructure/utils/storage";

const { useEffect, useState } = React;

export const EditProfile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleSubmit, register, errors, formState } = useForm({
    mode: "onBlur",
    criteriaMode: "all",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [imageLoader, setImageLoader] = useState<boolean>(false);

  const [editableData, setEditableData] = useState({});

  const [userProfileData, isLoading, userData] = useStore(
    (state: Record<string | number | symbol, unknown | any>) => [
      state.userProfileData,
      state.isLoading,
      state.userData,
    ]
  );

  const [showImage, setShowImage] = useState<string | null | any>(() =>
    storage.getItem("user_image")
  );

  useEffect(() => {
    useStore.setState({
      isLoading: true,
    });

    userData();
  }, []);

  const handleImageUploading = (e: {
    target: { files: { size: number }[] | any };
    type: string;
  }) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("entity", "companies");
    formData.append("file_type", "images");
    const FileSize = e.target.files[0].size / 1024 / 1024;

    if (e.type === "change") {
      setImageLoader(true);
    }

    if (FileSize > 2) {
      showNotification(NotificationStatus.Warning, t("error"), t("file_size"));
    } else {
      axiosApiInstance
        .post("/api/file-upload", formData)
        .then((res) => {
          setImageLoader(false);
          setShowImage(res.data.fullUrl);
          storage.setItem("user_image", res.data.fullUrl);

          useStore.setState({
            userProfileData: { ...userProfileData, image_url: res.data.file },
          });
          setEditableData({ ...editableData, image_url: res.data.file });
        })
        .catch((err) => {
          setImageLoader(false);
          showNotification(
            NotificationStatus.Error,
            t("error"),
            err.response?.data?.message
          );
        });
    }
  };

  const handleInputChange = (e: Event) => {
    const { name, value }: any = e.target;

    useStore.setState({
      userProfileData: { ...userProfileData, [name]: value },
    });

    setEditableData({ ...editableData, [name]: value });
  };

  const onSubmit = () => {
    setLoading(true);
    axiosApiInstance
      .put("api/company/v1/profile", editableData)
      .then(() => {
        setLoading(false);
        storage.setItem("user_image", showImage);
        storage.setItem("profile_data", JSON.stringify(userProfileData));
        storage.setItem("user_data", JSON.stringify(userProfileData));

        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
        navigate("/home");
      })
      .catch((error) => {
        setLoading(false);
        showNotification(
          NotificationStatus.Error,
          t("error"),
          error.response.data.message
        );
      });
  };

  if (isLoading) {
    return <Spinner size={"large"} />;
  }

  const handleOnSelect = (value: number[]) => {
    setEditableData({ ...editableData, categories: value });
    useStore.setState({
      userProfileData: { ...userProfileData, categories: value },
    });
  };

  return (
    <EditProfileForm
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      register={register}
      formState={formState}
      handleImageUploading={handleImageUploading}
      showImage={showImage}
      userData={userProfileData}
      handleInputChange={handleInputChange}
      loading={loading}
      imageLoader={imageLoader}
      handleOnSelect={handleOnSelect}
    />
  );
};
