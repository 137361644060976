import i18n from "i18n";
import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

export const SearchContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10 input {
    padding: 0.5rem;
    font-size: 1.5rem;
    width: 100%;
  }
`;

export const SearchWithPan = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 7rem;
  left: 70%;
  transform: translateX(-50%);
  z-index: 10;
  @media (max-width: 767px) {
    left: 49%;
  }
  @media (max-width: 991px) {
    left: 49%;
  }
`;

export const Input = styled.input`
  width: 400px;
  height: 54px;
  padding: 15px 20px 17px 64px;
  border-radius: 10px;
  box-shadow: 0 6px 9px 0 ${Colors.shadow};
  border: solid 0.7px ${Colors.border};
  background-color: ${Colors.white};
  margin-left: 16px;
  direction: ${(props) =>
    props.dir && i18n.language === "ar" ? "rtl" : "ltr"};

  :focus {
    border-color: ${Colors.white};
  }
`;

export const Button = styled.button`
  height: 54px;
  padding: 13px;
  border-radius: 10px;
  box-shadow: 0 6px 9px 0 ${Colors.shadow};
  border: solid 0.7px ${Colors.border};
  background-color: ${Colors.white};

  :focus {
    border-color: ${Colors.white};
  }
`;

export const mapStyles = [
  {
    featureType: "all",
    elementType: "geometry.fill",
    stylers: [
      {
        weight: "2.00",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#9c9c9c",
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7b7b7b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#46bcec",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c8d7d4",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#070707",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
];
