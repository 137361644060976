/** @format */

import styled from "styled-components";
import { Menu } from "antd";

export const Header = styled.div`
  width: 100%;
  padding: 10px;
  box-shadow: 0 0 28px 0 #f0f0f0;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  direction: ltr;
  position: fixed;
  z-index: 1000;
`;

//@ts-ignore
export const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    width: 250px !important;
    box-shadow: 3px 3px 5px 6px #ccc;
    direction: ${(props: { language?: string }) =>
      props.language === "ar" ? "ltr" : "rtl"};
    &.ant-dropdown-menu-item {
      padding: 0;
    }
  }
`;
