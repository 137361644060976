/** @format */

import { create } from "zustand";

export const shoppersUseStore = create((set) => ({
  shoppers: [],
  loading: false,
  setShoppers: (data) => {
    set((state) => ({
      shoppers: {
        ...state.shoppers,
        data,
      },
    }));
  },
  setIsLoading: () => {
    set((state) => ({
      loading: state.loading,
    }));
  },
}));
