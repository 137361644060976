import styled from "styled-components";
import { Select } from "antd";
import Colors from "../../infrastructure/utils/Colors";

type Props = {
  language?: string;
  className?: string;
};
export const StyledSelect = styled(Select) <Props>`
  direction: ${(props) => (props.language === "ar" ? "ltr" : "rtl")};
  .ant-select-selector {
    direction: rtl;
  }

  border-radius: 10px;
  margin-top: ${(props) =>
    props.className !== "evaluationTemplateWidth" ? "25px" : "unset"};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  margin: 13px 0;

  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: ${(props: any) =>
    props.style ? Colors.lipstick_5 : Colors.black_4};
    border-radius: 10px;
    border-color: ${(props: any) =>
    props.style ? Colors.lipstick : Colors.black_4};
    align-items: baseline;
    width: ${(props: any) =>
    props.className === "evaluationTemplateWidth" ? "90%" : "unset"};
  }

  .ant-select-dropdown ant-select-dropdown-placement-topLeft {
    width: ${(props) =>
    props.className !== "evaluation-place" ? "180px" : ""};
  }

  &.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 50px;
  }

  &.ant-select {
    width: ${(props) =>
    props.className === "evaluation-place"
      ? " 700px"
      : props.className === "full-width"
        ? "100%"
        : props.className === "jobs"
          ? "342px"
          : props.className === "price"
            ? "485px"
            : "201px"};
    @media (max-width: 767px) {
      width: 100%;
    }
    margin-top: ${(props) =>
    props.className === "jobs" ||
      props.className === "price" ||
      props.className === "evaluation-place" ||
      props.className === "evaluationTemplateWidth" ||
      props.className === "company"
      ? "13px"
      : "unset"};
  }

  .ant-select-arrow {
    left: ${(props) => (props.language === "ar" ? "11px" : "0px")};
    right: ${(props) => (props.language === "ar" ? "0px" : "11px")};
  }

  &.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 0px !important;
  }

  .ant-select-selection-placeholder {
    padding-inline-end: 2px !important;
  }

  .ant-select-selection-item {
    line-height: 49px !important;
    align-self: center;
    height: 100%;
    direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};
  }

  .ant-select-item-option-selected {
    background-color: ${Colors.charcoal_grey_54} !important;
  }

  &.ant-select-multiple .ant-select-selection-item {
    background-color: unset;
    border-color: ${Colors.dark_mint};
    align-items: center;
    border-radius: 5px;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${(props) =>
    props.style ? Colors.lipstick_5 : Colors.black_4};
    min-height: 51px;
    border-radius: 10px;
    border-color: ${(props) =>
    props.style ? Colors.lipstick : Colors.black_4};
    align-items: center;
  }

  .ant-select-selection-overflow {
    direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};
  }

  .ant-select-selection-overflow-item {
    direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};
  }

  .ant-select-selection-placeholder {
    font-family: Famtree;
    font-size: 14px;
    font-weight: normal;
    color: ${Colors.charcoal_grey};
    text-align: ${(props) => (props.language === "ar" ? "right" : "left")};
  }

  &.ant-select-multiple .ant-select-selection-placeholder {
    left: ${(props) => (props.language === "ar" ? "unset" : "10px")};
    right: ${(props) => (props.language === "ar" ? "10px" : "unset")};
  }
`;
