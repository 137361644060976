import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-contnet: space-between;
  margin-top: 39px;
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    justify-content: ${(props: { language?: string }) =>
      props.language === "ar" ? "end" : "unset"};
    @media (max-width: 767px) {
      justify-content: ${(props: { language?: string }) =>
        props.language === "ar" ? "space-between" : "unset"};
    }
  }
`;

export const Card = styled.div`
  width: 47%;
  margin: 0 0 27px 23px;
  padding: 21px 25px 17px 29px;
  border-radius: 16px;
  border: solid 0.7px #cccccc;
  color: ${Colors.charcoal_grey_54};
  direction: rtl;
`;

export const Question = styled.p`
  font-family: Famtree;
  font-size: 18px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
`;

export const Answer = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.dark_green};
`;
