/** @format */

import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { StyledSelect as AntDSelect } from "./style";

type Props = {
  onChange?: any;
  defaultValue?: any;
  style?: React.CSSProperties | undefined;
  children?: ReactNode;
  onSelect?: (value: string) => void;
  className?: string;
  value?: string | number | undefined | any;
  title?: string | any;
  width?: any;
  mode?: "multiple" | "tags" | undefined;
  allowClear?: boolean;
  placeholder?: string | ReactNode;
  showArrow?: boolean;
  errorStyle?: React.CSSProperties | undefined | any;
  id?: string;
  ref?: React.Ref<any> | undefined;
  options?: any;
  onSearch?: (value: string) => void;
  filterOption?: any;
  onDeselect?: (e: any) => void;
};

export const Select = ({
  onChange,
  defaultValue,
  children,
  value,
  className,
  mode,
  placeholder,
  errorStyle,
  id,
  ref,
  options,
  onSearch,
  filterOption,
  onDeselect,
}: Props): JSX.Element => {
  const { i18n } = useTranslation();
  return (
    <AntDSelect
      id={id}
      onChange={onChange}
      defaultValue={defaultValue}
      size="large"
      className={className}
      direction={i18n.language === "ar" ? "rtl" : "ltr"}
      value={value}
      mode={mode}
      placeholder={placeholder}
      showArrow={true}
      maxTagTextLength={1000}
      style={errorStyle}
      language={i18n.language === "ar" ? "ar" : "en"}
      ref={ref}
      options={options}
      onSearch={onSearch}
      filterOption={filterOption}
      onDeselect={onDeselect}
    >
      {children}
    </AntDSelect>
  );
};

Select.defaultProps = {};
