/** @format */

import * as React from "react";
import { SVGProps } from "react";

const WhatsappResponsiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <a
    href="https://wa.me/message/BE25YFNFGSI3P1"
    target="_blank"
    rel="noreferrer"
  >
    <svg width={22} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M21.506 0v20H.988V0h20.518z" opacity={0.58} />
        <path
          d="M9.794 1.783c-3.574.575-6.438 3.4-6.993 6.884a8.17 8.17 0 0 0 1.034 5.5l-.76 2.758c-.172.625.418 1.192 1.05 1.017l2.788-.75a8.678 8.678 0 0 0 4.326 1.141c4.821 0 8.822-4.141 8.54-8.825-.29-4.833-4.796-8.558-9.985-7.725zm5.334 11.742a1.948 1.948 0 0 1-.7.492 2.14 2.14 0 0 1-.838.158c-.436 0-.907-.1-1.394-.308a7.884 7.884 0 0 1-1.47-.825c-.496-.35-.958-.742-1.402-1.167a12.004 12.004 0 0 1-1.197-1.367 7.286 7.286 0 0 1-.838-1.425 3.453 3.453 0 0 1-.308-1.366c0-.284.051-.559.154-.809a1.86 1.86 0 0 1 .496-.7c.273-.266.573-.391.889-.391.12 0 .24.025.35.075.112.05.214.125.291.233l.992 1.367c.077.108.137.2.17.291a.58.58 0 0 1 .06.259c0 .1-.034.2-.093.3-.06.1-.137.2-.24.3l-.325.333a.211.211 0 0 0-.068.167c0 .033.009.066.017.1a.567.567 0 0 1 .034.083c.077.142.214.317.402.533a11.419 11.419 0 0 0 1.308 1.275c.222.184.41.309.556.384.025.008.051.025.077.033a.24.24 0 0 0 .11.017.236.236 0 0 0 .18-.075l.325-.317a1.37 1.37 0 0 1 .308-.233.6.6 0 0 1 .573-.034c.094.042.196.092.299.167l1.42.983a.71.71 0 0 1 .238.267.852.852 0 0 1 .069.325c-.051.142-.086.3-.154.45-.077.15-.171.292-.29.425z"
          fill="#000"
        />
      </g>
    </svg>
  </a>
);

export default WhatsappResponsiveIcon;
