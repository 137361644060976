/** @format */

import React from "react";
import { StyledTable as AntDTable, TableContainer } from "./style";
import { TableProps } from "infrastructure/interfaces/table.interface";
import { Trans, useTranslation } from "react-i18next";
import EmptyTemplate from "common/EmptyTemplate";
import IconNoForms from "assets/svg/icon-no-forms";
import { NoResultText } from "pages/style";

const CustomTable: React.FC<TableProps> = ({
  columns,
  data,
  loading,
  pagination,
}) => {
  const { i18n } = useTranslation();
  return (
    <TableContainer>
      <AntDTable
        rowKey={(record: any) => record.id}
        columns={columns}
        dataSource={data}
        size="small"
        loading={loading}
        language={i18n.language === "ar" ? "ar" : "en"}
        locale={{
          triggerDesc:
            i18n.language === "ar" ? "ترتيب تنازلي" : "Descending order",
          triggerAsc:
            i18n.language === "ar" ? "ترتيب تصاعدي" : "Ascending order",
          cancelSort: i18n.language === "ar" ? "الغاء الترتيب" : "Cancel Sort",
          emptyText: (
            <EmptyTemplate>
              {!loading && (
                <>
                  <IconNoForms />
                  <NoResultText>
                    <Trans>there_are_no_matching_esults</Trans>
                  </NoResultText>
                </>
              )}
            </EmptyTemplate>
          ),
        }}
        style={
          i18n.language === "ar" ? { direction: "rtl" } : { direction: "ltr" }
        }
        pagination={pagination}
      />
    </TableContainer>
  );
};

export default CustomTable;
