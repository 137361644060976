/** @format */

import React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import Creatable from "react-select/creatable";

export const WrappedSelect = ({
  options,
  handleOnChange,
  styles,
  value,
  menuIsOpen,
  onBlur,
  onFocus,
  handleKeydown,
  className,
  name,
}: any) => {
  const { i18n } = useTranslation();

  const creatableRef = useRef<any>(null);
  return (
    <div
      onClick={() => {
        handleKeydown();
        creatableRef.current?.focus();
      }}
      style={{ width: "100%" }}
    >
      <Creatable
        ref={creatableRef}
        className={className}
        name={name}
        onChange={handleOnChange}
        openMenuOnClick
        createOptionPosition="first"
        options={options}
        menuIsOpen={menuIsOpen}
        placeholder=""
        onFocus={onFocus}
        onBlur={onBlur}
        isClearable
        isRtl={i18n.language === "ar"}
        styles={styles}
        formatCreateLabel={(inputValue: string) =>
          i18n.language === "ar"
            ? `+ \u00A0 \u00A0 اضف  "${inputValue}"`
            : `Create + \u00A0 \u00A0 ${inputValue}`
        }
        noOptionsMessage={() =>
          i18n.language === "ar" ? "لا يوجد اختيارات" : "No options"
        }
        defaultValue={options.filter(
          (item: any) => item.value === value.category && item
        )}
        closeMenuOnSelect
      />
    </div>
  );
};
