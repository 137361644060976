import React from "react";

function JobsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      viewBox="0 0 18 22"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1624 -251)">
          <g transform="translate(1415)">
            <g transform="translate(0 240)">
              <g transform="translate(199.957 4)">
                <g
                  stroke="#344247"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  transform="translate(10.105 8)"
                >
                  <path
                    strokeLinecap="round"
                    d="M6.79 14.84l-2.843.686.686-2.842 4.606-4.606a1.525 1.525 0 012.156 2.157L6.79 14.84z"
                  ></path>
                  <path d="M11.226 2.368h2.721c.872 0 1.58.707 1.58 1.58v14.21c0 .872-.708 1.579-1.58 1.579H1.842a1.579 1.579 0 01-1.579-1.58V3.948c0-.872.707-1.579 1.58-1.579h2.739"></path>
                  <path
                    strokeLinecap="round"
                    d="M9.737.263H6.053a1.579 1.579 0 100 3.158h3.684a1.579 1.579 0 100-3.158z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default JobsIcon;
