/** @format */

import styled from "styled-components";
import { Modal } from "antd";
import Colors from "infrastructure/utils/Colors";

type Props = {
  width?: string;
  className?: string;
  language?: string;
};

export const StyledModal = styled(Modal)<Props>`
  direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};
  .ant-modal-content {
    border-radius: 24px;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  }

  .ant-modal-title {
    text-align: ${(props) => (props.language === "ar" ? "right" : "left")};
  }

  .ant-modal-close-x {
    left: 0;
    right: 0;
  }
  font-family: Famtree;
  color: ${Colors.charcoal_grey};
  font-size: 14px;

  .ant-modal-footer {
    display: flex;
    border-top: 0px;
    flex-direction: row-reverse;
  }

  .ant-modal-header {
    border-radius: 24px;
    padding: ${(props) => (props.language === "ar" ? "10px 0" : "10px 0")};
    padding-bottom: 6px;
    border-bottom: 0px;
  }

  .ant-btn {
    border-radius: 8px;
    background-color: ${Colors.dark_mint};
    font-family: Famtree;
    font-size: 12px;
    color: white;
    border-color: ${Colors.dark_mint};
  }

  .ant-btn-primary {
    background-color: ${Colors.dark_mint} !important;
    color: white !important;
    display: ${(props) => (props.className ? "none" : "block")};
    font-weight: bold;
  }

  .ant-btn {
    background-color: ${Colors.lipstick_5};
    border-color: ${Colors.lipstick_5};
    color: ${Colors.lipstick};
    display: ${(props) => (props.className ? "none" : "block")};
  }

  .ant-modal-body {
  }

  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-right: 8px;
  }
`;
