/** @format */

import React from "react";
import EmptyTemplate from "common/EmptyTemplate";
import { useNavigate } from "react-router-dom";
import {
  Pargraph,
  Title,
  MobileHeader,
  SearchWrapper,
  CardsContainer,
  MobileMoreBtn,
  BranchesContainer,
  CardItem,
  CardHeader,
  HeaderTitle,
  RatingWrapper,
} from "./style";
import { Trans, useTranslation } from "react-i18next";
import IconNoForms from "assets/svg/icon-no-forms";
import { Button } from "common/Button";
import { Menu } from "antd";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { useQuery, useQueryClient } from "react-query";
import { fetchGetRequestsWithPagination } from "infrastructure/helpers/fetchServerRequests";
import { Header } from "../style";
import EditIcon from "assets/svg/edit-icon";
import DeleteIcon from "assets/svg/icon-delete";
import SearchInput from "common/Search";
import { Rate } from "common/Rate";
import { Helmet } from "react-helmet";
import { Container as MenuContainer } from "common/Menu/style";
import styled from "styled-components";
import { List } from "antd";
import { Modal } from "antd";

const { useState } = React;

const CompanyBranch = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [, setIsModalVisible] = useState<any>({
    id: false,
  });
  const [page, SetPage] = useState<number>(1);

  const { data, isLoading } = useQuery(
    ["branchesListData", page, searchInputValue],
    () =>
      fetchGetRequestsWithPagination(
        `api/company/v1/branches?page=${page}&search=${searchInputValue}`
      )
  );

  const handleDeleteBranch = (id: number) => {
    axiosApiInstance
      .delete(`api/company/v1/branches/${id}`)
      .then(() => {
        queryClient.invalidateQueries("branchesListData");
        setIsModalVisible({ [id]: false });
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          " ",
          err.response.data.message
        );
      });
  };

  const handleSearchInputChange = (e: any) => {
    setSearchInputValue(e.target?.value);
  };

  const handlePagination = (page: number) => {
    SetPage(page);
  };

  const [modal1Visible, setModal1Visible] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<any>(null);

  if (isLoading) {
    return <p></p>;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar" ? "الفروع - تدري " : " Branches - Tdree"}
        </title>
      </Helmet>
      <MobileHeader>
        <Header language={i18n.language === "ar" ? "ar" : "en"}>
          <Title>
            <Trans>branches</Trans>
          </Title>
        </Header>
        <Button size={"large"} onClick={() => navigate("/branches/create")}>
          {t("new")}
        </Button>
      </MobileHeader>

      <SearchWrapper>
        <SearchInput
          handleChange={(e: any) => handleSearchInputChange(e)}
          placeholder={t("search_for_address")}
        />
      </SearchWrapper>
      <BranchesContainer>
        {data?.branches?.length !== 0 && (
          <List
            dataSource={data?.branches}
            pagination={{
              pageSize: data?.pagination?.per_page,
              total: data?.pagination?.total,
              current: page,
              defaultPageSize: 10,
              hideOnSinglePage: true,
              showSizeChanger: true,

              onShowSizeChange: (page) => {
                handlePagination(page);
              },
              onChange: (page) => {
                handlePagination(page);
              },
            }}
            grid={{
              gutter: 8,
              xs: 1,
              sm: 1,
              md: 1,
            }}
            renderItem={(branch: any, index: number) => (
              <>
                <CardsContainer
                  language={i18n.language === "ar" ? "ar" : "en"}
                  key={index}
                >
                  <CardItem>
                    <CardHeader>
                      <HeaderTitle
                        onClick={() => navigate(`/branches/${branch.id}`)}
                      >
                        {branch.name}
                      </HeaderTitle>
                      <MobileMoreBtn
                        onClick={() => {
                          setModal1Visible(true);
                          setSelectedBranch(branch);
                        }}
                      >
                        ...
                      </MobileMoreBtn>
                      {/* modal  */}
                    </CardHeader>
                    <RatingWrapper>
                      {Number(branch.rating) ? (
                        <>
                          <Rate count={1} defaultValue={1} disabled />
                          {branch.rating}
                        </>
                      ) : (
                        <Rate count={1} defaultValue={0} disabled />
                      )}
                    </RatingWrapper>
                    <div
                      className="company-info"
                      style={{
                        marginBottom: "5px",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0",
                          fontFamily: "Famtree",
                          fontSize: "13px",
                          fontWeight: "normal",
                        }}
                      >
                        {branch.companyName}
                      </p>
                    </div>
                    <div
                      className="adress-info"
                      style={{
                        marginBottom: "5px",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0",
                          fontFamily: "Famtree",
                          fontSize: "12px",
                          fontWeight: "normal",
                          color: "#92999c",
                        }}
                      >
                        {branch.companyName}
                      </p>
                    </div>
                  </CardItem>
                </CardsContainer>
              </>
            )}
          />
        )}
      </BranchesContainer>
      {data?.branches?.length === 0 && !isLoading && (
        <EmptyTemplate>
          <IconNoForms />
          <Pargraph>
            <Trans>no_branches_are_created_yet</Trans>
          </Pargraph>
        </EmptyTemplate>
      )}
      {/* Modal  */}
      <Modal
        title=""
        style={{ top: 684 }}
        visible={modal1Visible}
        width="98%"
        onOk={() => setModal1Visible(false)}
        onCancel={() => setModal1Visible(false)}
        footer={null}
      >
        <StyledMenu language={i18n.language === "ar" ? "ar" : "en"}>
          <Menu.Item key="1">
            <MenuContainer
              onClick={() => {
                navigate(`branches/edit/${selectedBranch.id}`);
              }}
              language={i18n.language === "ar" ? "ar" : "en"}
            >
              {<EditIcon />}
              <a>
                <Trans>edit</Trans>
              </a>
            </MenuContainer>
          </Menu.Item>
          <Menu.Item key="2">
            <MenuContainer
              onClick={() => handleDeleteBranch(selectedBranch.id)}
              language={i18n.language === "ar" ? "ar" : "en"}
            >
              {<DeleteIcon />}
              <a>
                <Trans>delete</Trans>
              </a>
            </MenuContainer>
          </Menu.Item>
        </StyledMenu>
      </Modal>
    </>
  );
};

export default CompanyBranch;

//@ts-ignore
const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    width: 140px !important;
    box-shadow: 3px 3px 5px 6px #ccc;
    direction: ${(props: { language?: string }) =>
      props.language === "ar" ? "ltr" : "rtl"};
  }
  margin-top: 20px;
`;
