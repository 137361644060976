/** @format */

import * as React from 'react';
import styled from 'styled-components';

function InvisibleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    //@ts-ignore
    <StyledSvg
      width={22}
      height={19}
      {...props}
      viewBox='0 0 22 19'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>{'icons8-invisible'}</title>
      <g fill='none' fillRule='evenodd' opacity={0.54}>
        <path
          stroke='#344247'
          strokeWidth={1.5}
          strokeLinecap='round'
          d='M2.816 17.289L18.395 1.711'
        />
        <path
          d='M10.601 3.184c1.086 0 2.147.169 3.154.489l-1.036 1.035a8.684 8.684 0 00-2.118-.26c-4.067 0-7.878 2.87-8.859 6.681a.625.625 0 01-.766.455.625.625 0 01-.455-.766c1.137-4.425 5.377-7.634 10.08-7.634zM20.235 11.584a.629.629 0 01-.766-.455C18.832 8.658 17 6.578 14.68 5.424l.952-.951c2.459 1.33 4.353 3.604 5.056 6.345a.625.625 0 01-.454.766z'
          fill='#344247'
          fillRule='nonzero'
        />
        <path
          d='M12.866 7.239l-.918.918a2.81 2.81 0 011.466 2.467 2.81 2.81 0 01-2.809 2.804 2.808 2.808 0 01-2.463-1.465l-.918.918a4.072 4.072 0 003.381 1.81 4.076 4.076 0 004.072-4.067c0-1.406-.72-2.652-1.81-3.385zm-3.595.918l1.595-1.596c-.088-.004-.172-.008-.26-.008a4.074 4.074 0 00-4.06 4.328l1.596-1.596a2.8 2.8 0 011.129-1.128z'
          fill='#344247'
          fillRule='nonzero'
        />
      </g>
    </StyledSvg>
  );
}

export default InvisibleIcon;

const StyledSvg = styled.svg`
  position: absolute;
  top: 61px;
  left: ${(props) =>
    props.lang === 'en'
      ? 'unset'
      : props.className === 'register'
      ? '28px'
      : '15px'};
  right: ${(props) => (props.lang === 'en' ? '43px' : 'unset')};
  transform: translateY(-50%);
`;
