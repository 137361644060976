import React from "react";

function FormsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      viewBox="0 0 22 18"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g stroke="#344247" strokeWidth="1.5" transform="translate(-1622 -385)">
          <g transform="translate(1415)">
            <g transform="translate(0 372)">
              <g transform="translate(208 14)">
                <path d="M.263 13.421V2.368c0-1.162.943-2.105 2.105-2.105h15.264c1.162 0 2.105.943 2.105 2.105v11.053a2.105 2.105 0 01-2.105 2.105H2.368a2.105 2.105 0 01-2.105-2.105z"></path>
                <path strokeLinecap="round" d="M11.842 5L3.947 5"></path>
                <path
                  strokeLinecap="round"
                  d="M11.842 8.158L3.947 8.158"
                ></path>
                <path strokeLinecap="round" d="M16.053 11.316L15 11.316"></path>
                <path strokeLinecap="round" d="M16.053 8.158L15 8.158"></path>
                <path strokeLinecap="round" d="M16.053 5L15 5"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FormsIcon;
