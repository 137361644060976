/** @format */

import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";
import { Button } from "antd";

export const EvaluationTemplateFormContainer = styled.div`
  width: 90%;
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  padding: 23px 16px 18px 17px;
  align-items: baseline;
  justify-content: flex-end;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Head = styled.p`
  font-size: 20px;
  color: ${Colors.charcoal_grey};
  font-weight: bold;
  font-family: Famtree;
  line-height: normal;
  text-align: end;
  padding-top: 29px;
`;

export const Card = styled.div`
  background-color: white;
  margin: 32px 0 0 30px;
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  height: 744px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 83%;
`;

export const Pargraph = styled.div`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 18px;
  font-weight: normal;
  margin: 18px 0 34px;
`;

export const Span = styled.span`
  color: white;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
`;

export const P = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 30px 43px 98px 0px;
`;

export const Label = styled.p`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  align-self: ${(props: { language?: String }) =>
    props.language === "ar" ? "flex-end" : "flex-start"};
  @media (max-width: 800px) {
    font-size: 11px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

export const Hr = styled.div`
  width: 923px;
  color: "#dddddd";
  margin: 0 auto;
  height: 2px;
`;

export const SectionTitle = styled.p`
  text-align: ${(props: { language?: string }) =>
    props.language === "ar" ? "right" : "left"};
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  color: ${Colors.charcoal_grey_54};
  margin-top: 50px;
  margin-bottom: 15px;
  @media (max-width: 800px) {
    width: 95%;
  }
`;

export const CreateFormSection = styled.section`
  margin: 0px auto;
  width: 70%;

  display: flex;
  flex-direction: column;
  align-items: ${(props: { language: string }) =>
    props.language === "ar" ? "flex-end" : "flex-start"};

  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const SupContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  margin: 0 auto;

  @media (max-width: 800px) {
    display: -webkit-box;
    flex-direction: unset;
    align-items: unset;
    margin: unset;
  }
`;

export const QuestionsHeadContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
`;

export const QuestionsHeadContainerWithoutWeight = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: flex-end;
`;

export const QuestionBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 23px 16px 50px 17px;
  align-items: ${(props: { language?: string }) =>
    props.language === "ar" ? "flex-start" : "flex-end"};
`;

export const SwitchLabel = styled.div`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  margin-left: 7px;
  margin-right: 14px;

  @media (max-width: 800px) {
    font-size: 11px;
    align-self: baseline;
  }
`;

export const SelectLabel = styled.div`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  text-align: center;
  display: flex;
  flex-direction: ${(props: { language?: String }) =>
    props.language === "ar" ? "row" : "row-reverse"};
  line-height: 51px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const QuestionHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const QuestionNumber = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const OptionValue = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin: 0 14px;
  margin-bottom: 0px;
  margin-top: 6px;
  text-align: left;
`;

export const QuestionTypeScale = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: baseline;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const MultiChoiceContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const Icons = styled.div`
  position: absolute;
  min-width: 50px;
  text-align: center;
`;

export const MultiChoiceQuestionsContainer = styled.div`
  display: flex;
  flex-direction: coulmn;
`;

export const ColumnCell = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  color: ${Colors.charcoal_grey};
  word-break: break-all;
`;
export const ColumnCellName = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  cursor: pointer;
  color: ${Colors.charcoal_grey};
`;

export const MoreBtn = styled.a`
  font-family: Famtree;
  font-size: 36px;
  line-height: 0;
  margin-top: -10px;
  letter-spacing: -2px;
  cursor: pointer;
  color: ${Colors.charcoal_grey_38};
`;

export const Title = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 29px 0px 32px 0px;
`;

export const QuestionDropdownContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${(props: { language?: String }) =>
    props.language === "ar" ? "row" : "row-reverse"};
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props: { language?: String }) =>
    props.language === "ar" ? "end" : "start"};
  width: 49%;
  @media (max-width: 800px) {
    width: 48%;
  }
  .question-type-select,
  .question-focus-select {
    width: 100% !important;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  direction: ${(props: { language?: String }) =>
    props.language === "ar" ? "rtl" : "rtl"};
  flex-direction: ${(props: { language?: String }) =>
    props.language === "ar" ? "" : "row-reverse"};
  margin-top: 20px;
  .checkbox-value {
    font-family: Famtree;
    font-size: 14px;
    font-weight: normal;
    color: #344247;
    margin: 0 14px;
  }
`;

export const SliderWrapper = styled.div`
  width: 90%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const EmptyState = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyMessage = styled.p`
  font-family: Famtree;
  font-size: 17px;
  font-weight: bold;
  color: ${Colors.charcoal_grey_70};
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const TabButton = styled(Button)`
  border-color: unset;
  font-family: Famtree;
  font-size: 14px;
  width: 300px;
  height: 45px;
  margin: 0 0 0 18px;
  padding: 12px 20px 17px 20px;
  border-radius: 5px;
  border: solid 0.5px #f7f7f7;
  color: #344247;
  border-color: #344247;
  background-color: #f7f7f7;
  font-weight: normal;
  border-color: #ccc;

  :focus {
    color: #344247;
    border-color: #344247;
    background-color: #f7f7f7;
    font-weight: normal;
  }

  :hover {
    color: #344247;
    border-color: #344247;
  }

  &.active {
    border: solid 1px #44c874;
    color: #44c874;
    border-color: #44c874;
    background-color: #daf4e3;
    font-weight: bold;

    :focus {
      color: #44c874;
      border-color: #44c874;
      background-color: #daf4e3;
      font-weight: bold;
    }
    :hover {
      color: #44c874;
      border-color: #44c874;
    }
  }
`;

export const WeightWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  .switch-btn {
    margin-right: 15px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
`;

export const WeightAndPointsText = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: #344247;
  margin-left: 10px;

  @media (max-width: 800px) {
    font-size: 11px;
  }
`;

export const InfoLabel = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 10%;
  justify-content: space-between;
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  align-self: ${(props: { language?: String }) =>
    props.language === "ar" ? "flex-end" : "flex-start"};

  @media (max-width: 800px) {
    font-size: 11px;
  }
`;

export const ChoiceOptionsWithPoints = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const DrawerBodyTitle = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #344247;
  text-align: right;
`;

export const ExampleSpan = styled.p`
  font-family: Famtree;
  font-size: 12px;
  font-weight: bold;
  color: #344247;
  text-align: ${(props: { language?: String }) =>
    props.language === "ar" ? "right" : "left"};
`;

export const ScaleDetailsBox = styled.div`
  border-radius: 10px;
  border: solid 1px rgba(221, 221, 221, 0.3);
  background-color: #f7f7f7;
  padding: 16px 16px 17px 50px;
  text-align: end;
`;

export const OptionsAndPointsLabels = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 24px;
  width: ${(props: { language?: string }) =>
    props.language === "ar" ? "100%" : "33%"};
  justify-content: space-between;
`;

export const ImgLoadingContainer = styled.div`
  width: 512px;
  height: 98px;
  margin: 16px 0 0;
  padding: 16px 16px 16px 27px;
  border-radius: 7px;
  border: ${(props: { active?: boolean }) =>
    props.active ? " solid 1px #44c874 " : "solid 1px #ddd "};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
`;

export const LabelImag = styled.label`
  height: 126px;
  padding: 13px 84px;
  display: block;
  cursor: pointer;
  border-radius: 10px;
  border: solid 1px #dddddd;
  border-style: dashed;
  margin-left: 0;
  color: #44c874;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #44c874;
  position: relative;

  ::before {
    content: "اضغط لآرفاق أو السحب والإفلات";
    position: absolute;
    top: 55.5%;
    left: 48%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 700px) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const LabelImagEng = styled.label`
  width: 512px;
  height: 126px;
  padding: 13px 84px;
  display: block;
  cursor: pointer;
  border-radius: 10px;
  border: solid 1px #dddddd;
  border-style: dashed;
  margin-left: 0;
  color: #44c874;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #44c874;
  position: relative;

  ::before {
    content: "Attach File";
    position: absolute;
    top: 55.5%;
    left: 48%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-bottom: 30px;
  align-items: center;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  position: relative;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: end;
  }
`;

export const FileName = styled.h3`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  margin-right: 16px;
`;
