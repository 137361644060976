/** @format */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import languageEN from "infrastructure/locales/en/translate.json";
import languageAR from "infrastructure/locales/ar/translate.json";
import storage from "infrastructure/utils/storage";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options

  .init({
    debug: true,
    fallbackLng: "ar",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: storage.getItem("i18nextLng") || "ar",
    ns: ["translations"],
    defaultNS: "translations",
    resources: {
      en: languageEN,
      ar: languageAR,
    },
    detection: {
      order: ["navigator"],
      caches: ["cookies"],
    },
  });

export default i18n;
