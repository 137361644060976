import Colors from "../../infrastructure/utils/Colors";
import styled from "styled-components";

// pdf cover
export const PdfCover = styled.div`
  display: none;
  min-height: ${(props: { pageHeight?: string }) =>
    props.pageHeight ? props.pageHeight : "65vh"};
  @media print {
    display: block;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;

  @media print {
    margin-bottom: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

export const FlexStart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 5px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoTitle = styled.h1`
  margin: 0 10px;
`;

export const Span = styled.span`
  color: #000;
  font-size: 18px;
  font-weight: bold;
`;

export const Item = styled.div`
  margin: 25px 0;
  min-width: 250px;
`;

export const Description = styled.p`
  font-size: 14px;
  margin: 5px 0;
`;

export const Label = styled.label`
  color: ${Colors.battleship_grey};
  font-size: 16px;
`;

// pdf print
export const PrintWrapper = styled.div`
  font-family: Famtree;
`;

export const DownloadIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 auto 11px auto;
  width: 63%;
  color: #000;
  direction: rtl;
  cursor: pointer;
  @media (max-width: 767px) {
    display: none;
  }
  .download-pdf {
    display: flex;
    align-items: center;
    cursor: pointer;
    all: unset;
  }
  .download-after-scroll {
    background-color: #44c874;
    width: 100%;
    color: #44c874;
    border-radius: 20px;
    padding: 10px 0;
    text-align: center;
    margin: 30px 0;
    span {
      color: #fff;
      font-weight: bold;
    }
  }
  span {
    font-family: Famtree;
    font-size: 14px;
    font-weight: normal;
    color: ${Colors.charcoal_grey};
    margin: 0 10px 0 10px;
    color: #000;
  }
  @media (max-width: 900px) {
    width: 95%;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0 20px 0 20px;
  button {
    color: #000;
    padding: 0;
    font-family: Famtree;
  }
`;

export const PrintContent = styled.div`
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  padding: 10px;
  border-radius: 4px;
  font-family: Famtree;
  .extra_question {
    display: none;
  }
  @media all {
    .page-break {
      display: block;
    }
  }
  @media print {
    display: block;
    .extra_question {
      display: block;
    }
    .extra_with_slider {
      display: none;
    }
    @page {
      margin: 0mm 5mm;
      size: auto;
    }
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
    .page-break {
      margin-top: 1rem;
      display: block;
      break-before: auto;
      break-after: auto;
      page-break-inside: avoid;
    }
  }
`;
