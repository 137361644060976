/** @format */

import * as React from "react";
import { SVGProps } from "react";

const ArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ marginLeft: "10px" }}
  >
    <path
      d="M16.564 8.772 11 3.208 5.436 8.772M11 18.792V3.364"
      stroke="#E20A0A"
      strokeWidth={1.35}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0,0"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowUp;
