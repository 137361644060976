import * as React from "react";

function ShortAnswerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={19}
      viewBox="0 0 20 19"
    >
      <g transform="translate(0 1)" fill="none" fillRule="evenodd">
        <path
          fill="#BFAEFF"
          fillRule="nonzero"
          d="M12.25 7h-1.5v4.75a.75.75 0 11-1.5 0V7h-1.5a.75.75 0 110-1.5h4.5a.75.75 0 110 1.5z"
        />
        <circle
          cx={18.25}
          cy={8.5}
          r={1.75}
          fill="#BFAEFF"
          fillRule="nonzero"
        />
        <circle cx={1.75} cy={8.5} r={1.75} fill="#BFAEFF" fillRule="nonzero" />
        <path
          stroke="#BFAEFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M3.75.25h12.5a2 2 0 012 2v12.5a2 2 0 01-2 2H3.75a2 2 0 01-2-2V2.25a2 2 0 012-2z"
        />
      </g>
    </svg>
  );
}

export default ShortAnswerIcon;
