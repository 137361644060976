import React from "react";

function MapIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="21"
      viewBox="0 0 19 21"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1624 -296)">
          <g transform="translate(1415)">
            <g transform="translate(0 284)">
              <g transform="translate(199.957 4)">
                <g
                  stroke="#344247"
                  strokeWidth="1.5"
                  transform="translate(10 8.5)"
                >
                  <circle cx="8.421" cy="8.421" r="2.368"></circle>
                  <path d="M16.579 8.421a8.158 8.158 0 00-16.316 0 8.12 8.12 0 001.958 5.293s3.871 4.402 5.113 5.587c.61.581 1.563.581 2.173 0 1.447-1.38 5.114-5.587 5.114-5.587a8.115 8.115 0 001.958-5.293z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MapIcon;
