/** @format */

import { Input } from 'common/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select as AntDSelect } from 'antd';
import { useQuery } from 'react-query';
import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { Select } from 'common/Select';
import { Typography } from 'antd';
import { StyledForm } from './styles';

const { Text } = Typography;

const { Option } = AntDSelect;

const RequestConsultationForm = ({ form }) => {
  const { t, i18n } = useTranslation();

  const { data: cities } = useQuery(
    ['cities'],
    () => fetchGetRequests('api/countries/1/cities'),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const { data: countries } = useQuery(
    ['countries'],
    () => fetchGetRequests('api/countries'),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const validateMessages = {
    required: t('required_error_message'),
    pattern: {
      mismatch: t('numbers_only'),
    },

    types: {
      email: t('patternـisـnotـcorrect'),
    },
  };

  return (
    <StyledForm
      form={form}
      layout='vertical'
      name='form_in_modal'
      initialValues={{ modifier: 'public' }}
      style={{ direction: i18n.language === 'ar' ? 'rtl' : 'ltr' }}
      validateMessages={validateMessages}
      lang={i18n.language === 'ar' ? 'ar' : 'en'}
    >
      <StyledForm.Item
        name='name'
        label={t('name')}
        rules={[{ required: true, message: t('required_error_message') }]}
        labelAlign='left'
      >
        <Input />
      </StyledForm.Item>

      <StyledForm.Item
        name='company'
        label={t('company')}
        rules={[{ required: true, message: t('required_error_message') }]}
      >
        <Input />
      </StyledForm.Item>

      <StyledForm.Item
        name='field'
        label={t('field_type')}
        rules={[{ required: true, message: t('required_error_message') }]}
      >
        <Input />
      </StyledForm.Item>

      <StyledForm.Item
        name='email'
        label={t('email')}
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input />
      </StyledForm.Item>

      <StyledForm.Item
        name='mobile'
        label={t('phone_number')}
        rules={[
          {
            required: true,
            pattern: /^[0-9]*$/,
          },
        ]}
      >
        <Input />
      </StyledForm.Item>

      <StyledForm.Item
        label={t('country')}
        name='country_id'
        rules={[{ required: true, message: t('required_error_message') }]}
      >
        <Select placeholder={t('select_country')} className='full-width'>
          {countries?.response.countries.map(
            (country: { id: number; name: string }) => (
              <Option key={country.id} value={country.id}>
                <Text>{country.name}</Text>
              </Option>
            )
          )}
        </Select>
      </StyledForm.Item>

      <StyledForm.Item
        label={t('city')}
        name='city_id'
        rules={[{ required: true, message: t('required_error_message') }]}
      >
        <Select placeholder={t('select_city')} className='full-width'>
          {cities?.response.cities.map((city: { id: number; name: string }) => (
            <Option key={city.id} value={city.id}>
              <Text style={{ fontFamily: 'Famtree' }}>{city.name}</Text>
            </Option>
          ))}
        </Select>
      </StyledForm.Item>
    </StyledForm>
  );
};

export default RequestConsultationForm;
