import * as React from "react";

function DateIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={18}
      viewBox="0 0 19 18"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#50BD9F"
          strokeWidth={1.5}
          d="M15.072 17.25h-11.5a2.5 2.5 0 01-2.5-2.5V3.25a2.5 2.5 0 012.5-2.5h11.5a2.5 2.5 0 012.5 2.5v11.5a2.5 2.5 0 01-2.5 2.5zm-14-12.5h16.5"
        />
        <path
          fill="#50BD9F"
          fillRule="nonzero"
          d="M6.572 14.5a.749.749 0 01-.75-.75V9.29l-.513.171a.75.75 0 11-.474-1.423l1.5-.5a.751.751 0 01.987.712v5.5c0 .415-.335.75-.75.75z"
        />
        <path
          stroke="#50BD9F"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M13.072 13.75h-3.5c0-1.367 1.268-1.68 1.73-1.918.471-.243 1.77-.657 1.77-1.957a1.75 1.75 0 00-3.481-.258"
        />
      </g>
    </svg>
  );
}

export default DateIcon;
