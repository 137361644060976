import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

export const P = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  margin-bottom: 0px;
  margin-right: 10px;
  @media (max-width: 991px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

export const Span = styled.span`
  cursor: pointer;
  margin: ${(props: { language?: string }) =>
    props.language === "ar" ? "5px 11px 0 0" : "5px 11px 0 0"};
`;

type Props = {
  language?: string;
  display?: boolean;
};
export const LogoutWrapper = styled.div<Props>`
  display: flex;
  align-items: center;
  flex-direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "row" : "row-reverse"};
  cursor: pointer;
  @media (max-width: 767px) {
    display: ${(props: { display?: boolean }) =>
      props.display ? "flex" : "none"};
  }

  margin-right: 10px;
`;
