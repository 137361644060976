/** @format */

import React from "react";
import { CustomHeader } from "common/Header";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";
import { useNavigate, useParams } from "react-router-dom";
import Unauthorized from "common/UnAuthorized";
import NotFound from "common/NotFound";
import { Container, TabsWrapper } from "../style";
import { Trans, useTranslation } from "react-i18next";
import Tabs from "common/Tabs";
import DetailsSection from "./details-section";
import Spinner from "common/LoadingIndecator";
import QuestionsDetails from "components/QuestionsDetails/view";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { axiosApiInstance } from "infrastructure/utils/api";
import Modal from "common/Modal";
import { StyledP } from "common/Menu/style";
import { Shoppers } from "./shoppers-listing";
import ShoppersDetails from "./shoppers-details";
import { ShoppersSummary } from "./shoppers-summary";
import { Helmet } from "react-helmet";

const { useState } = React;

const JobsDetails = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] =
    useState<boolean>(false);

  const [type, setType] = useState<string>("");

  const { data, isLoading } = useQuery(["jobDetails"], () =>
    fetchGetRequests(`api/company/v2/jobs/${id}`)
  );

  const showModal = (type: string) => {
    setType(type);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDuplicateModal = () => {
    setIsDuplicateModalVisible(true);
  };

  const handleCancelDuplicateModal = () => {
    setIsDuplicateModalVisible(false);
  };

  const handleDeleteJob = useMutation(
    (text: string | number | undefined) =>
      axiosApiInstance.delete(`api/company/v1/jobs/${text}`),
    {
      onError: (err: any, previousValue) => {
        queryClient.setQueryData("jobsList", previousValue);
        if (err.response.data.statusCode === 400) {
          showNotification(
            NotificationStatus.Error,
            " ",
            err.response.data.message
          );
        }
      },
      onSuccess: () => {
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
        navigate("/jobs");
      },
      onSettled: () => {
        queryClient.invalidateQueries("jobsList");
      },
    }
  );

  const handleCancelJob = useMutation(
    () => axiosApiInstance.post("api/company/v1/jobs/cancel", { job_id: id }),
    {
      onMutate: async () => {
        setIsModalVisible(false);
      },
      onError: (err: any, previousValue) => {
        queryClient.setQueryData("jobsList", previousValue);
        if (err.response.data.statusCode === 400) {
          showNotification(
            NotificationStatus.Error,
            " ",
            err.response.data.message
          );
        }
      },
      onSuccess: () => {
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
        navigate("/jobs");
      },
      onSettled: () => {
        queryClient.invalidateQueries("jobsList");
      },
    }
  );

  const handleDuplicate = (id: string | undefined) => {
    axiosApiInstance
      .post("api/company/v1/jobs/duplicate", { job_id: id })
      .then(() => {
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
        navigate("/jobs");
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          t("Error"),
          err.response.data.message
        );
      });
  };

  if (data?.error === 403) return <Unauthorized />;
  if (data?.error === 404) return <NotFound />;
  if (isLoading) return <Spinner size={"large"} tip={"Loading..."} />;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> تفاصيل المهمة -تدري </title>
      </Helmet>
      <CustomHeader
        title={data?.response?.job?.name}
        path={"/jobs"}
        editPath={`/jobs/edit/${id}`}
        handleDeleteIcon={() => showModal("delete")}
        handleCancelJob={() => showModal("cancel")}
        handleDuplicate={() => handleDuplicate(id)}
        status={data?.response?.job?.status.id}
        component="jobs"
        cloning={true}
        isDuplicateModalVisible={isDuplicateModalVisible}
        showDuplicateModal={showDuplicateModal}
        handleCancelDuplicateModal={handleCancelDuplicateModal}
        duplicateTitle={<Trans>duplicate_job</Trans>}
        duplicateMessage={<Trans>duplicate_job_message</Trans>}
      />

      <Modal
        type="jobs"
        title={
          <StyledP>
            {type === "delete" ? (
              <Trans>delete_job</Trans>
            ) : (
              <Trans>cancel_job</Trans>
            )}
          </StyledP>
        }
        visible={isModalVisible}
        onOk={() => {
          type === "delete"
            ? handleDeleteJob.mutate(id)
            : handleCancelJob.mutate();
        }}
        onCancel={handleCancel}
      >
        {type === "delete" ? (
          <Trans>job_modal_delete_message</Trans>
        ) : (
          <Trans>cancel_job_message_modal</Trans>
        )}
      </Modal>

      <Container>
        <Tabs
          tabOneTitle={t("evaluations")}
          tabTwoTitle={t("questions")}
          tabThreeTitle={t("details")}
          tabOneContent={
            <TabsWrapper>
              <Tabs
                type="card"
                tabOneTitle={t("summary")}
                tabTwoTitle={t("details")}
                tabThreeTitle={t("all")}
                tabOneContent={
                  <ShoppersSummary
                    templateId={data?.response?.job.evaluation_template_id}
                    branch={data?.response.job.branch}
                    startTime={data?.response.job.startTime}
                    endTime={data?.response.job.endTime}
                    pdf_file={data?.response.job?.pdf_file}
                    shoppersCount={data?.response?.job?.shoppersData.count}
                  />
                }
                tabTwoContent={
                  <ShoppersDetails
                    branch={data?.response.job.branch}
                    startTime={data?.response.job.startTime}
                    endTime={data?.response.job.endTime}
                    shoppersCount={data?.response?.job?.shoppersData.count}
                  />
                }
                tabThreeContent={<Shoppers />}
              />
            </TabsWrapper>
          }
          tabTwoContent={
            <QuestionsDetails
              templateFile={data?.response?.job?.templateFile}
            />
          }
          tabThreeContent={<DetailsSection data={data?.response?.job} />}
          centered={true}
          className={"mainTabs"}
        />
      </Container>
    </>
  );
};

export default JobsDetails;
