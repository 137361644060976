/** @format */

import Spinner from "common/LoadingIndecator";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { axiosApiInstance } from "infrastructure/utils/api";
import React from "react";
import { useEffect } from "react";
import { Trans } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";

export const Verfication = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    axiosApiInstance
      .get(
        `api/email/verify/${Object.values(params)[0] + "" + location.search}`
      )
      .then((res) => {
        if (res.data.status === "ok") {
          navigate("/login");
          showNotification(
            NotificationStatus.Success,
            <Trans>done</Trans>,
            <Trans>verify_done_message</Trans>
          );
        }
      });
  }, []);

  return <Spinner size="large" />;
};
