import React from "react";
import { Trans } from "react-i18next";
import { Count, CountWrapper } from "./style";

const AnswerCount = ({
  totalShoppersCount,
  answeredShoppersCount,
  skippedShoppersCount,
}: any) => {
  return (
    <CountWrapper>
      <Count>
        {totalShoppersCount < 3 || totalShoppersCount > 10 ? (
          <Trans
            values={{
              totalShoppersCount: totalShoppersCount,
              answeredShoppersCount: answeredShoppersCount,
            }}
          >
            shoppers_answered_question_more_10
          </Trans>
        ) : (
          <Trans
            values={{
              totalShoppersCount: totalShoppersCount,
              answeredShoppersCount: answeredShoppersCount,
            }}
          >
            shoppers_answered_question_less_10
          </Trans>
        )}
      </Count>
      {skippedShoppersCount > 0 && (
        <Count>
          <Trans
            values={{
              totalShoppersCount: totalShoppersCount,
              skippedShoppersCount: skippedShoppersCount,
            }}
          >
            shoppers_skipped_count
          </Trans>
        </Count>
      )}
    </CountWrapper>
  );
};

export default AnswerCount;
