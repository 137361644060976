import { Skeleton } from "antd";
import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

export const StyledCard = styled.div`
  height: 270px;
  border-radius: 16px;
  border: solid 0.5px #cccccc;
  background-color: ${Colors.white};
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  padding: 15px;
`;

export const StyledSkeleton = styled(Skeleton)`
  &.ant-skeleton {
    width: 117%;
    direction: rtl;
  }
`;

export const Title = styled.span`
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  margin: 0 15px;
`;

export const Status = styled.span`
  font-family: Famtree;
  font-size: 12px;
  font-weight: bold;
  color: ${(props: { status: number | false }) =>
    props.status === 1
      ? Colors.charcoal_grey_54
      : props.status === 2
      ? Colors.dark_yellow
      : props.status === 3
      ? Colors.orange
      : props.status === 4
      ? Colors.yellow
      : props.status === 5
      ? Colors.blue
      : props.status === 6
      ? Colors.lipstick
      : props.status === 7
      ? Colors.dark_mint
      : props.status === 8
      ? Colors.red
      : Colors.yellow};
`;

export const CircleSpan = styled.span`
  width: 12px;
  height: 12px;
  margin: 5px 0 4px 8px;
  color: ${(props: { status: number | false }) =>
    props.status === 1
      ? Colors.dark_mint
      : props.status === 2
      ? Colors.lipstick
      : props.status === 3
      ? Colors.orange
      : props.status === 4
      ? Colors.blue
      : Colors.yellow};
`;

export const TimeSpan = styled.span`
  color: ${(props: { createdAt?: boolean }) =>
    props.createdAt ? Colors.charcoal_grey_38 : Colors.charcoal_grey};
  font-size: 14px;
  font-family: Famtree;
  margin-bottom: 25px;
  word-break: break-all;
`;

export const TimesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  max-width: 95%;
`;

export const Label = styled.label`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_38};
`;

export const TimesSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShoppersContainer = styled.div`
  margin-top: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const NoShoppersLabel = styled.label`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

/* small card styling */

type Props = {
  className?: string;
  language?: string;
};
//@ts-ignore
export const SmallCardStyle = styled.div<Props>`
  min-height: 134px;
  border-radius: 16px;
  border: solid 0.5px #cccccc;
  background-color: ${Colors.white};
  padding: 15px;
  width: 95%;
  direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};

  :hover {
    cursor: ${(props) => props.className === "shopperDetails" && "pointer"};
    background-color: ${(props) =>
      props.className === "shopperDetails" && Colors.light_gray};
    border: ${(props) =>
      props.className === "shopperDetails" && `2px solid ${Colors.dark_mint}`};
  }
`;

export const ShopperDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${(props: { language: string }) =>
    props.language === "ar" ? "0 17px 0 0" : "0 0 0 17px"};
`;

export const ShoppersTitle = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin-bottom: 0px;
  text-align: ${(props: { language?: string }) =>
    props.language === "ar" ? "right " : "left"};

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

export const ShoppersAddress = styled.span`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  color: #757575;
  text-align: ${(props: { language?: string }) =>
    props.language === "ar" ? "right " : "left"};

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

export const ShoppersStatusAndIdRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: ${(props: { language: string }) =>
    props.language === "ar" ? "59%" : "77%"};
  margin-top: 22px;
`;

export const JobIdSpan = styled.span`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  color: ${Colors.charcoal_grey_54};
`;

export const ShoppersStatus = styled.span`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  color: ${(props: { status: number }) => props.status === 5 && "#7ed321"};
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

export const StyledLink = styled.a`
  color: ${Colors.charcoal_grey};
  word-break: break-all;
  :hover {
    color: ${Colors.dark_mint};
  }
`;

export const PaymentFailerSpan = styled.span`
  background-color: red;
  color: white;
  height: 30px;
  width: 30%;
  font-family: Famtree;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 4px;
  border: 1px solid;
  border-radius: 10px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div``;

export const JobID = styled.span`
  color: #817a7a;
  font-family: Famtree;
  font-size: 12px;
  margin-bottom: 0;
`;
