/**
 * /* @no-unused-expressions
 *
 * @format
 */

import React, { useMemo } from "react";
import Form from "./form";
import {
  CreateFormSection,
  EvaluationTemplateFormContainer,
  Label,
} from "../style";
import { Trans, useTranslation } from "react-i18next";
import { axiosApiInstance } from "infrastructure/utils/api";
import { QuestionsProps } from "infrastructure/interfaces/evaluationForm.interface";
import { useForm } from "react-hook-form";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { useNavigate, useParams } from "react-router-dom";
import { mockedArr } from "infrastructure/helpers/questionTypeList";
import { useStore } from "infrastructure/zustandStore/evaluation-details-store";
import { QuestionTypesStore } from "infrastructure/zustandStore/question-types-store";
import { CustomHeader } from "common/Header";
import Spinner from "common/LoadingIndecator";
import { Helmet } from "react-helmet";
import shallow from "zustand/shallow";
import i18n from "i18n";
import hash from "object-hash";

const { v4: uuidv4 } = require("uuid"); //using for the draggable component and the delete functionality for multi and single choices

const { useEffect, useState } = React;

const EditEvaluationTemplate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saved, setSaved] = useState(false);

  const [evaluationDetailsData, evaluationDetails, loading] = useStore(
    (state: any) => [
      state.evaluationDetailsData,
      state.evaluationDetails,
      state.loading,
    ],
    shallow
  );

  useEffect(() => {
    evaluationDetails(id);
    questionTypesList();
    useStore.setState({ loading: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // question_categories
  const question_categories = localStorage.getItem("question_categories")
    ? JSON.parse(localStorage.getItem("question_categories") || "")
    : [];

  const [selectInputValues, setSelectInputValues] =
    useState(question_categories);

  const [questionTypes, questionTypesList] = QuestionTypesStore(
    (state: any) => [state.questionTypes, state.questionTypesList]
  );

  useEffect(() => {
    let initialEvaluationTemplate = JSON.parse(
      //@ts-ignore
      localStorage.getItem(`draftEditForm.${id}`)
    );

    const interval = setInterval(() => {
      if (
        initialEvaluationTemplate &&
        hash(initialEvaluationTemplate) !== hash(evaluationDetailsData)
      ) {
        initialEvaluationTemplate = evaluationDetailsData;

        useStore.setState({ evaluationDetailsData: initialEvaluationTemplate });
        localStorage.setItem(
          `draftEditForm.${id}`,
          JSON.stringify(initialEvaluationTemplate)
        );
        setSaved(true);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
      setSaved(false);
    };
  }, [evaluationDetailsData]);

  useEffect(() => {
    const valuesList = selectInputValues.filter(
      (v: any, i: number, a: any) =>
        a.findIndex((v2: any) => v2.value === v.value) === i
    );
    localStorage.setItem("question_categories", JSON.stringify(valuesList));
  }, [selectInputValues]);

  const computedEvaluationTemplates = useMemo(() => {
    return {
      ...evaluationDetailsData,
      questions: evaluationDetailsData?.questions?.map(
        (
          question: {
            weight_percentage: number;
            total_points: number;
            id: number;
            options: any[];
          },
          index: number
        ) => {
          const sumTotalPoints = evaluationDetailsData.questions.reduce(
            (
              acc: any = 0,
              curr: { questionType: { id: number }; total_points: any }
            ) => {
              if (
                curr?.questionType?.id === 2 ||
                curr?.questionType?.id === 8 ||
                curr?.questionType?.id === 9
              ) {
                return acc + curr.total_points;
              } else {
                return acc;
              }
            },
            0
          );

          if (question) {
            if (question.total_points !== undefined || !isNaN(sumTotalPoints)) {
              question.weight_percentage = Math.round(
                (question.total_points * 100) / sumTotalPoints
              );
            }
          }

          return question;
        }
      ),
    };
  }, [evaluationDetailsData]);

  const { handleSubmit, register, errors, formState } = useForm();

  const handleRemoveQuestionBlockOrMultiChoiceQuestionsItem = (
    index: number,
    i: number,
    type: string
  ) => {
    const list = evaluationDetailsData.questions;
    if (type === "multiChoice") {
      const questions = list.map((question: { options: any[] }) => {
        const options = question?.options?.filter((option: any) => {
          return option.id !== i;
        });
        const sumOfMultiChoiceOptions = options?.reduce(
          (acc: number, option: any) => acc + option.points,
          0
        );

        list[index].total_points = sumOfMultiChoiceOptions;

        return { ...question, options };
      });
      useStore.setState({
        evaluationDetailsData: {
          ...evaluationDetailsData,
          questions: questions,
        },
      });
    } else {
      list.splice(index, 1);
      if (index !== 0) {
        useStore.setState({
          evaluationDetailsData: { ...evaluationDetailsData, questions: list },
        });
      }
    }
  };

  const handleAddQuestionBlockOrMultiChoiceQuestion = (
    index: number,
    i: number,
    type: string
  ) => {
    if (
      type === "multiChoice" &&
      evaluationDetailsData?.questions[index].options?.length - 1 === i &&
      evaluationDetailsData?.questions[index].options?.length <= 5
    ) {
      const newQuestions: Array<QuestionsProps> = [
        ...evaluationDetailsData.questions,
      ];
      newQuestions[index] = {
        ...newQuestions[index],
        options: [
          ...evaluationDetailsData.questions[index].options,
          { id: uuidv4(), option: "", points: 0 },
        ],
      };
      useStore.setState({
        evaluationDetailsData: {
          ...evaluationDetailsData,
          questions: newQuestions,
        },
      });
    } else if (
      type === "multiChoice" &&
      (evaluationDetailsData?.questions[index].options?.length <= 5 &&
        evaluationDetailsData?.questions[index].options?.length - 1) ===
        i + 1
    ) {
      let newQuestions: Array<QuestionsProps> = [
        ...evaluationDetailsData.questions,
      ];
      newQuestions[index] = {
        ...newQuestions[index],
        options: [
          ...evaluationDetailsData.questions[index].options,
          { id: uuidv4(), option: "", points: null },
        ],
      };
      useStore.setState({
        evaluationDetailsData: {
          ...evaluationDetailsData,
          questions: newQuestions,
        },
      });
    } else if (
      type !== "multiChoice" &&
      evaluationDetailsData.questions.length - 1 === index
    ) {
      useStore.setState({
        evaluationDetailsData: {
          ...evaluationDetailsData,
          questions: [
            ...evaluationDetailsData.questions,
            {
              id: uuidv4(),
              question_type_id: 0,
              question: "",
              required: true,
              weight_percentage: 0,
              total_points: 0,
            },
          ],
        },
      });
    }
  };

  const handleOnChangeSwitchButton = (checked: boolean, i: number) => {
    const list: any = evaluationDetailsData.questions;
    list[i]["required"] = checked;
    useStore.setState({
      evaluationDetailsData: { ...evaluationDetailsData, questions: list },
    });
  };

  const handleInputChange = (e: any, index: any, i: any, type: any) => {
    const { name, value, id }: any = e.target;
    let list: any = evaluationDetailsData.questions;
    let mainList: any = evaluationDetailsData;
    if (type === "form") {
      if (
        id === "min" ||
        id === "max" ||
        id === "step" ||
        id === "min_points" ||
        id === "max_points"
      ) {
        const scaleChoicePoints = Object.values({
          min_points:
            id === "min_points"
              ? parseInt(value)
              : parseInt(list[index].scale?.min_points || 0),
          max_points:
            id === "max_points"
              ? parseInt(value)
              : parseInt(list[index].scale?.max_points || 0),
        });

        list[index].total_points = Math.max(...scaleChoicePoints);
        list[index].scale[id] = value;
      } else if (id === "options") {
        list[index].options[i].option = value;
      } else if (id === "points") {
        list[index].options[i].points = parseInt(value);
        if (list[index].questionType.id === 9) {
          let arr: any[] = [];
          list[index].options?.filter((item: { [x: string]: any }) => {
            if (item.points > 0) {
              arr.push(item.points);
            }
          });
          list[index].total_points = Math.max(...arr);
        }
        if (list[index].questionType.id === 8) {
          let arr: any[] = [];
          list[index].options?.filter((item: { [x: string]: any }) => {
            if (item.points > 0) {
              arr.push(item.points);
            }
          });

          const sumOfMultiChoiceOptions = arr?.reduce(
            (partialSum: any, a: any) => partialSum + a,
            0
          );

          list[index].total_points = sumOfMultiChoiceOptions;
          // useStore.setState({
          //   evaluationDetailsData: {
          //     ...evaluationDetailsData,
          //     questions: list,
          //   },
          // });
        }
      } else {
        list[index][id] = value;
        useStore.setState({
          evaluationDetailsData: {
            ...evaluationDetailsData,
            questions: list,
          },
        });
      }
    } else {
      mainList[name] = value;
      useStore.setState({
        evaluationDetailsData: { ...evaluationDetailsData, questions: list },
      });
    }
  };

  const handleSelectOnChange = (value: number, i: number, name: any) => {
    const list: any = evaluationDetailsData.questions;

    list[i]["questionType"] = { id: value, name: name.title };

    if (list[i].questionType.id === 2) {
      list[i]["scale"] = {
        min: null,
        max: null,
        step: null,
        min_points: null,
        max_points: null,
      };
    }

    if (list[i].questionType.id === 8) {
      list[i]["options"] = [
        { id: uuidv4(), option: "", points: null },
        { id: uuidv4(), option: "جميع الاجابات لا تنطبق", points: 0 },
      ];
    } else if (list[i].questionType.id === 9) {
      list[i]["options"] = [
        { id: uuidv4(), option: "", points: null },
        { id: uuidv4(), option: "", points: null },
      ];
    }

    useStore.setState({
      evaluationDetailsData: { ...evaluationDetailsData, questions: list },
    });
  };

  const handleQuestionCategoryChange = (newSelectValue: any, i: any) => {
    const list: any = evaluationDetailsData.questions;
    list[i]["category"] = newSelectValue ? newSelectValue.value : "";
    useStore.setState({ ...evaluationDetailsData, questions: list });
    newSelectValue &&
      setSelectInputValues([...selectInputValues, newSelectValue]);
  };

  const onExtraImagesChange = (e: any, i: number) => {
    const list: any = evaluationDetailsData.questions;
    list[i]["extra_images"] = e.target.checked;
    useStore.setState({ ...evaluationDetailsData, questions: list });
  };

  const onExtraVideosChange = (e: any, i: number) => {
    const list: any = evaluationDetailsData.questions;
    list[i]["extra_videos"] = e.target.checked;
    useStore.setState({ ...evaluationDetailsData, questions: list });
  };

  const onSubmit = () => {
    setIsLoading(true);
    delete computedEvaluationTemplates.id;

    computedEvaluationTemplates.questions.map((item: any, index: number) => {
      item["question_type_id"] = item["questionType"];
      item.question_type_id = item.questionType.id;
      delete item.questionType;
    });

    axiosApiInstance
      .put(
        `api/company/v1/evaluation-templates/${id}`,
        computedEvaluationTemplates
      )
      .then(() => {
        setIsLoading(false);
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );

        localStorage.clearItem("draftEditForm");

        navigate("/evaluation-templates");
      })
      .catch((err) => {
        setIsLoading(false);

        showNotification(
          NotificationStatus.Error,
          t("error"),
          err.response.data.message
        );
      });
  };

  if (loading) {
    return <Spinner size={"large"} tip={"Loading..."} />;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> تعديل نموذج تقييم -تدري </title>
      </Helmet>
      <EvaluationTemplateFormContainer>
        {saved && (
          <Label language={i18n.language === "ar" ? "ar" : "en"}>
            Saved....
          </Label>
        )}
        <CustomHeader
          title={<Trans>evaluation_template_edit_name</Trans>}
          path={"/evaluation-templates"}
          type="withoutLeftContainer"
        />
      </EvaluationTemplateFormContainer>
      <CreateFormSection language={i18n.language === "ar" ? "ar" : "en"}>
        {loading ? (
          <Spinner size={"large"} tip={"Loading..."} />
        ) : (
          <Form
            setEvaluationTemplates={() => {}}
            questionTypes={questionTypes}
            mockedArr={mockedArr}
            handleRemoveQuestionBlockOrMultiChoiceQuestionsItem={
              handleRemoveQuestionBlockOrMultiChoiceQuestionsItem
            }
            handleAddQuestionBlockOrMultiChoiceQuestion={
              handleAddQuestionBlockOrMultiChoiceQuestion
            }
            handleOnChangeSwitchButton={handleOnChangeSwitchButton}
            handleInputChange={handleInputChange}
            handleSelectOnChange={handleSelectOnChange}
            handleSubmit={handleSubmit(onSubmit)}
            errors={errors}
            register={register}
            formState={formState}
            type="Edit"
            editableData={computedEvaluationTemplates}
            loading={isLoading}
            onExtraImagesChange={onExtraImagesChange}
            onExtraVideosChange={onExtraVideosChange}
            handleQuestionCategoryChange={handleQuestionCategoryChange}
            question_categories={question_categories}
          />
        )}
      </CreateFormSection>
    </>
  );
};

export default EditEvaluationTemplate;
