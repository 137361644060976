/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { CustomHeader } from "common/Header";
import { PContainer, Container, Hr, ContnetContainer } from "../style";
import Step from "common/Step";
import DescriptionForm from "./description-form";
import { useForm } from "react-hook-form";
import DemandsForm from "./demands-form";
import { useStore } from "infrastructure/zustandStore/branch-list-store";
import { createJobUseStore } from "infrastructure/zustandStore/create-job-store";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import PaymentForm from "./payment-form";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";
import { Helmet } from "react-helmet";
import storage from "infrastructure/utils/storage";

const { useEffect, useState } = React;

type Props = {
  name: string;
  value: null | string | any;
};

const CreateEvaluationJob = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [jobsData, res, jobsDataFn] = createJobUseStore(
    (state: Record<string | number | symbol, any>) => [
      state.jobsData,
      state.res,
      state.jobsDataFn,
    ]
  );

  const { data: templates } = useQuery(
    ["templates"],
    () => fetchGetRequests("api/company/v1/evaluation-templates/active"),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const [branchListData, branchList] = useStore(
    (state: Record<string | number | symbol, any>) => [
      state.branchListData,
      state.branchList,
    ]
  );

  const { data: configrationList } = useQuery(
    ["configrationList"],
    () => fetchGetRequests("api/configurations"),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const [current, setCurrent] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<string>("");
  const [jobId, setJobId] = useState<number>();

  const [evaluationTemplateId, setEvaluationTemplateId] = useState<number>(0);

  const { handleSubmit, register, errors, formState, watch, setValue, reset } =
    useForm<{
      evaluationTemplateSelect: number;
      picker: Array<number>;
      evluationPlaceSelect: number;
      ageSelect: number;
      genderSelect: Array<number>;
      educationSelect: number;
      skillsSelect: Array<number>;
      experiencesSelect: number;
      languagesSelect: Array<number>;
      nationalitySelect: number;
      extraItemsSelect: number;
      paymentType: string;
      description: string;
      instructions: string;
    }>({
      defaultValues: {
        evaluationTemplateSelect: 0,
        picker: [],
        evluationPlaceSelect: 0,
        ageSelect: 0,
        genderSelect: [],
        educationSelect: 0,
        skillsSelect: [],
        experiencesSelect: 0,
        languagesSelect: [],
        nationalitySelect: 0,
        extraItemsSelect: 0,
        paymentType: "",
        description: "",
        instructions: "",
      },
    });

  useEffect(() => {
    branchList();
    jobsDataFn();
  }, []);

  const next = () => {
    if (current === 0) {
      setCurrent(current + 1);
    } else if (current === 1 && jobsData) {
      axiosApiInstance
        .post("api/company/v1/jobs", jobsData)
        .then((res) => {
          setJobId(res.data.id);
          createJobUseStore.setState({ res: res.data });
          storage.setItem("jobs_data", JSON.stringify(res.data));
          setCurrent(current + 1);
        })
        .catch((err) => {
          showNotification(
            NotificationStatus.Error,
            t("error"),
            err.response.data.message
          );
        });
    }
  };

  const prev = () => {
    reset();
    createJobUseStore.setState({ jobsData: { extra_pay: false } });
    if (current === 2) {
      navigate(`/jobs/edit/${jobId}`);
    }
    setCurrent(current - 1);
  };

  const handleSelectOnChange = (value: number, item?: any, type?: string) => {
    const { title, defaultValue }: any = item;
    const list: any = { ...jobsData };

    if (type === "evaluationTemplateSelect") {
      if (list["name"] === "" && list["description"] === "") {
        list["name"] = defaultValue?.name;
        list["description"] = defaultValue?.description;
      } else {
        list["name"] = defaultValue?.name;
        list["description"] = defaultValue?.description;
      }
    }

    if (item && item.length > 0 && item instanceof Array) {
      const items: any = { ...item };
      const title = items[0].title;
      list[title] = value;
    } else if (value === null) {
      delete list[title];
    } else {
      list[title] = value;
    }

    setEvaluationTemplateId(value);
    createJobUseStore.setState({ jobsData: list });
  };

  const handleInputChange = (e: { target: Props }) => {
    let { name, value }: Props = e.target;
    let list = { ...jobsData };
    if (name === "shoppers_count" || name === "extra_item_price") {
      list[name] = parseInt(value);
      createJobUseStore.setState({ jobsData: list });
    } else {
      list[name] = value;
      createJobUseStore.setState({ jobsData: list });
    }
  };

  const handleRangePickerOnchange = (date: any, dateString: Array<string>) => {
    const list: any = { ...jobsData };
    list["start_time"] = dateString[0];
    list["end_time"] = dateString[1];
    createJobUseStore.setState({ jobsData: list });
  };

  const handleOnChangeSwitchButton = (checked: boolean) => {
    const list: any = { ...jobsData };
    list["extra_pay"] = checked;
    createJobUseStore.setState({ jobsData: list });
  };

  const steps = [
    {
      title: t("description"),
      content: (
        <DescriptionForm
          templates={templates?.response?.data?.templates}
          handleSelectOnChange={handleSelectOnChange}
          evaluationTemplateId={evaluationTemplateId}
          handleInputChange={handleInputChange}
          template={jobsData}
          handleSubmit={handleSubmit(next)}
          register={register}
          errors={errors}
          formState={formState}
          watch={watch}
          setValue={setValue}
        />
      ),
      description: t("job_description_span"),
    },
    {
      title: t("demands"),
      content: (
        <DemandsForm
          branchListData={branchListData}
          handleSelectOnChange={handleSelectOnChange}
          handleRangePickerOnchange={handleRangePickerOnchange}
          handleInputChange={handleInputChange}
          handleOnChangeSwitchButton={handleOnChangeSwitchButton}
          handleSubmit={handleSubmit(next)}
          register={register}
          errors={errors}
          formState={formState}
          watch={watch}
          setValue={setValue}
          current={current}
          prev={prev}
          data={jobsData}
          configrationList={configrationList?.response?.configuration}
        />
      ),
      description: t("demands_description_span"),
    },
    {
      title: t("payment"),
      content: (
        <PaymentForm
          res={res}
          current={current}
          prev={prev}
          setPaymentType={setPaymentType}
          paymentType={paymentType}
        />
      ),
      description: t("payment_description_span"),
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> أضف مهمة -تدري </title>
      </Helmet>
      <PContainer>
        <CustomHeader
          title={<Trans>add_evaluation_job</Trans>}
          path={"/jobs"}
          pdf="jobss"
        />
      </PContainer>

      <Container>
        <Step steps={steps} current={current} />
        <Hr />
        <ContnetContainer>{steps[current].content}</ContnetContainer>
      </Container>
    </>
  );
};

export default CreateEvaluationJob;
