/** @format */

import React from "react";
import {
  Icon,
  LeftAnswers,
  NumberStyle,
  PercentageSpan,
  PointFromText,
  Question,
  QuestionsAnswersWrapper,
  QuestionTypeStyle,
  RightAnswers,
  Types,
} from "./style";

const QuestionAnswer = ({ type, question, mockedArr, index }: any) => {
  const getPercentage = (percentage) => {
    if (percentage && percentage > 0) {
      return percentage;
    }
    if (percentage === 0) {
      return percentage.toString();
    } else {
      return undefined;
    }
  };

  return (
    <QuestionsAnswersWrapper>
      <RightAnswers>
        <NumberStyle>{index + 1} -</NumberStyle>
        <Question className="details">{question.question}</Question>
      </RightAnswers>
      <LeftAnswers>
        {type === "points" &&
          (question.questionType.id === 2 ||
            question.questionType.id === 8 ||
            question.questionType.id === 9) &&
          getPercentage(question?.points?.percentage) && (
            <PointFromText>
              <PercentageSpan>
                {getPercentage(question?.points?.percentage) + "%"}
              </PercentageSpan>
            </PointFromText>
          )}
        <Types>
          {mockedArr.map((value: any, index: number) => {
            return (
              value.name === question.questionType.name && (
                <React.Fragment key={index}>
                  <Icon>{value.icon}</Icon>
                  <QuestionTypeStyle>
                    {question.questionType.translation}
                  </QuestionTypeStyle>
                </React.Fragment>
              )
            );
          })}
        </Types>
      </LeftAnswers>
    </QuestionsAnswersWrapper>
  );
};

export default QuestionAnswer;
