/** @format */

import { Table } from "antd";
import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

type Props = {
  type?: string;
  jobs?: string;
  language?: string;
  className?: string;
};
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  direction: ${(props: Props) => (props.language === "ar" ? "rtl" : "ltr")};
  @media (max-width: 800px) {
    flex-direction: column;
    width: ${(props: Props) => (props.type === "template" ? "90%" : "100%")};
    align-items: ${(props: Props) =>
    props.type === "template" ? "center" : "flex-start"};
  }
`;

export const StyledTable = styled(Table)`
  width: 95%;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 1000px;
    height: 15px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    display: block !important;
  }
  ::-webkit-scrollbar-thumb {
    background: #44c874;
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #6ea553;
    cursor: pointer;
  }

  .ant-table-cell {
    cursor: pointer;
    background-color: white;
    font-family: Famtree;
    font-size: 12px;
    font-weight: normal;
    color: ${Colors.charcoal_grey_54};
    text-align: ${(props: { language?: string }) =>
    props.language === "ar" ? "right" : "left"};
  }

  .ant-table {
    @media (max-width: 800px) {
      overflow-x: scroll;
    }
  }

  .ant-pagination.mini .ant-pagination-item {
    background: ${Colors.light_gray};
    border-color: ${Colors.light_gray};
    border-radius: 8px;
    color: ${Colors.dark_mint};
    width: 28px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .ant-pagination-item a {
    color: ${Colors.dark_mint};
  }

  .ant-pagination-item-active a {
    color: white !important;
  }

  .ant-pagination-item-active {
    background: ${Colors.dark_mint} !important;
    border-color: ${Colors.dark_mint} !important;
  }

  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    background: rgba(52, 66, 71, 0.1) !important;
    border-color: rgba(52, 66, 71, 0.1) !important;
    color: #344247 !important ;
    border-radius: 8px;
  }

  .ant-pagination-item-link {
    color: ${Colors.dark_mint};
  }

  .ant-spin-container {
    height: 100%;
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: ${Colors.light_gray};
  }

  .ant-table-pagination.ant-pagination {
    direction: ltr;
  }

  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th,
  .ant-table.ant-table-middle tfoot > tr > td {
    padding: 9px 8px;
  }

  .ant-pagination-options-size-changer {
    display: none;
  }
`;

export const TableContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  display: flex;
  align-items: center;
  flex-direction: column;
  height: ${(props: Props) => props.className === "jobs" && "83vh"};
  direction: ${(props: Props) => props.language === "ar" ? 'rtl' : 'ltr'};
  @media (max-width: 767px) {
    margin: 0 10px;
  }

`;

export const SingleInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
`;
