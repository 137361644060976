/** @format */

import React from "react";
import { DoneBadge, InprogressBadge, CanceledBadge } from "./style";
import { Tooltip } from "antd";

const Toolip = ({
  count,
}: {
  count: { done: number; underway: number; canceled: number };
}) => {
  const customColors = [
    {
      status: "done",
      title: "مهمة منجزة",
      color: "#7ed321",
      count: count.done,
    },
    {
      status: "inProgress",
      title: "قيد التنفيد",
      color: "#f5a623",
      count: count.underway,
    },
    {
      status: "cancelled",
      title: "ملغاة",
      color: "#fda59f",
      count: count.canceled,
    },
  ];

  return (
    <>
      {customColors.map((item) => (
        <Tooltip
          title={<div dir="rtl">{item.count + item.title}</div>}
          color={item.color}
          key={item.color}
          placement="bottom"
        >
          {item.status === "done" ? (
            <DoneBadge data-testid="badge-done-testid">{item.count}</DoneBadge>
          ) : item.status === "inProgress" ? (
            <InprogressBadge data-testid="badge-inprogress-testid">
              {item.count}
            </InprogressBadge>
          ) : (
            <CanceledBadge data-testid="badge-cancel-testid">
              {item.count}
            </CanceledBadge>
          )}
        </Tooltip>
      ))}
    </>
  );
};

export default Toolip;
