import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { CustomHeader } from "common/Header";
import Tabs from "common/Tabs";
import { ChangePassword } from "./ChangePassword";
import { EditProfile } from "./EditProfile";
import { ProfileContainer, TabsContainer } from "pages/User/style";
import { Helmet } from "react-helmet";

export const Profile = () => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar" ? "الملف الشخصي -تدرى" : "Profile"}
        </title>
        ‍
      </Helmet>
      <ProfileContainer>
        <CustomHeader
          title={<Trans>profile</Trans>}
          path={"/home"}
          component="changePassword"
          type="withoutLeftContainer"
        />
        <TabsContainer>
          <Tabs
            tabOneTitle={t("edit_profile")}
            tabTwoTitle={t("change_password")}
            tabOneContent={<EditProfile />}
            tabTwoContent={<ChangePassword />}
            className="changePassword"
          />
        </TabsContainer>
      </ProfileContainer>
    </>
  );
};
