/** @format */

import { create } from "zustand";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { axiosApiInstance } from "infrastructure/utils/api";

export const useStore = create((set: any) => ({
  categories: [],
  categoriesList: async () => {
    try {
      const response = await axiosApiInstance.get("api/categories");
      set({ categories: response.data.categories });
    } catch (error) {
      showNotification(
        NotificationStatus.Error,
        "Error",
        // @ts-ignore: Unreachable code error
        error.response?.data?.message
      );
    }
  },
}));
