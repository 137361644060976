import styled from "styled-components";

export const DoneBadge = styled.span`
  border-radius: 50%;
  border: 1px solid;
  width: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(126, 211, 33, 0.12);
  border-color: rgba(126, 211, 33, 0.12);
  color: #7ed321;
  margin-left: 8px;
`;

export const InprogressBadge = styled.span`
  border-radius: 50%;
  border: 1px solid;
  width: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(245, 166, 35, 0.2);
  border-color: rgba(245, 166, 35, 0.2);
  color: #f5a623;
  margin-left: 8px;
`;

export const CanceledBadge = styled.span`
  border-radius: 50%;
  border: 1px solid;
  width: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(253, 165, 159, 0.2);
  border-color: rgba(253, 165, 159, 0.2);
  color: #fda59f;
`;
