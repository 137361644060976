import styled from "styled-components";

import { Rate as AntDRate } from "antd";
import Colors from "../../infrastructure/utils/Colors";

export const StyledRate = styled(AntDRate)`
  margin-left: 5px;
  &.ant-rate {
    font-size: ${(props: { className: string }) =>
      props.className === "shopperDetails" ? "25px" : "12px"};
    color: ${Colors.mint_red};
  }

  .ant-rate-star {
    margin: 0px;
  }
`;
