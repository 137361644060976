/** @format */

import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { Container, StyledList } from "./style";
import Spinner from "common/LoadingIndecator";

type Props = {
  data: unknown[] | undefined | any;
  renderItem: ((item: unknown, index: number) => React.ReactNode) | undefined;
  grid: {
    gutter: number;
    column?: number;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };
  paginationInfo?:
    | {
        count: number;
        total: number;
      }
    | any;
  searchResults?: Array<string> | any;
  setLimit: ((value: number) => void | any) | undefined | any;
  setPage: ((value: number) => void | any) | undefined | any;
  type?: string | undefined | any;
};

export const List = React.memo((props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    handleInfiniteOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInfiniteOnLoad = async () => {
    setLoading(true);
    if (props?.paginationInfo?.count < props?.paginationInfo?.total) {
      props.setLimit(props.data?.length + 10);
      // props.setPage(1);
    }
    if (props?.data?.length === props?.paginationInfo?.total) {
      setLoading(false);
    }
    if (props?.searchResults?.length > 0) {
      setLoading(false);
    }
  };

  return (
    <Container type={props.type}>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={handleInfiniteOnLoad}
        hasMore={true || false}
        useWindow={false}
      >
        <StyledList
          grid={props.grid}
          dataSource={props.data}
          type={props.type}
          renderItem={props.renderItem}
        >
          {loading && props.type !== "shoppersList" && (
            <div>
              <Spin />
            </div>
          )}
          {!props?.data && <Spinner size={"large"} tip="Loading" />}
        </StyledList>
      </InfiniteScroll>
    </Container>
  );
});
