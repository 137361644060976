/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "common/Table";
import { ColumnCell, ColumnCellName, MoreBtn } from "./style";
import { Trans, useTranslation } from "react-i18next";
import Tag from "common/Tag";
import Toolip from "common/Toolip";

import { timeStampToDate } from "infrastructure/helpers/timeStampToDate";
import { ColumnsType } from "antd/lib/table";
import { useQuery } from "react-query";
import { fetchGetRequestsWithPagination } from "infrastructure/helpers/fetchServerRequests";

import { Container, TablesWrapper } from "pages/style";
import { Helmet } from "react-helmet";
import { Dropdown } from "common/Dropdown";
import { Menu, MenuProps } from "antd";
import { StyledP, Container as MenuContainer } from "common/Menu/style";
import Modal from "common/Modal";
import {
  showNotification,
  NotificationStatus,
} from "infrastructure/helpers/showNotifications";
import { axiosApiInstance } from "infrastructure/utils/api";
import styled from "styled-components";

const TdreeTemplates = ({ searchResults }: any) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [page, SetPage] = useState<number>(1);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] =
    useState<boolean>(false);
  const [row, setRow] = useState<{ status: { id: number }; id: number }>({
    status: { id: 0 },
    id: 0,
  });

  const showDuplicateModal = () => {
    setIsDuplicateModalVisible(true);
  };

  const handleCancelDuplicateModal = () => {
    setIsDuplicateModalVisible(false);
  };

  const { data, isLoading } = useQuery(
    ["evaluationListData", page, searchResults],
    () =>
      fetchGetRequestsWithPagination(
        `api/company/v1/evaluation-templates/admin?page=${page}&limit=10&search=${searchResults}`
      ),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const handlePagination = (page: number) => {
    SetPage(page);
  };

  const handleDuplicate = (id: number) => {
    axiosApiInstance
      .post("api/company/v1/evaluation-templates/duplicate", {
        template_id: id,
      })
      .then((res) => {
        setIsDuplicateModalVisible(false);
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
      })
      .catch((err) => {
        setIsDuplicateModalVisible(false);
        showNotification(
          NotificationStatus.Error,
          t("Error"),
          err.response.data.message
        );
      });
  };

  const handleMenuClick = (row: any) => {
    setRow(row);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <StyledMenu language={i18n.language === "ar" ? "ar" : "en"}>
          <Menu.Item key="1">
            <MenuContainer language={i18n.language === "ar" ? "ar" : "en"}>
              <i className="fa fa-clone" />
              <a onClick={showDuplicateModal}>
                <Trans>duplicate</Trans>
              </a>
            </MenuContainer>
          </Menu.Item>
        </StyledMenu>
      ),
    },
  ];

  const columns: ColumnsType<object> | undefined = [
    {
      title: t("template_num"),
      dataIndex: "id",
      key: "رقم النموذج",
      render: (text: string) => <ColumnCell>{text}</ColumnCell>,
    },
    {
      title: t("status"),
      key: "الحالة",
      render: (record: any) => (
        <ColumnCell>
          {record.status?.id === 1 ? (
            <Tag color="rgba(52, 66, 71, 0.1)">{record.status?.name}</Tag>
          ) : (
            <Tag color="blue">{record.status?.name}</Tag>
          )}
        </ColumnCell>
      ),
    },
    {
      title: t("template_name"),
      dataIndex: "name",
      key: "اسم النموذج",
      render: (text: string, record: any) => (
        <ColumnCellName
          onClick={() => navigate(`/evaluation-templates/${record.id}`)}
        >
          {text}
        </ColumnCellName>
      ),
    },
    {
      title: t("created_at"),
      dataIndex: "created_at",
      key: "تاريخ الإنشاء",
      render: (text: string) => (
        <ColumnCell>{timeStampToDate(text)}</ColumnCell>
      ),
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      },
    },
    {
      title: t("jobs"),
      key: "تاريخ الإنشاالوظائف ",
      render: (record) => (
        <ColumnCell>
          <Toolip count={record.jobs} />
        </ColumnCell>
      ),
    },
    {
      title: " ",
      align: "right",
      render: (record) => (
        <>
          <Dropdown items={{ items }} onClick={() => handleMenuClick(record)}>
            <MoreBtn
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              ...
            </MoreBtn>
          </Dropdown>
          <Modal
            title={<StyledP>{<Trans>duplicate</Trans>}</StyledP>}
            visible={isDuplicateModalVisible}
            onOk={() => handleDuplicate(row.id)}
            onCancel={handleCancelDuplicateModal}
          >
            <Trans>duplicate_message</Trans>
          </Modal>
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar"
            ? "نماذج تقييم تدري -تدري "
            : "Tdree Templates - Tdree"}
        </title>
      </Helmet>
      <Container>
        <TablesWrapper>
          <CustomTable
            columns={columns}
            data={data?.templates}
            loading={isLoading}
            pagination={{
              position: [i18n.language === "ar" ? "bottomRight" : "bottomLeft"],
              total: data?.pagination.total,
              defaultCurrent: data?.pagination.current_page || 1,
              onChange: (page: number) => handlePagination(page),
            }}
          />
        </TablesWrapper>
      </Container>
    </>
  );
};

export default TdreeTemplates;

//@ts-ignore
export const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    width: 140px !important;
    box-shadow: 3px 3px 5px 6px #ccc;
    direction: ${(props: { language?: string }) =>
      props.language === "ar" ? "ltr" : "rtl"};
  }
`;
