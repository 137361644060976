/** @format */
import * as React from "react";
import { SVGProps } from "react";

const BranchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={50} height={50} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect fill="#43C773" width={50} height={50} rx={25} />
      <g stroke="#FFF" strokeLinejoin="round" strokeWidth={1.8}>
        <path
          d="m23.381 30.488-3.214.775.775-3.215 5.21-5.21a1.725 1.725 0 0 1 2.439 2.44l-5.21 5.21z"
          strokeLinecap="round"
        />
        <path d="M28.4 16.381h3.077c.987 0 1.786.8 1.786 1.786V34.24c0 .986-.8 1.785-1.786 1.785H17.786c-.987 0-1.786-.8-1.786-1.785V18.167c0-.986.8-1.786 1.786-1.786h3.098" />
        <path
          d="M26.715 14h-4.167a1.786 1.786 0 1 0 0 3.572h4.167a1.786 1.786 0 1 0 0-3.572z"
          strokeLinecap="round"
        />
      </g>
    </g>
  </svg>
);

export default BranchIcon;
