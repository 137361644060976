import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";

export const Container = styled.div`
  width: 100%;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "ltr" : "rtl"};
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 700px;
  margin: 0 auto;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const Label = styled.label`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  margin-top: 30px;
`;

export const SelectLabel = styled.div`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  line-height: 51px;
`;

export const SelectWithSwitchContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 55%;
  justify-content: space-between;
  align-items: baseline;
  @media (max-width: 767px) {
    flex-direction: row;
    display: block;
    width: 100%;
  }
`;

export const SwitchLabel = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  margin-right: 14px;
`;

export const SwitchBtnContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-self: flex-end;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  @media (max-width: 767px) {
    align-self: unset;
    flex-direction: row;
    margin-top: 10px;
  }
`;

export const AddressLables = styled.div`
  width: 97%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const MapLabel = styled.button`
  color: ${Colors.dark_mint};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  margin-top: 30px;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 0;
`;

export const ButtonContainer = styled.div`
  width: 727px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 23px 16px 18px 17px;
  align-items: flex-start;
  @media (max-width: 767px) {
    width: 100%;
    button {
      width: 25%;
    }
  }
`;

export const P = styled.p`
  text-align: right;
  font-size: 22px;
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  margin: 0px;
`;
