/** @format */

import React from "react";
import ChargeWithCreditForm from "./form";

const ChargeWithCredit = () => {
  return <ChargeWithCreditForm />;
};

export default ChargeWithCredit;
