/** @format */

import styled from "styled-components";
import { Switch } from "antd";
import Colors from "../../infrastructure/utils/Colors";

export const StyledSwitch = styled(Switch)`
  &.ant-switch {
    background-color: ${(props: { className: boolean | any }) =>
      props.className ? Colors.dark_mint : Colors.light_gray};
  }

  &.ant-switch-checked {
    background-color: ${Colors.dark_mint} !important;
  }
`;
