/** @format */

import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";
import { Button, Layout, Menu } from "antd";

const { Header } = Layout;

type ContainerProps = {
  component?: string;
  language?: string;
};

export const Container = styled.div<ContainerProps>`
  width: 95%;
  display: flex;
  height: 80px;
  margin: 0 auto;
  margin-bottom: ${(props) =>
    props.component === "changePassword" ? "unset" : "50px"};
  padding-top: 40px;
  flex-direction: row-reverse;
  justify-content: space-between;
  direction: ${(props) => (props.language === "ar" ? "ltr" : "rtl")};
  align-items: baseline;

  @media (max-width: 767px) {
    margin: 40px 15px 31px 15px;
  }
`;

export const P = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  text-align: end;
  margin: ${(props: { language?: string }) =>
    props.language === "ar" ? " 0px 15px 0px 0px" : " 0px 0px 0px 15px"};
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
`;

export const LeftContainer = styled.div`
  display: flex;
  padding-top: 5px;
  flex-direction: row;
  justify-content: space-evenly;
  // width: 16%;
  align-items: baseline;
`;

export const BackIcon = styled.span`
  font-size: 30px;
  margin-top: 4px;
  color: ${Colors.charcoal_grey};
  cursor: pointer;
  @media (max-width: 900px) {
    margin-top: unset;
  }
`;

export const CancelIcon = styled.span`
  font-size: 25px;
  color: ${Colors.charcoal_grey};
`;

export const CloneIcon = styled.span`
  font-size: 25px;
  margin-right: 30px;
  color: ${Colors.charcoal_grey};
`;

export const StyledHeader = styled(Header)`
  &.ant-layout-header,
  .ant-layout-footer {
    background: ${Colors.white};
    margin-top: 14px;
  }
`;

export const StyledResponsiveHeader = styled(Header)`
  // height: 48px;
  padding: 5px 12px;
  &.ant-layout-header,
  .ant-layout-footer {
    background: ${Colors.white};
    direction: rtl;
  }
`;

//@ts-ignore
export const StyledMenu = styled(Menu)`
&.ant-menu-horizontal{
  border-bottom:unset;
  align-items: center;
};

&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu{
   padding:0px;
};

&.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after{
  border-bottom:unset;
  color:unset;
};



  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{
  border-bottom:unset;
  color:black;
  };

  &.ant-menu-overflow-item .ant-menu-item .ant-menu-item .ant-menu-item-only-child{
    color:unset;
  }

  &.ant-menu-horizontal > .ant-menu-item::after, .ant-menu-horizontal > .ant-menu-submenu::after{
    border-bottom:unset;
    content:unset;
  }
}
`;

type Props = {
  type?: string;
  className?: string;
};
export const Text = styled.span<Props>`
  font-size: ${(props) => (props.className === "mobile" ? "13px" : "16px")};
  font-family: Famtree;
  color: ${(props) =>
    props.type === "text" ? Colors.charcoal_grey : Colors.white};
`;

export const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row-reverse;
  margin-left: 16px;
`;

export const IconWithLabel = styled.div`
display: flex;
flex-direction: row;
align-items: center';
justify-content: space-evenly;
width: 130px;
cursor: pointer;
align-items:center;
`;
