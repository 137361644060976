/** @format */

import * as React from "react";
import { SVGProps } from "react";

const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ marginLeft: "10px" }}
  >
    <path
      d="M16.564 13.228 11 18.792l-5.564-5.564M11 3.208v15.428"
      stroke="#2A9E2C"
      strokeWidth={1.35}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0,0"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default ArrowDown;
