import * as React from "react";

function User(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width={137}
      height={160}
      viewBox="0 0 137 160"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      cursor="pointer"
    >
      <title>{"Group 7"}</title>
      <defs>
        <filter
          x="-40.9%"
          y="-33.8%"
          width="181.7%"
          height="181.7%"
          filterUnits="objectBoundingBox"
          id="prefix__a"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={3.5}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.262511322 0 0 0 0 0.262511322 0 0 0 0 0.262511322 0 0 0 0.241176792 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <circle id="prefix__b" cx={68} cy={136.574} r={14.074} />
      </defs>
      <g transform="translate(.5)" fill="none" fillRule="evenodd">
        <circle fill="#F7F7F7" cx={68} cy={68} r={68} />
        <g
          transform="translate(39.5 30)"
          stroke="#344247"
          strokeOpacity={0.2}
          strokeWidth={2.5}
        >
          <circle cx={28.215} cy={16.929} r={15.989} />
          <path d="M49.847 46.085H6.584A5.643 5.643 0 00.94 51.728v2.257c0 8.664 11.052 16.553 27.274 16.553s27.275-7.89 27.275-16.553v-2.257a5.643 5.643 0 00-5.643-5.643z" />
        </g>
        <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
        <use fill="#FFF" xlinkHref="#prefix__b" />
        <g opacity={0.54} stroke="#344247" strokeWidth={1.5}>
          <path
            d="M63.139 141.361l.824-2.886a.554.554 0 01.142-.24l6.148-6.149a1.528 1.528 0 012.16 2.16l-6.148 6.15a.558.558 0 01-.24.14l-2.886.825z"
            strokeLinejoin="round"
          />
          <path d="M69.42 132.92l2.16 2.16" />
        </g>
      </g>
    </svg>
  );
}

export default User;
