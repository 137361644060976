/** @format */

import React, { useEffect } from 'react';
import {
  Form,
  Label,
  InputWrapper,
  Error,
  Hr,
  InputHint,
  ButtonContainer,
} from '../style';
import { Input } from 'common/Input';
import { Trans, useTranslation } from 'react-i18next';
import { ManagersFormProps } from 'infrastructure/interfaces/managers-form.interface';
import ReactSelect from 'common/ReactSelect';
import { CheckboxLabel, CheckboxWrapper } from 'pages/style';
import { Checkbox } from 'common/Checkbox';
import { Button } from 'common/Button';

const ManagerForm: React.FC<ManagersFormProps> = ({
  handleSubmit,
  errors,
  register,
  branchesOptions,
  formState,
  handleUpdateData,
  loadingSubmit,
  managerDetails,
  isEdit,
  externalData,
  selectAllText,
}) => {
  const { t, i18n } = useTranslation();
  const updatedOptions = [
    { label: selectAllText, value: 'all' },
    ...branchesOptions,
  ];
  useEffect(() => {
    register('branchesSelect', {
      validate: (value: number) => value || 'This is required.',
    });
  }, []);

  return (
    <Form
      onSubmit={handleSubmit}
      language={i18n.language === 'ar' ? 'ar' : 'en'}
      noValidate
    >
      <InputWrapper>
        <Label>
          <Trans>manager_name</Trans>
        </Label>
        <Input
          name='name'
          type='text'
          placeholder={t('name')}
          dir='true'
          width='full_width'
          errorName={errors.name}
          ref={register({ required: true })}
          defaultValue={isEdit ? managerDetails?.name : ''}
        />
        {errors?.name?.types?.required && (
          <Error>
            <Trans>manager_name_required</Trans>
          </Error>
        )}
      </InputWrapper>
      {/* role  */}
      <InputWrapper>
        <Label>
          <Trans>role</Trans>
        </Label>
        <Input
          name='role'
          type='text'
          placeholder={t('role')}
          dir='true'
          width='full_width'
          errorName={errors.role}
          ref={register({ required: true })}
          defaultValue={isEdit ? managerDetails?.role : ''}
        />
        {errors?.role?.types?.required && (
          <Error>
            <Trans>role_required</Trans>
          </Error>
        )}
      </InputWrapper>
      {/* email  */}
      <InputWrapper>
        <Label>
          <Trans>email</Trans>
        </Label>
        <Input
          name='email'
          type='text'
          placeholder={t('email')}
          dir='true'
          width='full_width'
          errorName={errors.email}
          ref={register({ required: true })}
          defaultValue={isEdit ? managerDetails?.email : ''}
          disabled={isEdit}
        />
        {errors?.email?.types?.required && (
          <Error>
            <Trans>email_required</Trans>
          </Error>
        )}
      </InputWrapper>

      <InputWrapper>
        <Label>
          <Trans>show_report</Trans>
        </Label>
        <Input
          name='show_jobs_after_x_hours'
          type='number'
          placeholder={'ادخل عدد الساعات'}
          dir='true'
          width='full_width'
          maxLength={4}
          errorName={errors.show_jobs_after_x_hours}
          ref={register({ required: true, pattern: /^[0-9\b]+$/ })}
          defaultValue={isEdit ? managerDetails?.show_jobs_after_x_hours : ''}
        />
        {errors?.show_jobs_after_x_hours?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}
      </InputWrapper>

      {/* phone  */}
      <InputWrapper>
        <Label>
          <Trans>phone_number</Trans>
        </Label>
        <Input
          name='mobile'
          type='text'
          placeholder={t('phone_number')}
          dir='true'
          width='full_width'
          errorName={errors.mobile}
          ref={register({
            required: true,
            pattern: {
              value: /^(?:00|\\+)/i,
              message: 'invalid email address',
            },
          })}
          defaultValue={isEdit ? managerDetails?.mobile : ''}
        />
        {errors?.mobile?.types?.required && (
          <Error>
            <Trans>phone_number_required</Trans>
          </Error>
        )}
        {errors?.mobile?.types?.pattern && (
          <Error>
            <Trans>phone_start_error</Trans>
          </Error>
        )}
        <InputHint>
          <Trans>phone_should_start_msg</Trans>
        </InputHint>
        <InputHint>
          <Trans>phone_number_ex</Trans>
        </InputHint>
      </InputWrapper>

      <Hr />
      {/* branches */}
      <InputWrapper>
        <Label>
          <Trans>select_branch</Trans>
        </Label>
        <ReactSelect
          className='branch-select'
          options={updatedOptions}
          placeholder={t('select_branch')}
          onChange={(values: any) => {
            const selected =
              values.length &&
              values.find((option: any) => option.value === 'all')
                ? updatedOptions.slice(1)
                : values;
            handleUpdateData(selected, 'branches');
          }}
          errorStyle={errors.branchesSelect}
          isMulti
          value={isEdit ? externalData?.branches : ''}
        />
        {errors?.branchesSelect && (
          <Error
            style={{
              direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
            }}
          >
            <Trans>required_error_message</Trans>
          </Error>
        )}
      </InputWrapper>
      {/* checkboxs */}
      <InputWrapper>
        <CheckboxWrapper>
          <Checkbox
            onChange={(event) =>
              handleUpdateData(event.target.checked, 'show_wallet')
            }
            checked={isEdit ? externalData?.show_wallet : false}
          />
          <CheckboxLabel>
            <Trans>show_wallet_company_app</Trans>
          </CheckboxLabel>
        </CheckboxWrapper>
      </InputWrapper>

      <InputWrapper>
        <CheckboxWrapper>
          <Checkbox
            onChange={(event) =>
              handleUpdateData(event.target.checked, 'show_shoppers_count')
            }
            checked={isEdit ? externalData?.show_shoppers_count : false}
          />
          <CheckboxLabel>
            <Trans>show_shoppers_number</Trans>
          </CheckboxLabel>
        </CheckboxWrapper>
      </InputWrapper>

      <InputWrapper>
        <CheckboxWrapper>
          <Checkbox
            onChange={(event) =>
              handleUpdateData(event.target.checked, 'show_dates')
            }
            checked={isEdit ? externalData?.show_dates : false}
          />
          <CheckboxLabel>
            <Trans>show_dates</Trans>
          </CheckboxLabel>
        </CheckboxWrapper>
      </InputWrapper>

      <InputWrapper>
        <CheckboxWrapper>
          <Checkbox
            onChange={(event) =>
              handleUpdateData(event.target.checked, 'show_home')
            }
            checked={isEdit ? externalData?.show_home : false}
          />
          <CheckboxLabel>
            <Trans>show_home_in_app</Trans>
          </CheckboxLabel>
        </CheckboxWrapper>
      </InputWrapper>

      <InputWrapper>
        <CheckboxWrapper>
          <Checkbox
            onChange={(event) =>
              handleUpdateData(event.target.checked, 'show_order')
            }
            checked={isEdit ? externalData?.show_order : false}
          />
          <CheckboxLabel>
            <Trans>show_order_in_app</Trans>
          </CheckboxLabel>
        </CheckboxWrapper>
      </InputWrapper>

      <InputWrapper>
        <CheckboxWrapper>
          <Checkbox
            onChange={(event) =>
              handleUpdateData(event.target.checked, 'view_all_reports')
            }
            checked={isEdit ? externalData?.view_all_reports : false}
          />
          <CheckboxLabel>
            <Trans>show_all_reports</Trans>
          </CheckboxLabel>
        </CheckboxWrapper>
      </InputWrapper>

      <ButtonContainer>
        <Button
          disabled={formState.isSubmitting}
          size={'large'}
          htmlType='submit'
          loading={loadingSubmit}
        >
          <Trans>save</Trans>
        </Button>
      </ButtonContainer>
    </Form>
  );
};

export default ManagerForm;
