/** @format */

import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import {
  PrintWrapper,
  PrintContent,
  DownloadIconWrapper,
  ButtonWrapper,
} from "./style";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";

const Content = React.forwardRef(({ children }: any, ref) => {
  const { i18n } = useTranslation();

  return (
    // @ts-ignore: Unreachable code error
    <PrintContent ref={ref} language={i18n.language === "ar" ? "ar" : "en"}>
      {children}
    </PrintContent>
  );
});

const PrintPDF = ({ children, handleExportExcel, pdf_file }: any) => {
  const componentRef = useRef(null);
  return (
    <PrintWrapper>
      <DownloadIconWrapper>
        {pdf_file ? (
          <a
            href={pdf_file}
            download="1662.xlsx"
            target="_blank"
            rel="noreferrer"
          >
            Download File
          </a>
        ) : (
          <ReactToPrint
            trigger={() => (
              <button className="download-pdf">
                <span>
                  <Trans>download_pdf</Trans>
                </span>
                <i className="fa fa-file-pdf-o fa-lg"></i>
              </button>
            )}
            content={() => componentRef.current}
          />
        )}
        {handleExportExcel && (
          <ButtonWrapper>
            <Button type="link" onClick={handleExportExcel}>
              <Trans>download_excel</Trans>
            </Button>
            <FileExcelOutlined style={{ fontSize: "19px" }} />
          </ButtonWrapper>
        )}
      </DownloadIconWrapper>
      <Content ref={componentRef}>{children}</Content>
    </PrintWrapper>
  );
};

export default PrintPDF;
