/** @format */

import React, { useCallback, useState, useRef, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Empty } from "common/Empty";
import { Player } from "common/Player";
import { IQuestion } from "infrastructure/interfaces/job-details.interface";
import {
  Hr,
  QuestionContainer,
} from "pages/EvaluationTemplate/EvaluationDetails/style";
import {
  ClickableSpan,
  Answers,
  Question,
  ShoppersDataContainer,
  StyledPlayerWrapper,
  AnswersWrapper,
  PageSummaryContainer,
  ImageWrapper,
  NoResultWrapper,
  SpinnerContainer,
  SummaryMessageWrapper,
  SummaryMessage,
  CarouselWrapper,
  NoteWrapper,
  NoteHeader,
  NoteHeaderText,
  NoteText,
  Pargraph,
  BranchesList,
  FlexWrapper,
} from "./style";
import { mockedArr } from "infrastructure/helpers/questionTypeList";
import styled from "styled-components";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "common/Button";
import { Image, List, Skeleton, Spin, Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { axiosApiInstance } from "infrastructure/utils/api";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import PrintPDF from "components/ReactPdf";
import PdfCoverr from "components/ReactPdf/cover";
import EvaluationCategory from "components/Summary/EvaluationCategroy";
import SummaryPointsReview from "components/Summary/summaryPointsReview";
import QuestionAnswer from "components/Summary/QuestionAnswer";
import AnswerCount from "components/Summary/AnswerCount";
import AnswerChart from "components/Summary/AnswerChart";
import ExtraQuestions from "components/Summary/ExtraQuestions";
import "react-lazy-load-image-component/src/effects/blur.css";
import Spinner from "common/LoadingIndecator";
import Avatar from "common/Avatar";
import InfiniteScroll from "react-infinite-scroll-component";

const Details = () => {
  const navigate = useNavigate();
  const urlParams = useParams();
  const [isDetailsLoading, setDetailsLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [pagination, setPagination] = useState<any>(null);
  const [page, setPage] = useState(1);

  const [show, setShow] = useState<any>({});
  const [notes, setNotes] = useState<any>({});
  const [images, setImages] = useState<any>({});
  const [videos, setVideos] = useState<any>({});

  const { i18n, t } = useTranslation();
  const componentRef: React.MutableRefObject<undefined> | any = useRef();

  const { data: dashboardSummary, status: summaryRequestStatus } = useQuery(
    ["summary"],
    () =>
      fetchGetRequests(`api/company/v2/dashboard/summary?${urlParams?.params}`),
    {
      onSuccess: () => {
        setDetailsLoading(false);
      },
      onError: () => {
        setDetailsLoading(false);
      },
    }
  );

  const [question, setQuestion] = useState<any>([]);

  const handleNotes = (index: number) => {
    setNotes({ ...notes, [index]: !notes[index] });
  };

  const handleImages = (index: number) => {
    setImages({ ...images, [index]: !images[index] });
  };

  const handleVideos = (index: number) => {
    setVideos({ ...videos, [index]: !videos[index] });
  };

  const [questionsExtraData, setQuestionsExtraData] = useState<Array<any>>([]);

  const renderExtras = (item: IQuestion, index: number) => {
    //@ts-ignore
    const answersOption = [...new Set(item?.answers)]?.map((option) => {
      return {
        label: option,
        value: option,
      };
    });
    answersOption.push({
      value: "all",
      label: i18n.language === "ar" ? "الكل" : "All",
    });
    if (item.extras) {
      return (
        <>
          <Hr />
          {(item?.extras?.images?.length ||
            item?.extras?.videos?.length ||
            item?.extras?.text?.length) && (
            <>
              {/* images section  */}

              {item.extras?.images?.length ? (
                <StyledNotesButton onClick={() => handleImages(index)}>
                  <Trans>show_images</Trans>
                </StyledNotesButton>
              ) : (
                <Pargraph>
                  <Trans>no_attachments_img</Trans>
                </Pargraph>
              )}

              {images[index] && item?.extras?.images?.length && (
                <FlexWrapper>
                  {item.extras?.images?.map((image: any, index: number) => {
                    const imgTooltip = () => (
                      <h3 style={{ direction: "rtl", color: "#fff" }}>
                        <Trans
                          values={{
                            name: image?.message?.branchName,
                            date: image?.message?.visitDate,
                            time: image?.message?.visitTime,
                            answer: image?.message?.answer,
                          }}
                        >
                          summary_image_slider_message
                        </Trans>
                      </h3>
                    );
                    return (
                      <Tooltip title={imgTooltip}>
                        <ImageWrapper
                          key={index}
                          language={i18n.language === "ar" ? "ar" : "en"}
                        >
                          <Image
                            src={image.extra}
                            preview={true}
                            loading="lazy"
                            width={100}
                            height={100}
                          />
                        </ImageWrapper>
                      </Tooltip>
                    );
                  })}
                </FlexWrapper>
              )}

              <Hr />

              {item.extras?.videos?.length ? (
                <StyledNotesButton onClick={() => handleVideos(index)}>
                  <Trans>show_videos</Trans>
                </StyledNotesButton>
              ) : (
                <Pargraph>
                  <Trans>no_attachments_video</Trans>
                </Pargraph>
              )}

              {videos[index] && item?.extras?.videos?.length ? (
                <CarouselWrapper>
                  <>
                    {item.extras?.videos?.map(
                      (
                        video: { extra: string; message: any },
                        index: number
                      ) => {
                        return (
                          <>
                            <StyledPlayerWrapper
                              key={index}
                              language={i18n.language === "ar" ? "ar" : "en"}
                            >
                              <Player
                                key={index}
                                url={video.extra}
                                controls={true}
                                video_width="100%"
                                video_height="100"
                              />
                            </StyledPlayerWrapper>
                          </>
                        );
                      }
                    )}
                  </>
                </CarouselWrapper>
              ) : (
                <></>
              )}

              <Hr />
              {/* notes  */}
              {item.extras?.text?.length > 0 ? (
                <StyledNotesButton onClick={() => handleNotes(index)}>
                  <Trans>show_notes</Trans>
                </StyledNotesButton>
              ) : (
                <Pargraph>
                  <Trans>no_notes_attachments</Trans>
                </Pargraph>
              )}
              {notes[index] &&
                item.extras?.text?.map(
                  (text: { extra: string; message: any }, index: number) => {
                    return (
                      <NoteWrapper
                        key={index}
                        language={i18n.language === "ar" ? "ar" : "en"}
                      >
                        <NoteHeader>
                          <NoteHeaderText>
                            {text.message.branchName}
                          </NoteHeaderText>
                          <div>
                            <NoteHeaderText>
                              {text.message.visitDate}
                            </NoteHeaderText>
                            <NoteHeaderText>
                              {text.message.visitTime}
                            </NoteHeaderText>
                          </div>
                        </NoteHeader>
                        <NoteText>{text.extra}</NoteText>
                      </NoteWrapper>
                    );
                  }
                )}
              <Hr />
            </>
          )}
        </>
      );
    } else {
      <Spinner size="small" />;
    }
  };

  const getQuestionItem = (item: any, index: number) => {
    setShow({ ...show, [index]: !show[index] });
    if (question) {
      setQuestion([...question, item]);
    }
  };

  const loadTheRestOfTheAnswers = useCallback(
    (id: number, index: number) => {
      if (question[index]?.id === id && question[index].answersCount > 3) {
        return (
          <QuestionContainer language={i18n.language === "ar" ? "ar" : "en"}>
            {question[index]?.questionType.id === 5 ||
            question[index]?.questionType.id === 4 ? (
              <AnswersWrapper>
                {question[index]?.answers
                  ?.slice(3, question[index]?.answers?.length)
                  ?.map((answer: string, index: number) => {
                    return (
                      <StyledPlayerWrapper key={index} className="page-break">
                        <Player key={index} url={answer} controls={true} />
                      </StyledPlayerWrapper>
                    );
                  })}
              </AnswersWrapper>
            ) : question[index]?.questionType.id === 3 ? (
              <AnswersWrapper>
                {question[index]?.answers
                  .slice(3, question[index]?.answers?.length)
                  ?.map((answer: string, index: number) => {
                    return (
                      <ImageWrapper
                        key={index}
                        className="page-break"
                        language={i18n.language}
                      >
                        <Avatar
                          src={answer}
                          size="large"
                          shape="square"
                          preview={true}
                        />
                      </ImageWrapper>
                    );
                  })}
              </AnswersWrapper>
            ) : (
              question[index]?.questionType.id !== 8 &&
              question[index]?.questionType.id !== 9 &&
              question[index]?.questionType.id !== 2 &&
              question[index]?.answers
                .slice(3, question[index]?.answers?.length)
                ?.map((answer: Array<any>, index: number) => {
                  return (
                    <div key={index} className="page-break">
                      <Answers>{answer}</Answers>
                    </div>
                  );
                })
            )}
          </QuestionContainer>
        );
      }
    },
    [question]
  );

  const handleExportExcel = () => {
    const { params }: any = urlParams;
    axiosApiInstance
      .get(`api/company/v1/dashboard/summary/export?${params}`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  const loadMoreData = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await axiosApiInstance.get(
        `api/company/v2/dashboard/summary/questions?${urlParams?.params}&page=${page}`
      );

      setItems((prevItems) => [...prevItems, ...response.data.data.questions]);
      const data = [...response.data.data.questions];
      setQuestionsExtraData(data);
      setQuestion(response.data.data.questions);
      setPagination(response.data.data.pagination);
      setLoading(false);

      if (page <= response.data.data.pagination.total_pages) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  // eslint-disable-next-line
  let scale;

  items.length &&
    items.map((question: any, index: number) => {
      scale = {
        min: question.scale?.min,
        step: question.scale?.step,
        max: question.scale?.max,
      };

      function move(input: any[], from: any, to: any) {
        let numberOfDeletedElm = 1;

        const elm = input.splice(from, numberOfDeletedElm)[0];

        numberOfDeletedElm = 0;

        input.splice(to, numberOfDeletedElm, elm);
      }

      if (question?.options) {
        const lastElement = question?.options[question?.options?.length - 1];

        const lastIndex = question?.options.findIndex(
          (el) => el === lastElement
        );
        const indexOf = question?.options.findIndex((object) => {
          return object.option === "جميع الاجابات لا تنطبق";
        });
        move(question?.options, indexOf, lastIndex);
      }
    });

  if (isDetailsLoading) {
    return (
      <SpinnerContainer>
        <Spin size="large" />
      </SpinnerContainer>
    );
  }

  return (
    <PageSummaryContainer language={i18n.language === "ar" ? "ar" : "en"}>
      {(dashboardSummary?.response?.dashboardSummary &&
        Object.keys(dashboardSummary?.response?.dashboardSummary)?.length > 0 &&
        summaryRequestStatus) ||
      question.length ? (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {i18n.language === "ar" ? "الملخص - تدري" : "Tdree - summary"}
            </title>
          </Helmet>
          <SummaryMessageWrapper>
            <SummaryMessage>
              {dashboardSummary?.response?.dashboardSummary?.message
                ?.visitsCount < 3 ||
              dashboardSummary?.response?.dashboardSummary?.message
                ?.visitsCount > 10 ? (
                <Trans
                  values={{
                    branch:
                      dashboardSummary?.response?.dashboardSummary?.message
                        ?.branchesCount,
                    mission:
                      dashboardSummary?.response?.dashboardSummary?.message
                        ?.jobsCount,
                    visit:
                      dashboardSummary?.response?.dashboardSummary?.message
                        ?.visitsCount,
                    totalJobsCount:
                      dashboardSummary?.response?.dashboardSummary?.message
                        ?.totalJobsCount,
                  }}
                >
                  dashboard_summary_message_more_than_10
                </Trans>
              ) : (
                <Trans
                  values={{
                    branch:
                      dashboardSummary?.response?.dashboardSummary?.message
                        ?.branchesCount,
                    mission:
                      dashboardSummary?.response?.dashboardSummary?.message
                        ?.jobsCount,
                    visit:
                      dashboardSummary?.response?.dashboardSummary?.message
                        ?.visitsCount,
                    totalJobsCount:
                      dashboardSummary?.response?.dashboardSummary?.message
                        ?.totalJobsCount,
                  }}
                >
                  dashboard_summary_message_less_than_10
                </Trans>
              )}
              <BranchesList>
                {dashboardSummary?.response?.dashboardSummary?.branches.map(
                  (branch: string) => (
                    <li>{branch}</li>
                  )
                )}
              </BranchesList>
            </SummaryMessage>
          </SummaryMessageWrapper>
          {/* new pdf  */}
          <InfiniteScroll
            style={{ height: "100%", overflowX: "hidden" }}
            dataLength={items?.length || 0}
            next={loadMoreData}
            hasMore={pagination?.has_more || false}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            scrollableTarget="scrollableDiv"
          >
            <PrintPDF handleExportExcel={handleExportExcel}>
              <PdfCoverr
                template_name={dashboardSummary?.response?.job?.template?.name}
                description={
                  dashboardSummary?.response?.job?.template?.description
                }
                branch_name={dashboardSummary?.response?.job?.branch?.name}
                questions_number={pagination?.count}
                focus_of_evaluation={dashboardSummary?.response?.dashboardSummary?.questionCategories?.map(
                  (category: any, index: any) => (
                    <EvaluationCategory
                      key={index}
                      category={
                        Object.keys(category).length > 0
                          ? category.category
                          : category
                      }
                    />
                  )
                )}
                show_summary={false}
                percentage={
                  dashboardSummary?.response?.dashboardSummary?.branchRate
                    ?.percentage
                }
                questionCategories={
                  dashboardSummary?.response?.dashboardSummary
                    ?.questionCategories
                }
                branchesList={
                  dashboardSummary?.response?.dashboardSummary?.branches
                }
              />
              {dashboardSummary?.response?.dashboardSummary?.branchRate && (
                <SummaryPointsReview
                  percentage={
                    dashboardSummary?.response?.dashboardSummary?.branchRate
                      ?.percentage
                  }
                  questionCategories={
                    dashboardSummary?.response?.dashboardSummary
                      ?.questionCategories
                  }
                  total_rating={true}
                />
              )}
              <ShoppersDataContainer
                ref={componentRef}
                language={i18n.language === "ar" ? "ar" : "en"}
              >
                {questionsExtraData?.length > 0 ? (
                  <List
                    dataSource={items}
                    grid={{
                      gutter: 8,
                      xs: 1,
                      sm: 1,
                      md: 1,
                      lg: 1,
                      xl: 1,
                      xxl: 1,
                    }}
                    renderItem={(item: any, index: number) => (
                      <List.Item key={index}>
                        <div className="page-break" key={index}>
                          <QuestionContainer
                            language={i18n.language === "ar" ? "ar" : "en"}
                          >
                            {/* category  */}
                            {item.category && (
                              <EvaluationCategory category={item.category} />
                            )}
                            {/* question with answer */}
                            <QuestionAnswer
                              type="points"
                              question={item}
                              language={i18n.language === "ar" ? "ar" : "en"}
                              index={index}
                              mockedArr={mockedArr}
                            />
                            <AnswerCount
                              totalShoppersCount={item.totalShoppersCount}
                              answeredShoppersCount={item.answeredShoppersCount}
                              skippedShoppersCount={item.skippedShoppersCount}
                            />
                            <AnswerChart
                              question={item}
                              language={i18n.language === "ar" ? "ar" : "en"}
                            />
                          </QuestionContainer>
                          {item.questionType?.id !== 8 &&
                          item.questionType?.id !== 9 &&
                          item.questionType?.id !== 2 &&
                          item?.answers?.length > 3 &&
                          !show[index] ? (
                            <ClickableSpan
                              onClick={() => {
                                getQuestionItem(item, index);
                              }}
                            >
                              <Trans>show_all</Trans> ({item.answersCount})
                            </ClickableSpan>
                          ) : (
                            loadTheRestOfTheAnswers(item.id, index)
                          )}
                          <QuestionContainer
                            language={i18n.language === "ar" ? "ar" : "en"}
                          >
                            <ExtraQuestions
                              question={item}
                              language={i18n.language === "ar" ? "ar" : "en"}
                              index={index}
                              show_extra={true}
                            />
                            <div
                              className="extra_with_slider"
                              style={{ width: "100%" }}
                            >
                              {renderExtras(item, index)}
                            </div>
                          </QuestionContainer>
                        </div>
                      </List.Item>
                    )}
                  />
                ) : (
                  <QuestionContainer
                    language={i18n.language === "ar" ? "ar" : "en"}
                  >
                    <Trans>no_questions</Trans>
                  </QuestionContainer>
                )}
              </ShoppersDataContainer>
            </PrintPDF>
          </InfiniteScroll>
        </>
      ) : (
        <NoResultWrapper>
          <Empty
            description={
              <>
                <Question>
                  <Trans>no_summary_data</Trans>
                </Question>
                <Button size={"large"} onClick={() => navigate("/home")}>
                  {t("back_to_home")}
                </Button>
              </>
            }
            className=""
          />
        </NoResultWrapper>
      )}
    </PageSummaryContainer>
  );
};

export default React.memo(Details);

const StyledNotesButton = styled(Button)`
  height: 50px;
  line-height: 45px;
  border-radius: 9px;
  background-color: #f8f8f8;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #44c874;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media print {
    display: none;
  }
`;
