/** @format */

import { create } from "zustand";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";

type Props = {
  jobsData: {
    evaluation_template_id?: null;
    branch_id?: number;
    name?: string;
    description?: string;
    instructions?: string;
    start_time?: string;
    end_time?: string;
    shoppers_count?: string;
    extra_pay?: boolean;
    skills?: any[];
    languages?: any[];
  };
  res: { description: string; id: number; total: number };
  jobsDataFn: () => void;
};
export const createJobUseStore = create(
  (set: any): Props => ({
    jobsData: {
      evaluation_template_id: null,
      branch_id: 0,
      name: "",
      description: "",
      instructions: "",
      start_time: "",
      end_time: "",
      shoppers_count: "",
      extra_pay: false,
      skills: [],
      languages: [],
    },
    res: { description: "", id: 0, total: 0 },
    jobsDataFn: async () => {
      try {
        set({
          jobsData: {
            evaluation_template_id: null,
            branch_id: 0,
            name: "",
            description: "",
            instructions: "",
            start_time: "",
            end_time: "",
            shoppers_count: "",
            extra_pay: false,
            skills: [],
            languages: [],
          },
          res: { description: "", id: 0, total: 0 },
        });
      } catch (error) {
        showNotification(
          NotificationStatus.Error,
          " ",
          error.response.data.message
        );
      }
    },
  })
);
