/** @format */

import React, { useState } from 'react';
import { LeftContainer, RightContainer, StyledContainer } from '../style';
import { ForgotPasswordForm } from './form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { axiosApiInstance } from 'infrastructure/utils/api';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import { redirect, useNavigate } from 'react-router-dom';
import TadreeComponent from 'assets/svg/tdree-component';
import { Helmet } from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import storage from 'infrastructure/utils/storage';

export const ForgotPassword = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isAuthed = !!storage.getItem('access_token');
  const [isLoading, setIsloading] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 800 });

  const { handleSubmit, register, errors, formState } = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
  });

  const onSubmit = (e: { email: string }) => {
    setIsloading(true);

    axiosApiInstance
      .post('api/company/forget-password', { email: e.email })
      .then((res) => {
        showNotification(
          NotificationStatus.Success,
          t('done'),
          res.data.message
        );
        setIsloading(false);
        navigate('/login');
      })
      .catch((error) => {
        setIsloading(false);
        showNotification(
          NotificationStatus.Error,
          t('error'),
          error?.response.data.message
        );
      });
  };

  if (isAuthed) {
    return <> {redirect('/home')} </>;
  }

  return (
    <>
      <StyledContainer>
        <Helmet>
          <meta charSet='utf-8' />
          <title> نسيت كلمة السر -تدري </title>
        </Helmet>

        <LeftContainer right={i18n.language === 'ar' || false}>
          <ForgotPasswordForm
            onSubmit={handleSubmit(onSubmit)}
            errors={errors}
            register={register}
            formState={formState}
            loading={isLoading}
          />
        </LeftContainer>
        {!isMobile && (
          <RightContainer right={i18n.language === 'ar' || false}>
            <TadreeComponent />
          </RightContainer>
        )}
      </StyledContainer>
    </>
  );
};
