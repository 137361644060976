import { Tag } from "antd";
import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

export const StyledTag = styled(Tag)`
  margin: 0;
  &.ant-tag-has-color,
  .ant-tag-has-color a,
  .ant-tag-has-color a:hover,
  .ant-tag-has-color .anticon-close,
  .ant-tag-has-color .anticon-close:hover {
    color: ${Colors.charcoal_grey_54};
  }
`;
