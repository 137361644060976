/**
 * @format
 */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "common/Button";
import { Input } from "common/Input";
import TextArea from "common/TextArea";
import { EvaluationListProps } from "infrastructure/interfaces/evaluation-list.interface";
import { Error } from "../../style";
import { Label, DescriptionFormContainer } from "../style";
import { JobsForm } from "infrastructure/interfaces/jobs.interface";
import { ButtonContainer } from "pages/User/style";
import ReactSelect from "common/ReactSelect";

const { useEffect } = React;

const DescriptionForm = ({
  templates,
  handleSelectOnChange,
  evaluationTemplateId,
  handleInputChange,
  template,
  handleSubmit,
  register,
  errors,
  formState,
  setValue,
}: JobsForm) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    register("evaluationTemplateSelect", {
      validate: (value: number) => value || "This is required.",
    });

    register("instructions", {
      validate: (value: number) => value || "This is required.",
    });
  }, []);

  const companyOptions = templates
    ?.map((item: EvaluationListProps) => {
      if (item.created_by === "company") {
        return {
          value: item.id,
          label: item.name,
          defaultValue: item,
          title: "evaluation_template_id",
          key: item.id,
        };
      }
    })
    .filter(Boolean);

  const adminOptions = templates
    ?.map((item: EvaluationListProps) => {
      if (item.created_by === "admin") {
        return {
          value: item.id,
          label: item.name,
          defaultValue: item,
          title: "evaluation_template_id",
          key: item.id,
        };
      }
    })
    .filter(Boolean);

  const groupedOptions = [
    {
      label: <Trans>company_templates</Trans>,
      options: companyOptions,
    },
    {
      label: <Trans>suggested_templates</Trans>,
      options: adminOptions,
    },
  ];

  return (
    <DescriptionFormContainer
      onSubmit={handleSubmit}
      language={i18n.language === "ar" ? "ar" : "en"}
    >
      <Label>
        <Trans>evaluation_template</Trans>
      </Label>
      <ReactSelect
        className="template-select"
        options={groupedOptions}
        onChange={(item: any) => {
          handleSelectOnChange(item.value, item, "evaluationTemplateSelect");
          setValue("evaluationTemplateSelect", item);
        }}
        placeholder={t("select_evaluation_template")}
        errorStyle={errors.evaluationTemplateSelect}
        value=""
        isSearchable
      />
      {errors?.evaluationTemplateSelect && (
        <Error
          style={{
            direction: i18n.language === "ar" ? "rtl" : "ltr",
          }}
        >
          <Trans>required_error_message</Trans>
        </Error>
      )}

      <Label>
        <Trans>job_name</Trans>
      </Label>
      <Input
        name="name"
        width={"formWidth"}
        errorName={errors.name}
        defaultValue={
          template?.evaluation_template_id === evaluationTemplateId
            ? template?.name
            : null
        }
        onChange={(e: Event | { target: { name: string; value: number } }) =>
          handleInputChange(e)
        }
        inputRef={register({ required: true })}
        className={"jobsinput"}
        placeholder={t("job_name_placeholder")}
      />
      {errors.name && (
        <Error
          style={{
            direction: i18n.language === "ar" ? "rtl" : "ltr",
          }}
        >
          <Trans>required_error_message</Trans>
        </Error>
      )}

      <Label>
        <Trans>job_description</Trans>
      </Label>

      {template?.evaluation_template_id !== null ? (
        <TextArea
          name="description"
          value={
            template?.evaluation_template_id === evaluationTemplateId &&
            template?.description
          }
          onChange={(e: { target: any }) => {
            handleInputChange(e);
            setValue("description", template?.description);
          }}
          inputRef={register({ required: true })}
          placeholder={t("job_description_placeholder")}
        />
      ) : (
        <TextArea
          name="description"
          onChange={(e: { target: any }) => {
            handleInputChange(e);
            setValue("description", e);
          }}
          inputRef={register({ required: true })}
          placeholder={t("job_description_placeholder")}
        />
      )}
      {errors.description && (
        <Error
          style={{
            direction: i18n.language === "ar" ? "rtl" : "ltr",
          }}
        >
          <Trans>required_error_message</Trans>
        </Error>
      )}

      <Label>
        <Trans>instructions</Trans>
      </Label>

      <TextArea
        name="instructions"
        errorName={errors.instructions}
        onChange={(e: Event) => {
          handleInputChange(e);
          setValue("instructions", e);
        }}
        inputRef={register({ required: true })}
        errorStyle={errors.instructions}
        placeholder={t("job_instructions_placeholder")}
      />
      {errors.instructions && (
        <Error
          style={{
            direction: i18n.language === "ar" ? "rtl" : "ltr",
          }}
        >
          <Trans>required_error_message</Trans>
        </Error>
      )}
      <ButtonContainer>
        <Button
          id="next-button"
          type="primary"
          onClick={handleSubmit}
          htmlType="submit"
          disabled={formState.isSubmitting}
        >
          <Trans>next</Trans>
        </Button>
      </ButtonContainer>
    </DescriptionFormContainer>
  );
};

export default DescriptionForm;
