/** @format */

import React, { FunctionComponent } from "react";
import { Result } from "antd";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Unauthorized: FunctionComponent = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Result
      status="403"
      title="403"
      subTitle={t("unauthorized_message")}
      extra={
        <Button size={"large"} onClick={() => navigate("/home")}>
          {t("back_to_home")}
        </Button>
      }
    />
  );
};
export default Unauthorized;
