/**
 * /* eslint-disable no-restricted-globals
 *
 * @format
 */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import FormTemplate from "common/FormTemplate";
import MapContainer from "common/Map";
import { BlackTitleBold, P, BlackTitle, Form, Hr } from "./style";
import { CompanyBranchDataProps } from "infrastructure/interfaces/branches-form.interface";
import { useMediaQuery } from "react-responsive";

const Details = ({ data }: { data: CompanyBranchDataProps }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const { i18n } = useTranslation();
  return (
    <Form language={i18n.language === "ar" ? "ar" : "en"} noValidate>
      <FormTemplate>
        {/* branch name */}
        <P>
          <Trans>branch_name</Trans>
        </P>
        <BlackTitleBold>{data.name}</BlackTitleBold>
        <Hr />
        {/* shop name */}
        <P>
          <Trans>shop_name</Trans>
        </P>
        <BlackTitle>{data.shop_name}</BlackTitle>
        <Hr />
        {!data.is_website && (
          <>
            <P>
              <Trans>branch_address</Trans>
            </P>
            <BlackTitle>{data.address}</BlackTitle>
            <Hr />
          </>
        )}

        {!data.is_website && (
          <>
            <P>
              <Trans>branch_details</Trans>
            </P>
            <BlackTitle>{data.address_description}</BlackTitle>
            <Hr />
          </>
        )}

        <P>
          <Trans>branch_category</Trans>
        </P>
        <BlackTitle>{data.category?.name}</BlackTitle>
        {data.is_website && (
          <>
            <Hr />
            <P>
              <Trans>website</Trans>
            </P>
            <BlackTitle>{data.website}</BlackTitle>
          </>
        )}
        {!data.is_website && (
          <>
            {/* country  */}
            <Hr />

            <P>
              <Trans>country</Trans>
            </P>
            <BlackTitle>{data.country_name}</BlackTitle>
            {/* city  */}
            <Hr />

            <P>
              <Trans>city</Trans>
            </P>
            <BlackTitle>{data.city_name}</BlackTitle>
            <Hr />
            <P>
              <Trans>location</Trans>
            </P>
            <MapContainer
              width={isMobile ? "68vw" : isTablet ? "610px" : "870px"}
              height
              lat={Number(data.lat)}
              lng={Number(data.long)}
              mode
            />
          </>
        )}
      </FormTemplate>
    </Form>
  );
};

export default React.memo(Details);
