/** @format */

import * as React from "react";
import { SVGProps } from "react";

const LanguageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      transform="translate(.957 1)"
      stroke="#344247"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx={9} cy={9} r={8.763} />
      <ellipse cx={9} cy={9} rx={4.026} ry={8.763} />
      <path d="M.947 5.921h16.106M.947 12.079h16.106" />
    </g>
  </svg>
);

export default LanguageIcon;
