import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";
import { Menu } from "antd";

export const CompanyWalletBalanceContainer = styled.div`
  height: 159px;
  width: 30%;
  padding: 20px;
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 0 2px 20px 0 rgba(48, 48, 48, 0.05);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: ${(props: { language?: string }) =>
    props.language === "ar" ? "flex-end" : "flex-start"};
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  @media (max-width: 767px) {
    width: 90%;
    margin: 0 auto;
    padding: 10px;
  }
  @media (min-width: 767px) and (max-width: 991px) {
    width: 50%;
  }
`;

export const StyledLink = styled.a`
  color: ${Colors.dark_mint};
  cursor: pointer;
  :hover {
    color: ${Colors.dark_mint};
  }
  font-family: Famtree;
  font-size: 15px;
  font-weight: normal;
  display: flex;
  align-items: center;
  gap: 0 5px;
`;

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BalanceText = styled.h6`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: rgba(52, 66, 71, 0.54);
`;

export const BalanceValue = styled.p`
  font-family: Famtree;
  font-weight: bold;
  color: #000;
  font-size: 24px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const BalanaceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WithdrawValueText = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  margin: 0px;
  color: ${Colors.dark_mint};
  white-space: nowrap;
`;

export const RefundValueText = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  margin: 0px;
  color: #e20a0a;
  white-space: nowrap;
`;

export const DepositeValueText = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  margin: 0px;
  color: #0051cd;
  white-space: nowrap;
`;

export const ChargeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: 0 auto;
  align-items: space-evenly;
  margin-top: 49px;
  @media (max-width: 991px) {
    width: 70%;
  }
  @media (max-width: 767px) {
    width: 90%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
  width: 100%;
  align-items: center;
  position: relative;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: end;
  }
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const InputsContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  @media (max-width: 767px) {
    margin-bottom: 25px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;

  align-self: end;
  margin-bottom: 25px;
  .previous {
    margin: 0 30px;
  }
`;

export const WalletHeader = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  @media (max-width: 767px) {
    display: none;
  }
`;

export const HeaderTitle = styled.h3`
  margin-bottom: 15px;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 25%;
`;

export const Label = styled.label`
  color: ${Colors.battleship_grey};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  margin: 0 3px;
  white-space: nowrap;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

//@ts-ignore
export const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    width: 140px !important;
    box-shadow: 3px 3px 5px 6px #ccc;
  }
`;

export const Payments = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: end;
  margin: 15px 25px 35px 25px;
`;

export const Payment = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
`;

export const QRWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  background-color: #f5f4f3;
  padding: 8px;
`;
