import { Space } from "antd";
import styled from "styled-components";

export const SpaceStyled = styled(Space)`
  position: fixed;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  .ant-spin-dot-item {
    background-color: #44c874;
  }
  .ant-spin-text {
    color: #44c874;
  }
`;
