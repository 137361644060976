/** @format */

import React from "react";
import {
  NumberStyle,
  QuestionStyle,
  QuestionTypeStyle,
  Icon,
  Option,
  SubContainer,
  QuestionContainer,
  RightContainer,
  Types,
  OptionsStyle,
  ScaleTitle,
  ScaleContainer,
  TextBox,
  CategoryWrapper,
  CheckboxWrapper,
  ChecksWrapper,
  MultiOptionsSection,
  PointsLabel,
  Hr,
  Text,
} from "pages/EvaluationTemplate/EvaluationDetails/style";
import { Trans } from "react-i18next";
import i18n from "i18n";
import { mockedArr } from "infrastructure/helpers/questionTypeList";
import { Props } from "infrastructure/interfaces/evaluationTemplateDetails.interface";
import CheckIcon from "assets/svg/icon-check";
import "./styles.css";

const EvaluationTemplateQuestionDetails: React.FC<{
  type: string | undefined;
  data: Array<Props> | any;
}> = ({ type, data }): JSX.Element => {
  return (
    <>
      {data &&
        data.map((question: Props, index: number) => {
          const scale = {
            min: question.scale?.min,
            step: question.scale?.step,
            max: question.scale?.max,
          };

          function move(input: any[], from: any, to: any) {
            let numberOfDeletedElm = 1;

            const elm = input.splice(from, numberOfDeletedElm)[0];

            numberOfDeletedElm = 0;

            input.splice(to, numberOfDeletedElm, elm);
          }

          if (question?.options) {
            const lastElement =
              question?.options[question?.options?.length - 1];

            const lastIndex = question?.options.findIndex(
              (el) => el === lastElement
            );
            const indexOf = question?.options.findIndex((object) => {
              return object.option === "جميع الاجابات لا تنطبق";
            });
            move(question?.options, indexOf, lastIndex);
          }

          return (
            <QuestionContainer
              key={index}
              language={i18n.language === "ar" ? "ar" : "en"}
            >
              <CategoryWrapper>
                <div className="category-wrapper">
                  <p className="category">{question.category}#</p>
                </div>
              </CategoryWrapper>
              <SubContainer>
                <RightContainer>
                  <NumberStyle>-{index + 1}</NumberStyle>
                  <QuestionStyle
                    language={i18n.language === "ar" ? "ar" : "en"}
                  >
                    <Trans>{question.question}</Trans>
                  </QuestionStyle>
                </RightContainer>
                <Types>
                  {mockedArr.map((value, index) => {
                    return value.name === question.questionType.name ? (
                      <React.Fragment key={index}>
                        <Icon>{value.icon}</Icon>
                        <QuestionTypeStyle
                          language={i18n.language === "ar" ? "ar" : "en"}
                        >
                          {question.questionType.translation}
                        </QuestionTypeStyle>
                      </React.Fragment>
                    ) : (
                      ""
                    );
                  })}
                </Types>
              </SubContainer>
              {question.questionType.id === 8 ||
              question.questionType.id === 9 ? (
                <OptionsStyle>
                  <div style={{ width: "100%" }}>
                    {question.options &&
                      question?.options.map((item, index) => {
                        return (
                          <MultiOptionsSection key={index}>
                            <TextBox
                              language={i18n.language === "ar" ? "ar" : "en"}
                            >
                              <Text>
                                {item?.option === "جميع الاجابات لا تنطبق" ? (
                                  <Trans>all_answers_not_applicable</Trans>
                                ) : (
                                  item?.option
                                )}
                              </Text>
                            </TextBox>
                            {type === "points" && (
                              <PointsLabel>النقاط : {item?.points}</PointsLabel>
                            )}
                          </MultiOptionsSection>
                        );
                      })}
                  </div>
                </OptionsStyle>
              ) : (
                ""
              )}

              {(question.extra_images || question.extra_videos) && (
                <ChecksWrapper>
                  {question.extra_images && (
                    <CheckboxWrapper>
                      <CheckIcon />
                      <Trans>extra_images_enabled</Trans>
                    </CheckboxWrapper>
                  )}
                  {question.extra_videos && (
                    <CheckboxWrapper>
                      <CheckIcon />
                      <Trans>extra_videos_enabled</Trans>
                    </CheckboxWrapper>
                  )}
                </ChecksWrapper>
              )}

              {question.questionType.id === 2 ? (
                <ScaleContainer>
                  {question.scale &&
                    Object.keys(scale).map((key: string | any) => {
                      return (
                        <OptionsStyle key={key}>
                          <ScaleTitle
                            language={i18n.language === "ar" ? "ar" : "en"}
                          >
                            <Trans>{key}</Trans>
                          </ScaleTitle>
                          {/* @ts-ignore */}
                          <Option>{question.scale[key]}</Option>
                          {key === "min" ? (
                            <PointsLabel>
                              {type === "points" && (
                                <> النقاط : {question.scale["min_points"]} </>
                              )}
                            </PointsLabel>
                          ) : key === "max" ? (
                            <PointsLabel>
                              {type === "points" && (
                                <> النقاط : {question.scale["max_points"]} </>
                              )}
                            </PointsLabel>
                          ) : (
                            <PointsLabel>
                              {type === "points" && <> </>}
                            </PointsLabel>
                          )}
                        </OptionsStyle>
                      );
                    })}
                  <Hr />
                </ScaleContainer>
              ) : (
                ""
              )}
            </QuestionContainer>
          );
        })}
    </>
  );
};

export default React.memo(EvaluationTemplateQuestionDetails);
