/** @format */

import React from "react";
import { StyledProgress } from "./style";

type Props = {
  percent?: number | undefined | any;
  size?: "small" | "default" | undefined;
  status?: "success" | "normal" | "exception" | "active" | undefined;
  format?:
    | ((
        percent?: number | undefined,
        successPercent?: number | undefined
      ) => React.ReactNode)
    | undefined;
  showInfo?: boolean;
  style?: any;
  strokeColor?: string;
  type?: "line" | "circle" | "dashboard" | undefined;
};

export const Progress = ({
  percent,
  size,
  status,
  format,
  showInfo,
  style,
  strokeColor,
  type,
}: Props) => {
  return (
    <StyledProgress
      percent={percent}
      size={size}
      status={status}
      format={format}
      showInfo={showInfo}
      style={style}
      strokeColor={strokeColor}
      type={type}
      gapDegree={130}
    />
  );
};
