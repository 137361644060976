/** @format */

import { create } from "zustand";

export const jobSummaryQuestionsUseStore = create((set) => ({
  jobQuestion: [],
  setJobQuestions: (questions) => {
    set((state) => ({
      jobQuestion: {
        ...state.jobQuestion,
        questions,
      },
    }));
  },
}));
