/** @format */

import React, { ReactElement } from "react";
import { StyledEmpty } from "./style";

export interface Props {
  description: string | ReactElement;
  className?: string;
}

export const Empty = ({ description, className }: Props) => {
  return (
    <StyledEmpty
      description={description}
      className={className!}
      data-testid="Empty-testid"
    />
  );
};
