/* eslint-disable no-unused-vars */
import { ReactNode } from "react";
import { notification } from "antd";

export enum NotificationStatus {
  Info = "info",
  Success = "success",
  Error = "error",
  Warning = "warning",
}

notification.config({
  duration: 5,
  placement: "topLeft",
});

export const showNotification = (
  type: NotificationStatus,
  message: string | ReactNode,
  description: string | ReactNode
) => {
  notification[type]({
    message,
    description,
    style: { fontFamily: "Famtree", paddingLeft: "10px", paddingTop: "40px" },
  });
};
