/** @format */

import React from "react";
import { StyledModal as AntDModal } from "./style";
import { ModalProps } from "../../infrastructure/interfaces/modal.interface";
import { Trans, useTranslation } from "react-i18next";

const Modal = ({
  children,
  title,
  visible,
  onOk,
  onCancel,
  width,
  display,
  loading,
  type,
  footer,
  hideOk,
  okText,
}: ModalProps) => {
  const { i18n } = useTranslation();

  return (
    <AntDModal
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      width={width}
      className={display}
      cancelText={type !== "jobs" ? <Trans>cancel</Trans> : <Trans>undo</Trans>}
      okText={okText ? <Trans>{okText}</Trans> : <Trans>ok</Trans>}
      okButtonProps={{
        loading: loading,
        style: {
          display: hideOk ? "none" : "block",
        },
        className: "map-button-submit",
      }}
      footer={footer}
      language={i18n.language === "ar" ? "ar" : "en"}
    >
      {children}
    </AntDModal>
  );
};

export default Modal;
