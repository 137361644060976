/** @format */

import * as React from "react";
import { SVGProps } from "react";

const NounNaturalEmoji = (props: SVGProps<SVGSVGElement>) => (
  <svg width={23} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#F5A425">
      <path d="M11.5 0C5.432 0 .5 4.932.5 11s4.932 11 11 11 11-4.932 11-11-4.932-11-11-11zm0 1.335A9.656 9.656 0 0 1 21.166 11a9.656 9.656 0 0 1-9.666 9.666A9.656 9.656 0 0 1 1.834 11 9.656 9.656 0 0 1 11.5 1.335z" />
      <path d="M8.104 14c-.31 0-.604.34-.604.75s.28.753.604.75h6.792c.324.003.604-.34.604-.75s-.293-.75-.604-.75H8.104zM8 7c-.82 0-1.5.681-1.5 1.5S7.18 10 8 10c.819 0 1.5-.68 1.5-1.5C9.5 7.681 8.82 7 8 7zm7 0c-.82 0-1.5.68-1.5 1.5S14.18 10 15 10s1.5-.68 1.5-1.5S15.82 7 15 7z" />
    </g>
  </svg>
);

export default NounNaturalEmoji;
