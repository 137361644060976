/** @format */

import { CustomHeader } from 'common/Header';
import {
  fetchGetRequests,
  fetchGetRequestsWithPagination,
} from 'infrastructure/helpers/fetchServerRequests';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import { axiosApiInstance } from 'infrastructure/utils/api';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ManagerForm from './ManagerForm';
import Spinner from 'common/LoadingIndecator';

const Form = () => {
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<any>();
  let location = useLocation();

  const isEdit = location?.pathname.includes('edit');

  const { handleSubmit, register, unregister, errors, formState } = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
  });

  const { data: branchesList } = useQuery(['BranchesData'], () =>
    fetchGetRequestsWithPagination(`api/company/v1/branches`)
  );

  const { data: managerInfo, isLoading: isManagerDetailsLoading } = useQuery(
    ['ManagerDetails'],
    () => fetchGetRequests(`api/company/v1/managers/${params?.id}`),
    {
      // The query will not execute until the userId exists
      enabled: isEdit,
      keepPreviousData: false,
      onSuccess: (data) => {
        setManagerDetails(data?.response?.manager);
      },
    }
  );

  const [branchesOptions, setBranchesOptions] = useState<any>([]);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [externalData, setExternalData] = useState<any>({
    branches: [],
    show_wallet: false,
    show_shoppers_count: false,
    show_dates: false,
    show_home: false,
    show_order: false,
    view_all_reports: false,
  });
  const [managerDetails, setManagerDetails] = useState<any>({});

  useEffect(() => {
    if (branchesList) {
      setBranchesOptions(
        branchesList?.branches?.map((branch: { id: Number; name: String }) => {
          return {
            value: branch.id,
            label: branch.name,
          };
        })
      );
    }
  }, [branchesList]);

  useEffect(() => {
    if (managerInfo) {
      setExternalData({
        branches: managerInfo?.response?.manager?.branches,
        show_wallet: managerInfo?.response?.manager?.show_wallet,
        show_shoppers_count: Boolean(
          managerInfo?.response?.manager?.show_shoppers_count
        ),
        show_dates: Boolean(managerInfo?.response?.manager?.show_dates),
        show_home: Boolean(managerInfo?.response?.manager?.show_home),
        show_order: Boolean(managerInfo?.response?.manager?.show_order),
        view_all_reports: Boolean(
          managerInfo?.response?.manager?.view_all_reports
        ),
      });
    }
  }, [managerInfo?.response?.manager]);

  useEffect(() => {
    if (externalData?.branches?.length) {
      //@ts-ignore
      unregister('branchesSelect');
    }
  }, [externalData]);

  const handleUpdateData = (value: any, type: string) => {
    switch (type) {
      case 'branches':
        return setExternalData((prevState: any) => ({
          ...prevState,
          branches: value?.map(
            (branch: { label: string; value: number }) => branch.value
          ),
        }));
      case 'show_wallet':
        return setExternalData((prevState: any) => ({
          ...prevState,
          show_wallet: value,
        }));
      case 'show_shoppers_count':
        return setExternalData((prevState: any) => ({
          ...prevState,
          show_shoppers_count: value,
        }));
      case 'show_dates':
        return setExternalData((prevState: any) => ({
          ...prevState,
          show_dates: value,
        }));
      case 'show_home':
        return setExternalData((prevState: any) => ({
          ...prevState,
          show_home: value,
        }));
      case 'show_order':
        return setExternalData((prevState: any) => ({
          ...prevState,
          show_order: value,
        }));
      case 'view_all_reports':
        return setExternalData((prevState: any) => ({
          ...prevState,
          view_all_reports: value,
        }));

      default:
        return {};
    }
  };

  const handleSubmitManager = (data: any) => {
    const { name, mobile, email, role, show_jobs_after_x_hours } = data;
    const {
      branches,
      show_wallet,
      show_shoppers_count,
      show_dates,
      show_home,
      show_order,
      view_all_reports,
    } = externalData;
    const urlParams = {
      name,
      mobile,
      email,
      role,
      branches,
      show_wallet,
      show_jobs_after_x_hours,
      show_shoppers_count,
      show_dates,
      show_home,
      show_order,
      view_all_reports,
    };

    setLoadingSubmit(true);
    isEdit
      ? axiosApiInstance
          .put(`api/company/v1/managers/${params?.id}`, urlParams)
          .then(() => {
            queryClient.invalidateQueries('managersListData');
            setLoadingSubmit(false);
            showNotification(
              NotificationStatus.Success,
              t('done'),
              t('done_message')
            );
            navigate('/managers');
          })
          .catch((err) => {
            showNotification(
              NotificationStatus.Error,
              ' ',
              err.response.data.message
            );
            setLoadingSubmit(false);
          })
      : axiosApiInstance
          .post(`api/company/v1/managers`, urlParams)
          .then(() => {
            queryClient.invalidateQueries('managersListData');
            setLoadingSubmit(false);
            showNotification(
              NotificationStatus.Success,
              t('done'),
              t('done_message')
            );
            navigate('/managers');
          })
          .catch((err) => {
            showNotification(
              NotificationStatus.Error,
              ' ',
              err.response.data.message
            );
            setLoadingSubmit(false);
          });
  };

  if (isManagerDetailsLoading) {
    return <Spinner size={'large'} tip={'loading'} />;
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {i18n.language === 'ar' ? 'المدير - تدري' : 'Manager - Tdree'}
        </title>
      </Helmet>
      <CustomHeader
        title={
          isEdit ? <Trans>edit_manager</Trans> : <Trans>add_manager</Trans>
        }
        path={'/managers'}
      />
      <ManagerForm
        handleSubmit={handleSubmit(handleSubmitManager)}
        errors={errors}
        register={register}
        formState={formState}
        branchesOptions={branchesOptions}
        handleUpdateData={handleUpdateData}
        loadingSubmit={loadingSubmit}
        managerDetails={managerDetails}
        externalData={externalData}
        isEdit={isEdit}
        selectAllText={t('select_all')}
        data={{
          active: false,
          branches: {
            value: 0,
            label: '',
          },
          email: '',
          email_after: false,
          email_after_visit: false,
          id: 0,
          mobile: '',
          name: '',
          role: '',
          show_wallet: false,
        }}
      />
    </>
  );
};

export default Form;
