/** @format */

import React from "react";
import { SmallCard } from "common/Card";
import { List } from "components/List";
import { List as AntDList } from "antd";
import { Empty } from "common/Empty";
import { Question, ListWrapper } from "../style";
import { Trans, useTranslation } from "react-i18next";
import { shoppersUseStore } from "infrastructure/zustandStore/shoppers-store";
import Spinner from "common/LoadingIndecator";

export const Shoppers = () => {
  const { i18n } = useTranslation();

  const [shoppers, loading] = shoppersUseStore((state: any) => [
    state.shoppers,
    state.loading,
  ]);

  if (loading) return <Spinner size={"large"} tip={"Loading..."} />;

  return shoppers?.data?.length > 0 ? (
    <ListWrapper language={i18n.language === "ar" ? "ar" : "en"}>
      <List
        type="shoppersList"
        data={shoppers.data}
        setLimit={() => {}}
        setPage={() => {}}
        grid={{
          gutter: 8,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        renderItem={(item: any, index: number) => (
          <AntDList.Item key={index}>
            <SmallCard
              title={item.name}
              address={item.city + "," + item.country}
              status={item.status}
              rating={item.rating}
              image={item?.image}
              jobId={item.JobId}
            />
          </AntDList.Item>
        )}
      />
    </ListWrapper>
  ) : (
    <Empty
      description={
        <Question>
          <Trans>no_shoppers_data</Trans>
        </Question>
      }
    />
  );
};
