/** @format */

import * as React from "react";
import { SVGProps } from "react";

const RotateRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ marginLeft: "10px" }}
  >
    <path
      d="M13.65 4.657A9.184 9.184 0 0 0 11 4.263a7.946 7.946 0 0 0-7.947 7.947c0 4.4 3.556 7.957 7.947 7.957a7.946 7.946 0 0 0 7.948-7.948 7.947 7.947 0 0 0-1.339-4.418m-2.823-2.924-2.649-3.044m2.649 3.044-3.089 2.255"
      stroke="#0051CD"
      strokeWidth={1.35}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0,0"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default RotateRight;
