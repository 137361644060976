/** @format */

import * as React from "react";
import { SVGProps } from "react";

const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={58} height={63} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M23.244 46.134c5.66 0 5.854-6.453 9.63-10.094 3.962-3.82 16.725-.807 16.725-6.744 0-5.712.706-16.09-6.309-21.935C36.276 1.516 28.823 0 22.936 0 15.264 0 6.425 6.74 2.761 12.884.886 16.027 0 22.71 0 26.636c0 5.608 4.442 9.2 8.024 12.967a20.94 20.94 0 0 0 15.22 6.53z"
        fill="#E8F8EE"
      />
      <path
        d="m34.897 51.038 3.673 3.673 7.347-7.347M28.976 57.708c-4.398 0-8.773-1.112-12.108-3.335-5.848-3.915-5.848-10.295 0-14.186 6.646-4.447 17.545-4.447 24.191 0M37.893 14.57a10.646 10.646 0 0 1 1.837 5.993c-.024 5.8-4.592 10.513-10.343 10.706a4.393 4.393 0 0 0-.798 0c-5.751-.193-10.319-4.906-10.319-10.706 0-5.92 4.785-10.73 10.73-10.73"
        stroke="#292D32"
        strokeWidth={3.6}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
    </g>
  </svg>
);

export default ProfileIcon;
