/** @format */

import * as React from "react";

function Logo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width={43}
      height={46}
      viewBox="0 0 43 46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{"Group 9"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.283.13c10.413 0 18.855 8.441 18.855 18.855 0 4.511-1.584 8.652-4.227 11.898.018.017.035.036.052.055l6.841 7.599a3.63 3.63 0 01-5.394 4.857l-6.842-7.598a3.674 3.674 0 01-.223-.273 18.77 18.77 0 01-9.062 2.317C8.869 37.84.428 29.398.428 18.985.428 8.57 8.869.129 19.283.129z"
          fill="#44C874"
        />
        <path
          d="M19.283 10.17a8.814 8.814 0 11-8.243 5.687 3.63 3.63 0 005.295-4.963l-.147-.164a8.795 8.795 0 013.095-.56z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}

export default Logo;
