import * as React from "react";

function TemplateLogo(props: any) {
  return (
    <svg width={50} height={50} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <rect fill="#E8F8EE" width={50} height={50} rx={8} />
        <g stroke="#43C773" strokeLinejoin="round" strokeWidth={1.5}>
          <path d="M14 31.51V19.32A2.322 2.322 0 0116.321 17h16.831a2.322 2.322 0 012.322 2.321V31.51a2.322 2.322 0 01-2.322 2.322h-16.83A2.322 2.322 0 0114 31.509z" />
          <path
            strokeLinecap="round"
            d="M26.768 22.223h-8.705m8.705 3.483h-8.705m13.348 3.482H30.25m1.161-3.482H30.25m1.161-3.483H30.25"
          />
        </g>
      </g>
    </svg>
  );
}

export default TemplateLogo;
