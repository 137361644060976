/** @format */

import React from "react";
import { StyledPlayer } from "./style";

type Props = {
  url: string;
  playing: boolean;
  controls: boolean;
  style?: any;
  video_width?: string;
  video_height?: string;
};

export const Player = ({
  url,
  playing,
  controls,
  style,
  video_width,
  video_height,
}: Props) => {
  return (
    <StyledPlayer
      url={url}
      playing={playing}
      controls={controls}
      style={style || { marginBottom: "20px" }}
      pip={true}
      video_width={video_width}
      video_height={video_height}
    />
  );
};

Player.defaultProps = {
  playing: false,
  controls: true,
};
