/** @format */

import React from "react";
import { useQuery } from "react-query";
import { Trans, useTranslation } from "react-i18next";
import { fetchGetRequestsWithPagination } from "infrastructure/helpers/fetchServerRequests";
import CustomTable from "common/Table";
import { ColumnsType } from "antd/lib/table";
import { Rate } from "common/Rate";
import { timeStampToDate } from "infrastructure/helpers/timeStampToDate";
import { Container, RateWrapper, TablesWrapper } from "pages/style";
import { ColumnCell, ColumnCellName } from "../style";

const { useState } = React;

export const BranchesList = ({ searchResults, setSearchInputValue }: any) => {
  const { i18n } = useTranslation();
  const [page, SetPage] = useState<number>(1);

  const { data, isLoading } = useQuery(
    ["branchesRatingList", page, searchResults],
    () =>
      fetchGetRequestsWithPagination(
        `api/company/v1/branches/rates?page=${page}&search=${searchResults}`
      ),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const handlePagination = (page: number) => {
    SetPage(page);
  };

  const columns: ColumnsType<object> | undefined = [
    {
      title: <Trans>creation_date</Trans>,
      key: "تاريخ الإإنشاء",
      render: (record: { createdAt: string }) => (
        <ColumnCell>{timeStampToDate(record.createdAt)}</ColumnCell>
      ),
    },
    {
      title: <Trans>company_name</Trans>,
      key: "اسم الشركة",
      render: (record: any) => (
        <ColumnCellName>{record.branch.name}</ColumnCellName>
      ),
    },
    {
      title: <Trans>mission</Trans>,
      key: "المهمة",
      render: (record: any) => (
        <ColumnCellName>{record.job.name}</ColumnCellName>
      ),
    },
    {
      title: <Trans>shopper_name</Trans>,
      dataIndex: "shopper",
      key: "المتسوق",
      render: (text: { name: string }) => <ColumnCell>{text.name}</ColumnCell>,
    },
    {
      title: <Trans>shopper_rating_branch</Trans>,
      render: (record: { rate: number }) => (
        <>
          <ColumnCell>
            <RateWrapper>
              <Rate count={1} defaultValue={1} disabled /> {record.rate}
            </RateWrapper>
          </ColumnCell>
        </>
      ),
    },
  ];

  return (
    <>
      <Container>
        <TablesWrapper>
          <CustomTable
            columns={columns}
            data={data?.rates}
            loading={isLoading}
            pagination={{
              position: [i18n.language === "ar" ? "bottomRight" : "bottomLeft"],
              total: data?.pagination.total,
              defaultCurrent: data?.pagination.current_page || 1,
              onChange: (page: number) => handlePagination(page),
            }}
          />
        </TablesWrapper>
      </Container>
    </>
  );
};
