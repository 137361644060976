/** @format */

import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";
import { NavLink } from "react-router-dom";
import { Avatar } from "antd";

export const RightContainer = styled.div`
  box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #cccccc26;
  background-color: white;
  flex-direction: column;
  direction: ${(props: { language: string }) =>
    props.language === "en" && "rtl"};
`;

export const Container = styled.div`
  text-align: right;
`;
export const Span = styled.p`
  margin: 0px 10px;
  height: 34px;
`;

export const Li = styled.li`
  margin-bottom: 15px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  &:hover {
    color: ${Colors.green};
  }
`;

//@ts-ignore
export const ItemLink = styled((props) => <NavLink {...props} />)`
  color: ${Colors.charcoal_grey};
  text-decoration: none;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
`;

export const Item = styled.i`
  font-size: 24px;
  margin-top: -3px;
  height: 34px;
`;

export const Arrow = styled.i`
  float: left;
  font-size: 20px;
  margin-left: 10px;
  color: #d9d9d7;
`;
export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
export const Title = styled.h2`
  font-family: Famtree;
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 0;
  text-align: center;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "unset" : "ltr"};
`;

export const StyledAvatar = styled(Avatar)``;

export const IconWithLabel = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;
