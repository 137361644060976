/** @format */

import React from "react";
import App from "App";
import "index.css";
import "font-awesome/css/font-awesome.min.css";
import "antd/dist/reset.css";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-multi-carousel/lib/styles.css";
import ErrorBoundary from "common/ErrorBoundary";
import "./i18n";
import * as ReactDOMClient from "react-dom/client";
import { ConfigProvider } from "antd";
import locale from "antd/locale/ar_EG";
import { defaultToken } from "infrastructure/utils/defaultToken";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const container = document.getElementById("root") as HTMLElement;

const root = ReactDOMClient.createRoot(container);

const customRenderEmpty = () => <div style={{ textAlign: "center" }}></div>;

const appElement = (
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{ token: defaultToken }}
        renderEmpty={customRenderEmpty}
        locale={locale}
      >
        <App />
      </ConfigProvider>
    </QueryClientProvider>
  </ErrorBoundary>
);

root.render(appElement);
