/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Input } from "common/Input";
import {
  Form,
  Label,
  SelectLabel,
  SelectWithSwitchContainer,
  SwitchLabel,
  SwitchBtnContainer,
  AddressLables,
  MapLabel,
  Container,
  ButtonContainer,
  P,
} from "./style";
import { MapTitle } from "../Create/style";

import { BranchesFormProps } from "infrastructure/interfaces/branches-form.interface";
import { Error } from "../../style";
import { Select } from "common/Select";
import { SwitchBtn } from "common/Switch";
import { Button } from "common/Button";
import Modal from "common/Modal";
import MapContainer from "common/Map/index";
import { Select as AntDSelect } from "antd";
import ReactSelect from "common/ReactSelect";

const { Option } = AntDSelect;

const EditCompanyBranchForm: React.FC<BranchesFormProps> = ({
  errors,
  register,
  formState,
  categories,
  handleInputChange,
  handleOnChangeSwitchButton,
  data,
  handleSelectOnChange,
  handleSubmit,
  isModalVisible,
  showModal,
  handleCancel,
  initialValue = "",
  handleOk,
  loading,
  countriesOptions,
  citiesOptions,
  handleFetchCities,
  disableCity,
}): JSX.Element => {
  const { t, i18n } = useTranslation();
  return (
    <Container language={i18n.language === "ar" ? "ar" : "en"}>
      <Form onSubmit={handleSubmit} noValidate>
        {/* branch name  */}
        <Label>
          <Trans>branch_name</Trans>
        </Label>
        <Input
          name="name"
          type="text"
          placeholder={
            i18n.language === "ar" ? "مثال : فرع حطين" : "Branch name"
          }
          dir="true"
          width={"formWidth"}
          errorName={errors.name}
          ref={register({ required: true, maxLength: 60 })}
          onChange={(e: { target: { name: string; value: string } }) =>
            handleInputChange(e)
          }
          defaultValue={data.name === null ? initialValue : data.name}
        />
        {errors?.name?.types?.required && (
          <Error>
            <Trans>name_required</Trans>
          </Error>
        )}
        {errors?.name?.types?.maxLength && (
          <Error>
            <Trans values={{ num: 60 }}>max_length</Trans>
          </Error>
        )}
        {/* shop name */}
        <Label>
          <Trans>shop_name</Trans>
        </Label>
        <Input
          name="shop_name"
          type="text"
          placeholder={
            i18n.language === "ar" ? "مثال : محل الاسطورة" : "Shop name"
          }
          dir="true"
          width={"formWidth"}
          errorName={errors.shop_name}
          ref={register({ required: true, maxLength: 60 })}
          onChange={(e: { target: { name: string; value: string } }) =>
            handleInputChange(e)
          }
          defaultValue={data.shop_name === null ? initialValue : data.shop_name}
        />
        {errors?.shop_name?.types?.required && (
          <Error>
            <Trans>name_required</Trans>
          </Error>
        )}
        {errors?.shop_name?.types?.maxLength && (
          <Error>
            <Trans values={{ num: 60 }}>max_length</Trans>
          </Error>
        )}
        {/* Working field  */}
        <Label>
          <Trans>working_field</Trans>
        </Label>
        <SelectWithSwitchContainer>
          <Select
            defaultValue={
              <SelectLabel>
                <Trans>select_working_field_default_value</Trans>
              </SelectLabel>
            }
            onChange={(value: number, name: string) =>
              handleSelectOnChange(value, "category", name)
            }
            value={
              data.category?.name === null ? initialValue : data.category?.name
            }
          >
            {categories &&
              categories.map((category) => {
                return (
                  <Option
                    key={category.id}
                    value={category.id}
                    title={category.name}
                  >
                    <P> {category.name} </P>
                  </Option>
                );
              })}
          </Select>

          <SwitchBtnContainer language={i18n.language === "ar" ? "ar" : "en"}>
            <SwitchBtn
              onChange={(checked: boolean) => {
                handleOnChangeSwitchButton(checked);
              }}
              defaultChecked={data.is_website}
              className={data.is_website}
            />
            <SwitchLabel>
              <Trans>is_website</Trans>
            </SwitchLabel>
          </SwitchBtnContainer>
        </SelectWithSwitchContainer>

        {data.is_website ? (
          <>
            <Label>
              <Trans>website</Trans>
            </Label>
            <Input
              name="website"
              type="text"
              width={"formWidth"}
              errorName={errors.website}
              onChange={(e: { target: { name: string; value: string } }) =>
                handleInputChange(e)
              }
              ref={register({ required: true })}
              defaultValue={data.website === null ? initialValue : data.website}
            />
            {errors?.website?.types?.required && (
              <Error>
                <Trans>website_required</Trans>
              </Error>
            )}
          </>
        ) : (
          <>
            {/* country */}
            <Label>
              <Trans>country_label</Trans>
            </Label>
            <ReactSelect
              className="country-select"
              options={countriesOptions}
              onChange={(option: { value: number; label: string }) => {
                handleFetchCities(option.value);
                handleSelectOnChange(option.value, "country_id");
              }}
              placeholder={t("select_country")}
              value={data?.country_id}
              isDisabled={!data?.canEditِAddress}
            />

            {/* city */}
            <Label>
              <Trans>city</Trans>
            </Label>
            <ReactSelect
              className="city-select"
              options={citiesOptions}
              onChange={(option: { value: number; label: string }) => {
                handleSelectOnChange(option.value, "city_id");
              }}
              placeholder={t("select_city")}
              isDisabled={disableCity || !data?.canEditِAddress}
              value={data?.city_id}
            />
            {/* Address  */}
            <AddressLables>
              <Label>
                <Trans>address</Trans>
              </Label>

              <MapLabel
                type="button"
                disabled={!data?.canEditِAddress}
                onClick={showModal}
              >
                <Trans>map_address</Trans>
              </MapLabel>
            </AddressLables>
            <Input
              name="address"
              width={"formWidth"}
              errorName={errors.address}
              ref={register({ required: true })}
              defaultValue={data.address === null ? initialValue : data.address}
              disabled
            />
            {errors?.address?.types?.required && (
              <Error>
                <Trans>address_required</Trans>
              </Error>
            )}
            {/* Address description  */}
            <Label>
              <Trans>address_description</Trans>
            </Label>
            <Input
              name="address_description"
              type="text"
              dir="true"
              width={"formWidth"}
              errorName={errors.address_description}
              onChange={(e: { target: { name: string; value: string } }) =>
                handleInputChange(e)
              }
              defaultValue={
                data.address_description === null
                  ? initialValue
                  : data.address_description
              }
            />

            <Label>
              <Trans>website</Trans>
            </Label>
            <Input
              name="website"
              type="url"
              dir="true"
              width={"formWidth"}
              errorName={errors.website}
              ref={register({
                pattern:
                  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
              })}
              onChange={(e: { target: { name: string; value: string } }) =>
                handleInputChange(e)
              }
              defaultValue={data.website === null ? initialValue : data.website}
            />
            {errors?.website?.types?.pattern && (
              <Error>
                <Trans>website_pattern</Trans>
              </Error>
            )}
          </>
        )}
      </Form>

      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        title={
          <MapTitle>
            <Trans>map_title</Trans>
          </MapTitle>
        }
        text={<Trans>save</Trans>}
        width
      >
        <MapContainer />
      </Modal>

      <ButtonContainer>
        <Button
          disabled={formState.isSubmitting}
          size={"large"}
          onClick={handleSubmit}
          loading={loading}
        >
          <Trans>save</Trans>
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default EditCompanyBranchForm;
