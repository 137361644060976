/** @format */

import { create } from "zustand";

export const jobSummaryUseStore = create((set) => ({
  jobSummaryData: [],
  setJobSummary: (job) => {
    set((state) => ({
      jobSummaryData: {
        ...state.jobSummaryData,
        job,
      },
    }));
  },
}));
