/** @format */

import * as React from "react";
import { SVGProps } from "react";

const NounSmileGreen = (props: SVGProps<SVGSVGElement>) => (
  <svg width={23} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.5 0C5.433 0 .5 4.933.5 11s4.933 11 11 11 11-4.933 11-11-4.933-11-11-11zm0 1.435A9.554 9.554 0 0 1 21.065 11a9.554 9.554 0 0 1-9.565 9.565A9.554 9.554 0 0 1 1.935 11 9.554 9.554 0 0 1 11.5 1.435zM7.435 6.457a1.674 1.674 0 1 0 0 3.347 1.674 1.674 0 0 0 0-3.347zm8.13 0a1.674 1.674 0 1 0 0 3.347 1.674 1.674 0 0 0 0-3.347zm-9.4 6.695a.692.692 0 0 0-.569.374.726.726 0 0 0 .045.74c1.283 1.918 3.431 3.19 5.859 3.19s4.576-1.272 5.859-3.19a.742.742 0 0 0-.195-.994c-.313-.21-.792-.119-1 .194-1.037 1.55-2.735 2.556-4.664 2.556-1.93 0-3.627-1.006-4.663-2.556-.194-.228-.442-.327-.673-.314z"
      fill="#83AE00"
    />
  </svg>
);

export default NounSmileGreen;
