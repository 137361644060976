/** @format */

import React, { ReactNode } from "react";
import { Container } from "./style";

export type Props = {
  children: ReactNode;
  style?: any;
};
const FormTemplate = ({ children, style }: Props) => {
  return (
    <Container style={style} data-testid="form-template">
      {children}
    </Container>
  );
};

export default FormTemplate;
