/** @format */

import { create } from "zustand";
import { axiosApiInstance } from "infrastructure/utils/api";

export const useBranchCompanyDetailsStore = create((set: any) => ({
  branchDetailsData: [],
  loading: true,
  statusCode: "",
  branchDetails: async (id: number) => {
    try {
      const response = await axiosApiInstance.get(
        `api/company/v1/branches/${id}`
      );
      set({ branchDetailsData: response.data.branch, loading: false });
    } catch (error) {
      set({ loading: false, statusCode: error.response.data.statusCode });
    }
  },
}));
