/** @format */

import axios from "axios";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import storage from "./storage";

export const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_BASE_API_URL;
    config.headers = {
      Authorization: `Bearer ${storage.getItem("access_token")}`,
      Accept: "application/json",
      "X-Authorization": process.env.REACT_APP_API_KEY_WEB_COMPANY,
      "Content-Type": "application/json",
      "Accept-Language": storage.getItem("i18nextLng")
        ? storage.getItem("i18nextLng")
        : "ar",
    };
    config.params = { ...config.params };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      showNotification(
        NotificationStatus.Warning,
        " ",
        error.response?.data.message
      );
      storage.clearItem("access_token");
    }

    if (error?.response?.status === 403) {
      showNotification(
        NotificationStatus.Warning,
        "Un Authorized",
        error.response.data.message
      );
    }

    if (error.response?.status === 402) {
      showNotification(
        NotificationStatus.Error,
        "Internal Server Error",
        error.response.data.message
      );
    }

    if (error.response?.status === 404) {
      showNotification(
        NotificationStatus.Warning,
        "Not Found",
        error.response.data.message
      );
      return (window.location.href = "/home");
    }

    if (error.response?.status === 500) {
      showNotification(
        NotificationStatus.Error,
        "Internal Server Error",
        error.response.data.message
      );
    }
    return Promise.reject(error);
  }
);
