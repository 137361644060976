/** @format */

import * as React from "react";

const InstgramIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <a
    href="https://www.instagram.com/TdreeApp/"
    target="_blank"
    rel="noreferrer"
  >
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.413 2.667c-4.853 0-7.746 2.893-7.746 7.746v11.16c0 4.867 2.893 7.76 7.746 7.76h11.16c4.854 0 7.747-2.893 7.747-7.746V10.413c.013-4.853-2.88-7.746-7.733-7.746H10.413zM10.827 16A5.178 5.178 0 0 1 16 10.827 5.178 5.178 0 0 1 21.173 16 5.178 5.178 0 0 1 16 21.173 5.178 5.178 0 0 1 10.827 16zm12.786-6.387a1.54 1.54 0 0 1-.44.28 1.32 1.32 0 0 1-1.453-.28 1.54 1.54 0 0 1-.28-.44 1.327 1.327 0 0 1-.107-.506c0-.174.04-.347.107-.507.067-.173.16-.307.28-.44a1.331 1.331 0 0 1 1.2-.36c.093.013.173.04.253.08.08.027.16.067.24.12.067.04.134.107.2.16.12.133.214.267.28.44.067.16.107.333.107.507 0 .173-.04.346-.107.506a1.54 1.54 0 0 1-.28.44z"
        fillRule="evenodd"
      />
    </svg>
  </a>
);

export default InstgramIcon;
