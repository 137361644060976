/** @format */

import * as React from "react";
import { SVGProps } from "react";

const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={58} height={58} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M23.014 43.599c5.66 0 10.285-5.562 14.061-9.203 3.962-3.82 14.517-16.506 7.503-22.351C37.563 6.199 36.858 0 25.59 0 17.917 0 0 7.223 0 14.242c0 6.289 5.736 9.665 6.566 13.514 1.354 6.273.866 8.352 4.448 12.119 3.825 4.023 6.01 3.724 12 3.724z"
        fill="#E8F8EE"
      />
      <g
        stroke="#292D32"
        strokeDasharray="0,0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={3.6}
      >
        <path d="M51.524 28.782c2.827-2.827 1.909-5.679-2.03-6.355l-6.163-1.015c-1.039-.17-2.271-1.088-2.73-2.03l-3.408-6.815c-1.837-3.698-4.858-3.698-6.694 0l-3.408 6.815c-.459.942-1.691 1.836-2.73 2.03l-6.163 1.015c-3.94.652-4.858 3.504-2.03 6.355l4.81 4.81c.797.797 1.256 2.368.99 3.504L20.59 43.04c-1.087 4.688 1.426 6.525 5.559 4.06l5.776-3.432c1.039-.628 2.779-.628 3.818 0l5.776 3.432c4.132 2.44 6.646.628 5.558-4.06L45.7 37.096M19.333 12.083h-14.5m7.25 33.834h-7.25M7.25 29H4.833" />
      </g>
    </g>
  </svg>
);

export default StarIcon;
