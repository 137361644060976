/** @format */

import React, { useEffect, useState } from "react";
import {
  NumberStyle,
  QuestionStyle,
  QuestionTypeStyle,
  Icon,
  Option,
  SubContainer,
  QuestionContainer,
  RightContainer,
  Types,
  OptionsStyle,
  ScaleTitle,
  ScaleContainer,
  TextBox,
  CategoryWrapper,
  CheckboxWrapper,
  ChecksWrapper,
  MultiOptionsSection,
  PointsLabel,
  Hr,
  Text,
} from "pages/EvaluationTemplate/EvaluationDetails/style";
import { Trans } from "react-i18next";
import i18n from "i18n";
import { mockedArr } from "infrastructure/helpers/questionTypeList";
import { Props } from "infrastructure/interfaces/evaluationTemplateDetails.interface";
import CheckIcon from "assets/svg/icon-check";
import "./styles.css";
import { List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { axiosApiInstance } from "infrastructure/utils/api";
import { useStore } from "infrastructure/zustandStore/evaluation-template-store";

const QuestionDetailsRow: React.FC<{
  type: string;
}> = ({ type }): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [template] = useStore((state: any) => [state.template]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<any>(null);

  const loadMoreData = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await axiosApiInstance.get(
        `api/company/v2/evaluation-templates/${template?.data?.id}/questions?page=${page}`
      );

      setItems((prevItems) => [...prevItems, ...response.data.data?.questions]);
      setPagination(response.data.data.pagination);
      setLoading(false);

      if (page <= response.data.data.pagination.total_pages) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  let scale;

  items.length &&
    items.map((question: Props, index: number) => {
      scale = {
        min: question.scale?.min,
        step: question.scale?.step,
        max: question.scale?.max,
      };

      function move(input: any[], from: any, to: any) {
        let numberOfDeletedElm = 1;

        const elm = input.splice(from, numberOfDeletedElm)[0];

        numberOfDeletedElm = 0;

        input.splice(to, numberOfDeletedElm, elm);
      }

      if (question?.options) {
        const lastElement = question?.options[question?.options?.length - 1];

        const lastIndex = question?.options.findIndex(
          (el) => el === lastElement
        );
        const indexOf = question?.options.findIndex((object) => {
          return object.option === "جميع الاجابات لا تنطبق";
        });
        move(question?.options, indexOf, lastIndex);
      }
    });

  return (
    <div
      id="scrollableDiv"
      style={{
        height: 1000,
        overflow: "auto",
        padding: "0 16px",
        border: "1px solid rgba(140, 140, 140, 0.35)",
      }}
    >
      <InfiniteScroll
        style={{ height: "100%", overflowX: "hidden" }}
        dataLength={items?.length || 0}
        next={loadMoreData}
        hasMore={pagination?.has_more || false}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={items}
          grid={{
            gutter: 8,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
            xxl: 1,
          }}
          renderItem={(item: any, index: number) => (
            <List.Item key={index}>
              <QuestionContainer
                language={i18n.language === "ar" ? "ar" : "en"}
              >
                <CategoryWrapper>
                  <div className="category-wrapper">
                    <p className="category">{item.category}#</p>
                  </div>
                </CategoryWrapper>
                <SubContainer>
                  <RightContainer>
                    <NumberStyle>-{index + 1}</NumberStyle>
                    <QuestionStyle
                      language={i18n.language === "ar" ? "ar" : "en"}
                    >
                      <Trans>{item?.question}</Trans>
                    </QuestionStyle>
                  </RightContainer>
                  <Types>
                    {mockedArr.map((value, index) => {
                      return value.name === item?.questionType.name ? (
                        <React.Fragment key={index}>
                          <Icon>{value.icon}</Icon>
                          <QuestionTypeStyle
                            language={i18n.language === "ar" ? "ar" : "en"}
                          >
                            {item?.questionType.translation}
                          </QuestionTypeStyle>
                        </React.Fragment>
                      ) : (
                        ""
                      );
                    })}
                  </Types>
                </SubContainer>
                {item?.questionType.id === 8 || item?.questionType.id === 9 ? (
                  <OptionsStyle>
                    <div style={{ width: "100%" }}>
                      {item.options &&
                        item?.options.map((item, index) => {
                          return (
                            <MultiOptionsSection key={index}>
                              <TextBox
                                language={i18n.language === "ar" ? "ar" : "en"}
                              >
                                <Text>
                                  {item?.option === "جميع الاجابات لا تنطبق" ? (
                                    <Trans>all_answers_not_applicable</Trans>
                                  ) : (
                                    item?.option
                                  )}
                                </Text>
                              </TextBox>
                              {type === "points" && (
                                <PointsLabel>
                                  النقاط : {item?.points}
                                </PointsLabel>
                              )}
                            </MultiOptionsSection>
                          );
                        })}
                    </div>
                  </OptionsStyle>
                ) : (
                  ""
                )}

                {(item.extra_images || item.extra_videos) && (
                  <ChecksWrapper>
                    {item.extra_images && (
                      <CheckboxWrapper>
                        <CheckIcon />
                        <Trans>extra_images_enabled</Trans>
                      </CheckboxWrapper>
                    )}
                    {item.extra_videos && (
                      <CheckboxWrapper>
                        <CheckIcon />
                        <Trans>extra_videos_enabled</Trans>
                      </CheckboxWrapper>
                    )}
                  </ChecksWrapper>
                )}

                {item?.questionType.id === 2 ? (
                  <ScaleContainer>
                    {item.scale &&
                      Object.keys(scale).map((key: string | any) => {
                        return (
                          <OptionsStyle key={key}>
                            <ScaleTitle
                              language={i18n.language === "ar" ? "ar" : "en"}
                            >
                              <Trans>{key}</Trans>
                            </ScaleTitle>
                            {/* @ts-ignore */}
                            <Option>{item?.scale[key]}</Option>
                            {key === "min" ? (
                              <PointsLabel>
                                {type === "points" && (
                                  <> النقاط : {item.scale["min_points"]} </>
                                )}
                              </PointsLabel>
                            ) : key === "max" ? (
                              <PointsLabel>
                                {type === "points" && (
                                  <> النقاط : {item.scale["max_points"]} </>
                                )}
                              </PointsLabel>
                            ) : (
                              <PointsLabel>
                                {type === "points" && <> </>}
                              </PointsLabel>
                            )}
                          </OptionsStyle>
                        );
                      })}
                    <Hr />
                  </ScaleContainer>
                ) : (
                  ""
                )}
              </QuestionContainer>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

export default React.memo(QuestionDetailsRow);
