/** @format */

import React, { useEffect, useState } from "react";
import { CustomHeader } from "common/Header";
import Form from "./form";
import { useForm } from "react-hook-form";
import { useStore } from "infrastructure/zustandStore/categories-store";
import { CompanyBranchDataProps } from "infrastructure/interfaces/branches-form.interface";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { locationStore } from "infrastructure/zustandStore/location-store";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";

const CreateCompanyBranch = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {
    handleSubmit,
    register,
    errors,
    formState,
    reset,
    setValue,
    unregister,
  } = useForm({
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues: {
      category: 0,
      country: 0,
      city: 0,
    },
  });

  // fetch countries
  const { data: countries } = useQuery(
    ["CountriesList"],
    () => fetchGetRequests(`api/countries`),
    {
      keepPreviousData: false,
    }
  );

  const countriesOptions = countries
    ? countries?.response?.countries
        ?.map((country: any) => {
          if (country && country.name) {
            return {
              value: country.id,
              label: country.name,
            };
          }
        })
        .filter(Boolean)
    : [];

  // featch cities
  const [citiesOptions, setCitiesOptions] = useState<any>([]);
  const [disableCity, setDisableCity] = useState<boolean>(true);

  const handleFetchCities = (id: number) =>
    axiosApiInstance
      .get(`api/countries/${id}/cities`)
      .then((res) => {
        setDisableCity(false);
        const options = res?.data?.cities
          ?.map((city: any) => {
            if (city.name) {
              return {
                value: city.id,
                label: city.name,
              };
            }
          })
          .filter(Boolean);
        setCitiesOptions(options);
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          t("Error"),
          err.response.data.message
        );
      });

  const location: { lat: string; long: string } = locationStore(
    (state: Record<string | number | symbol, any>) => state.location
  );
  const address: string = locationStore(
    (state: Record<string | number | symbol, any>) => state.address
  );
  const locationFn: () => void = locationStore(
    (state: Record<string | number | symbol, any>) => state.locationFn
  );
  const district: string = locationStore(
    (state: Record<string | number | symbol, any>) => state.district
  );
  const city: string = locationStore(
    (state: Record<string | number | symbol, any>) => state.city
  );
  const country: string = locationStore(
    (state: Record<string | number | symbol, any>) => state.country
  );

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [categories, categoriesList] = useStore((state: any) => [
    state.categories,
    state.categoriesList,
  ]);

  const [data, setData] = useState<CompanyBranchDataProps>({
    name: "",
    is_website: false,
    category_id: 0,
    country_id: 0,
    city_id: 0,
  });

  useEffect(() => {
    categoriesList();
    locationFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    const list: CompanyBranchDataProps = { ...data };
    list["lat"] = location.lat.toString();
    list["long"] = location.long.toString();
    list["address"] = address;
    list["district"] = district;
    list["city"] = city;
    list["country"] = country;
    setData(list);
    setIsModalVisible(false);
  };

  const handleInputChange = (e: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = e.target;
    if (value !== "") {
      setData({ ...data, [name]: value });
    }
  };

  const handleOnChangeSwitchButton = (checked: boolean) => {
    const list: CompanyBranchDataProps = { ...data };
    list["is_website"] = checked;
    setData(list);
  };

  const handleSelectOnChange = (value: number, obj_key: string) => {
    const list: any = { ...data };
    list[obj_key] = value;
    setData(list);
  };

  useEffect(() => {
    if (data?.is_website) {
      unregister("city");
      unregister("country");
    }
    if (!data?.is_website) {
      register("category", {
        validate: (value: any) => {
          return value || "This is required.";
        },
      });

      register("country", {
        validate: (value: string) => {
          return value || "";
        },
      });

      register("city", {
        validate: (value: string) => {
          return value || "";
        },
      });
    }
    if (data?.category_id) {
      unregister("category");
    }
  }, [data, errors]);

  const onSubmit = () => {
    const {
      country_id,
      city_id,
      ...websiteFormData // assigns remaining keys to a new `noB` object
    } = data;
    setLoading(true);
    axiosApiInstance
      .post(
        "api/company/v1/branches",
        data?.is_website ? websiteFormData : data
      )
      .then(() => {
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("create_branch_sucess")
        );
        navigate("/branches");
        setLoading(false);
        reset();
      })
      .catch((err) => {
        setLoading(false);
        showNotification(
          NotificationStatus.Error,
          " ",
          err.response.data.message
        );
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar"
            ? " أضف فرع جديد -تدري "
            : "Add Branch - Tdree"}
        </title>
      </Helmet>
      <CustomHeader
        title={<Trans>add_company_branch</Trans>}
        path={"/branches"}
        component="branches"
      />
      <Form
        handleSubmit={handleSubmit(onSubmit)}
        errors={errors}
        register={register}
        formState={formState}
        categories={categories}
        handleInputChange={handleInputChange}
        handleOnChangeSwitchButton={handleOnChangeSwitchButton}
        data={data}
        handleSelectOnChange={handleSelectOnChange}
        showModal={showModal}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        address={address}
        handleOk={handleOk}
        loading={loading}
        countriesOptions={countriesOptions}
        citiesOptions={citiesOptions}
        handleFetchCities={handleFetchCities}
        disableCity={disableCity}
        setValue={setValue}
      />
    </>
  );
};

export default CreateCompanyBranch;
