import * as React from "react";

function SingleChoiceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={19}
      viewBox="0 0 18 19"
    >
      <g transform="translate(-.04 1)" fill="none" fillRule="evenodd">
        <circle
          cx={8.964}
          cy={8.313}
          r={8.104}
          stroke="#44DAE4"
          strokeWidth={1.5}
        />
        <circle cx={9} cy={8.551} r={2} fill="#44DAE4" />
      </g>
    </svg>
  );
}

export default SingleChoiceIcon;
