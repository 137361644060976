import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 80px;
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 40px;
  flex-direction: row-reverse;
  justify-content: center;
`;

export const P = styled.p`
  margin-bottom: 0;
  font-family: Famtree;
  font-size: 14px;
  color: ${Colors.charcoal_grey_54};
`;
export const BlackTitleBold = styled.p`
  margin: 9px 0 0 0;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};
`;
export const BlackTitle = styled.p`
  margin: 9px 0 0 0;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
`;
export const TitleBold = styled.p`
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.charcoal_grey_54};
`;
export const PContainer = styled.div`
  margin: 0px auto;
  margin-top: 10px;
  width: 923px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const NumberStyle = styled.div`
  margin-top: 4px;
  border-radius: 50%;
  background: rgba(68, 200, 116, 0.2);
  height: 25px;
  width: 25px;
  text-align: center;
  color: #344247;
  font-size: 14px;
`;

export const QuestionStyle = styled.div`
  margin: 5px 23px 0 0;
  font-family: Famtree;
  font-size: 14px;
  text-align: right;
  color: ${Colors.charcoal_grey};
`;
export const QuestionTypeStyle = styled.div`
  margin: 5px 10px 0 0;
  font-family: Famtree;
  font-size: 14px;
  text-align: right;
  color: ${Colors.charcoal_grey};
`;
export const Icon = styled.div`
  margin: 5px 0 0 0;
`;
export const Option = styled.div`
  text-align: center;
  width: 100px;
  height: 50px;
  margin: 0 0 0 13px;
  padding: 15px 17px 15px 21px;
  opacity: 0.77;
  border-radius: 10px;
  font-family: Famtree;
  font-size: 14px;
  color: ${Colors.charcoal_grey};
  background-color: ${Colors.black_4};
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
  margin: 0 auto;
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const Hr = styled.hr`
  background-color: rgb(221, 221, 221);
  height: 0.7px;
  border: 0;
  width: 100%;
  margin: 10px 0;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
export const OptionsStyle = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  padding-right: 50px;
  padding-top: 20px;
`;
export const Types = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 25%;
`;
export const RightContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 75%;
`;
export const SubContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
`;
