/** @format */

import React from "react";
import { StyledAvatar as AntDAvatar } from "./style";
import { Image } from "antd";

export interface IProps {
  src?: string;
  shape?: "circle" | "square" | undefined;
  size?: number | "small" | "large" | "default";
  onClick?: () => void;
  preview?: boolean;
  style?: any;
}

const Avatar = ({ src, shape, size, onClick, preview, style }: IProps) => {
  return (
    <AntDAvatar
      src={<Image src={src} preview={preview} onClick={onClick} />}
      shape={shape}
      size={size}
      style={style}
    />
  );
};

Avatar.defualtProps = {
  src: "",
  shape: "square",
  size: "large",
};

export default Avatar;
