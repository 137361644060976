/** @format */

import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { StyledSteps as AntDSteps } from "./style";
const { Step } = AntDSteps;

interface Props {
  steps: Array<StepsObj>;
  current: number;
  onChange?: (current: number) => void;
}

type StepsObj = {
  title: string;
  content: ReactNode;
  description?: string;
};

const Steps = ({ steps, current }: Props) => {
  const { i18n } = useTranslation();
  return (
    <AntDSteps
      current={current}
      direction="horizontal"
      language={i18n.language === "ar" ? "ar" : "en"}
    >
      {steps.map((item) => (
        <Step
          key={item.title}
          title={item.title}
          description={item.description}
        />
      ))}
    </AntDSteps>
  );
};

export default Steps;
