/** @format */

import {
  Answer,
  AnswersWrapper,
  ChoicesAnswer,
  Hr,
  ImageWrapper,
  Label,
  StyledPlayerWrapper,
} from "./style";
import React from "react";
import { Trans } from "react-i18next";
import AnsweredQuestions from "./AnsweredQuestions";
import { Player } from "common/Player";
import Avatar from "common/Avatar";

const NotAnswredQuestions = ({ question, language }: any) => {
  if (!question.question.answered) {
    return (
      <>
        <Answer>
          <Trans>question_not_answerd</Trans>
        </Answer>
        <Hr />
        <Label>
          <Trans>reason_not_answerd</Trans>
        </Label>

        {question?.question?.answers?.map((answer: any, index: number) => {
          return <ChoicesAnswer key={index}>{answer}</ChoicesAnswer>;
        })}
      </>
    );
  } else {
    return question.question.questionType?.id === 8 ||
      question.question.questionType?.id === 9 ? (
      question?.question?.options?.map((option: string, index: number) => {
        return (
          <AnsweredQuestions
            item={question}
            option={
              option === "جميع الاجابات لا تنطبق" ? (
                <Trans>all_answers_not_applicable</Trans>
              ) : (
                option
              )
            }
            key={index}
          />
        );
      })
    ) : question.question.questionType?.id === 2 ? (
      question?.question?.answers?.map((answer: any, index: number) => {
        return <Answer key={index}>{answer}</Answer>;
      })
    ) : question.question.questionType?.id === 4 ||
      question.question.questionType?.id === 5 ? (
      <AnswersWrapper>
        {question?.question?.answers?.map((answer: any, index: number) => {
          return (
            <StyledPlayerWrapper key={index} language={language}>
              <Player key={index} url={answer} controls={true} />
            </StyledPlayerWrapper>
          );
        })}
      </AnswersWrapper>
    ) : question.question.questionType?.id === 3 ? (
      <AnswersWrapper>
        {question?.question?.answers?.map((answer: any, index: number) => {
          return (
            <ImageWrapper key={index} language={language}>
              <Avatar
                src={answer}
                size={"large"}
                shape={"square"}
                preview={true}
              />
            </ImageWrapper>
          );
        })}
      </AnswersWrapper>
    ) : (
      question?.question?.answers?.map((answer: any, index: number) => {
        return <Answer key={index}>{answer}</Answer>;
      })
    );
  }
};

export default NotAnswredQuestions;
