import React from "react";
import { StyledSwitch as AntDSwitch } from "./style";

type Props = {
  checkedChildren?: string;
  unCheckedChildren?: string;
  style?: any;
  onChange: (value?: any) => void | any;
  checked?: boolean | any;
  handleClick?: () => void;
  defaultChecked?: boolean;
  className?: boolean | any;
};

export const SwitchBtn: React.FC<Props> = ({
  checkedChildren,
  unCheckedChildren,
  style,
  onChange,
  checked,
  handleClick,
  defaultChecked,
  className,
}) => {
  return (
    <AntDSwitch
      checkedChildren={checkedChildren}
      defaultChecked={defaultChecked}
      unCheckedChildren={unCheckedChildren}
      onChange={onChange}
      style={style}
      checked={checked}
      onClick={handleClick}
      className={className}
    />
  );
};

SwitchBtn.defaultProps = {
  checkedChildren: "",
  unCheckedChildren: "",
};
