/** @format */

import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 80px;
  margin: 0 auto;
  margin-bottom: 100px;
  margin-top: 40px;
  flex-direction: row-reverse;
  justify-content: center;
`;

export const P = styled.p`
  margin-bottom: 0;
  font-family: Famtree;
  font-size: 14px;
  color: ${Colors.charcoal_grey_54};
  text-align: right;
`;
export const BlackTitleBold = styled.p`
  margin: 9px 0 0 0;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  color: ${Colors.charcoal_grey};
`;
export const BlackTitle = styled.p`
  margin: 9px 0 0 0;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  text-align: ${(props: { language: string }) =>
    props.language === "ar" ? "right" : "left"};
  color: ${Colors.charcoal_grey};
`;
export const ScaleTitle = styled.div`
  font-family: Famtree;
  font-size: 14px;
  color: rgba(52, 66, 71, 0.54);
  text-align: ${(props: { language: string }) =>
    props.language === "ar" ? "right" : "left"};
  margin-bottom: 0;
  width: 100px;
`;
export const TitleBold = styled.p`
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  text-align: ${(props: { language: string }) =>
    props.language === "ar" ? "right" : "left"};
  color: ${Colors.charcoal_grey_54};
  margin-bottom: 0;
`;
export const PContainer = styled.div`
  margin: 5px auto;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    width: 90%;
  }
`;
export const NumberStyle = styled.div`
  color: #344247;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;

  @media (max-width: 800px) {
    margin-left: unset;
  }
`;

export const QuestionStyle = styled.div`
  margin: ${(props: { language: string }) =>
    props.language === "ar" ? " 5px 10px 0 0" : " 5px 0px 0 8px"};
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};
  text-align: right;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;
export const QuestionTypeStyle = styled.div`
  margin: ${(props: { language: string }) =>
    props.language === "ar" ? " 5px 10px 0 0" : " 5px 0px 0 8px"};

  font-family: Famtree;
  font-size: 14px;

  color: ${Colors.charcoal_grey};

  @media (max-width: 900px) {
    font-size: 11px;
  }
`;
export const Icon = styled.div`
  margin: 5px 0 0 0;
`;
export const Option = styled.div`
  text-align: center;
  height: 50px;
  margin: 0 25px 0 13px;
  padding: 15px;
  opacity: 0.77;
  border-radius: 10px;
  font-family: Famtree;
  font-size: 14px;
  color: ${Colors.charcoal_grey};
  background-color: ${Colors.black_4};
  width: 50%;
`;
export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 900px) {
    width: 100%;
    align-items: center;
  }
`;
export const Hr = styled.hr`
  background-color: rgb(221, 221, 221);
  height: 0.7px;
  border: 0;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: ${(props: { language?: string }) =>
    props.language === "ar" ? "flex-start" : "flex-start"};
  padding: 20px;
  min-height: ${(props: { minHieght?: string; language?: string }) =>
    props.minHieght ? props.minHieght : "auto"};
  width: 100%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const OptionsStyle = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  width: 100%;
  align-items: center;
  margin: 5px 0;
`;
export const Types = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
export const ScaleContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
`;

export const TextBox = styled.div`
  width: 38%;
  padding: 15px;
  opacity: 0.77;
  border-radius: 10px;
  font-family: Famtree;
  font-size: 14px;
  color: ${Colors.charcoal_grey};
  background-color: ${Colors.black_4};
  margin-bottom: 10px;
  text-align: ${(props: { language: string }) =>
    props.language === "ar" ? "right" : "left"};

  @media (max-width: 600px) {
    min-width: 60%;
  }
`;

export const Text = styled.p`
  line-height: 1.8;
  margin-bottom: 0;
`;

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-bottom: 15px;
  .category-wrapper {
    background-color: #fff1ea;
    padding: 8px 15px;
    border-radius: 10px;
    opacity: 0.77;
    .category {
      margin-bottom: 0;
      color: #ee9c6c;
      font-size: 12px;
    }
  }
`;

export const ChecksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px;
  padding: 10px 20px 0 10px;
  border-top: 1px solid #e6e8e9;
  border-bottom: 1px solid #e6e8e9;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin: 15px;
  font-size: 14px;
  .check-icon {
    margin-left: 15px;
  }
`;

export const MultiOptionsSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const PointsLabel = styled.div`
  display: flex;
  flex-direction: row-reverse;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: rgba(52, 66, 71, 0.54);
  width: 60%;

  @media (max-width: 600px) {
    width: 32%;
  }
`;
