/** @format */

import * as React from "react";
import { SVGProps } from "react";

const LinkedinResponsive = (props: SVGProps<SVGSVGElement>) => (
  <a
    href="https://www.linkedin.com/company/tdree-app"
    target="_blank"
    rel="noreferrer"
  >
    <svg width={17} height={17} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M13.458 0H3.542A3.542 3.542 0 0 0 0 3.542v9.916A3.542 3.542 0 0 0 3.542 17h9.916A3.542 3.542 0 0 0 17 13.458V3.542A3.542 3.542 0 0 0 13.458 0zM5.667 13.458H3.542V5.667h2.125v7.791zm-1.063-8.69c-.684 0-1.24-.56-1.24-1.249 0-.69.556-1.25 1.24-1.25.684 0 1.24.56 1.24 1.25s-.555 1.25-1.24 1.25zm9.563 8.69h-2.125v-3.97c0-2.385-2.834-2.204-2.834 0v3.97H7.083V5.667h2.125v1.25c.99-1.832 4.959-1.967 4.959 1.754v4.787z" />
    </svg>
  </a>
);

export default LinkedinResponsive;
