/** @format */

import React from "react";
import Select from "react-select";
import Colors from "../../infrastructure/utils/Colors";
import { useTranslation } from "react-i18next";

type SelectProps = {
  onChange: any;
  className?: string;
  value?: any;
  options: {}[];
  placeholder: string;
  isMulti?: boolean;
  errorStyle?: any;
  groupedOptions?: any;
  customStyles?: any;
  isSearchable?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  width?: string;
  isDisabled?: boolean;
  name?: string;
  ref?: any;
};

const ReactSelect = ({
  onChange,
  width,
  value,
  options,
  placeholder,
  isMulti,
  errorStyle,
  groupedOptions,
  customStyles,
  isSearchable,
  isClearable,
  closeMenuOnSelect,
  isDisabled,
  ref,
  name,
  className,
}: SelectProps) => {
  const { i18n, t } = useTranslation();

  const defaultStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: errorStyle ? "#FCF3F5" : Colors.black_4,
      borderRadius: "10px",
      borderColor: errorStyle ? "red" : Colors.black_4,
      minHeight: "50px",
      margin: "13px 0",
      cursor: isDisabled ? "not-allowed" : "pointer",
      boxShadow: "none",
      fontFamily: "Famtree",
    }),
    groupHeading: (provided: any) => ({
      ...provided,
      fontFamily: "Famtree",
      fontWeight: "bold",
      color: "#000",
      fontSize: "15px",
      textTransform: "capitalize",
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
    }),
  };

  const defaultValue = (options: any, value: any) => {
    const optionArray = groupedOptions ? groupedOptions : options;
    return Array.isArray(value)
      ? optionArray.filter(function (o1: any) {
          return value.some(function (o2: any) {
            return o1.value === o2;
          });
        })
      : optionArray.find((option: any) => option.value === value);
  };

  return (
    <div
      style={{
        direction: i18n.language === "ar" ? "rtl" : "ltr",
        width: width ? width : "100%",
      }}
    >
      <Select
        className={className}
        styles={customStyles ? customStyles : defaultStyles}
        onChange={(value) => onChange(value)}
        options={options}
        placeholder={placeholder}
        isMulti={isMulti}
        isRtl={i18n.language === "ar"}
        value={defaultValue(options, value)}
        isSearchable={isSearchable}
        isClearable={isClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={isDisabled}
        noOptionsMessage={({ inputValue }) =>
          !inputValue ? t("no_optons_found") : t("no_optons_found")
        }
        ref={ref}
        name={name}
      />
    </div>
  );
};

export default ReactSelect;
