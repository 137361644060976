import * as React from "react";
import { SVGProps } from "react";

const TermsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      stroke="#344247"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0,0"
      fill="none"
      fillRule="evenodd"
    >
      <path d="M12.37 8.88h5.25m-11.24 0 .75.75 2.25-2.25m2.99 8.5h5.25m-11.24 0 .75.75 2.25-2.25" />
      <path d="M15 22c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7h6z" />
    </g>
  </svg>
);

export default TermsIcon;
