import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={26}
      height={26}
      viewBox="0 0 26 26"
    >
      <g
        transform="translate(1 1)"
        stroke="#44C874"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
      >
        <circle cx={12.118} cy={12.118} r={8.944} />
        <path
          strokeLinecap="round"
          d="M12.118.289v2.885M.289 12.118h2.885m8.944 11.83v-2.886m11.83-8.944h-2.886"
        />
        <circle cx={12.118} cy={12.118} r={2.02} strokeLinecap="round" />
      </g>
    </svg>
  );
}

export default SvgComponent;
