import React from "react";

function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="21"
      viewBox="0 0 19 21"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g stroke="#344247" strokeWidth="1.5" transform="translate(-1623 -163)">
          <g transform="translate(1415)">
            <g transform="translate(209.457 163.5)">
              <path d="M.75 19.21h5c.276 0 .5-.235.5-.526v-5.263c0-.582.447-1.053 1-1.053h2.5c.553 0 1 .471 1 1.053v5.263c0 .29.224.527.5.527h5c.276 0 .5-.236.5-.527V8.637c0-.967-.422-1.881-1.143-2.48L8.5.263 1.393 6.157A3.222 3.222 0 00.25 8.637v10.047c0 .29.224.527.5.527z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HomeIcon;
