/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "common/Table";
import {
  ColumnCell,
  MoreBtn,
  ColumnCellName,
  Title,
  LabelImag,
  LeftButtonWarpper,
} from "./style";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "common/Button";
import { Dropdown } from "common/Dropdown";
import { Menu, MenuProps, Typography } from "antd";
import { Category } from "infrastructure/interfaces/company-branches.interface";
import { ColumnsType } from "antd/lib/table";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { useQuery, useQueryClient } from "react-query";
import { fetchGetRequestsWithPagination } from "infrastructure/helpers/fetchServerRequests";
import { Header } from "../style";
import EditIcon from "assets/svg/edit-icon";
import DeleteIcon from "assets/svg/icon-delete";
import { Container, TablesWrapper } from "pages/style";
import { ButtonsContainer } from "common/Table/style";
import SearchInput from "common/Search";
import { Rate } from "common/Rate";
import { Helmet } from "react-helmet";
import { StyledP, Container as MenuContainer } from "common/Menu/style";
import Modal from "common/Modal";
import styled from "styled-components";
import { Input } from "common/Input";
import Colors from "infrastructure/utils/Colors";

const { Link, Text } = Typography;

const { useState } = React;

const CompanyBranch = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState<any>({
    id: false,
  });

  const [record, setRecord] = useState<{ status: { id: number }; id: number }>({
    status: { id: 0 },
    id: 0,
  });

  const [page, SetPage] = useState<number>(1);
  const [loading, setIsLoading] = useState<boolean>(false);

  const { data, isLoading } = useQuery(
    ["branchesListData", page, searchInputValue],
    () =>
      fetchGetRequestsWithPagination(
        `api/company/v1/branches?page=${page}&search=${searchInputValue}`
      ),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const showModal = (id: number) => {
    setIsModalVisible({ [id]: true });
  };

  const handleCancel = (id: any) => {
    setIsModalVisible({ [id]: false });
  };

  const handleDeleteBranch = (id: any) => {
    setIsLoading(true);
    axiosApiInstance
      .delete(`api/company/v1/branches/${id}`)
      .then(() => {
        queryClient.invalidateQueries("branchesListData");
        setIsModalVisible({ [id]: false });
        setIsLoading(false);
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("delete_branch_sucess")
        );
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          " ",
          err.response.data.message
        );
        setIsLoading(false);
      });
  };

  const handleSearchInputChange = (e: any) => {
    setSearchInputValue(e.target?.value);
  };

  const handlePagination = (page: number) => {
    SetPage(page);
  };

  const handleMenuClick = (record: any) => {
    setRecord(record);
  };

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: (
        <StyledMenu language={i18n.language === "ar" ? "ar" : "en"}>
          <MenuContainer
            onClick={() => navigate(`edit/${record.id}`)}
            language={i18n.language === "ar" ? "ar" : "en"}
          >
            <Trans>edit</Trans>
            {<EditIcon />}
          </MenuContainer>
        </StyledMenu>
      ),
    },
    {
      key: 2,
      label: (
        <StyledMenu language={i18n.language === "ar" ? "ar" : "en"}>
          <MenuContainer
            onClick={() => showModal(record.id)}
            language={i18n.language === "ar" ? "ar" : "en"}
          >
            <Trans>delete</Trans>
            {<DeleteIcon />}
          </MenuContainer>
        </StyledMenu>
      ),
    },
  ];

  const columns: ColumnsType<object> | undefined = [
    {
      title: <Trans>id</Trans>,
      key: "1",
      dataIndex: "id",
      render: (id: number) => {
        return <ColumnCell>{id}</ColumnCell>;
      },
    },
    {
      title: <Trans>branch_name</Trans>,
      dataIndex: "name",
      key: "اسم الفرع",
      render: (text: string, record: any) => {
        return (
          <ColumnCellName onClick={() => navigate(`/branches/${record.id}`)}>
            {text}
          </ColumnCellName>
        );
      },
    },
    {
      title: <Trans>branch_category</Trans>,
      key: "المجال",
      dataIndex: "category",
      responsive: ["md"],
      render: (category: Category) => <ColumnCell>{category.name}</ColumnCell>,
    },
    {
      title: <Trans>branch_details</Trans>,
      key: "تفاصيل العنوان",
      responsive: ["lg"],
      render: (record: {
        isWebsite: boolean;
        address: string;
        website: string;
      }) => <ColumnCell>{record.address}</ColumnCell>,
    },
    {
      title: <Trans>ratings</Trans>,
      responsive: ["lg"],
      render: (record: { rating: number }) => (
        <ColumnCell>
          <Rate count={1} defaultValue={1} disabled /> {record.rating}
        </ColumnCell>
      ),
    },
    {
      title: " ",
      render: (record) => (
        <Dropdown items={{ items }} onClick={() => handleMenuClick(record)}>
          <MoreBtn>...</MoreBtn>
        </Dropdown>
      ),
    },
  ];

  const handleFileDownload = async (e: {
    branches: { name: any };
    target: { files: (string | Blob)[] | any };
  }) => {
    const file_url = "api/company/v1/branches/import";
    const formData = new FormData();
    formData.append("branches", e.target.files[0]);
    await axiosApiInstance
      .post(file_url, formData)
      .then(() => {
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("branches_uploaded_successfully")
        );
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          "Error",
          err.response.data.message
        );
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar" ? "الفروع - تدري " : " Branches - Tdree"}
        </title>
      </Helmet>
      <Header language={i18n.language === "ar" ? "ar" : "en"}>
        <Title>
          <Trans>branches</Trans>
        </Title>
      </Header>

      <Container language={i18n.language === "ar" ? "ar" : "en"}>
        <ButtonsContainer language={i18n.language === "ar" ? "ar" : "en"}>
          <SearchInput
            handleChange={(e: any) => handleSearchInputChange(e)}
            placeholder={t("search_placeholder")}
          />
          <LeftButtonWarpper>
            <Button size={"large"} onClick={() => navigate("/branches/create")}>
              {t("new_branch")}
            </Button>
            <label htmlFor="xlsFile">
              <LabelImag language={i18n.language === "ar" ? "ar" : "en"}>
                <Input
                  type="file"
                  name="branches"
                  id="xlsFile"
                  accept=".xls, .xlsx"
                  style={{ display: "none" }}
                  onChange={handleFileDownload}
                />
              </LabelImag>
            </label>
            <Link href="https://tdree-assets.s3.me-south-1.amazonaws.com/branches.xlsx">
              <Text
                style={{
                  fontFamily: "Famtree",
                  fontSize: "12px",
                  color: Colors.dark_mint,
                }}
              >
                <Trans>file_template</Trans>
              </Text>
            </Link>
          </LeftButtonWarpper>
        </ButtonsContainer>

        <TablesWrapper>
          <CustomTable
            columns={columns}
            data={data?.branches}
            loading={isLoading}
            pagination={{
              position: [i18n.language === "ar" ? "bottomRight" : "bottomLeft"],
              total: data?.pagination.total,
              defaultCurrent: data?.pagination.current_page || 1,
              onChange: (page: number) => handlePagination(page),
            }}
          />
        </TablesWrapper>
      </Container>

      <Modal
        title={<StyledP>{<Trans>delete_branch</Trans>}</StyledP>}
        visible={isModalVisible[record.id]}
        onOk={() => handleDeleteBranch(Object.keys(isModalVisible)[0])}
        onCancel={() => handleCancel(Object.keys(isModalVisible)[0])}
        loading={loading}
      >
        {<Trans>branch_modal_delete_message</Trans>}
      </Modal>
    </>
  );
};

export default CompanyBranch;

//@ts-ignore
const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    width: 140px !important;
    box-shadow: 3px 3px 5px 6px #ccc;
    direction: ${(props: { language?: string }) =>
      props.language === "ar" ? "ltr" : "rtl"};
    margin-top: 15px;
  }
`;
