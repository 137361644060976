/** @format */

import React, { useState } from "react";
import { StyledContainer } from "../style";
import { UserForm } from "./form";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";

export const ConsultationForm = () => {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState<boolean>(false);

  const { handleSubmit, register, errors, formState, reset } = useForm({
    mode: "onBlur",
    criteriaMode: "all",
  });

  const onSubmit = (e: { name: string; mobile: string; work: string }) => {
    setIsloading(true);
    axiosApiInstance
      .post("api/temp/consultation", {
        name: e.name,
        mobile: e.mobile,
        work: e.work,
      })
      .then((res) => {
        showNotification(
          NotificationStatus.Success,
          t("done"),
          res.data.message
        );
        setIsloading(false);
        reset({
          name: "",
          mobile: "",
          work: "",
        });
      })
      .catch((error) => {
        setIsloading(false);
        showNotification(
          NotificationStatus.Error,
          t("error"),
          error?.response.data.message
        );
      });
  };

  return (
    <>
      <StyledContainer>
        <UserForm
          onSubmit={handleSubmit(onSubmit)}
          errors={errors}
          register={register}
          formState={formState}
          loading={isLoading}
        />
      </StyledContainer>
    </>
  );
};
