/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useQuery } from "react-query";
import { Trans, useTranslation } from "react-i18next";
import { fetchGetRequestsWithPagination } from "infrastructure/helpers/fetchServerRequests";
import { Header, ColumnCell, ColumnCellName, MoreBtn, Title } from "../style";
import CustomTable from "common/Table";
import { ColumnsType } from "antd/lib/table";
import { timeStampToDate } from "infrastructure/helpers/timeStampToDate";
import Tag from "common/Tag";
import { Dropdown } from "common/Dropdown";
import { Divider, Menu, MenuProps } from "antd";
import { useReactToPrint } from "react-to-print";
import {
  BillContainer,
  HeaderWrapper,
  ContentWrapper,
  QRWrapper,
  Pargraph,
  LogoWrappper,
  CompanyName,
  Th,
  Td,
  Tr,
  Payment,
  Payments,
  Table,
  SmallPargraph,
  Footer,
  Bill,
  SAR,
  PromoPargraph,
  AmountValue,
} from "./style";
import Logo from "assets/svg/logo";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { Container, TablesWrapper } from "pages/style";
import { ButtonsContainer } from "common/Table/style";
import SearchInput from "common/Search";
import { Helmet } from "react-helmet";
import { StyledP, Container as MenuContainer } from "common/Menu/style";
import styled from "styled-components";
import Modal from "common/Modal";
import Colors from "infrastructure/utils/Colors";
import QRCode from "react-qr-code";

export const Invoices = () => {
  const { t, i18n } = useTranslation();
  const componentRef: React.MutableRefObject<undefined> | any = useRef();

  const handleAfterPrint = React.useCallback(() => {}, [componentRef.current]);

  const handlePrint: ((e) => void) | undefined | any = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    onAfterPrint: handleAfterPrint,
  });

  const [page, SetPage] = useState<number>(1);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [billId, setBillId] = useState<number>();
  const [isModalVisible, setIsModalVisible] = useState<any>({
    id: false,
  });

  const [record, setRecord] = useState<{ status: { id: number }; id: number }>({
    status: { id: 0 },
    id: 0,
  });

  const { data, isLoading } = useQuery(
    ["invoices", page, searchInputValue],
    () =>
      fetchGetRequestsWithPagination(
        `api/company/v1/transactions?page=${page}&search=${searchInputValue}`
      ),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  useEffect(() => {
    handlePrint();
  }, [billId]);

  const handlePagination = (page: number) => {
    SetPage(page);
  };

  const showModal = (id: number) => {
    setIsModalVisible({ [id]: true });
  };

  const handleCancel = (id: any) => {
    setIsModalVisible({ [id]: false });
  };

  const handleSendingTheBill = (id: number) => {
    axiosApiInstance
      .post("/api/company/v1/invoice/email", { id: id })
      .then(() => {
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
        setIsModalVisible(false);
      })
      .catch((err) => {
        setIsModalVisible(false);
        showNotification(
          NotificationStatus.Error,
          " ",
          err.response.data.message
        );
      });
  };

  const handleSearchInputChange = (e: any) => {
    setSearchInputValue(e.target?.value);
  };

  const handleMenuClick = (record: any) => {
    setRecord(record);
  };

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: (
        <StyledMenu>
          <MenuContainer>
            <a onClick={() => setBillId(record.id)}>
              <Trans>download_bill</Trans>
            </a>
          </MenuContainer>
        </StyledMenu>
      ),
    },
    {
      key: 2,
      label: (
        <StyledMenu>
          <MenuContainer>
            <a onClick={() => showModal(record.id)}>
              <Trans>send_bill</Trans>
            </a>
          </MenuContainer>
        </StyledMenu>
      ),
    },
  ];

  const columns: ColumnsType<object> | undefined = [
    {
      title: <Trans>release_date</Trans>,
      key: "تاريخ الإصدار",
      render: (record: { createdAt: string }) => (
        <ColumnCell>{timeStampToDate(record.createdAt)}</ColumnCell>
      ),
    },
    {
      title: <Trans>mission</Trans>,
      key: "المهمة",
      render: (record: any) => (
        <ColumnCellName>{record.job.name}</ColumnCellName>
      ),
    },
    {
      title: <Trans>value</Trans>,
      key: "القيمة",
      responsive: ["lg"],
      render: (record: { amount: number }) => (
        <ColumnCell>
          <AmountValue>{record.amount}</AmountValue>

          <SAR>
            <Trans>sar</Trans>
          </SAR>
        </ColumnCell>
      ),
    },
    {
      title: " ",
      key: " ",
      render: (record: any) => (
        <ColumnCell>
          {record.status?.id === 1 ? (
            <Tag color="rgba(52, 66, 71, 0.1)">{record.status?.name}</Tag>
          ) : record.status?.id === 2 ? (
            <Tag color="blue">{record.status?.name}</Tag>
          ) : (
            <Tag color="red">{record.status?.name}</Tag>
          )}
        </ColumnCell>
      ),
    },
    {
      title: " ",
      render: (record: any) => (
        <Dropdown items={{ items }}>
          <MoreBtn onClick={() => handleMenuClick(record)}>...</MoreBtn>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar" ? "استهلاكات الرصيد" : "Invoices - Tdree"}
        </title>
      </Helmet>
      <Header language={i18n.language === "ar" ? "ar" : "en"}>
        <Title>
          <Trans>balance_consumptions</Trans>
        </Title>
      </Header>

      <Container>
        <ButtonsContainer language={i18n.language === "ar" ? "ar" : "en"}>
          <SearchInput
            handleChange={handleSearchInputChange}
            placeholder={t("search_placeholder")}
          />
        </ButtonsContainer>
        <TablesWrapper>
          <CustomTable
            columns={columns}
            data={data?.transactions}
            loading={isLoading}
            pagination={{
              position: [i18n.language === "ar" ? "bottomRight" : "bottomLeft"],
              total: data?.pagination.total,
              defaultCurrent: data?.pagination.current_page || 1,
              onChange: (page: number) => handlePagination(page),
            }}
          />
        </TablesWrapper>
      </Container>

      <Bill>
        {data?.transactions.map((item: any) => {
          return (
            item.id === billId && (
              <div key={item.id}>
                <BillContainer ref={componentRef}>
                  <HeaderWrapper>
                    <LogoWrappper>
                      <Logo />
                    </LogoWrappper>
                    <div>
                      <Pargraph
                        fontWeight="normal"
                        color={Colors.dark_mint}
                        fontSize="14px"
                      >
                        <Trans>vat_invoice</Trans>
                      </Pargraph>
                    </div>
                  </HeaderWrapper>
                  <HeaderWrapper>
                    <LogoWrappper>
                      <Pargraph color="#000" fontWeight="bold" fontSize="14px">
                        <Trans>tdree</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>tdree_company_name</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>saudi_arabia_riyadh</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        www.tdree.com
                      </Pargraph>
                    </LogoWrappper>

                    {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Pargraph color='#000' fontSize='10px'>
                        <Trans>commercial_number</Trans>
                      </Pargraph>
                      <Pargraph color='#000' fontSize='10px'>
                        {'1010487662'}
                      </Pargraph>
                      <Pargraph color='#000' fontSize='10px'>
                        <Trans>tax_number</Trans>
                      </Pargraph>
                      <Pargraph color='#000' fontSize='10px'>
                        <span>310769921800003</span>
                      </Pargraph>
                    </div> */}
                  </HeaderWrapper>

                  <Divider
                    style={{
                      backgroundColor: Colors.dark_mint,
                      border: "4px solid #44c874",
                    }}
                  />

                  <ContentWrapper>
                    <CompanyName>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>invoice_to</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        {item?.company?.name}
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        {item?.company?.address}
                      </Pargraph>
                    </CompanyName>
                    {/* 
                    <CompanyName>
                      <Pargraph fontSize='10px' color='#000'>
                        <Trans>commercial_number</Trans>
                      </Pargraph>
                      <Pargraph fontSize='10px' color='#000'>
                        {item?.company?.commercial_number}
                      </Pargraph>
                      <Pargraph fontSize='10px' color='#000'>
                        <Trans>tax_number</Trans>
                      </Pargraph>
                      <Pargraph fontSize='10px' color='#000'>
                        {item?.company?.tax_number}
                      </Pargraph>
                    </CompanyName> */}
                  </ContentWrapper>

                  <Divider
                    style={{
                      backgroundColor: Colors.dark_mint,
                      border: "4px solid #44c874",
                    }}
                  />

                  <ContentWrapper>
                    <CompanyName>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>invoice_purpose</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>invoice_pdf_purpose</Trans>
                      </Pargraph>
                    </CompanyName>

                    <CompanyName>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>invoice_number</Trans>
                      </Pargraph>

                      <Pargraph fontSize="10px" color="#000">
                        {item?.id}
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>createdAt_wallet</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        {timeStampToDate(item?.createdAt)}
                      </Pargraph>
                    </CompanyName>
                  </ContentWrapper>

                  <Table>
                    <tbody>
                      <Tr>
                        <Th>
                          <Trans>description</Trans>
                        </Th>

                        <Th>
                          <Trans>shopper_price</Trans>
                        </Th>

                        <Th>
                          <Trans>quantity</Trans>
                        </Th>

                        <Th>
                          <Trans
                            values={{
                              vatPercentage: item?.job?.vat_percentage,
                            }}
                          >
                            total_amount
                          </Trans>
                        </Th>
                      </Tr>

                      <Tr>
                        <Td>
                          <Pargraph
                            fontSize="10px"
                            color="#000"
                            style={{ margin: "0" }}
                          >
                            <Trans>fees_to_increase_secret_shopper</Trans>
                          </Pargraph>
                        </Td>

                        <Td>{item?.job?.shopper_price}</Td>

                        <Td>{item?.job?.shoppers_count}</Td>

                        <Td>
                          {item?.job?.shoppers_price + item?.job?.vat_amount}
                        </Td>
                      </Tr>
                      {/* extra_pay  */}
                      {item.extraPay.extraPay && (
                        <Tr>
                          <Td>عناصر اضافية</Td>

                          <Td>{item?.extraPay?.price}</Td>

                          <Td>{item?.extraPay?.count}</Td>

                          <Td>{item?.extraPay?.total}</Td>
                        </Tr>
                      )}
                      {/* discount  */}
                      {item.hasPromoCode ? (
                        <Tr>
                          <Td>
                            <Pargraph
                              fontWeight="bold"
                              fontSize="8px"
                              color="#000"
                              style={{ display: "flex", margin: "0" }}
                            >
                              <Trans>promocode</Trans>
                              <span
                                style={{
                                  margin: "0 3px",
                                }}
                              >
                                ({item?.promocode?.name.split(1)})
                              </span>
                              <span
                                style={{
                                  margin: "0 3px",
                                }}
                              >
                                %{item?.promocode?.percentage}
                              </span>
                            </Pargraph>
                          </Td>

                          <Td>
                            {item?.promocode?.totalBeforePromo -
                              item?.promocode?.totalAfterPromo}
                          </Td>
                        </Tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </Table>

                  <ContentWrapper>
                    <Payments>
                      <Payment>
                        <SmallPargraph>
                          <Trans>total</Trans>
                        </SmallPargraph>
                        <SmallPargraph>
                          {item?.job?.shoppers_price}
                        </SmallPargraph>
                      </Payment>

                      {/* <Payment>
                        <SmallPargraph>
                          <Trans
                            values={{
                              vatPercentage: item?.job?.vat_percentage,
                            }}
                          >
                            vat
                          </Trans>
                        </SmallPargraph>
                        <SmallPargraph>{item?.job?.vat_amount}</SmallPargraph>
                      </Payment> */}

                      {item.extraPay.extraPay && (
                        <Payment>
                          <SmallPargraph>
                            <Trans>extra_items</Trans>
                          </SmallPargraph>
                          <SmallPargraph>
                            {item?.job?.extra_items_price}
                          </SmallPargraph>
                        </Payment>
                      )}

                      {item.hasPromoCode && (
                        <Payment>
                          <PromoPargraph>
                            <Trans
                              values={{
                                promocode: item?.promocode?.percentage,
                              }}
                            >
                              discount
                            </Trans>
                          </PromoPargraph>
                          <PromoPargraph>
                            -{" "}
                            {item?.promocode?.totalBeforePromo -
                              item?.promocode?.totalAfterPromo}
                          </PromoPargraph>
                        </Payment>
                      )}

                      <Divider style={{ margin: "0px" }} />

                      <Payment>
                        <SmallPargraph>
                          <Trans
                            values={{
                              vatPercentage: item?.job?.vat_percentage,
                            }}
                          >
                            اجمالي المبلغ المخصوم من المحفظة
                          </Trans>
                        </SmallPargraph>
                        <SmallPargraph>{item?.amount}</SmallPargraph>
                      </Payment>
                    </Payments>
                  </ContentWrapper>

                  <QRWrapper>
                    <div
                      style={{
                        height: "auto",
                        margin: "10 auto",
                        maxWidth: 200,
                        width: "100%",
                      }}
                    >
                      <QRCode
                        size={256}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        value={`${process.env.REACT_APP_URL}/invoices/${item.reference}`}
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                    <Pargraph fontSize="10px" color="#000">
                      <Trans>qr_code_scan</Trans>
                    </Pargraph>
                  </QRWrapper>

                  <Footer>
                    {/* <Pargraph fontSize='10px' color='#000'>
                      <Trans>view_balance_consumption_details</Trans>
                    </Pargraph> */}
                    <Pargraph fontSize="10px" color="#000">
                      <Trans>thank_you</Trans>
                    </Pargraph>
                  </Footer>
                </BillContainer>
              </div>
            )
          );
        })}
      </Bill>

      <Modal
        title={<StyledP>{<Trans>send_bill</Trans>}</StyledP>}
        visible={isModalVisible[record.id]}
        onOk={() => handleSendingTheBill(record.id)}
        onCancel={() => handleCancel(record.id)}
      >
        <p>{<Trans>send_bill_message</Trans>}</p>
      </Modal>
    </>
  );
};

//@ts-ignore
const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    width: 140px !important;
    box-shadow: 3px 3px 5px 6px #ccc;
  }
`;
