/** @format */

import React from "react";
import WebView from "./WebView";
import MobileView from "./MobileView";
import { useMediaQuery } from "react-responsive";

const CompanyBranch = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? <MobileView /> : <WebView />;
};

export default CompanyBranch;
