/** @format */
import { Divider } from "antd";
import Logo from "assets/svg/logo";
import { timeStampToDate } from "infrastructure/helpers/timeStampToDate";
import Colors from "infrastructure/utils/Colors";
import { axiosApiInstance } from "infrastructure/utils/api";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  BillContainer,
  HeaderWrapper,
  LogoWrappper,
  Pargraph,
  ContentWrapper,
  CompanyName,
  Tr,
  Th,
  Td,
  Payments,
  Payment,
  SmallPargraph,
  Footer,
  Table,
  PromoPargraph,
} from "./style";

const InvoiceQr = () => {
  const { id }: any = useParams();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    axiosApiInstance.get(`api/transaction/${id}`).then((response) => {
      setData(response.data.transaction);
    });
  }, []);

  return (
    data && (
      <div
        style={{
          width: "550px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          height: "100%",
        }}
      >
        <BillContainer>
          <HeaderWrapper>
            <LogoWrappper>
              <Logo />
            </LogoWrappper>
            <div>
              <Pargraph
                fontWeight="normal"
                color={Colors.dark_mint}
                fontSize="14px"
              >
                <Trans>vat_invoice</Trans>
              </Pargraph>
            </div>
          </HeaderWrapper>
          <HeaderWrapper>
            <LogoWrappper>
              <Pargraph color="#000" fontWeight="bold" fontSize="14px">
                <Trans>tdree</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                <Trans>tdree_company_name</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                <Trans>saudi_arabia_riyadh</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                www.tdree.com
              </Pargraph>
            </LogoWrappper>

            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Pargraph color='#000' fontSize='10px'>
                        <Trans>commercial_number</Trans>
                      </Pargraph>
                      <Pargraph color='#000' fontSize='10px'>
                        {'1010487662'}
                      </Pargraph>
                      <Pargraph color='#000' fontSize='10px'>
                        <Trans>tax_number</Trans>
                      </Pargraph>
                      <Pargraph color='#000' fontSize='10px'>
                        <span>310769921800003</span>
                      </Pargraph>
                    </div> */}
          </HeaderWrapper>

          <Divider
            style={{
              backgroundColor: Colors.dark_mint,
              border: "4px solid #44c874",
            }}
          />

          <ContentWrapper>
            <CompanyName>
              <Pargraph fontSize="10px" color="#000">
                <Trans>invoice_to</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                {data?.company?.name}
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                {data?.company?.address}
              </Pargraph>
            </CompanyName>
          </ContentWrapper>

          <Divider
            style={{
              backgroundColor: Colors.dark_mint,
              border: "4px solid #44c874",
            }}
          />

          <ContentWrapper>
            <CompanyName>
              <Pargraph fontSize="10px" color="#000">
                <Trans>invoice_purpose</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                <Trans>invoice_pdf_purpose</Trans>
              </Pargraph>
            </CompanyName>

            <CompanyName>
              <Pargraph fontSize="10px" color="#000">
                <Trans>invoice_number</Trans>
              </Pargraph>

              <Pargraph fontSize="10px" color="#000">
                {data?.id}
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                <Trans>createdAt_wallet</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                {timeStampToDate(data?.createdAt)}
              </Pargraph>
            </CompanyName>
          </ContentWrapper>

          <Table>
            <tbody>
              <Tr>
                <Th>
                  <Trans>description</Trans>
                </Th>

                <Th>
                  <Trans>shopper_price</Trans>
                </Th>

                <Th>
                  <Trans>quantity</Trans>
                </Th>

                <Th>
                  <Trans
                    values={{
                      vatPercentage: data?.job?.vat_percentage,
                    }}
                  >
                    total_amount
                  </Trans>
                </Th>
              </Tr>

              <Tr>
                <Td>
                  <Pargraph
                    fontSize="10px"
                    color="#000"
                    style={{ margin: "0" }}
                  >
                    <Trans>fees_to_increase_secret_shopper</Trans>
                  </Pargraph>
                </Td>

                <Td>{data?.job?.shopper_price}</Td>

                <Td>{data?.job?.shoppers_count}</Td>

                <Td>{data?.job?.shoppers_price + data?.job?.vat_amount}</Td>
              </Tr>
              {/* extra_pay  */}
              {data.extraPay.extraPay && (
                <Tr>
                  <Td>عناصر اضافية</Td>

                  <Td>{data?.extraPay?.price}</Td>

                  <Td>{data?.extraPay?.count}</Td>

                  <Td>{data?.extraPay?.total}</Td>
                </Tr>
              )}
              {/* discount  */}
              {data.hasPromoCode ? (
                <Tr>
                  <Td>
                    <Pargraph
                      fontWeight="bold"
                      fontSize="8px"
                      color="#000"
                      style={{ display: "flex", margin: "0" }}
                    >
                      <Trans>promocode</Trans>
                      <span
                        style={{
                          margin: "0 3px",
                        }}
                      >
                        ({data?.promocode?.name.split(1)})
                      </span>
                      <span
                        style={{
                          margin: "0 3px",
                        }}
                      >
                        %{data?.promocode?.percentage}
                      </span>
                    </Pargraph>
                  </Td>

                  <Td>
                    {data?.promocode?.totalBeforePromo -
                      data?.promocode?.totalAfterPromo}
                  </Td>
                </Tr>
              ) : (
                ""
              )}
            </tbody>
          </Table>

          <ContentWrapper>
            <Payments>
              <Payment>
                <SmallPargraph>
                  <Trans>total</Trans>
                </SmallPargraph>
                <SmallPargraph>{data?.job?.shoppers_price}</SmallPargraph>
              </Payment>

              {data.extraPay.extraPay && (
                <Payment>
                  <SmallPargraph>
                    <Trans>extra_items</Trans>
                  </SmallPargraph>
                  <SmallPargraph>{data?.job?.extra_items_price}</SmallPargraph>
                </Payment>
              )}

              {data.hasPromoCode && (
                <Payment>
                  <PromoPargraph>
                    <Trans
                      values={{
                        promocode: data?.promocode?.percentage,
                      }}
                    >
                      discount
                    </Trans>
                  </PromoPargraph>
                  <PromoPargraph>
                    -{" "}
                    {data?.promocode?.totalBeforePromo -
                      data?.promocode?.totalAfterPromo}
                  </PromoPargraph>
                </Payment>
              )}

              <Divider style={{ margin: "0px" }} />

              <Payment>
                <SmallPargraph>
                  <Trans
                    values={{
                      vatPercentage: data?.job?.vat_percentage,
                    }}
                  >
                    اجمالي المبلغ المخصوم من المحفظة
                  </Trans>
                </SmallPargraph>
                <SmallPargraph>{data?.amount}</SmallPargraph>
              </Payment>
            </Payments>
          </ContentWrapper>

          <Footer>
            <Pargraph fontSize="10px" color="#000">
              <Trans>thank_you</Trans>
            </Pargraph>
          </Footer>
        </BillContainer>
      </div>
    )
  );
};

export default InvoiceQr;
