/** @format */

import * as React from "react";

const WhatsappIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => (
  <a
    href="https://wa.me/message/BE25YFNFGSI3P1"
    target="_blank"
    rel="noreferrer"
  >
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M32 0v32H0V0h32z" opacity={0.58} />
        <path
          d="M13.733 2.853c-5.573.92-10.04 5.44-10.906 11.014-.507 3.226.16 6.28 1.613 8.8L3.253 27.08c-.266 1 .654 1.907 1.64 1.627l4.347-1.2a13.272 13.272 0 0 0 6.747 1.826c7.52 0 13.76-6.626 13.32-14.12a13.34 13.34 0 0 0-15.574-12.36zm8.32 18.787c-.333.36-.693.627-1.093.787-.4.173-.84.253-1.307.253-.68 0-1.413-.16-2.173-.493a12.212 12.212 0 0 1-2.293-1.32A19.266 19.266 0 0 1 13 19a19.069 19.069 0 0 1-1.867-2.187 11.73 11.73 0 0 1-1.306-2.28c-.32-.76-.48-1.493-.48-2.186 0-.454.08-.894.24-1.294.16-.413.413-.786.773-1.12.427-.426.893-.626 1.387-.626.186 0 .373.04.546.12.174.08.334.2.454.373l1.546 2.187c.12.173.214.32.267.466a.97.97 0 0 1 .093.414c0 .16-.053.32-.146.48-.094.16-.214.32-.374.48l-.506.533a.342.342 0 0 0-.107.267.67.67 0 0 0 .027.16.92.92 0 0 1 .053.133c.12.227.333.507.627.853a17.976 17.976 0 0 0 2.04 2.04c.346.294.64.494.866.614.04.013.08.04.12.053a.366.366 0 0 0 .174.027c.12 0 .2-.04.28-.12l.506-.507a2.18 2.18 0 0 1 .48-.373.918.918 0 0 1 .48-.147c.134 0 .267.027.414.093.146.067.306.147.466.267l2.214 1.573c.173.12.293.267.373.427.067.173.107.333.107.52-.08.227-.134.48-.24.72-.12.24-.267.467-.454.68z"
          fill="#000"
        />
      </g>
    </svg>
  </a>
);

export default WhatsappIcon;
