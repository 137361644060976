import React from 'react';
import { AnsweredOptions, AnsweredQuestionWrapper } from './style';
import { Checkbox } from 'common/Checkbox';

const AnsweredQuestions = ({ item, option }: any) => {
  const answered = item?.question?.answers.find(
    (element: string) => element === option
  );
  return (
    <AnsweredQuestionWrapper>
      {answered ? (
        <AnsweredOptions answer='yes'>
          <Checkbox checked /> {option}
        </AnsweredOptions>
      ) : (
        <AnsweredOptions answer='no'>
          <Checkbox disabled /> {option}
        </AnsweredOptions>
      )}
    </AnsweredQuestionWrapper>
  );
};

export default AnsweredQuestions;
