/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import { StyledPicker } from "./style";

const DateTimePicker = ({
  onChange,
  value,
  errorStyle,
  defaultValue,
  disabledDate,
  disabledTime,
}: {
  value?: any;
  onChange: (data: any, dateString: any) => void;
  errorStyle: any;
  defaultValue: any;
  disabledDate: any;
  disabledTime: any;
}) => {
  const { i18n } = useTranslation();
  return (
    <StyledPicker
      format="YYYY-MM-DD HH:mm"
      showTime={{ format: "HH:mm" }}
      onChange={onChange}
      value={value}
      style={errorStyle || undefined || null}
      defaultValue={defaultValue}
      disabledDate={disabledDate}
      disabledTime={disabledTime}
      placeholder={
        i18n.language === "ar"
          ? ["بدء التقييم", "انتهاء التقييم"]
          : ["Start Evaluation", "End Evaluation"]
      }
    />
  );
};

DateTimePicker.defaultProps = {
  errorStyle: undefined,
  disabledTime: undefined,
};

export default DateTimePicker;
