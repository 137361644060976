import { Steps } from "antd";
import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

export const StyledSteps = styled(Steps)`
  display: flex;
  flex-direction: row;

  &.ant-steps {
    width: 50%;
    direction: ${(props: { language: string }) =>
      props.language === "ar" ? "rtl" : "ltr"};

    @media (max-width: 900px) {
      width: 97%;
    }
  }

  @media (max-width: 900px) {
    &.ant-steps-vertical {
      display: flex;
      flex-direction: row;
    }

    &.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
      float: unset;
    }
  }

  .ant-steps-item-icon {
    background-color: ${Colors.dark_mint};
    color: ${Colors.charcoal_grey};
    border-color: ${Colors.dark_mint};
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: ${Colors.dark_mint};
    color: ${Colors.white};
    border-color: ${Colors.dark_mint};
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${Colors.white};
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: ${Colors.gray};
    color: ${Colors.charcoal_grey};
    border-color: ${Colors.gray};
  }

  .ant-steps-icon {
    font-family: Famtree;
    font-size: 18px;
    font-weight: bold;
  }

  .ant-steps-item-title {
    font-family: Famtree;
    font-size: 16px;
    font-weight: bold;
    color: ${Colors.charcoal_grey};

    @media (max-width: 900px) {
      font-size: 12px;
    }
  }

  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: ${Colors.charcoal_grey};
  }

  .ant-steps-item-title::after {
    right: 130%;
  }
  &.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item:last-child
    .ant-steps-item-title {
    padding-right: 15px;
  }

  .ant-steps-item-description {
    margin-top: 10px;
    font-family: Famtree;
    font-size: 10px;
    color: ${Colors.charcoal_grey_54};

    @media (max-width: 900px) {
      font-size: 9px;
    }
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    color: ${Colors.charcoal_grey_54};
  }

  &.steps-action {
    width: 700px;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: ${Colors.dark_mint};
  }

  &.ant-steps-horizontal:not(.ant-steps-label-vertical)
    .ant-steps-item-description {
    max-width: 160px;
  }

  &.ant-steps-vertical
    > .ant-steps-item:not(:last-child)
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    @media (max-width: 600px) {
      transform: rotate(90deg) scaleX(-1);

      top: -30px;
      left: 44px;
    }
  }
`;
