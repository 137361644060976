/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "common/Button";
import { Input } from "common/Input";
import { Error, Label } from "../../style";
import {
  ButtonContainer,
  Form,
  InputsContainer,
  TitleContainer,
  Title,
} from "../style";
import { FormProps } from "infrastructure/interfaces/forms.interface";

export const ForgotPasswordForm: React.FC<FormProps> = ({
  errors,
  register,
  formState,
  onSubmit,
  loading,
}): JSX.Element => {
  const { i18n } = useTranslation();
  return (
    <div
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
        marginTop: "120px",
      }}
    >
      <Form
        onSubmit={onSubmit}
        noValidate
        lang={i18n.language === "ar" ? "ar" : "en"}
      >
        <TitleContainer>
          <Title>
            <Trans>forgot_password</Trans>
          </Title>
        </TitleContainer>
        <InputsContainer language={i18n.language === "ar" ? "ar" : "en"}>
          <Label lang={i18n.language === "ar" ? "ar" : "en"}>
            <Trans>email</Trans>
          </Label>
          <Input
            name="email"
            type="text"
            login
            ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
            width="login"
            errorName={errors.email}
          />
          {errors?.email?.types?.required && (
            <Error>
              <Trans>email_required</Trans>
            </Error>
          )}
          {errors?.email?.types?.pattern && (
            <Error>
              <Trans>patternـisـnotـcorrect</Trans>
            </Error>
          )}
        </InputsContainer>
        <ButtonContainer lang={i18n.language === "ar" ? "ar" : "en"}>
          <Button
            size={"large"}
            disabled={formState.isSubmitting}
            onClick={onSubmit}
            loading={loading}
          >
            <Trans>send</Trans>
          </Button>
        </ButtonContainer>
      </Form>
    </div>
  );
};
