import * as React from "react";

function MultiChoiceIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={19}
      viewBox="0 0 19 19"
    >
      <g
        stroke="#FFBDAE"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path
          strokeLinejoin="round"
          d="M3.71 1.25h12.5a2 2 0 012 2v12.5a2 2 0 01-2 2H3.71a2 2 0 01-2-2V3.25a2 2 0 012-2z"
        />
        <path d="M6.46 9.336l2.612 2.612L13.52 7.5" />
      </g>
    </svg>
  );
}

export default MultiChoiceIcon;
