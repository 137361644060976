import styled from "styled-components";
import { Empty as AntDEmpty } from "antd";

export const StyledEmpty = styled(AntDEmpty)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props: { className: string }) =>
    props.className === "summary" ? "85%" : "100%"};
  margin: 10px 0 0 0;
  padding: 0 10px;
`;
