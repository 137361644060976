/** @format */

import React from "react";
import BlueDot from "assets/svg/blue-dot-ic";
import BranchIcon from "assets/svg/branches-ic";
import GreenDot from "assets/svg/green-dot-ic";
import YellowDot from "assets/svg/oran-dot-ic";
import RedDot from "assets/svg/red-dot-ic";
import LocationIcon from "assets/svg/icon-location";

import Colors from "infrastructure/utils/Colors";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";

import BarChart from "components/Charts/Bar";
import PieChart from "components/Charts/PieChart";
import AvergeIcon from "assets/svg/no-averge-icon";
import { Button } from "common/Button";
import { useNavigate } from "react-router-dom";

type Props = {
  leastUsed?: {
    name: string;
    jobsCount: number;
  };
  mostUsed?: {
    name: string;
    jobsCount: number;
  };
  inProgress?: number;
  fully?: number;
  done?: number;
  expired?: number;
  partially?: number;
  donePercentage?: number;
  startedPercentage?: number;
  acceptedPercentage?: number;
  withdrawPercentage?: number;
  branches?: Array<{
    id: number;
    name: string;
  }>;
  title?: string;
  monthCount?: any;
  data?: any;
  width?: string;
  count?: number;
  visitsCount?: number;
};

type BranchesDataProps = {
  data: Array<{}>;
  icon: any;
  title: string;
  type: string;
};

export const BranchesCount = ({ branches }: { branches: Props }) => {
  const { i18n } = useTranslation();

  return (
    <CounterBox language={i18n.language === "ar" ? "ar" : "en"}>
      <>
        <LocationIcon
          style={
            i18n.language === "ar"
              ? { marginLeft: "15px" }
              : { marginLeft: "unset" }
          }
        />
      </>
      <InfoWrapper>
        <Title
          color={Colors.charcoal_grey_54}
          language={i18n.language === "ar" ? "ar" : "unset"}
        >
          <Trans>branches_count</Trans>
        </Title>
        <TemplateData
          fontSize="24px"
          language={i18n.language === "ar" ? "ar" : "unset"}
        >
          <CountText>{`${branches?.count}`}</CountText>
          <Trans>branch</Trans>
        </TemplateData>
      </InfoWrapper>
    </CounterBox>
  );
};

export const BranchRate = ({ data, icon, title, type }: BranchesDataProps) => {
  const { i18n } = useTranslation();
  return (
    <RectangleContainer language={i18n.language === "ar" ? "ar" : "en"}>
      <Header>
        <Title color={"#344247"} fontSize={"16px"} fontWeight="bold">
          <Trans>{title}</Trans>
        </Title>
        {icon}
      </Header>
      {data && data.length ? (
        data.map((branch: any, i: number) => {
          return (
            <BranchVisit key={branch.id}>
              <div className="right-info">
                <div className="branch-order">
                  <span className="order">{i + 1}</span>
                </div>
                <p className="branch-name">{branch.name}</p>
              </div>
              {type === "visit" ? (
                <div className="left-info">
                  {branch.visits_count > 0 ? (
                    <>
                      <p className="branch-visit">
                        <span className="branch-visit-count">
                          {branch.visits_count}
                        </span>
                        {branch.visits_count === 1 ||
                        branch.visits_count < 10 ? (
                          <Trans>visits</Trans>
                        ) : (
                          <Trans>visit</Trans>
                        )}
                      </p>
                      <div className="vertical-line"></div>
                      <p className="last-visit">
                        <span className="last-visit-text">
                          <Trans>last_visit</Trans>
                        </span>
                        {branch.last_visit_time.slice(0, 10)}
                      </p>
                    </>
                  ) : (
                    <p className="no-visits">
                      <Trans>no_visits</Trans>
                    </p>
                  )}
                </div>
              ) : (
                <div className="left-info">
                  <p className="result_percentage">
                    {branch.result_percentage}%
                  </p>
                  <div className="vertical-line"></div>
                  <p className="branch-visit">
                    <span className="branch-visit-count">
                      {branch.visits_count}
                    </span>
                    <Trans>visits</Trans>
                  </p>
                </div>
              )}
              <hr className="hr-line" />
            </BranchVisit>
          );
        })
      ) : (
        <NoResult>
          <Trans>analytics_will_be_shown</Trans>
        </NoResult>
      )}
    </RectangleContainer>
  );
};

export const AddEvaluationTemplate = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <RectangleContainer language={i18n.language === "ar" ? "ar" : "en"}>
      <Title color="#ececec" fontWeight="bold">
        <Trans>result_rate</Trans>
      </Title>
      <TemplateData language={"unset"}>
        <AvergeIconWrapper>
          <AvergeIcon className="average-icon" />
        </AvergeIconWrapper>
        <Pargraph>
          <Trans>can_try_evaluation_points</Trans>
        </Pargraph>
        <ButtonWrapper>
          <Button
            type="primary"
            size="large"
            onClick={() => navigate("/evaluation-templates/create")}
          >
            <Trans>add_evaluation_template</Trans>
          </Button>
        </ButtonWrapper>
      </TemplateData>
    </RectangleContainer>
  );
};

export const JobsCount = ({ job }: { job: Props }) => {
  const { i18n } = useTranslation();
  return (
    <CounterBox language={i18n.language === "ar" ? "ar" : "en"}>
      <>
        <BranchIcon
          style={
            i18n.language === "ar"
              ? { marginLeft: "15px" }
              : { marginLeft: "unset" }
          }
        />
      </>
      <InfoWrapper>
        <Title
          color={Colors.charcoal_grey_54}
          language={i18n.language === "ar" ? "ar" : "unset"}
        >
          <Trans>visit_count</Trans>
        </Title>
        <TemplateData
          fontSize="24px"
          language={i18n.language === "ar" ? "ar" : "unset"}
        >
          <CountText>{`${job?.done}`}</CountText>
          <Trans>visit</Trans>
        </TemplateData>
      </InfoWrapper>
    </CounterBox>
  );
};

export const VerticalBar = ({ data, title, count, width }: Props) => {
  const { i18n } = useTranslation();
  const labels = false
    ? [
        "يناير",
        "فبراير",
        "مارس",
        "إبريل",
        "مايو",
        "يونيو",
        "يوليو",
        "أغسطس",
        "سبتمبر",
        "أكتوبر",
        "نوفمبر",
        "ديسمبر",
      ]
    : [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
  const options = {
    responsive: true,
    interaction: {
      mode: "point",
    },
    plugins: {
      legend: {
        position: "top",
        display: false,
        labels: {
          font: {
            size: 13,
            family: "Famtree",
            weight: "normal",
          },
          color: "red",
          textAlign: "left",
        },
      },
      tooltip: {
        intersect: false,
        titleAlign: "center",
        yAlign: "bottom",
        displayColors: false,
        pointStyle: "triangle",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };
  const isEmpty = Object.values(data).some((x) => x !== 0);
  return (
    <ChartBox language={i18n.language === "ar" ? "ar" : "en"}>
      <Title
        style={{ marginBottom: isEmpty ? "3px" : "102px" }}
        color={"#344247"}
        fontSize={"13px"}
        fontWeight="bold"
      >
        <Trans>{title}</Trans>
      </Title>
      {/* bar chart  */}
      {isEmpty ? (
        <StyledChartsWrapper width={width}>
          <BarChart
            data={{
              labels: labels,
              datasets: [
                {
                  label: "",
                  data: Object.values(data),
                  backgroundColor: "#43c773",
                  barThickness: 6,
                },
              ],
            }}
            options={options}
          />
        </StyledChartsWrapper>
      ) : (
        <NoResult>
          <Trans>analytics_will_be_shown</Trans>
        </NoResult>
      )}
    </ChartBox>
  );
};

export const Job = ({ jobs }: { jobs: Props }) => {
  const { i18n } = useTranslation();
  const data = {
    labels: [`%`, `%`, `%`, `%`, `%`],
    datasets: [
      {
        label: "",
        data: [
          jobs?.donePercentage,
          jobs?.startedPercentage,
          jobs?.acceptedPercentage,
          jobs?.withdrawPercentage,
        ],
        backgroundColor: ["#7ed321", "#ffc434", "#75a6c5", "#e41111"],
        borderColor: ["#7ed321", "#ffc434", "#75a6c5", "#e41111"],
        borderWidth: 1,
        cutout: 70,
      },
    ],
  };
  return (
    <ChartBox language={i18n.language === "ar" ? "ar" : "en"}>
      <Title
        style={{ marginBottom: jobs.count && jobs.count > 0 ? "3px" : "102px" }}
        color="#344247"
        fontSize={"13px"}
        fontWeight="bold"
      >
        <Trans>visits_status</Trans>
      </Title>
      {jobs.count && jobs.count > 0 ? (
        <>
          <StyledChartsWrapper width="220px">
            <PieChart data={data} />
          </StyledChartsWrapper>
          <JobStatus>
            <JobsStatusText color={"#7ed321"}>
              <GreenDot />
              <Trans>done_done</Trans>
            </JobsStatusText>

            <div className="job-info">
              <p className="job-percentage">{jobs?.donePercentage}%</p>
            </div>
          </JobStatus>

          <JobStatus>
            <JobsStatusText color="#ffc434">
              <YellowDot />
              <Trans>started</Trans>
            </JobsStatusText>
            <div className="job-info">
              <p className="job-percentage">{jobs?.startedPercentage}%</p>
            </div>
          </JobStatus>

          <JobStatus>
            <JobsStatusText color="#75a6c5">
              <BlueDot />
              <Trans>accepted</Trans>
            </JobsStatusText>
            <div className="job-info">
              <p className="job-percentage">{jobs?.acceptedPercentage}%</p>
            </div>
          </JobStatus>

          <JobStatus>
            <JobsStatusText color="#e41111">
              <RedDot />

              <Trans>withdraw</Trans>
            </JobsStatusText>
            <div className="job-info">
              <p className="job-percentage">{jobs?.withdrawPercentage}%</p>
            </div>
          </JobStatus>
        </>
      ) : (
        <NoResult>
          <Trans>analytics_will_be_shown</Trans>
        </NoResult>
      )}
    </ChartBox>
  );
};

interface StylesProps {
  color?: string;
  fontSize?: string;
  width?: string;
  language?: string;
  fontWeight?: string;
}

export const Title = styled.p`
  font-family: Famtree;
  color: ${(props: StylesProps) => props.color};
  font-size: ${(props: StylesProps) =>
    props.fontSize ? props.fontSize : "14px"};
  margin-bottom: 4px;
  width: ${(props: StylesProps) => (props.width ? props.width : "1005")};
  font-weight: ${(props: StylesProps) =>
    props.fontWeight ? "bold" : "normal"};
  @media (max-width: 991px) {
    font-size: 14px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

export const NoResult = styled.p`
  font-family: Famtree;
  color: #344247;
  font-size: 13px;
  margin: 0 0 60px 0;
  font-weight: normal;
  text-align: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
`;

export const TemplateData = styled.div`
  // white-space:nowrap;
  font-family: Famtree;
  font-size: ${(props: StylesProps) => props.fontSize};
  font-weight: bold;
  text-align: ${(props: StylesProps) =>
    props.language === "ar"
      ? "right"
      : props.language === "en"
      ? "unset"
      : "center"};
  color: #344247;
  word-break: break-all;
  margin-bottom: 0;
  .count-text {
    margin: 0 5px;
  }
`;

export const Container = styled.div`
  margin-bottom: 24px;
  padding: 30px;
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  background-color: #fff;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  box-shadow: 1px 1px 15px 2px rgba(48, 48, 48, 0.07);
`;

export const JobsStatusText = styled.p`
  font-family: Famtree;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${(props: StylesProps) => props.color};
  margin-bottom: 0;
`;

export const RectangleContainer = styled.div`
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  background-color: #fff;
  padding: 30px;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  margin: 0 auto;
  margin-bottom: 18px;
  box-shadow: 1px 1px 15px 2px rgba(48, 48, 48, 0.07);
`;

export const CounterBox = styled.div`
  padding: 5px;
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  background-color: #fff;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  box-shadow: 1px 1px 15px 2px rgba(48, 48, 48, 0.07);
`;

export const ChartBox = styled.div`
  padding: 20px 26px;
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  background-color: #fff;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  margin-bottom: 18px;
  box-shadow: 1px 1px 15px 2px rgba(48, 48, 48, 0.07);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

export const BranchVisit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .right-info,
  .left-info {
    display: flex;
    align-items: center;
  }
  .hr-line {
    display: none;
  }
  @media (max-width: 1700px) {
    display: block;
    .right-info {
      margin-bottom: 10px;
    }
    .hr-line {
      display: block;
      margin: 15px 0;
    }
  }
  .right-info {
    .branch-order {
      width: 17px;
      height: 17px;
      background-color: #eaeaea;
      border-radius: 50%;
      text-align: center;
      line-height: 17px;
    }
    .order {
      color: #909090;
      font-style: normal;
      font-size: 10px;
      font-weight: bold;
      margin-bottom: 0;
    }
    .branch-name {
      font-family: Famtree;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: ${(props: { language?: string }) =>
        props.language === "ar" ? "right" : "left"};
      color: #344247;
      margin: 0 10px;
    }
  }
  .left-info {
    .branch-visit,
    .result_percentage {
      margin-bottom: 0;
      font-family: Famtree;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #344247;
      margin: 0 5px 0px 5px;
    }
    .result_percentage {
      font-weight: bold;
    }
    .branch-visit-count {
      margin: 0 5px 0px 5px;
    }
    .vertical-line {
      width: 1.5px;
      height: 19px;
      margin: 0 8px 0 8px;
      font-family: Famtree;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      background-color: #d1cccc;
    }
    .last-visit {
      margin-bottom: 0;
    }
    .last-visit-text {
      margin: 0 5px 0 5px;
      font-size: 12px;
    }
    .no-visits {
      color: #9b9393;
      margin-bottom: 0;
    }
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

export const CountText = styled.span`
  margin: 0 5px;
`;

export const VisitText = styled.span`
  font-size: 13px;
  font-weight: normal;
  white-space: nowrap;
`;

export const Visit = styled.span`
  margin: 0 7px;
  font-size: 13px;
  font-weight: normal;
`;

export const StyledChartsWrapper = styled.div`
  margin: 30px auto;
  width: ${(props: StylesProps) => (props.width ? props.width : "100%")};
`;

export const JobStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  white-space: nowrap;
  .job-info {
    display: flex;
    align-items: center;
    width: 140px;
    .job-percentage {
      margin-bottom: 0;
      color: #344247;
      font-family: Famtree;
      font-size: 13px;
      font-weight: normal;
      width: 40px;
    }
    .job-name {
      margin-bottom: 0;
      color: #344247;
      font-family: Famtree;
      font-size: 13px;
      font-weight: normal;
      width: 40px;
      span {
        margin: 0 5px;
      }
    }
    .vertical-line {
      width: 2px;
      height: 19px;
      margin: 0 15px 0 15px;
      font-family: Famtree;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      background-color: #d1cccc;
    }
  }
`;

export const Pargraph = styled.p`
  font-family: Famtree;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  color: #344247;
  min-height: 80px;
  margin-bottom: 43px;
`;

export const AvergeIconWrapper = styled.div`
  margin: 150px 43px;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 266px;
  .add-template {
    width: 184px;
    margin: 0 auto;
  }
`;
