/** @format */

import * as React from "react";
import { SVGProps } from "react";

const ArrowNavLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg width={6} height={10} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m1 1 3.9 3.9L1 8.8"
      stroke="#D9D9D7"
      strokeWidth={1.5}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowNavLeft;
