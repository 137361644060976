/** @format */

import { Drawer } from "antd";
import styled from "styled-components";

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    background-color: #ebecf0;
    border-radius: 1px;
    border: solid 1px #d9dde4;
    padding: 19px 24px 8px 20px;
  }

  .ant-drawer-content-wrapper {
    transition: unset;
  }
  .ant-drawer-title {
    font-family: Famtree;
    font-size: 14px;
    font-weight: bold;
    color: #344247;
    text-align: end;
  }

  .ant-drawer-close {
    color: #344247;
    font-size: 14px;
    font-weight: bold;
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
  }

  &.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    height: 55%;
    bottom: 0;
  }

  &.ant-drawer-left .ant-drawer-content-wrapper {
    height: 55%;
    bottom: 0;
  }
`;
