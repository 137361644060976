import * as React from "react";

function ScaleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={20}
      viewBox="0 0 21 20"
    >
      <path
        d="M13.21 5.25l-2-2m-1.5 3.5l-1-1m-.5 4.5l-2-2m-1.5 3.5l-1-1m15.414-5.242l-4.172-4.172a2 2 0 00-2.828 0L1.796 11.664a2 2 0 000 2.829l4.171 4.171a2 2 0 002.829 0L19.125 8.335a2 2 0 00-.001-2.827z"
        stroke="#8CD69D"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ScaleIcon;
