/** @format */

import * as React from "react";
import { SVGProps } from "react";
const GooglePlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={27}
    {...props}
    style={{ marginLeft: "10px" }}
  >
    <defs>
      <linearGradient id="a" x1="60.825%" x2="26.895%" y1="4.947%" y2="71.933%">
        <stop offset="0%" stopColor="#00A0FF" />
        <stop offset=".7%" stopColor="#00A1FF" />
        <stop offset="26%" stopColor="#00BEFF" />
        <stop offset="51.2%" stopColor="#00D2FF" />
        <stop offset="76%" stopColor="#00DFFF" />
        <stop offset="100%" stopColor="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="107.595%"
        x2="-130.516%"
        y1="50.007%"
        y2="50.007%"
      >
        <stop offset="0%" stopColor="#FFE000" />
        <stop offset="40.9%" stopColor="#FFBD00" />
        <stop offset="77.5%" stopColor="orange" />
        <stop offset="100%" stopColor="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="86.245%"
        x2="-50.123%"
        y1="30.535%"
        y2="137.665%"
      >
        <stop offset="0%" stopColor="#FF3A44" />
        <stop offset="100%" stopColor="#C31162" />
      </linearGradient>
      <linearGradient
        id="d"
        x1="-18.805%"
        x2="42.087%"
        y1="-13.006%"
        y2="34.804%"
      >
        <stop offset="0%" stopColor="#32A071" />
        <stop offset="6.9%" stopColor="#2DA771" />
        <stop offset="47.6%" stopColor="#15CF74" />
        <stop offset="80.1%" stopColor="#06E775" />
        <stop offset="100%" stopColor="#00F076" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="url(#a)"
        d="M.473.414C.173.74 0 1.242 0 1.894v23.207c0 .652.173 1.154.485 1.47l.081.069 12.88-13.003v-.292L.555.345.473.414z"
      />
      <path
        fill="url(#b)"
        d="m17.731 17.988-4.296-4.338v-.304l4.298-4.339.092.059 5.083 2.916c1.456.828 1.456 2.192 0 3.032l-5.083 2.916-.094.058z"
      />
      <path
        fill="url(#c)"
        d="m17.824 17.93-4.389-4.432L.473 26.583c.474.513 1.27.572 2.161.07l15.19-8.723"
      />
      <path
        fill="url(#d)"
        d="M17.824 9.067 2.634.356C1.744-.158.947-.088.474.426l12.96 13.072 4.39-4.431z"
      />
      <path
        fill="#000"
        fillRule="nonzero"
        d="M17.731 17.836 2.645 26.49c-.843.49-1.594.456-2.079.012l-.081.082.081.07c.485.442 1.236.477 2.08-.012l15.19-8.711-.105-.094z"
        opacity={0.2}
      />
      <path
        fill="#000"
        fillRule="nonzero"
        d="m22.907 14.862-5.187 2.974.093.094 5.083-2.916c.727-.42 1.086-.967 1.086-1.516-.047.502-.416.98-1.075 1.364z"
        opacity={0.12}
      />
      <path
        fill="#FFF"
        d="m2.634.508 20.273 11.627c.659.373 1.028.862 1.086 1.364 0-.547-.358-1.096-1.086-1.516L2.634.356C1.18-.484 0 .216 0 1.895v.151C0 .367 1.179-.32 2.634.508z"
        opacity={0.25}
      />
    </g>
  </svg>
);
export default GooglePlayIcon;
