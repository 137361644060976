import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";
import { Alert } from "antd";

export const Container = styled.div`
  background-color: white;
  margin: 0px 0 0 30px;
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 83vh;
  direction: rtl;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  justify-content: space-between;
  margin-top: 11px;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  border-radius: 16px;
  border: solid 0.5px #cccccc;
  background-color: ${Colors.white};
  direction: rtl;
  padding: 20px;
  margin-top: 15px;
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardTitle = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: unset;
`;

export const Span = styled.span`
  color: ${Colors.charcoal_grey_54};
  margin-right: 10px;
  display: inline-block;
`;

export const SubP = styled.span`
  margin-right: 70px;
  text-align: right;
  direction: rtl;
`;

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;

export const Chart = styled.div`
  width: 70%;
`;

export const ChartDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChartNumber = styled.div`
  width: 100px;
  height: 56px;
  background-color: ${Colors.light_gray};
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 13px;
  font-weight: normal;
  margin-top: 10px;
  text-align: center;
  align-self: center;
  line-height: 53px;
`;

export const OnBoarding = styled.div`
  width: 98%;
  align-self: flex-end;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const Wrapper = styled.div`
  width: 98%;
  align-self: flex-end;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const FiltrationHeader = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 15px;
  @media (max-width: 1224px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;

export const DetailsButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px auto 15px auto;
  align-items: center;
  width: 96%;
  padding: 15px 20px;
  border-radius: 3px;
  border: 1px solid blue;
  background-color: #e6efff;
  .details-button {
    margin: 0;
    background-color: #4d93ff;
    font-family: Famtree;
    width: 136px;
    height: 34px;
    padding-left: 25px;
    padding-right: 25px;
    font-weight: normal;
  }
`;

export const FilterDetailsInfo = styled.div`
  margin-bottom: 0;
  color: #0055d9;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  display: flex;
  gap: 6px;
`;

export const NormalText = styled.span`
  font-family: Famtree;
`;

export const Hint = styled.p`
  font-family: Famtree;
  font-size: 13px;
  color: red;
  margin: 0px 20px 25px 10px;
  font-weight: bold;
  direction: ${(props: { language?: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};
`;

export const BoldText = styled.span`
  font-family: Famtree;
  font-weight: bold;
`;

export const DateFilter = styled.div`
  width: 40%;
  margin: 0 5px;
  .ant-picker {
    width: 100%;
  }
  .ant-picker-input > input::placeholder {
    color: rgb(118, 118, 118);
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const SelectFilter = styled.div`
  margin: 0 5px;
  width: 35%;
  .react-select__placeholder {
    color: #344247;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const StyledAlert = styled(Alert)`
  font-family: Famtree;
  font-size: 14px;
  .ant-alert-message {
    color: ${Colors.dark_blue};
    padding-right: 10px;
  }
`;

export const LastUpdate = styled.div`
  width: 98%;
  align-self: flex-end;
  margin: 10px 0;
  display: flex;
  justify-content: center;
`;

export const Pargraph = styled.p`
  font-family: Famtree;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #344247;
  margin: 0 5px 0 5px;
`;

export const HomeWrapper = styled.div`
  background-color: #ffffff;
`;
