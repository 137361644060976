/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";

import Tabs from "common/Tabs";
import { Header, Title } from "pages/style";
import { Container } from "pages/style";
import { ButtonsContainer } from "common/Table/style";
import SearchInput from "common/Search";
import { Button } from "common/Button";
import { useNavigate } from "react-router-dom";
import { JobsList } from "./jobsList";
import { BranchesList } from "./branchesList";
import { Helmet } from "react-helmet";

const { useState } = React;

const Ratings = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [searchInputValue, setSearchInputValue] = useState<string>("");

  const handleSearchInputChange = (e: any) => {
    setSearchInputValue(e.target?.value);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar" ? "التقييمات -تدري" : "Ratings - Tdree"}
        </title>
      </Helmet>
      <Header language={i18n.language === "ar" ? "ar" : "en"}>
        <Title>
          <Trans>ratings</Trans>
        </Title>
      </Header>
      <Container>
        <ButtonsContainer language={i18n.language === "ar" ? "ar" : "en"}>
          <SearchInput
            handleChange={(e) => handleSearchInputChange(e)}
            placeholder={t("search_placeholder")}
          />
          <Button
            size={"large"}
            onClick={() => navigate("/evaluation-templates/create")}
          >
            {t("new_template")}
          </Button>
        </ButtonsContainer>

        <Tabs
          className="mainTabs"
          tabOneTitle={t("jobs_ratings")}
          tabTwoTitle={t("branches_ratings")}
          tabOneContent={
            <JobsList
              searchResults={searchInputValue}
              setSearchInputValue={setSearchInputValue}
            />
          }
          tabTwoContent={
            <BranchesList
              searchResults={searchInputValue}
              setSearchInputValue={setSearchInputValue}
            />
          }
        />
      </Container>
    </>
  );
};

export default Ratings;
