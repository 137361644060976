import styled from "styled-components";
import { Input } from "antd";
import Colors from "../../infrastructure/utils/Colors";

const { TextArea } = Input;

export const StyledTextArea = styled(TextArea)`
  &.ant-input {
    height: ${(props) => (props.name === "message" ? "170px" : "226px")};
  }

  margin: 13px 0;
  border: 1px solid;
  background-color: ${(props: any) =>
    props.errorName ? Colors.lipstick_5 : Colors.black_4};
  border-radius: 10px;
  box-sizing: border-box;
  border-color: ${(props: any) =>
    props.errorName ? Colors.lipstick : Colors.black_4};

  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  padding-top: 20px;

  ::placeholder {
    font-family: Famtree;
    font-size: 11px;
    color: ${Colors.charcoal_grey_38};
    text-align: ${(props: { language: string }) =>
      props.language === "ar" ? "right" : "left"};
  }

  text-align: ${(props) => (props.language === "ar" ? "right" : "left")};
`;
