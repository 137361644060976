/** @format */

import * as React from "react";
import { SVGProps } from "react";

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <a href="https://twitter.com/TdreeApp" target="_blank" rel="noreferrer">
    <svg width={32} height={32} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M29 8.405a10.24 10.24 0 0 1-2.946.808 5.137 5.137 0 0 0 2.255-2.838 10.275 10.275 0 0 1-3.257 1.245A5.12 5.12 0 0 0 21.308 6c-3.311 0-5.744 3.09-4.997 6.297a14.56 14.56 0 0 1-10.57-5.358 5.136 5.136 0 0 0 1.586 6.847 5.107 5.107 0 0 1-2.322-.641c-.056 2.376 1.647 4.599 4.114 5.094a5.14 5.14 0 0 1-2.317.087 5.133 5.133 0 0 0 4.792 3.561A10.313 10.313 0 0 1 4 24.012a14.52 14.52 0 0 0 7.863 2.305c9.522 0 14.903-8.043 14.578-15.257A10.442 10.442 0 0 0 29 8.405z" />
    </svg>
  </a>
);

export default TwitterIcon;
