/** @format */

import * as React from "react";
import { SVGProps } from "react";

const TwitterResponsiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <a href="https://twitter.com/TdreeApp" target="_blank" rel="noreferrer">
    <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20 5.657a6.892 6.892 0 0 1-2.003.557 3.532 3.532 0 0 0 1.533-1.956c-.673.405-1.42.7-2.215.858A3.46 3.46 0 0 0 14.77 4c-2.252 0-3.907 2.129-3.398 4.34-2.898-.148-5.468-1.555-7.188-3.693-.914 1.588-.474 3.666 1.078 4.719a3.438 3.438 0 0 1-1.578-.443c-.039 1.638 1.12 3.17 2.797 3.51a3.451 3.451 0 0 1-1.576.06c.444 1.405 1.732 2.426 3.259 2.455A6.946 6.946 0 0 1 3 16.412 9.78 9.78 0 0 0 8.347 18c6.475 0 10.134-5.542 9.913-10.513A7.154 7.154 0 0 0 20 5.657z" />
    </svg>
  </a>
);

export default TwitterResponsiveIcon;
