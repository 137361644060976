/** @format */

import styled from "styled-components";
import { Slider } from "antd";
import Colors from "../../infrastructure/utils/Colors";

export const StyledSlider = styled(Slider)`
  &.ant-slider {
    width: 62%;
    margin: 20px 25px 20px 60px;

    @media (max-width: 800px) {
      margin: 25px auto;
      width: 94%;
    }
  }

  .ant-slider-handle {
    border: solid 2px ${Colors.dark_mint};
  }

  .ant-slider-track {
    background-color: ${Colors.dark_mint};
  }

  &.ant-slider:hover .ant-slider-track {
    background-color: ${Colors.dark_mint};
    color: ${Colors.dark_mint};
  }

  & .ant-slider-rail {
    background-color: rgba(52, 66, 71, 0.12);
  }
`;
