/** @format */

import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  Label,
  SinglePaymentFormContainer,
  TotalPayment,
  StepsActions,
  SubmitContainer,
  TotalWithDiscount,
  TotalBeforeDiscount,
  Th,
  Td,
  Tr,
  SAR,
} from "../style";

import { Button } from "common/Button";
import Spinner from "common/LoadingIndecator";

import styled from "styled-components";
import WalletIcon from "assets/svg/wallet";
import { axiosApiInstance } from "infrastructure/utils/api";
import { useNavigate } from "react-router-dom";
import {
  showNotification,
  NotificationStatus,
} from "infrastructure/helpers/showNotifications";
import { StyledLink } from "pages/Wallet/style";
import ArrowIcon from "assets/svg/arrow-left-icon";

const PaymentForm = ({ res, current }: any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [walletBalance, setWalletBalance] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    axiosApiInstance.get("api/company/v1/wallet/balance").then((res) => {
      setWalletBalance(res.data.balance);
    });
    const script = document.createElement("script");
    script.src = "https://secure.paytabs.sa/payment/js/paylib.js";
    script.async = true;
    script.type = "text/javascript";
    document.body.appendChild(script);
    setIsLoading(false);
  }, []);

  const onSubmit = () => {
    setIsLoading(true);
    axiosApiInstance
      .post("/api/company/v1/jobs/pay", {
        amount: res.total,
        job_id: res.id,
        reference: res.reference,
      })
      .then(() => {
        setIsLoading(false);
        navigate("/jobs");
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          t("error"),
          err.response.data.message
        );
        setIsLoading(false);
      });
  };

  return (
    <SinglePaymentFormContainer language={i18n.language === "ar" ? "ar" : "en"}>
      <table
        style={{
          width: "100%",
          fontFamily: "Famtree",
          marginTop: "30px",
          borderCollapse: "collapse",
          alignSelf: "flex-end",
          marginBottom: "10px",
          border: "4px",
        }}
      >
        <tbody>
          <Tr>
            <Td language={i18n.language === "ar" ? "ar" : "en"}>
              {res?.description.extra_items_price}
              <SAR>
                <Trans>sar</Trans>
              </SAR>
            </Td>
            <Th>
              <Trans>extra_items_price</Trans>
            </Th>
          </Tr>

          <Tr>
            <Td language={i18n.language === "ar" ? "ar" : "en"}>
              {res.description.shoppers_price}
              <SAR>
                <Trans>sar</Trans>
              </SAR>
            </Td>
            <Th>
              <Trans>shoppers_price</Trans>
            </Th>
          </Tr>

          <Tr>
            <Td language={i18n.language === "ar" ? "ar" : "en"}>
              {res.description.vat_amount}
              <SAR>
                <Trans>sar</Trans>
              </SAR>
            </Td>
            <Th>
              <Trans>vat_amount</Trans>
            </Th>
          </Tr>

          <Tr>
            <Td language={i18n.language === "ar" ? "ar" : "en"}>
              {res.description.shoppers_count}
            </Td>
            <Th>
              <Trans>shoppers_count</Trans>
            </Th>
          </Tr>

          <Tr>
            <Td language={i18n.language === "ar" ? "ar" : "en"}>
              {res.description.branches_count}
            </Td>
            <Th>
              <Trans>branches_count</Trans>
            </Th>
          </Tr>

          {res.hasPromoCode && (
            <Tr>
              <Td language={i18n.language === "ar" ? "ar" : "en"}>
                {res.promocode.percentage}%
              </Td>
              <Th>
                <span style={{ fontWeight: "bold" }}>
                  ({res.promocode.name})
                </span>
                <span style={{ margin: "0 5px" }}>
                  <Trans>promocode</Trans>
                </span>
              </Th>
            </Tr>
          )}

          <Tr>
            <Td language={i18n.language === "ar" ? "ar" : "en"}>
              {res.hasPromoCode ? (
                <TotalWithDiscount>
                  <TotalBeforeDiscount>
                    {(res?.promocode?.totalBeforePromo).toFixed(2)}
                    <SAR>
                      <Trans>sar</Trans>
                    </SAR>
                  </TotalBeforeDiscount>
                  <TotalPayment className="total-payment">
                    {(res?.promocode?.totalAfterPromo).toFixed(2)}
                    <SAR>
                      <Trans>sar</Trans>
                    </SAR>
                  </TotalPayment>
                </TotalWithDiscount>
              ) : (
                <TotalPayment>
                  {(res?.total).toFixed(2)}
                  <SAR>
                    <Trans>sar</Trans>
                  </SAR>
                </TotalPayment>
              )}
            </Td>
            <Th>
              <Trans>total</Trans>
            </Th>
          </Tr>
        </tbody>
      </table>

      <Label style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
        <Trans>payment_way</Trans>
      </Label>

      <WalletPaymentContainer>
        <WalletWrapper language={i18n.language === "ar" ? "ar" : "en"}>
          <Text language={i18n.language === "ar" ? "ar" : "en"}>
            <Trans>charge_by_credit</Trans>
          </Text>
          <WalletIcon />
        </WalletWrapper>

        {walletBalance && walletBalance.split(",").join("") >= res.total ? (
          <ChargeBalanceText>
            <Trans values={{ count: walletBalance }}>wallet_balance</Trans>
          </ChargeBalanceText>
        ) : (
          <>
            <ChargeBalanceText>
              <Trans values={{ count: walletBalance }}>wallet_balance</Trans>
            </ChargeBalanceText>
            <WalletWrapper
              style={{ marginRight: "32px" }}
              language={i18n.language === "ar" ? "ar" : "en"}
            >
              <StyledLink
                style={{ marginRight: "40px" }}
                onClick={() => navigate("/charge")}
              >
                <Trans>recharge_balance</Trans>
                <ArrowIcon />
              </StyledLink>
              <Label>
                {walletBalance && (
                  <Trans
                    values={{
                      count: res.total - walletBalance.split(",").join(""),
                    }}
                  >
                    recharge_message
                  </Trans>
                )}
              </Label>
            </WalletWrapper>
          </>
        )}
      </WalletPaymentContainer>

      <StepsActions className="payment">
        {current === 2 && (
          <SubmitContainer id="job-sumbit-container">
            {isLoading && <Spinner />}
            <button
              id="job-payment-submit"
              className={"payment"}
              onClick={onSubmit}
              disabled={
                (walletBalance && walletBalance.split(",").join("") >= res.total
                  ? false
                  : true) || isLoading
              }
              style={
                (walletBalance && walletBalance.split(",").join("") >= res.total
                  ? false
                  : true) || isLoading
                  ? {
                      backgroundColor: "#cccccc",
                      color: "#666666",
                      border: "1px solid #999999",
                      alignSelf: "flexStart",
                      width: "100%",
                      height: "51px",
                      borderRadius: "10px",
                      fontFamily: "Famtree",
                      fontSize: "14px",
                      fontWeight: "bold",
                      cursor: "not-allowed",
                    }
                  : {
                      backgroundColor: "#44c874",
                      color: "white",
                      border: "0",
                      alignSelf: "center",
                      width: "100%",
                      height: "51px",
                      borderRadius: "10px",
                      fontFamily: "Famtree",
                      fontSize: "14px",
                      fontWeight: "bold",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }
              }
            >
              <Trans>pay</Trans>
            </button>
          </SubmitContainer>
        )}

        {current === 2 && (
          <Button id="save-later" type="link" onClick={() => navigate("/jobs")}>
            <Trans>save_later</Trans>
          </Button>
        )}
      </StepsActions>
    </SinglePaymentFormContainer>
  );
};

export default PaymentForm;

export const WalletPaymentContainer = styled.div`
  width: 100%;
  margin: 18.2px 1.4px 60px 0.6px;
  padding: 12px 18.6px 12px 10px;
  border-radius: 10px;
  border: solid 1px rgba(221, 221, 221, 0.3);
  background-color: #f7f7f7;
  align-items: end;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p<{ language: string }>`
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  color: #374449;
  margin-right: 10px;
  margin-left: ${(props) => props.language === "en" && "10px"};
`;

export const WalletWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: ${(props: { language: string }) =>
    props.language === "ar" ? "baseline" : "start"};
`;

export const ChargeBalanceText = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: #374449;
  text-align: center;
  margin-bottom: 0px;
  margin-right: 32px;
`;
