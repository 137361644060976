import * as React from "react";

function InvoicesIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width={22}
      height={18}
      viewBox="0 0 22 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{"icons8-invoice"}</title>
      <g
        stroke="#344247"
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <path d="M1.263 14.421V3.368c0-1.162.943-2.105 2.105-2.105h15.264c1.162 0 2.105.943 2.105 2.105v11.053a2.105 2.105 0 01-2.105 2.105H3.368a2.105 2.105 0 01-2.105-2.105z" />
        <path
          strokeLinecap="round"
          d="M12.842 6H4.947M12.842 9.158H4.947M17.053 12.316H16M17.053 9.158H16M17.053 6H16"
        />
      </g>
    </svg>
  );
}

export default InvoicesIcon;
