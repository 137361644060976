/** @format */

import * as React from "react";
import { SVGProps } from "react";

const TaskIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={66} height={64} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M30.187 48c6.51 0 6.734-7.422 11.078-11.61 4.557-4.394 10-7.077 13.022-13.376 3.022-6.298 7.028-12.888-1.04-19.612-8.07-6.724-17.138-1.405-23.909-1.405-8.825 0-21.948.69-26.162 7.757C1.019 13.369 0 21.058 0 25.574c0 6.45 4.548 12.539 8.668 16.871C13.07 47.073 23.297 48 30.188 48z"
        fill="#E8F8EE"
      />
      <g
        stroke="#292D32"
        strokeWidth={3.6}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      >
        <path d="M37.894 27.46h12.688m-27.164 0 1.813 1.813 5.437-5.438m7.226 20.542h12.688m-27.164 0 1.813 1.812 5.437-5.437" />
        <path d="M12.833 37.296v4.954c0 12.083 4.834 16.917 16.917 16.917h14.5c12.083 0 16.917-4.834 16.917-16.917v-14.5c0-12.083-4.834-16.917-16.917-16.917h-14.5c-12.083 0-16.917 4.834-16.917 16.917" />
      </g>
    </g>
  </svg>
);

export default TaskIcon;
