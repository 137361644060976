/** @format */

import React, { useState } from "react";
import PaymentForm from "./payment-form";
import storage from "infrastructure/utils/storage";

const JobsPayment = () => {
  const [paymentType, setPaymentType] = useState<string>("");
  const jobsData = JSON.parse(storage.getItem("jobs_data") || "{}");
  return (
    <>
      <PaymentForm
        res={jobsData}
        setPaymentType={setPaymentType}
        paymentType={paymentType}
      />
    </>
  );
};

export default JobsPayment;
