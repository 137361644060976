/** @format */

import React, { ChangeEvent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../Input";

const SearchInput: React.FC<{
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: ReactNode | string;
}> = ({ handleChange, placeholder }) => {
  const { i18n } = useTranslation();
  return (
    <Input
      name="description"
      type="text"
      dir="true"
      width={"searchWidth"}
      placeholder={placeholder}
      onChange={(e: any) => handleChange(e)}
      style={
        i18n.language === "ar" ? { textAlign: "rtl" } : { textAlign: "ltr" }
      }
    />
  );
};

export default SearchInput;
