/** @format */
//@ts-nocheck

import { List as AntDList } from "antd";
import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

export const Container = styled.div`
  width: 100%;
  overflow: ${(props: { type: string }) =>
    props.type !== undefined && props.type !== "shoppersList" && "auto"};
  padding: ${(props: { type: string }) =>
    props.type !== undefined && props.type !== "shoppersList" && "5px"};

  margin: 0 auto;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${Colors.light_gray};
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.gray};
    border-radius: 10px;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 5px;
    margin-top: 10px;
  }
`;

export const StyledList = styled(AntDList)`
  &.ant-list-split .ant-list-item {
    border-bottom: unset;
    margin-bottom: 5px;
  }

  ant-list-item &.ant-list {
    display: contents;
  }

  .ant-spin-nested-loading {
    width: ${(props: { type: string }) =>
      props.type === "shoppersList" ? "66%" : "100%"};
    margin: 0 auto;
    @media (max-width: 767px) {
      width: 90%;
    }
  }
`;

export const Loading = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
`;
