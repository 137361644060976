/** @format */

import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Header, Title, Container, TablesWrapper } from "pages/style";
import TemplateLogo from "assets/svg/template-ic";
import ArrowIcon from "assets/svg/arrow-left-icon";
import ArrowLeft from "assets/svg/wallet_left_arrow";

import {
  CompanyWalletBalanceContainer,
  BalanaceWrapper,
  BalanceContainer,
  BalanceText,
  BalanceValue,
  StyledLink,
  DepositeValueText,
  RefundValueText,
  WithdrawValueText,
  StyledMenu,
  Payments,
  Payment,
  QRWrapper,
} from "./style";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  showNotification,
  NotificationStatus,
} from "infrastructure/helpers/showNotifications";
import { useQuery } from "react-query";
import { fetchGetRequestsWithPagination } from "infrastructure/helpers/fetchServerRequests";
import CustomTable from "common/Table";
import { ColumnsType } from "antd/lib/table";
import { timeStampToDate } from "infrastructure/helpers/timeStampToDate";
import ArrowDown from "assets/svg/arrow-down";
import ArrowUp from "assets/svg/arrow-up";
import RotateRight from "assets/svg/rotate-right";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useReactToPrint } from "react-to-print";
import {
  BillContainer,
  HeaderWrapper,
  ContentWrapper,
  Pargraph,
  LogoWrappper,
  CompanyName,
  Th,
  Td,
  Tr,
  Table,
  Footer,
  Bill,
  SmallPargraph,
} from "../Invoices/style";
import { Divider, MenuProps, Dropdown as MenuItems } from "antd";
import { StyledP, Container as MenuContainer } from "common/Menu/style";
import { MoreBtn } from "../EvaluationTemplate/style";
import Logo from "assets/svg/logo";
import Modal from "common/Modal";
import { ColumnCell } from "../style";
import { ColumnTitle } from "antd/es/table/interface";
import Colors from "infrastructure/utils/Colors";
import QRCode from "react-qr-code";
interface DataType {
  title: ColumnTitle<DataType>;
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const Wallet = () => {
  const componentRef = React.useRef(null);
  const [isModalVisible, setIsModalVisible] = useState<any>({
    id: false,
  });
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [walletBalance, setWalletBallance] = useState();
  const [page, setPage] = useState(1);
  const [billId, setBillId] = useState<number>();

  const [record, setRecord] = useState<{ status: { id: number }; id: number }>({
    status: { id: 0 },
    id: 0,
  });

  const { data, isLoading } = useQuery(
    ["walletTransactions", page],
    () =>
      fetchGetRequestsWithPagination(
        `api/company/v1/wallet/transactions?page=${page}&limit=9`
      ),
    {
      staleTime: 5000,
    }
  );

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, [componentRef.current]);

  const handlePrint: ((e) => void) | undefined | any = useReactToPrint({
    content: () => componentRef.current,
    removeAfterPrint: true,
    onAfterPrint: handleAfterPrint,
  });

  const showModal = (id: number) => {
    setIsModalVisible({ [id]: true });
  };

  const handleCancel = (id: any) => {
    setIsModalVisible({ [id]: false });
  };

  const handleSendingTheBill = (id: number) => {
    axiosApiInstance
      .post("/api/company/v1/wallet/email", { id: id })
      .then(() => {
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
        setIsModalVisible(false);
      })
      .catch((err) => {
        setIsModalVisible(false);
        showNotification(
          NotificationStatus.Error,
          " ",
          err.response.data.message
        );
      });
  };

  useEffect(() => {
    axiosApiInstance
      .get("/api/company/v1/wallet/balance")
      .then((res) => {
        setWalletBallance(res.data.balance);
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          t("error"),
          err.response.data.message
        );
      });
  }, []);

  useEffect(() => {
    handlePrint();
  }, [billId, record]);

  const handlePagination = (page: number) => {
    setPage(page);
  };

  const handleMenuClick = (
    record: React.SetStateAction<{ status: { id: number }; id: number }>
  ) => {
    setRecord(record);
  };

  const items: MenuProps["items"] = [
    {
      key: 1,
      label: (
        <StyledMenu>
          <MenuContainer>
            <a onClick={() => setBillId(record.id)}>
              <Trans>download_bill</Trans>
            </a>
          </MenuContainer>
        </StyledMenu>
      ),
    },
    {
      key: 2,
      label: (
        <StyledMenu>
          <MenuContainer>
            <a onClick={() => showModal(record.id)}>
              <Trans>send_bill</Trans>
            </a>
          </MenuContainer>
        </StyledMenu>
      ),
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: t("transaction"),
      key: "العملية",
      render: (record: { type: { translation: string } }) => (
        <ColumnCell>{record.type.translation}</ColumnCell>
      ),
    },
    {
      title: t("transaction_date"),
      key: "تاريخ العملية",
      render: (record: { createdAt: string }) => (
        <ColumnCell>{timeStampToDate(record.createdAt)}</ColumnCell>
      ),
    },
    {
      title: t("value"),
      key: "القيمة",
      render: (record: { amount: number; type: { name: string } }) =>
        record.type.name === "deposit" ? (
          <WithdrawValueText>
            <ArrowDown />
            {record.amount} <Trans>sar</Trans>
          </WithdrawValueText>
        ) : record.type.name === "withdraw" ? (
          <RefundValueText>
            <ArrowUp />
            {record.amount} <Trans>sar</Trans>
          </RefundValueText>
        ) : (
          <DepositeValueText>
            <RotateRight />
            {record.amount} <Trans>sar</Trans>
          </DepositeValueText>
        ),
    },
    {
      title: t("value_amount"),
      key: "4",
      render: (record: { total_amount: string }) => (
        <ColumnCell>{record.total_amount}</ColumnCell>
      ),
    },
    {
      title: " ",
      render: (record) => (
        <MenuItems
          //@ts-ignore
          menu={{ items }}
        >
          <MoreBtn onClick={() => handleMenuClick(record)}>...</MoreBtn>
        </MenuItems>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar" ? "المحفظة - تدري" : "Wallet - Tdree"}
        </title>
      </Helmet>

      <Header language={i18n.language === "ar" ? "ar" : "en"}>
        <Title>
          <Trans>wallet</Trans>
        </Title>
      </Header>

      <CompanyWalletBalanceContainer
        language={i18n.language === "ar" ? "ar" : "en"}
      >
        <BalanaceWrapper>
          <TemplateLogo
            style={{
              margin: i18n.language === "ar" ? "0 0 0 15px" : "0 15px 0 0",
            }}
          />
          <BalanceContainer>
            <BalanceText>
              <Trans>balance</Trans>
            </BalanceText>
            <BalanceValue>
              <bdi>{walletBalance}</bdi> <Trans>sar</Trans>
            </BalanceValue>
            <StyledLink onClick={() => navigate("/charge")}>
              <Trans>recharge_balance</Trans>
              {i18n.language === "ar" ? <ArrowIcon /> : <ArrowLeft />}
            </StyledLink>
          </BalanceContainer>
        </BalanaceWrapper>
      </CompanyWalletBalanceContainer>

      <Title language={i18n.language === "ar" ? "ar" : "en"}>
        <Trans>wallet_transactions</Trans>
      </Title>

      <Container language={i18n.language === "ar" ? "ar" : "en"}>
        <TablesWrapper>
          <CustomTable
            columns={columns}
            data={data?.transactions}
            loading={isLoading}
            pagination={{
              position: [i18n.language === "ar" ? "bottomRight" : "bottomLeft"],
              total: data?.pagination.total,
              size: "small",
              defaultCurrent: data?.pagination.current_page || 1,
              onChange: (page: number) => handlePagination(page),
            }}
          />
        </TablesWrapper>
      </Container>

      <Bill>
        {data?.transactions?.map((item: any) => {
          return (
            item.id === billId && (
              <div key={item.id}>
                <BillContainer ref={componentRef}>
                  <HeaderWrapper>
                    <LogoWrappper>
                      <Logo />
                    </LogoWrappper>
                    <div>
                      <Pargraph
                        fontWeight="normal"
                        color={Colors.dark_mint}
                        fontSize="14px"
                      >
                        <Trans>wallet_charge_invoice</Trans>
                      </Pargraph>
                    </div>
                  </HeaderWrapper>
                  <HeaderWrapper>
                    <LogoWrappper>
                      <Pargraph color="#000" fontWeight="bold" fontSize="14px">
                        <Trans>tdree</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>tdree_company_name</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>saudi_arabia_riyadh</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        www.tdree.com
                      </Pargraph>
                    </LogoWrappper>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Pargraph color="#000" fontSize="10px">
                        <Trans>commercial_number</Trans>
                      </Pargraph>
                      <Pargraph color="#000" fontSize="10px">
                        {"1010487662"}
                      </Pargraph>
                      <Pargraph color="#000" fontSize="10px">
                        <Trans>tax_number</Trans>
                      </Pargraph>
                      <Pargraph color="#000" fontSize="10px">
                        <span>310769921800003</span>
                      </Pargraph>
                    </div>
                  </HeaderWrapper>
                  <Divider
                    style={{
                      backgroundColor: Colors.dark_mint,
                      border: "4px solid #44c874",
                    }}
                  />
                  <ContentWrapper>
                    <CompanyName>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>invoice_to</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        {item.company.name}
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        {item.company.address}
                      </Pargraph>
                    </CompanyName>

                    <CompanyName>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>commercial_number</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        {item.company.commercial_number}
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>tax_number</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        {item.company.tax_number}
                      </Pargraph>
                    </CompanyName>
                  </ContentWrapper>

                  <Divider
                    style={{
                      backgroundColor: Colors.dark_mint,
                      border: "4px solid #44c874",
                    }}
                  />

                  <ContentWrapper>
                    <CompanyName>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>wallet_invoice_purpose</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>wallet_pdf_purpose</Trans>
                      </Pargraph>
                    </CompanyName>

                    <CompanyName>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>wallet_invoice_number</Trans>
                      </Pargraph>

                      <Pargraph fontSize="10px" color="#000">
                        {item.id}
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        <Trans>wallet_createdat</Trans>
                      </Pargraph>
                      <Pargraph fontSize="10px" color="#000">
                        {timeStampToDate(item.createdAt)}
                      </Pargraph>
                    </CompanyName>
                  </ContentWrapper>

                  <Table>
                    <tbody>
                      <Tr>
                        <Th>
                          <Trans>description</Trans>
                        </Th>

                        <Th>
                          <Trans>total_amounnt_without_vats</Trans>
                        </Th>
                        <Th>
                          <Trans>vat_percentage</Trans>
                        </Th>

                        <Th>
                          <Trans>vat_amount</Trans>
                        </Th>

                        <Th>
                          <Trans
                            values={{
                              vatPercentage: item?.vat_percentage,
                            }}
                          >
                            total_amount
                          </Trans>
                        </Th>
                      </Tr>

                      <Tr>
                        <Td>
                          <Pargraph
                            fontSize="10px"
                            color="#000"
                            style={{ margin: "0" }}
                          >
                            <Trans>wallet_descriptionn</Trans>
                          </Pargraph>
                        </Td>

                        <Td>{item.amount}</Td>

                        <Td>{item.vat_percentage}</Td>

                        <Td>{item.vat_amount}</Td>
                        <Td>{item.total_amount}</Td>
                      </Tr>
                    </tbody>
                  </Table>

                  <ContentWrapper>
                    <Payments>
                      <Payment>
                        <SmallPargraph>
                          <Trans>wallet_total</Trans>
                        </SmallPargraph>
                        <SmallPargraph>{item.amount}</SmallPargraph>
                      </Payment>

                      <Payment>
                        <SmallPargraph>
                          <Trans
                            values={{
                              vatPercentage: item?.vat_percentage,
                            }}
                          >
                            vat
                          </Trans>
                        </SmallPargraph>
                        <SmallPargraph>{item.vat_amount}</SmallPargraph>
                      </Payment>

                      <Payment>
                        <SmallPargraph>
                          <Trans
                            values={{
                              vatPercentage: item?.vat_percentage,
                            }}
                          >
                            wallet_total_amount
                          </Trans>
                        </SmallPargraph>
                        <SmallPargraph>{item.total_amount}</SmallPargraph>
                      </Payment>
                    </Payments>
                  </ContentWrapper>

                  <QRWrapper>
                    <div
                      style={{
                        height: "auto",
                        margin: "10 auto",
                        maxWidth: 200,
                        width: "100%",
                      }}
                    >
                      <QRCode
                        size={256}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        value={`${process.env.REACT_APP_URL}/wallet/${item.id}`}
                        viewBox={`0 0 256 256`}
                      />
                    </div>
                    <Pargraph fontSize="10px" color="#000">
                      <Trans>qr_code_scan</Trans>
                    </Pargraph>
                  </QRWrapper>

                  <Footer>
                    <Pargraph fontSize="10px" color="#000">
                      <Trans>view_balance_consumption_details</Trans>
                    </Pargraph>
                    <Pargraph fontSize="10px" color="#000">
                      <Trans>thank_you</Trans>
                    </Pargraph>
                  </Footer>
                </BillContainer>
              </div>
            )
          );
        })}
      </Bill>

      <Modal
        title={<StyledP>{<Trans>send_bill</Trans>}</StyledP>}
        visible={isModalVisible[record.id]}
        onOk={() => handleSendingTheBill(record.id)}
        onCancel={() => handleCancel(record.id)}
      >
        <p>{<Trans>send_bill_message</Trans>}</p>
      </Modal>
    </>
  );
};

export default Wallet;
