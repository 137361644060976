/** @format */

import * as React from "react";
import { useTranslation } from "react-i18next";

function YellowDot(props: any) {
  const { i18n } = useTranslation();
  return (
    <svg
      width={8}
      height={8}
      style={
        i18n.language === "ar" ? { marginLeft: "8px" } : { marginRight: "8px" }
      }
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={77}
        y={7}
        width={8}
        height={8}
        rx={4}
        transform="translate(-77 -7)"
        fill="#FFC434"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default YellowDot;
