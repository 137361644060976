/** @format */

import styled from "styled-components";

import Carousel from "react-multi-carousel";

//@ts-ignore
export const StyledCarousel = styled(Carousel)<{ className: string }>`
  direction: ${(props) => props.className};
  &.react-multi-carousel-list {
    width: 63%;
    margin: 0 auto;
    margin-bottom: 17px;
    visibility: visible;
    @media (max-width: 900px) {
      width: 85%;
      margin: 20px auto 10px auto;
    }
  }

  .react-multi-carousel-track {
    backface-visibility: visible;
  }

  .react-multi-carousel-item {
    backface-visibility: visible;
    transform-style: unset;
  }
`;
