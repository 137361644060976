/** @format */

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18n';
import FormTemplate from '../../common/FormTemplate';
import { axiosApiInstance } from 'infrastructure/utils/api';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import {
  BlackTitleBold,
  P,
  BlackTitle,
  TitleBold,
  PContainer,
  Form,
  Hr,
} from '../../pages/EvaluationTemplate/EvaluationDetails/style';
import QuestionDetailsRow from '.';
import { DetailsProps } from '../../infrastructure/interfaces/evaluationTemplateDetails.interface';
import { Document, Page, pdfjs } from 'react-pdf';
import FileUploadIcon from 'assets/svg/fileUploadIcon';
import {
  ImgLoadingContainer,
  FileName,
  ImageContainer,
  LabelImag,
  LabelImagEng,
} from 'pages/EvaluationTemplate/style';
import { useStore } from 'infrastructure/zustandStore/evaluation-template-store';
import EvaluationTemplateQuestionDetails from './evaluation-template-question-details';
import { Progress } from 'common/Progress';
import RedIconDelete from 'assets/svg/red-icon-delete';
import { Input } from 'common/Input';
import { useParams } from 'react-router';

const QuestionsDetails = ({
  data,
  type,
  setUpdateTemplate,
  templateFile,
}: DetailsProps) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showPdf, setShowPdf] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [showImage, setShowImage] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const handleShowPdf = () => {
    setShowPdf(!showPdf);
  };
  const [template] = useStore((state: any) => [state.template]);

  // FILE UPLOAD
  const handleImageUploading = async (e: {
    file: { name: any };
    target: { files: (string | Blob)[] | any };
  }) => {
    setFileLoading(true);
    setFileName(e.target.files[0].name);
    const image_url = '/api/file-upload';
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('entity', 'templates');
    formData.append('file_type', 'files');
    formData.append('temp_path', 'false');

    formData !== null &&
      (await axiosApiInstance
        .post(image_url, formData)
        .then((res) => {
          setFileLoading(false);
          setShowImage(res.data);
          axiosApiInstance
            .post('api/company/v1/evaluation-templates/file', {
              id,
              file: res.data.fullUrl,
            })
            .then((updateRes) => {
              setUpdateTemplate(true);
              showNotification(
                NotificationStatus.Success,
                t('done'),
                t('done_message')
              );
            });
        })
        .catch((err) => {
          setFileLoading(false);
          showNotification(NotificationStatus.Error, 'Error', err.message);
        }));
  };
  const handleDeleteFile = () => {
    setShowImage(null);
  };

  return (
    <Form>
      <FormTemplate>
        <P>
          <Trans>evaluation_template_name</Trans>
        </P>
        <BlackTitleBold>
          {type === 'evaluation-template' ? data?.name : template.data?.name}
        </BlackTitleBold>
        <Hr />
        <P>
          <Trans>evaluation_template_description</Trans>
        </P>
        <BlackTitle language={i18n.language === 'ar' ? 'ar' : 'en'}>
          {type === 'evaluation-template'
            ? data?.description
            : template?.data?.description}
        </BlackTitle>
        <>
          <Hr />
          <P>
            <Trans>add_pdf_to_template</Trans>
          </P>
          <>
            {/* UPLOAD */}
            {type === 'evaluation-template' && (
              <ImageContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
                {fileLoading && (
                  <div style={{ position: 'relative' }}>
                    <ImgLoadingContainer>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          alignItems: 'center',
                        }}
                      >
                        <FileUploadIcon />
                        <FileName>{fileName}</FileName>
                      </div>
                      <Progress status='active' percent={40} showInfo />
                    </ImgLoadingContainer>
                  </div>
                )}

                <label htmlFor='file' style={{ width: '100%' }}>
                  {showImage ? (
                    <div style={{ position: 'relative' }}>
                      <ImgLoadingContainer active>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row-reverse',
                              alignItems: 'flex-start',
                            }}
                          >
                            <FileUploadIcon />
                            <FileName>{fileName}</FileName>
                          </div>
                          <RedIconDelete onClick={() => handleDeleteFile()} />
                        </div>
                        <Progress status='success' percent={100} showInfo />
                      </ImgLoadingContainer>
                    </div>
                  ) : i18n.language === 'ar' && !fileLoading ? (
                    <LabelImag>
                      <Input
                        type='file'
                        id='file'
                        accept='.pdf'
                        name='image_url'
                        style={{ display: 'none' }}
                        onChange={(e: any) => handleImageUploading(e)}
                      />
                    </LabelImag>
                  ) : (
                    i18n.language === 'en' &&
                    !fileLoading && (
                      <LabelImagEng>
                        <Input
                          type='file'
                          id='file'
                          accept='.pdf'
                          name='image_url'
                          style={{ display: 'none' }}
                          onChange={(e: any) => handleImageUploading(e)}
                        />
                      </LabelImagEng>
                    )
                  )}
                </label>
              </ImageContainer>
            )}
            {/* DISPLAY */}
            <ImgLoadingContainer
              active={showPdf && true}
              onClick={handleShowPdf}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'center',
                }}
              >
                <FileUploadIcon />
                <FileName>
                  <Trans>upload_template_pdf_summary</Trans>
                </FileName>
              </div>
            </ImgLoadingContainer>
            {showPdf &&
              (data?.file || templateFile || template?.data?.file ? (
                <>
                  <Document
                    file={
                      type === 'evaluation-template'
                        ? data?.file
                        : template?.data?.file
                    }
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={t('loading_pdf')}
                    noData={t('no_file_selected')}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>
                  <div>
                    <div
                      style={{
                        direction: i18n.language === 'ar' ? 'rtl' : 'ltr',
                      }}
                    >
                      <p style={{ margin: '5px' }}>
                        {pageNumber || (numPages ? 1 : '--')} {t('of')}{' '}
                        {numPages || '--'}
                      </p>
                      <button
                        type='button'
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                        style={{
                          margin: '0 5px',
                          color: '#fff',
                          borderRadius: '15px',
                        }}
                      >
                        <Trans>previous</Trans>
                      </button>
                      <button
                        type='button'
                        disabled={pageNumber >= numPages!}
                        onClick={nextPage}
                        style={{
                          margin: '0 5px',
                          color: '#fff',
                          borderRadius: '15px',
                        }}
                      >
                        <Trans>next</Trans>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div style={{ margin: '10px 0' }}>
                  <Trans>no_attachments</Trans>
                </div>
              ))}
          </>
        </>
      </FormTemplate>
      <PContainer>
        <TitleBold language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>evaluation_questions</Trans>
        </TitleBold>
      </PContainer>
      <FormTemplate>
        {type === 'evaluation-template' ? (
          <EvaluationTemplateQuestionDetails
            data={data?.questions}
            type={data?.type}
          />
        ) : (
          <QuestionDetailsRow type={template?.data?.type} />
        )}
      </FormTemplate>
    </Form>
  );
};

export default React.memo(QuestionsDetails);
