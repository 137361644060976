import Colors from "./Colors";

export const defaultToken = {
  fontFamily: "Famtree",
  colorPrimary: Colors.dark_mint,
  colorPrimaryHover: Colors.dark_mint,
  colorBorder: Colors.dark_mint,
  borderHover: " 0.5px",
  colorError: Colors.lipstick,
  colorErrorBorder: Colors.lipstick,
};
