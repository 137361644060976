/** @format */

//@ts-nocheck
import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

export const Head = styled.p`
  font-size: 20px;
  color: ${Colors.charcoal_grey};
  font-weight: bold;
  font-family: Famtree;
  line-height: normal;
  text-align: end;
  padding-top: 29px;
`;

type Props = {
  className?: string;
  language?: string;
};
export const EmptyCard = styled.div<Props>`
  background-color: white;
  min-height: 50vh;
  margin: ${(props) =>
    props.language === "ar" ? "0px 0px 30px 30px" : "0px 25px 0px 0px"};
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  justify-content: ${(props) =>
    props.className === "help" ? "unset" : "center"};

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${Colors.light_gray};
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.gray};
    border-radius: 10px;
  }

  @media (max-width: 900px) {
    margin: unset;
    padding: 14px;
  }
`;

export const Pargraph = styled.div`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 18px;
  font-weight: normal;
  margin: 18px 0 34px;
`;

export const Span = styled.span`
  color: white;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
`;
