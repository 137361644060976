/** @format */

import Cookies from "js-cookie";

const storage = {
  getItem: (key: string) => {
    return Cookies.get(key);
  },
  setItem: (key: string, item: string) => {
    Cookies.set(key, item);
  },
  clearItem: (item: string) => {
    Cookies.remove(item);
  },
};

export default storage;
