/** @format */

import { create } from "zustand";

export const useStore = create((set) => ({
  template: [],
  setTemplates: (data) => {
    set((state) => ({
      template: {
        ...state.template,
        data,
      },
    }));
  },
}));
