import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid;
  padding: 10px;
  position: absolute;
  bottom: 0px;
`;

export const CopyRightText = styled.p`
  font-family: Famtree;
  font-size: 13px;
  color: ${Colors.charcoal_grey};
  margin: 0px;
`;

export const Text = styled.a`
  font-family: Famtree;
  font-size: 13px;
  color: ${Colors.charcoal_grey};

  :hover {
    color: ${Colors.dark_mint};
  }
`;
export const RightText = styled.p`
  font-family: Famtree;
  font-size: 13px;
  margin-right: 15px;
  color: ${Colors.charcoal_grey};
`;

export const SubContainer = styled.div`
  display: flex;
  margin-left: 31px;
  margin-right: auto;
`;
