/**
 * /* @no-unused-expressions
 *
 * @format
 */

//@ts-nocheck
import React, { useEffect, useMemo } from 'react';
import FormTemplate from 'common/FormTemplate';
import { CustomHeader } from 'common/Header';
import { Input } from 'common/Input';
import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { mockedArr } from 'infrastructure/helpers/questionTypeList';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import {
  DataProps,
  QuestionsProps,
} from 'infrastructure/interfaces/evaluationForm.interface';
import { axiosApiInstance } from 'infrastructure/utils/api';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Error } from '../../style';
import {
  CreateFormSection,
  EmptyMessage,
  EmptyState,
  EvaluationTemplateFormContainer,
  Label,
  SectionTitle,
  TabButton,
  TabsContainer,
  ImgLoadingContainer,
  LabelImag,
  LabelImagEng,
  ImageContainer,
  FileName,
} from '../style';
import Form from './form';
import WeightForm from './weightForm';
import hash from 'object-hash';
import FileUploadIcon from 'assets/svg/fileUploadIcon';
import { Progress } from 'common/Progress';
import RedIconDelete from 'assets/svg/red-icon-delete';

const { v4: uuidv4 } = require('uuid'); //using for the draggable component and the delete functionality for multi and single choices

const { useState } = React;

let initialEvaluationTemplate = JSON.parse(
  localStorage.getItem('draftForm')
) || {
  id: uuidv4(),
  name: '',
  description: '',
  type: '',
  questions: [
    {
      id: uuidv4(),
      question_type_id: 0,
      question: '',
      required: true,
      file: '',
      extra_images: false,
      extra_videos: false,
      category: '',
      total_points: 0,
      weight_percentage: 0,
      options: [
        {
          id: uuidv4(),
          option: 'جميع الاجابات لا تنطبق',
          points: 0,
        },
      ],
      scale: { min: 0, max: 0, step: 0 },
    },
  ],
};

const CreateEvaluationTemplate = () => {
  let sumTotalPoints = 0;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [showImage, setShowImage] = useState(null);
  const [requestedImgUrl, setRequestedImgUrl] = useState<object | string | any>(
    {
      file: '',
    }
  );

  const [fileLoading, setFileLoading] = useState(false);

  const [fileName, setFileName] = useState('');

  const [active, setActive] = useState<any>({
    key: initialEvaluationTemplate.type,
  });

  const [evaluationTemplates, setEvaluationTemplates] = useState<DataProps>(
    () => ({
      ...initialEvaluationTemplate,
    })
  );

  const { handleSubmit, register, errors, formState, reset } = useForm({
    defaultValues: initialEvaluationTemplate,
    mode: 'onBlur',
  });

  const { data }: any = useQuery(
    ['questionTypes'],
    () => fetchGetRequests('api/question-types'),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  // question_categories
  const question_categories = localStorage.getItem('question_categories')
    ? JSON.parse(localStorage.getItem('question_categories') || '')
    : [];

  const [selectInputValues, setSelectInputValues] =
    useState(question_categories);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hash(initialEvaluationTemplate) !== hash(evaluationTemplates)) {
        initialEvaluationTemplate = evaluationTemplates;
        setEvaluationTemplates(initialEvaluationTemplate);
        localStorage.setItem(
          'draftForm',
          JSON.stringify(initialEvaluationTemplate)
        );

        setSaved(true);
      }
    }, 300);

    return () => {
      clearInterval(interval);
      setSaved(false);
    };
  }, [evaluationTemplates, initialEvaluationTemplate]);

  useEffect(() => {
    const valuesList = selectInputValues.filter(
      (v: { value: any }, i: any, a: any[]) =>
        a.findIndex((v2: { value: any }) => v2.value === v.value) === i
    );
    localStorage.setItem('question_categories', JSON.stringify(valuesList));
  }, [selectInputValues]);

  const handleRemoveQuestionBlockOrMultiChoiceQuestionsItem = (
    index: number,
    i: number,
    type: string
  ) => {
    const list = evaluationTemplates.questions;
    if (type === 'multiChoice') {
      const questions = list.map((question) => {
        const options = question?.options?.filter((option: any) => {
          return option.id !== i;
        });
        const sumOfMultiChoiceOptions = options?.reduce(
          (acc: number, option: any) => acc + option.points,
          0
        );

        list[index].total_points = sumOfMultiChoiceOptions;

        return { ...question, options };
      });
      setEvaluationTemplates((prevState) => {
        return {
          ...prevState,
          questions,
        };
      });
    } else {
      const questions = list.filter((question) => question.id !== index);
      setEvaluationTemplates((prevState) => {
        return {
          ...prevState,
          questions,
        };
      });
    }
  };

  const computedEvaluationTemplates = useMemo(() => {
    return {
      ...evaluationTemplates,
      questions: evaluationTemplates?.questions?.map((question) => {
        sumTotalPoints = evaluationTemplates.questions.reduce((acc, curr) => {
          if (
            curr.question_type_id === 2 ||
            curr.question_type_id === 8 ||
            curr.question_type_id === 9
          ) {
            return acc + curr.total_points;
          } else {
            return acc;
          }
        }, 0);

        question.weight_percentage = Math.round(
          (question.total_points * 100) / sumTotalPoints
        );
        return question;
      }),
    };
  }, [evaluationTemplates]);

  const handleAddQuestionBlockOrMultiChoiceQuestion = (
    index: number,
    i: number,
    type: string
  ) => {
    if (
      type === 'multiChoice' &&
      evaluationTemplates?.questions[index].options?.length - 1 === i &&
      evaluationTemplates?.questions[index].options?.length <= 4
    ) {
      let newQuestions: Array<QuestionsProps> = [
        ...evaluationTemplates.questions,
      ];
      newQuestions[index] = {
        ...newQuestions[index],
        options: [
          ...evaluationTemplates.questions[index].options,
          {
            id: uuidv4(),
            option: '',
            points: null,
          },
        ],
      };

      setEvaluationTemplates({
        ...evaluationTemplates,
        questions: newQuestions,
      });
    } else if (
      type !== 'multiChoice' &&
      evaluationTemplates.questions.length - 1 === index
    ) {
      setEvaluationTemplates({
        ...evaluationTemplates,
        questions: [
          //@ts-ignore
          ...evaluationTemplates.questions,
          //@ts-ignore
          {
            id: uuidv4(),
            question_type_id: 0,
            question: '',
            required: true,
            scale: { min: 0, max: 0, step: 0 },
            options: [
              {
                id: uuidv4(),
                option: '',
                points: null,
              },
            ],
          },
        ],
      });
    }
  };

  const handleOnChangeSwitchButton = (checked: boolean, i: number) => {
    const list: any = evaluationTemplates.questions;
    list[i]['required'] = checked;
    setEvaluationTemplates({ ...evaluationTemplates, questions: list });
  };

  const handleInputChange = (e: Event, index?: number, i?: any, type?: any) => {
    const { name, value, id }: any = e.target;
    if (type === 'form') {
      let list: any = evaluationTemplates.questions;
      if (
        id === 'min' ||
        id === 'max' ||
        id === 'step' ||
        id === 'min_points' ||
        id === 'max_points'
      ) {
        const scaleChoicePoints = Object.values({
          min_points:
            id === 'min_points'
              ? parseInt(value)
              : parseInt(list[index].scale.min_points || 0),
          max_points:
            id === 'max_points'
              ? parseInt(value)
              : parseInt(list[index].scale.max_points || 0),
        });

        list[index].total_points = Math.max(...scaleChoicePoints);

        list[index].scale[id] = parseInt(value);
        setEvaluationTemplates({ ...evaluationTemplates, questions: list });
      } else if (id === 'options') {
        list[index].options[i].option = value;
        setEvaluationTemplates({ ...evaluationTemplates, questions: list });
      } else if (id === 'points') {
        list[index].options[i].points = parseInt(value);
        if (list[index].question_type_id === 9) {
          let arr = [];
          list[index].options?.filter((item: { [x: string]: any }) => {
            if (item.points > 0) {
              arr.push(item.points);
            }
          });
          list[index].total_points = Math.max(...arr);
        }
        if (list[index].question_type_id === 8) {
          let arr = [];
          list[index].options?.filter((item: { [x: string]: any }) => {
            if (item.points > 0) {
              arr.push(item.points);
            }
          });

          const sumOfMultiChoiceOptions = arr?.reduce(
            (partialSum: any, a: any) => partialSum + a,
            0
          );

          list[index].total_points = sumOfMultiChoiceOptions;
        }
        setEvaluationTemplates({ ...evaluationTemplates, questions: list });
      } else {
        list[index][id] = value;
        setEvaluationTemplates({ ...evaluationTemplates, questions: list });
      }
    } else {
      let list: any = { ...evaluationTemplates };
      list[name] = value;
      setEvaluationTemplates(list);
    }
  };

  const handleSelectOnChange = (value: number, i: number) => {
    const list: any = evaluationTemplates.questions;
    list[i]['question_type_id'] = value;
    if (list[i].question_type_id === 9) {
      list[i].options = [
        { option: '', points: null },
        { option: '', points: null },
      ];
    }
    setEvaluationTemplates({ ...evaluationTemplates, questions: list });
  };

  const handleQuestionCategoryChange = (newSelectValue: any, i: any) => {
    const list: any = evaluationTemplates.questions;
    list[i]['category'] = newSelectValue ? newSelectValue.value : '';
    setEvaluationTemplates({ ...evaluationTemplates, questions: list });
    newSelectValue &&
      setSelectInputValues([...selectInputValues, newSelectValue]);
  };

  const onExtraImagesChange = (e: any, i: number) => {
    const list: any = evaluationTemplates.questions;
    list[i]['extra_images'] = e.target.checked;
    setEvaluationTemplates({ ...evaluationTemplates, questions: list });
  };

  const onExtraVideosChange = (e: any, i: number) => {
    const list: any = evaluationTemplates.questions;
    list[i]['extra_videos'] = e.target.checked;
    setEvaluationTemplates({ ...evaluationTemplates, questions: list });
  };

  const onSubmit = () => {
    setLoading(true);
    delete computedEvaluationTemplates.id;
    if (active.key === 'points') {
      computedEvaluationTemplates.type = 'points';
    } else {
      computedEvaluationTemplates.type = 'default';
    }
    let options = [];
    computedEvaluationTemplates.questions.map((question: any, index) => {
      if (question.question_type_id === 8) {
        options = computedEvaluationTemplates?.questions[index]?.options?.push({
          option: 'جميع الاجابات لا تنطبق',
          points: 0,
        });
      }

      options = question?.options?.map(
        (option: { id?: string; options: string; points: number }) => {
          delete option.id;
          return option;
        }
      );
      question.options = options;
      if (question.options?.includes('') || question.question_type_id === 2) {
        delete question.options;
      }
      if (question.question_type_id !== 2) {
        delete question.scale;
      }
      if (question.question_type_id !== 8 && question.question_type_id !== 9) {
        delete question.options;
      }
      return options;
    });

    if (requestedImgUrl! == null) {
      computedEvaluationTemplates['file'] = requestedImgUrl;
    }

    axiosApiInstance
      .post('api/company/v1/evaluation-templates', computedEvaluationTemplates)
      .then(() => {
        setLoading(false);
        showNotification(
          NotificationStatus.Success,
          t('done'),
          t('done_message')
        );
        reset();
        localStorage.removeItem('draftForm');
        navigate('/evaluation-templates');
      })
      .catch((err) => {
        setLoading(false);
        showNotification(
          NotificationStatus.Error,
          t('error'),
          err.response?.data.message
        );
      });
  };

  const handleTabClick = (key: string) => {
    //@ts-ignore
    setActive({ key: key });
    let list: any = { ...evaluationTemplates };
    list['type'] = key;
    setEvaluationTemplates(list);
  };

  const handleImageUploading = async (e: {
    file: { name: any };
    target: { files: (string | Blob)[] };
  }) => {
    setFileLoading(true);
    setFileName(e.target.files[0].name);
    const image_url = '/api/file-upload';
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('entity', 'templates');
    formData.append('file_type', 'files');
    formData.append('temp_path', 'false');

    formData !== null &&
      (await axiosApiInstance
        .post(image_url, formData)
        .then((res) => {
          setFileLoading(false);
          setShowImage(res.data);
          setRequestedImgUrl(res.data.fullUrl);
          evaluationTemplates.file = res.data.fullUrl;
        })
        .catch((err) => {
          setFileLoading(false);
          showNotification(NotificationStatus.Error, 'Error', err.message);
        }));
  };

  const handleDeleteFile = () => {
    setShowImage(null);
  };

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {i18n.language === 'ar'
            ? 'أضف نموذج تقييم - تدري'
            : 'Add evaluation template - tdree'}
        </title>
      </Helmet>
      <EvaluationTemplateFormContainer>
        {saved && (
          <Label
            language={i18n.language === 'ar' ? 'ar' : 'en'}
            style={{ fontSize: '10px', width: '100%' }}
          >
            ... جاري الحفظ التلقائي
          </Label>
        )}
        <CustomHeader
          title={<Trans>add_evaluation_template</Trans>}
          path={'/evaluation-templates'}
          pdff='evaluations'
        />
      </EvaluationTemplateFormContainer>

      <FormTemplate style={{ margin: '0 auto', width: '70%' }}>
        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>evaluation_template_name</Trans>
        </Label>
        <Input
          name='name'
          placeholder={t('placeholder')}
          type='text'
          dir={i18n.language === 'ar' && 'true'}
          style={{ width: '100%' }}
          errorName={errors.name}
          ref={register({ required: true, maxLength: 50 })}
          onChange={(e: Event) => handleInputChange(e)}
        />
        {errors?.name?.type && (
          <Error>
            <Trans>evaluation_template_name_required</Trans>
          </Error>
        )}
        {errors?.name?.type && (
          <Error>
            <Trans
              values={{
                count: 50,
              }}
            >
              maxlenght_error_message
            </Trans>
          </Error>
        )}
        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>evaluation_template_description</Trans>
        </Label>
        <Input
          name='description'
          type='text'
          dir='true'
          style={{ width: '100%' }}
          errorName={errors.description}
          onChange={(e: Event) => handleInputChange(e)}
          ref={register({ required: true, maxLength: 200 })}
        />
        {errors?.description?.type && (
          <Error>
            <Trans>evaluation_template_description_required</Trans>
          </Error>
        )}
        {errors?.description?.type && (
          <Error>
            <Trans
              values={{
                count: 200,
              }}
            >
              maxlenght_error_message
            </Trans>
          </Error>
        )}

        <Label language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>add_pdf_to_template</Trans>
        </Label>

        <ImageContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
          {fileLoading && (
            <div style={{ position: 'relative' }}>
              <ImgLoadingContainer>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                  }}
                >
                  <FileUploadIcon />
                  <FileName>{fileName}</FileName>
                </div>
                <Progress status='active' percent={40} showInfo />
              </ImgLoadingContainer>
            </div>
          )}

          <label htmlFor='file' style={{ width: '100%' }}>
            {showImage ? (
              <div style={{ position: 'relative' }}>
                <ImgLoadingContainer active>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'flex-start',
                      }}
                    >
                      <FileUploadIcon />
                      <FileName>{fileName}</FileName>
                    </div>
                    <RedIconDelete onClick={() => handleDeleteFile()} />
                  </div>
                  <Progress status='success' percent={100} showInfo />
                </ImgLoadingContainer>
              </div>
            ) : i18n.language === 'ar' && !fileLoading ? (
              <LabelImag>
                <Input
                  type='file'
                  id='file'
                  accept='.pdf'
                  name='image_url'
                  style={{ display: 'none' }}
                  onChange={(e: any) => handleImageUploading(e)}
                />
              </LabelImag>
            ) : (
              i18n.language === 'en' &&
              !fileLoading && (
                <LabelImagEng>
                  <Input
                    type='file'
                    id='file'
                    accept='.pdf'
                    name='image_url'
                    style={{ display: 'none' }}
                    onChange={(e: any) => handleImageUploading(e)}
                  />
                </LabelImagEng>
              )
            )}
          </label>
        </ImageContainer>
      </FormTemplate>

      <CreateFormSection language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <SectionTitle language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>evaluation_type</Trans>
        </SectionTitle>

        <TabsContainer>
          <TabButton
            style={{ marginBottom: '15px' }}
            onClick={() => handleTabClick('default')}
            //@ts-ignore
            className={active.key === 'default' ? 'active' : 'notActive'}
          >
            <Trans>base_template</Trans>
          </TabButton>

          <TabButton
            onClick={() => handleTabClick('points')}
            //@ts-ignore
            className={active.key === 'points' ? 'active' : 'notActive'}
          >
            <Trans>template_with_points_and_weight</Trans>
          </TabButton>
        </TabsContainer>

        {!active.key && active.key !== 'default' && active.key !== 'points' && (
          <EmptyState>
            <EmptyMessage>
              <Trans>select_tab</Trans>
            </EmptyMessage>
          </EmptyState>
        )}

        {
          //@ts-ignore
          active.key === 'default' && (
            <Form
              data={evaluationTemplates}
              setEvaluationTemplates={setEvaluationTemplates}
              questionTypes={data?.response?.questionTypes}
              mockedArr={mockedArr}
              handleRemoveQuestionBlockOrMultiChoiceQuestionsItem={
                handleRemoveQuestionBlockOrMultiChoiceQuestionsItem
              }
              handleAddQuestionBlockOrMultiChoiceQuestion={
                handleAddQuestionBlockOrMultiChoiceQuestion
              }
              handleOnChangeSwitchButton={handleOnChangeSwitchButton}
              handleInputChange={handleInputChange}
              handleSelectOnChange={handleSelectOnChange}
              handleSubmit={handleSubmit(onSubmit)}
              errors={errors}
              register={register}
              formState={formState}
              loading={loading}
              onExtraImagesChange={onExtraImagesChange}
              onExtraVideosChange={onExtraVideosChange}
              handleQuestionCategoryChange={handleQuestionCategoryChange}
              question_categories={question_categories}
            />
          )
        }
        {
          //@ts-ignore
          active.key === 'points' && (
            <WeightForm
              data={computedEvaluationTemplates}
              setEvaluationTemplates={setEvaluationTemplates}
              questionTypes={data?.response?.questionTypes}
              mockedArr={mockedArr}
              handleRemoveQuestionBlockOrMultiChoiceQuestionsItem={
                handleRemoveQuestionBlockOrMultiChoiceQuestionsItem
              }
              handleAddQuestionBlockOrMultiChoiceQuestion={
                handleAddQuestionBlockOrMultiChoiceQuestion
              }
              handleOnChangeSwitchButton={handleOnChangeSwitchButton}
              handleInputChange={handleInputChange}
              handleSelectOnChange={handleSelectOnChange}
              handleSubmit={handleSubmit(onSubmit)}
              errors={errors}
              register={register}
              formState={formState}
              loading={loading}
              onExtraImagesChange={onExtraImagesChange}
              onExtraVideosChange={onExtraVideosChange}
              handleQuestionCategoryChange={handleQuestionCategoryChange}
              question_categories={question_categories}
            />
          )
        }
      </CreateFormSection>
    </>
  );
};

export default CreateEvaluationTemplate;
