/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { timeStampToDate } from "infrastructure/helpers/timeStampToDate";
import { JobsDetailsProps } from "infrastructure/interfaces/jobs.interface";
import {
  DetailsSectionContainer,
  Head,
  Titles,
  NameLabel,
  TimeLabel,
  StatusLabel,
  DescriptionSection,
  InstructionLabel,
  OptionalValues,
} from "../style";

const DetailsSection = ({ data }: JobsDetailsProps) => {
  const { i18n } = useTranslation();
  return (
    <DetailsSectionContainer language={i18n.language === "ar" ? "ar" : "en"}>
      <Head>
        <div>
          <Titles>
            <Trans>evaluation_place</Trans>
          </Titles>
          <NameLabel>{data?.branch?.name}</NameLabel>
        </div>

        <div>
          <Titles>
            <Trans>starting_evaluation</Trans>
          </Titles>
          <TimeLabel> {timeStampToDate(data?.startTime)} </TimeLabel>
        </div>

        <div>
          <Titles>
            <Trans>ending_evaluation</Trans>
          </Titles>
          <TimeLabel>{timeStampToDate(data?.endTime)}</TimeLabel>
        </div>

        <div>
          <Titles>
            <Trans>evaluation_status</Trans>
          </Titles>
          <StatusLabel>{data?.status.name}</StatusLabel>
        </div>
      </Head>

      <DescriptionSection>
        <Titles>
          <Trans>description</Trans>
        </Titles>
        <NameLabel>{data?.description}</NameLabel>
      </DescriptionSection>

      <DescriptionSection>
        <Titles>
          <Trans>instructions</Trans>
        </Titles>
        <NameLabel>{data?.instructions}</NameLabel>
      </DescriptionSection>

      <InstructionLabel>
        <Trans>instructions_label</Trans>
      </InstructionLabel>

      <Head>
        <OptionalValues>
          <Titles>
            <Trans>gender</Trans>
          </Titles>
          <NameLabel>{data?.gender}</NameLabel>
        </OptionalValues>

        <OptionalValues>
          <Titles>
            <Trans>age</Trans>
          </Titles>
          <NameLabel>
            {data?.age?.name ? data.age.name : <Trans>not_specified</Trans>}
          </NameLabel>
        </OptionalValues>

        <OptionalValues>
          <Titles>
            <Trans>education</Trans>
          </Titles>
          <NameLabel>
            {data?.education?.name ? (
              data?.education?.name
            ) : (
              <Trans>not_specified</Trans>
            )}
          </NameLabel>
        </OptionalValues>

        <OptionalValues>
          <Titles>
            <Trans>experiences</Trans>
          </Titles>
          <NameLabel>
            {data?.experience?.name ? (
              data?.experience?.name
            ) : (
              <Trans>not_specified</Trans>
            )}
          </NameLabel>
        </OptionalValues>

        <OptionalValues>
          <Titles>
            <Trans>languages</Trans>
          </Titles>
          <NameLabel>
            {data?.languages?.length !== 0 ? (
              data?.languages?.map((language: string) => {
                return "," + language;
              })
            ) : (
              <Trans>not_specified</Trans>
            )}
          </NameLabel>
        </OptionalValues>

        <OptionalValues>
          <Titles>
            <Trans>nationality</Trans>
          </Titles>
          <NameLabel>
            {data?.nationality?.name ? (
              data?.nationality?.name
            ) : (
              <Trans>not_specified</Trans>
            )}
          </NameLabel>
        </OptionalValues>

        <OptionalValues>
          <Titles>
            <Trans>skills</Trans>
          </Titles>
          <NameLabel>
            {data?.skills?.length !== 0 ? (
              data?.skills?.map((skill: string) => {
                return "," + skill;
              })
            ) : (
              <Trans>not_specified</Trans>
            )}
          </NameLabel>
        </OptionalValues>

        {data?.extra_pay && (
          <OptionalValues>
            <Titles>
              <Trans>extra_items</Trans>
            </Titles>
            <NameLabel>
              {data?.extraPay?.name}: <Trans>by_amount</Trans>
              {data?.extraPay?.amount} <Trans>sar</Trans>
            </NameLabel>
          </OptionalValues>
        )}
      </Head>
    </DetailsSectionContainer>
  );
};

export default DetailsSection;
