/** @format */

import React from 'react';
import { StyledContainer, LeftContainer, RightContainer } from '../style';

import Form from './form';
import { axiosApiInstance } from 'infrastructure/utils/api';
import { useNavigate } from 'react-router-dom';
import { LoginData } from 'infrastructure/interfaces/login.interface';
import { useQueryClient, useMutation } from 'react-query';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import TadreeComponent from 'assets/svg/tdree-component';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import storage from 'infrastructure/utils/storage';
import Spinner from 'common/LoadingIndecator';

const { useState } = React;

const Login: any = () => {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 800 });

  queryClient.setMutationDefaults('login', {
    mutationFn: (userData: LoginData) =>
      axiosApiInstance.post('api/company/login', {
        email: userData?.email,
        password: userData?.password,
      }),
    onError: (err) => {
      setServerError(err);
      storage.setItem('user_data', err.response?.config?.data);
      setIsloading(false);
      if (err.response.data.statusCode === 400) {
        showNotification(
          NotificationStatus.Error,
          ' ',
          err.response.data.message
        );
      }
    },
    onSuccess: (res) => {
      const data = {
        id: res.data.company.id,
        name: res.data.company.name,
        mobile: res.data.company.mobile,
        website: res.data.company.website,
        image_url: res.data.company.image,
        email: res.data.company.email,
        newUser: res.data.company.newUser,
      };

      storage.setItem('access_token', res.data.access_token);
      storage.setItem('user_image', res.data.company.image);
      storage.setItem('user_data', JSON.stringify(data) || '');
      setIsloading(false);

      if (res.status === 200) {
        navigate('/home');
      }
    },
  });

  const mutation = useMutation('login');

  const onSubmit = (userData: LoginData | any) => {
    setIsloading(true);
    mutation.mutate(userData);
  };

  if (isLoading) {
    return <Spinner size={'large'} tip={'Loading...'} />;
  }

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{i18n.language === 'ar' ? 'تدري' : 'Tdree'}</title>
      </Helmet>
      <StyledContainer>
        {!isMobile && (
          <RightContainer right={i18n.language === 'ar' || false}>
            <TadreeComponent />
          </RightContainer>
        )}
        <LeftContainer login right={i18n.language === 'ar' || false}>
          <Form onSubmit={onSubmit} serverError={serverError} />
        </LeftContainer>
      </StyledContainer>
    </>
  );
};

export default Login;
