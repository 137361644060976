/** @format */

import React from "react";
import { useNavigate } from "react-router";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { useTranslation } from "react-i18next";
import Spinner from "common/LoadingIndecator";

export const PayTabsForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");
  const amount = params.get("amount");

  if (token) {
    axiosApiInstance
      .post("/api/company/v1/wallet/charge?source=paytabs", {
        amount: amount,
        payment_token: token,
      })
      .then((res) => {
        if (res.data.redirect) {
          window.location.href = res.data.redirectURL;
        } else if (!res.data.redirect) {
          navigate("/wallet");
          showNotification(
            NotificationStatus.Success,
            t("done"),
            t("done_message")
          );
        } else {
          showNotification(
            NotificationStatus.Error,
            t("error"),
            t("payment_failed")
          );
        }
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          t("error"),
          err.response.data.message
        );
        navigate("/wallet");
      });
  }

  return (
    <Spinner
      size="default"
      tip="Please wait while your transaction is processing..."
    />
  );
};
