/** @format */

import {
  showNotification,
  NotificationStatus,
} from "infrastructure/helpers/showNotifications";
import { axiosApiInstance } from "infrastructure/utils/api";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import ChargeWithBankAccountForm from "./form";

type ChargeByBankAccountData = {
  sender_name: string;
  bank_name: string;
  amount: number;
  image_url: string;
};
const ChargeWithBankAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleSubmit, register, errors, formState } = useForm({
    mode: "onBlur",
    criteriaMode: "all",
  });

  const [requestedImgUrl, setRequestedImgUrl] = useState<object | string | any>(
    {
      file: "",
    }
  );
  const [loading, setLoading] = useState(false);
  const [showImage, setShowImage] = useState(null);

  const queryClient = useQueryClient();
  const mutation = useMutation("chargeWithBankAccount");

  queryClient.setMutationDefaults("chargeWithBankAccount", {
    mutationFn: (userData: ChargeByBankAccountData) =>
      axiosApiInstance.post("api/company/v1/wallet/charge/bank", {
        sender_name: userData.sender_name,
        bank_name: userData.bank_name,
        amount: userData.amount,
        image_url: requestedImgUrl.file,
      }),
    onError: (err) => {
      if (err.response.data.statusCode === 400) {
        showNotification(
          NotificationStatus.Error,
          " ",
          err.response.data.message
        );
      }
    },
    onSuccess: () => {
      navigate("/wallet");
      showNotification(
        NotificationStatus.Success,
        t("done"),
        t("done_message")
      );
    },
  });

  const onSubmit = (userData) => {
    mutation.mutate(userData);
  };

  const handleImageUploading = async (e: {
    file: { name: any };
    target: { files: (string | Blob)[] };
  }) => {
    setLoading(true);
    const image_url = "/api/file-upload";
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("entity", "wallet");
    formData.append("file_type", "images");
    formData.append("temp_path", "true");

    await axiosApiInstance
      .post(image_url, formData)
      .then((res) => {
        setLoading(false);
        setShowImage(res.data);
        setRequestedImgUrl(res.data);
      })
      .catch((err) => {
        setLoading(false);
        showNotification(
          NotificationStatus.Error,
          "Error",
          err.response.data.message
        );
      });
  };

  return (
    <ChargeWithBankAccountForm
      onSubmit={handleSubmit(onSubmit)}
      errors={errors}
      register={register}
      formState={formState}
      handleImageUploading={handleImageUploading}
      requestedImgUrl={requestedImgUrl}
      loading={loading}
      showImage={showImage}
    />
  );
};

export default ChargeWithBankAccount;
