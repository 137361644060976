/** @format */

import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import FormTemplate from 'common/FormTemplate';
import { BlackTitleBold, P, Form, Hr } from './style';
import { ManagersFormProps } from 'infrastructure/interfaces/managers-form.interface';
import { useQuery } from 'react-query';
import { fetchGetRequestsWithPagination } from 'infrastructure/helpers/fetchServerRequests';

const Details = ({ data }: ManagersFormProps) => {
  const { i18n } = useTranslation();

  const { data: branchesList } = useQuery(['BranchesData'], () =>
    fetchGetRequestsWithPagination(`api/company/v1/branches`)
  );

  const [branches, setBranches] = useState<any>([]);

  useEffect(() => {
    if (branchesList) {
      setBranches(
        branchesList?.branches?.map((branch: { id: Number; name: String }) => {
          return {
            value: branch.id,
            label: branch.name,
          };
        })
      );
    }
  }, [branchesList]);

  return (
    <Form language={i18n.language === 'ar' ? 'ar' : 'en'} noValidate>
      <FormTemplate>
        {/* name */}
        <P>
          <Trans>manager_name</Trans>
        </P>
        <BlackTitleBold>{data?.name}</BlackTitleBold>
        <Hr />
        {/* role */}
        <P>
          <Trans>role</Trans>
        </P>
        <BlackTitleBold>{data?.role}</BlackTitleBold>
        <Hr />
        {/* email */}
        <P>
          <Trans>email</Trans>
        </P>
        <BlackTitleBold>{data?.email}</BlackTitleBold>
        <Hr />
        {/* phone */}
        <P>
          <Trans>phone_number</Trans>
        </P>
        <BlackTitleBold>{data?.mobile}</BlackTitleBold>
        <Hr />
        {/* show_wallet */}
        <P>
          <Trans>show_wallet_company_app</Trans>
        </P>
        <BlackTitleBold>
          {data?.show_wallet ? <Trans>yes</Trans> : <Trans>no</Trans>}
        </BlackTitleBold>
        <Hr />
        {/* report */}
        <P>
          <Trans>show_all_reports</Trans>
        </P>
        <BlackTitleBold>
          {data?.view_all_reports ? <Trans>yes</Trans> : <Trans>no</Trans>}
        </BlackTitleBold>
        <Hr />
        <P>
          <Trans>show_shoppers_number</Trans>
        </P>
        <BlackTitleBold>
          {data?.show_shoppers_count ? <Trans>yes</Trans> : <Trans>no</Trans>}
        </BlackTitleBold>
        <Hr />

        <P>
          <Trans>show_dates</Trans>
        </P>
        <BlackTitleBold>
          {data?.show_dates ? <Trans>yes</Trans> : <Trans>no</Trans>}
        </BlackTitleBold>
        <Hr />

        <P>
          <Trans>show_home_in_app</Trans>
        </P>
        <BlackTitleBold>
          {data?.show_home ? <Trans>yes</Trans> : <Trans>no</Trans>}
        </BlackTitleBold>
        <Hr />

        <P>
          <Trans>show_order_in_app</Trans>
        </P>
        <BlackTitleBold>
          {data?.show_order ? <Trans>yes</Trans> : <Trans>no</Trans>}
        </BlackTitleBold>
        <Hr />

        <P>
          <Trans>branches</Trans>
        </P>
        {branches.map((branch) => (
          <BlackTitleBold>{branch.label}</BlackTitleBold>
        ))}
      </FormTemplate>
    </Form>
  );
};

export default React.memo(Details);
