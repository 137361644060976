import * as React from "react";
import { SVGProps } from "react";

const AvergeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={184} height={160} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none">
      <path
        d="m170.362 148.287 4.956-1.575a16.826 16.826 0 0 0-.12-8.087c-1.99 4.436-7.833 5.523-11.161 9.063a10.123 10.123 0 0 0-2.582 8.373l-1.043 3.545a16.903 16.903 0 0 0 12.307-7.014 16.375 16.375 0 0 0 1.914-3.63 44.86 44.86 0 0 1-4.271-.675z"
        fill="#F2F2F2"
      />
      <path
        d="m165.065 141.734 3.735-3.625a16.82 16.82 0 0 0-3.706-7.184c.192 4.86-4.553 8.443-5.956 13.099a10.126 10.126 0 0 0 1.416 8.648l.645 3.638a16.943 16.943 0 0 0 7.893-11.777 16.39 16.39 0 0 0 .097-4.104 45.087 45.087 0 0 1-4.124 1.305z"
        fill="#F2F2F2"
      />
      <path
        d="M140.237 10.623H.544a.556.556 0 0 1 0-1.113h139.693a.556.556 0 0 1 0 1.113z"
        fill="#3F3D56"
      />
      <ellipse fill="#3F3D56" cx={10.053} cy={3.334} rx={3.256} ry={3.334} />
      <ellipse fill="#3F3D56" cx={21.3} cy={3.334} rx={3.256} ry={3.334} />
      <ellipse fill="#3F3D56" cx={32.546} cy={3.334} rx={3.256} ry={3.334} />
      <path
        d="M133.465.826h-7.989a.606.606 0 0 0 0 1.213h7.99a.606.606 0 0 0 0-1.213zm0 2.275h-7.989a.606.606 0 0 0 0 1.212h7.99a.606.606 0 0 0 0-1.212zm0 2.272h-7.989a.606.606 0 0 0 0 1.212h7.99a.606.606 0 0 0 0-1.212z"
        fill="#3F3D56"
      />
      <ellipse fill="#E6E7E8" cx={10.822} cy={29.203} rx={4.025} ry={4.032} />
      <path
        d="M113.317 38.133H7.996a1.2 1.2 0 0 1-1.199-1.201 1.2 1.2 0 0 1 1.199-1.201h105.321a1.2 1.2 0 0 1 1.199 1.2 1.2 1.2 0 0 1-1.199 1.202zm-27.622 4.744h-77.7a1.2 1.2 0 0 1-1.198-1.2 1.2 1.2 0 0 1 1.199-1.201h77.699a1.2 1.2 0 0 1 1.198 1.2 1.2 1.2 0 0 1-1.198 1.201z"
        fill="#E6E7E8"
      />
      <ellipse fill="#E6E7E8" cx={10.822} cy={92.143} rx={4.025} ry={4.032} />
      <path
        d="M113.317 101.072H7.996a1.203 1.203 0 0 1 0-2.402h105.321c.692.04 1.24.6 1.265 1.294a1.187 1.187 0 0 1-1.265 1.108zm-27.622 4.745h-77.7a1.2 1.2 0 0 1-1.198-1.2 1.2 1.2 0 0 1 1.199-1.202h77.699a1.2 1.2 0 0 1 1.198 1.201 1.2 1.2 0 0 1-1.198 1.201z"
        fill="#E6E7E8"
      />
      <ellipse fill="#E6E7E8" cx={10.822} cy={58.301} rx={4.025} ry={4.032} />
      <path
        d="M113.317 67.23H7.996a1.2 1.2 0 0 1-1.199-1.2 1.2 1.2 0 0 1 1.199-1.202h105.321a1.2 1.2 0 0 1 1.199 1.201 1.2 1.2 0 0 1-1.199 1.201zM98.65 71.975H7.996a1.2 1.2 0 0 1-1.199-1.201 1.2 1.2 0 0 1 1.199-1.2H98.65a1.2 1.2 0 0 1 1.2 1.2 1.2 1.2 0 0 1-1.2 1.2zm0 5.035H7.996a1.2 1.2 0 0 1-1.199-1.201 1.2 1.2 0 0 1 1.199-1.2H98.65a1.2 1.2 0 0 1 1.2 1.2 1.2 1.2 0 0 1-1.2 1.2z"
        fill="#E6E7E8"
      />
      <ellipse fill="#E4F9ED" cx={94.246} cy={99.944} rx={29.807} ry={29.862} />
      <ellipse fill="#3F3D56" cx={94.205} cy={106.097} rx={4.258} ry={4.266} />
      <ellipse fill="#43C773" cx={94.205} cy={106.097} rx={1.965} ry={1.969} />
      <path
        d="M102.11 115.593H86.382a5.886 5.886 0 0 1-5.874-5.884v-7.224a5.886 5.886 0 0 1 5.874-5.885h15.726a5.886 5.886 0 0 1 5.874 5.885v7.224a5.886 5.886 0 0 1-5.874 5.884zM86.382 97.216a5.27 5.27 0 0 0-5.259 5.269v7.224a5.27 5.27 0 0 0 5.259 5.268h15.726a5.27 5.27 0 0 0 5.259-5.268v-7.224a5.27 5.27 0 0 0-5.259-5.269H86.383z"
        fill="#3F3D58"
      />
      <path
        d="M85.451 111.672h13.96a5.512 5.512 0 0 0 3.9-1.62 5.533 5.533 0 0 0 1.616-3.908v-6.045a5.512 5.512 0 0 0-.363-1.961 5.516 5.516 0 0 1 2.192 4.404v6.045a5.533 5.533 0 0 1-1.616 3.909 5.512 5.512 0 0 1-3.901 1.618H87.28a5.515 5.515 0 0 1-5.153-3.566 5.479 5.479 0 0 0 3.324 1.124z"
        fill="#3F3D56"
      />
      <path fill="#A0616A" d="M128.198 156.05h-3.43l-1.632-13.256h5.063z" />
      <path
        d="M128.319 159.634H117.77v-.134a4.11 4.11 0 0 1 4.105-4.113l1.927-1.464 3.595 1.464h.921v4.247z"
        fill="#2F2E41"
      />
      <path fill="#A0616A" d="M152.468 156.05h3.43l1.632-13.256h-5.063z" />
      <path
        d="M152.347 159.634h10.548v-.134a4.11 4.11 0 0 0-4.105-4.113l-1.927-1.464-3.595 1.464h-.92v4.247zm-29.362-26.581c.05-.756.454-4.939.454-4.939l.964-8.87 1.7-23.515.112-1.539 8.907-1.713 6.088-2.924 7.236 2.317 5.634 2.117s.01.906.053 2c.058 1.434.159 3.19.4 3.647.403.807.302 5.14.302 5.14s.4 8.815.904 9.782c.504.966.606 2.98.454 3.383-.152.403.504 10.99.504 10.99s1.684 18.733.627 21.558c-1.056 2.825-5.048.975-5.048.975s.193-.754-.965-1.11c-1.158-.355-4.684-26.918-4.684-26.918s-.656-5.447-1.26-7.008c-.602-1.561-1.157-5.947-1.157-5.947s-3.724-7.259-3.772-8.873c0 0-.487-1.51-.948.152-.46 1.663-1.819 5.645-1.819 5.645l-1.324 4.89-1.558 7.308-1.008 6.152s-1.51 8.165-1.751 8.518c-.241.353 0 7.813 0 7.813s-1.167 6.352-2.817 7.461c-1.65 1.11-6.02.018-6.02.018s-.258-15.753-.208-16.51z"
        fill="#2F2E41"
      />
      <path
        fill="#2F2E41"
        d="m126.785 72.744 1.98 5.976-2.443 2.914 1.076 1.11-.794 1.567-2.07 16.437 11.347 4.31 3.82-4.798 3.013 4.799 13.128-3.214-3.348-20.96v-1.37l-.945-2.646v-5.601l2.496-16.674-7.45-2.614-3.563-2.825-1.153 3.973-4.908-.875-.128-2.985-3.755 3.555-3.163.904-3.786.867-.483 1.209.965-.242-.157 15.719z"
      />
      <path
        d="m123.394 94.609 1.905-18.478.503-7.606-5.333-.966s.211 5.438-.487 8.506c-.69 3.028-.092 18.138-.05 18.542a2.603 2.603 0 0 0-.694 2.865 2.595 2.595 0 0 0 4.848.002 2.603 2.603 0 0 0-.692-2.865z"
        fill="#A0616A"
      />
      <path
        d="m118.192 79.96 1.483.306 5.09 1.056.281.058.6-2.392.698-.744 1.326-6.964.07-.363 2.005-4.291 1.386-12.9-5.156.929c-.027.026-.051.055-.075.084-.74.841-1.269 2.01-2.132 3.188-2.81 3.83-4.966 13.135-4.882 18.234.003.082-.082.171-.183.263a2.832 2.832 0 0 0-.511 3.536z"
        fill="#2F2E41"
      />
      <path
        d="m156.549 94.609-1.906-18.478-.503-7.606 5.334-.966s-.212 5.438.487 8.506c.69 3.028.091 18.138.049 18.542a2.603 2.603 0 0 1 .694 2.865 2.595 2.595 0 0 1-4.847.002 2.603 2.603 0 0 1 .692-2.865z"
        fill="#A0616A"
      />
      <path
        d="m161.751 79.96-1.483.306-5.09 1.056-.282.058-.6-2.392-.697-.744-1.327-6.964-.07-.363-2.004-4.291.458-12.9 3.312.929c.026.026.05.055.075.084.74.841 1.268 2.01 2.132 3.188 2.81 3.83 4.966 13.135 4.881 18.234-.002.082.082.171.184.263a2.834 2.834 0 0 1 .511 3.536z"
        fill="#2F2E41"
      />
      <path
        d="m134.42 55.635 2.423-6.366 6.189-.114 2.054 6.48 7.381 41.313s-22.614 6.31-22.385-1.274c.242-7.975 1.33-18.567 1.33-18.567l3.008-21.472z"
        fill="#4E4D5E"
      />
      <ellipse fill="#A0616A" cx={139.799} cy={40.183} rx={7.291} ry={7.304} />
      <path
        d="M132.079 34.885c1.186-1.295 2.671-3.664 7.168-4.659 5.556-1.23 8.182 3.8 6.78 3.882 4.098 1.243 1.466 9.738.291 9.026a5.785 5.785 0 0 1-2.334-3.968l-.223-2.01c-.032 1.635-1.7 1.852-3.405 1.25a2.14 2.14 0 0 1 1.51-1.491 7.307 7.307 0 0 1-4.295 1.873 2.93 2.93 0 0 1 1.422-1.961l-.067-.066a7.023 7.023 0 0 1-2.254 1.278c-.94.27-2.066.124-2.71-.613a2.004 2.004 0 0 1-.385-.694c.14 1.295-.728 2.774-1.402 2.52-.56-.11-.887-3.506-.096-4.367z"
        fill="#2F2E41"
      />
      <path
        d="M184 159.633a.367.367 0 0 1-.366.367H97.446a.366.366 0 0 1 0-.733h86.188a.365.365 0 0 1 .366.366z"
        fill="#CCC"
      />
      <path
        d="M94.488 83.752c-2.915 0-5.478 1.322-6.929 3.307a8.943 8.943 0 0 1 5.342-1.717c4.515 0 8.188 3.168 8.188 7.062v3.238h1.586v-4.827c0-3.895-3.672-7.063-8.187-7.063z"
        fill="#3F3D56"
      />
      <path
        d="M103.766 97.298h-19.04v-5.612c0-4.528 4.27-8.212 9.52-8.212s9.52 3.684 9.52 8.212v5.612zm-18.425-.616h17.81v-4.996c0-4.188-3.995-7.596-8.905-7.596-4.91 0-8.905 3.408-8.905 7.596v4.996z"
        fill="#3F3D58"
      />
      <path
        d="M99.122 95.156H89.37a1.658 1.658 0 0 1-1.655-1.658v-2.234c0-3.114 2.93-5.647 6.53-5.647 3.602 0 6.532 2.533 6.532 5.647v2.234a1.658 1.658 0 0 1-1.655 1.658zm-4.876-8.923c-3.262 0-5.916 2.257-5.916 5.031v2.234c0 .575.466 1.04 1.04 1.042h9.752a1.042 1.042 0 0 0 1.04-1.042v-2.234c0-2.774-2.654-5.031-5.916-5.031z"
        fill="#3F3D58"
      />
    </g>
  </svg>
);

export default AvergeIcon;
