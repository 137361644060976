/** @format */

import * as React from "react";
import { SVGProps } from "react";

const NounSmileEmoji = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 0C4.933 0 0 4.933 0 11s4.933 11 11 11 11-4.933 11-11S17.067 0 11 0zm0 1.435A9.554 9.554 0 0 1 20.565 11 9.554 9.554 0 0 1 11 20.565 9.554 9.554 0 0 1 1.435 11 9.554 9.554 0 0 1 11 1.435zM6.935 6.457a1.674 1.674 0 1 0 0 3.347 1.674 1.674 0 0 0 0-3.347zm8.13 0a1.674 1.674 0 1 0 0 3.347 1.674 1.674 0 0 0 0-3.347zm-9.4 10.998a.692.692 0 0 1-.569-.373.726.726 0 0 1 .045-.74c1.283-1.918 3.431-3.19 5.859-3.19s4.576 1.272 5.859 3.19a.742.742 0 0 1-.195.994c-.313.21-.792.119-1-.194-1.037-1.55-2.735-2.556-4.664-2.556-1.93 0-3.627 1.006-4.663 2.556-.194.227-.442.327-.673.313z"
      fill="#EC8577"
    />
  </svg>
);

export default NounSmileEmoji;
