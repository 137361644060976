/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { CustomInput, CustomInputPassword } from "common/Input";
import { Button } from "common/Button";
import {
  Form as StyledForm,
  Title,
  SubTitle,
  LinkStyle,
  TitleContainer,
  LoginContainer,
  LinksContainer,
  StyledVerficationBox,
  Parghrap,
  CustomForm,
  StyledLink,
} from "pages/User/style";

import { Error, Label } from "pages/style";

import { FormProps } from "infrastructure/interfaces/forms.interface";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button as AntDButton } from "antd";
import { axiosApiInstance } from "infrastructure/utils/api";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGooglePlay, faApple } from "@fortawesome/free-brands-svg-icons";
import Footer from "common/Footer";
import { useMediaQuery } from "react-responsive";
import storage from "infrastructure/utils/storage";

const Form: React.FC<FormProps> = ({
  onSubmit,
  loading,
  serverError,
}): JSX.Element => {
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const userEmail: any = storage.getItem("user_data");

  const handleResendVerfication = () => {
    setLoading(true);
    axiosApiInstance
      .post("api/email/resend", {
        email: userEmail && JSON.parse(userEmail)?.email,
      })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <LoginContainer
      style={
        i18n.language === "en" ? { direction: "ltr" } : { direction: "rtl" }
      }
    >
      <StyledForm>
        <TitleContainer>
          <Title>
            <Trans>login_for_companies</Trans>
          </Title>
          <SubTitle>
            <Trans>Don't_have_an_account?</Trans>
            <LinkStyle to={{ pathname: "/register" }}>
              <Trans>register_now</Trans>
            </LinkStyle>
          </SubTitle>
        </TitleContainer>
        {serverError?.response?.data?.code === 700020 ? (
          <StyledVerficationBox language={i18n.language === "ar" ? "ar" : "en"}>
            <InfoCircleOutlined
              style={
                i18n.language === "ar"
                  ? { marginLeft: "unset" }
                  : { marginRight: "10px" }
              }
            />
            <Parghrap>
              <Trans>we_are_sent_you_an_email_on</Trans>
              {userEmail && JSON.parse(userEmail)?.email}
              <Trans>please_follow_this_link</Trans>
              <AntDButton type="link" style={{ padding: "1px" }}>
                {isLoading ? (
                  <LoadingOutlined />
                ) : (
                  <StyledLink onClick={handleResendVerfication}>
                    <Trans>re_send</Trans>
                  </StyledLink>
                )}
              </AntDButton>
            </Parghrap>
          </StyledVerficationBox>
        ) : location?.state === "registered" ? (
          <StyledVerficationBox language={i18n.language === "ar" ? "ar" : "en"}>
            <InfoCircleOutlined
              style={
                i18n.language === "ar"
                  ? { marginLeft: "unset" }
                  : { marginRight: "10px" }
              }
            />
            <Parghrap>
              <Trans>we_are_sent_you_an_email_on</Trans>
              {userEmail && JSON.parse(userEmail)?.email}
              <Trans>please_follow_this_link</Trans>
              <AntDButton type="link" style={{ padding: "1px" }}>
                {isLoading ? (
                  <LoadingOutlined />
                ) : (
                  <StyledLink onClick={handleResendVerfication}>
                    <Trans>re_send</Trans>
                  </StyledLink>
                )}
              </AntDButton>
            </Parghrap>
          </StyledVerficationBox>
        ) : (
          <></>
        )}

        <CustomForm
          name="normal_login"
          className="login-form"
          onFinish={(values) => {
            onSubmit(values);
          }}
          onSubmit={onSubmit}
          language={i18n.language === "ar" ? "ar" : "en"}
        >
          <Label lang={i18n.language === "ar" ? "ar" : "en"}>
            <Trans>email</Trans>
          </Label>

          <CustomForm.Item
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <Error>
                    <Trans>email_required</Trans>
                  </Error>
                ),
              },
              {
                pattern:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: (
                  <Error>
                    <Trans>patternـisـnotـcorrect</Trans>
                  </Error>
                ),
              },
            ]}
          >
            <CustomInput type="text" />
          </CustomForm.Item>

          <Label lang={i18n.language === "ar" ? "ar" : "en"}>
            <Trans>password</Trans>
          </Label>

          <CustomForm.Item
            name="password"
            rules={[
              {
                required: true,
                message: (
                  <Error>
                    <Trans>password_required</Trans>
                  </Error>
                ),
              },
              {
                minLength: 8,
                message: (
                  <Error>
                    <Trans>passwordـminLength_8</Trans>
                  </Error>
                ),
              },
            ]}
          >
            <CustomInputPassword type="text" />
          </CustomForm.Item>
          <CustomForm.Item>
            <LinkStyle className="forget-password" to={"/forgot-password"}>
              <Trans>forget_password</Trans>
            </LinkStyle>

            <Button size="large" htmlType="submit" loading={loading}>
              <Trans>login</Trans>
            </Button>
          </CustomForm.Item>
        </CustomForm>
        {!isMobile && (
          <>
            <SubTitle
              style={{
                direction: "rtl",
                marginBottom: "33px",
              }}
            >
              <Trans>download_tdree_parghraph</Trans>
            </SubTitle>

            <LinksContainer>
              <a
                className="mobile-btn"
                type="link"
                href="https://apps.apple.com/eg/app/tdree/id1558676299"
              >
                {/* @ts-ignore */}
                <FontAwesomeIcon icon={faApple} size="1x" />
                <span className="btn-text">App Store</span>
              </a>

              <a
                className="mobile-btn"
                type="link"
                href="https://play.google.com/store/apps/details?id=com.tdree"
              >
                {/* @ts-ignore */}
                <FontAwesomeIcon icon={faGooglePlay} size="1x" />
                <span className="btn-text">Google Store</span>
              </a>
            </LinksContainer>
          </>
        )}
      </StyledForm>
      {!isMobile && <Footer />}
    </LoginContainer>
  );
};

export default Form;
