/** @format */

import React, { FC } from "react";
import { ButtonProps } from "infrastructure/interfaces/button.interface";
import { Button as AntDButton } from "antd";

export const Button: FC<ButtonProps> = ({
  onClick,
  children,
  ...props
}): JSX.Element => {
  return (
    <AntDButton {...props} onClick={onClick} data-testid="button">
      {children}
    </AntDButton>
  );
};

Button.defaultProps = {
  type: "primary",
};
