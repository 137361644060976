/** @format */

import React from "react";
import { StyledRate } from "./style";

type Props = {
  count?: number | undefined;
  disabled?: boolean | undefined;
  defaultValue?: number | undefined;
  className?: any;
  onChange?: (value: number) => void;
  value?: number;
  style?: any;
};
export const Rate = ({
  count,
  disabled,
  defaultValue,
  className,
  onChange,
  value,
  style,
}: Props) => {
  return (
    <StyledRate
      count={count}
      disabled={disabled}
      defaultValue={defaultValue}
      className={className}
      onChange={onChange}
      value={value}
      style={style}
    />
  );
};

Rate.defaultProps = {
  count: 5,
  disabled: false,
  defaultValue: 0,
};
