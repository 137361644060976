/** @format */

import React, { useState } from "react";
import {
  Answers,
  AnswersWrapper,
  ChartLabels,
  ChoiceChart,
  ImageWrapper,
  LinePrpgressPercentage,
  ScaleChart,
  ShopperCount,
  Span,
  StyledChartsWrapper,
  StyledPlayerWrapper,
} from "./style";
import { Progress } from "common/Progress";
import { Player } from "common/Player";
import Avatar from "common/Avatar";
import { Trans } from "react-i18next";
import { ClickableSpan } from "pages/Jobs/style";

const AnswerChart = ({ question, language }: any) => {
  const [showMore, setShowMore] = useState(false);

  const displayedAnswers = showMore
    ? question?.answers
    : question?.answers?.slice(0, 3);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderMoreOrLessImages = (imagesLength) => {
    if (imagesLength > 3) {
      showMore ? (
        <ClickableSpan onClick={toggleShowMore}>
          <Trans>show_less</Trans>
        </ClickableSpan>
      ) : (
        <ClickableSpan onClick={toggleShowMore}>
          <Trans>show_all</Trans> ({imagesLength - 3})
        </ClickableSpan>
      );
    }
  };

  return (
    <StyledChartsWrapper>
      {
        // renderScaleAnswers
        question?.questionType?.id === 2 ? (
          question.chart.map((el: any, index: number) => (
            <React.Fragment key={index}>
              <ChoiceChart>
                <ScaleChart>
                  <Span>{el.option}</Span>
                </ScaleChart>

                <ShopperCount>
                  <Trans values={{ count: el?.count }}>shopper_count</Trans>
                </ShopperCount>
              </ChoiceChart>
            </React.Fragment>
          ))
        ) : //   render single and multi choiceAnswers
        question?.questionType?.id === 9 || question?.questionType?.id === 8 ? (
          question.chart.map((el: any, index: number) => (
            <React.Fragment key={index}>
              <ChartLabels>{el.option}</ChartLabels>
              <ChoiceChart>
                <Progress
                  percent={el?.percentage}
                  size="default"
                  style={{
                    direction: "rtl",
                    marginBottom: "0",
                  }}
                  strokeColor={el.percentage > 50 ? "#44c874" : "#ff4d4f"}
                  showInfo={false}
                />
                <LinePrpgressPercentage>
                  {el.percentage}%
                </LinePrpgressPercentage>
                <ShopperCount>
                  <Trans values={{ count: el?.count }}>shopper_count</Trans>
                </ShopperCount>
              </ChoiceChart>
            </React.Fragment>
          ))
        ) : //   renderVideoAndAudioAnswers
        question?.questionType?.id === 4 || question?.questionType?.id === 5 ? (
          <>
            {question.answers.length > 3 && (
              <AnswersWrapper>
                {displayedAnswers.map((answer: string, index: number) => (
                  <StyledPlayerWrapper
                    language={language}
                    key={index}
                    className="page-break"
                  >
                    <Player key={index} url={answer} controls={true} />
                  </StyledPlayerWrapper>
                ))}
              </AnswersWrapper>
            )}
          </>
        ) : //   renderImageAnswers
        question?.questionType?.id === 3 ? (
          <>
            {question?.answers?.length && (
              <>
                <AnswersWrapper>
                  {displayedAnswers.map((answer: string, index: number) => (
                    // Render the displayed answers here
                    <ImageWrapper key={index} language={language}>
                      <Avatar
                        src={answer}
                        size={"large"}
                        shape={"square"}
                        preview={true}
                      />
                    </ImageWrapper>
                  ))}
                </AnswersWrapper>
              </>
            )}

            {renderMoreOrLessImages(question?.answers?.length)}
          </>
        ) : //   renderTheRestOfQuestionTypes
        question?.questionType?.id === 1 ||
          question?.questionType?.id === 6 ||
          question?.questionType?.id === 7 ? (
          <AnswersWrapper className="page-break">
            {question.answers.map((answer: string, index: number) => {
              return <Answers key={index}>{answer}</Answers>;
            })}
          </AnswersWrapper>
        ) : (
          ""
        )
      }
    </StyledChartsWrapper>
  );
};

export default AnswerChart;
