import * as React from "react";

function LiveIcon(props: any) {
  return (
    <svg width={32} height={15} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.98 4.963a.383.383 0 00-.53.11 4.195 4.195 0 000 4.61.382.382 0 10.64-.421 3.43 3.43 0 010-3.769.382.382 0 00-.11-.53zM7.408 2.348a.383.383 0 00-.53.11 8.956 8.956 0 000 9.839.382.382 0 10.64-.42 8.189 8.189 0 010-8.998.382.382 0 00-.11-.53zM3.054.063a.384.384 0 00-.53.11 13.112 13.112 0 000 14.41.382.382 0 10.639-.421 12.347 12.347 0 010-13.57.382.382 0 00-.11-.53zm17.966 4.9a.383.383 0 00-.11.53 3.432 3.432 0 010 3.77.382.382 0 10.64.419c.919-1.4.919-3.21 0-4.61a.382.382 0 00-.53-.11zm3.571-2.615a.383.383 0 00-.11.53 8.187 8.187 0 010 8.998.383.383 0 10.64.42 8.954 8.954 0 000-9.838.383.383 0 00-.53-.11zM29.476.172a.384.384 0 00-.64.42 12.347 12.347 0 010 13.57.383.383 0 10.64.42 13.114 13.114 0 000-14.41zM16 4.985a2.395 2.395 0 00-2.393 2.393A2.395 2.395 0 0016 9.77a2.395 2.395 0 002.392-2.392A2.395 2.395 0 0016 4.985z"
        fill="#0055D9"
      />
    </svg>
  );
}

export default LiveIcon;
