/** @format */

import * as React from "react";

const ProfileIconResponsive = () => (
  <svg width={36} height={35} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M16.43 32.22c4.002 0 4.14-4.506 6.809-7.05 2.8-2.667 11.823-.562 11.823-4.71 0-3.989.499-11.236-4.46-15.319C25.643 1.06 20.375 0 16.214 0 10.79 0 4.542 4.708 1.952 8.998.626 11.193 0 15.862 0 18.603c0 3.917 3.14 6.425 5.672 9.056A14.888 14.888 0 0 0 16.43 32.22z"
        fill="#E8F8EE"
      />
      <path
        d="m19.855 28.4 2.09 2.027 4.18-4.054m-9.639 5.707c-2.502 0-4.99-.613-6.888-1.84-3.328-2.16-3.328-5.68 0-7.827 3.78-2.453 9.982-2.453 13.763 0M21.56 8.28a5.75 5.75 0 0 1 1.045 3.307c-.014 3.2-2.612 5.8-5.885 5.906a2.577 2.577 0 0 0-.454 0c-3.272-.106-5.87-2.706-5.87-5.906 0-3.267 2.722-5.92 6.104-5.92"
        stroke="#292D32"
        strokeWidth={2.016}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      />
    </g>
  </svg>
);

export default ProfileIconResponsive;
