/** @format */

import React, { forwardRef } from "react";
import { InputStyle, StyledAntdInput, StyledAntdInputPassword } from "./style";
import { useState } from "react";
import { useTranslation } from "react-i18next";
export const Input = forwardRef<HTMLInputElement, any>((props, ref) => {
  const [keyboardlng, setKeyboardlng] = useState("");
  const { i18n } = useTranslation();

  const preventMinus = (e: {
    code: string;
    preventDefault: () => void;
    target: any;
  }) => {
    if (props.type === "number") {
      if (e.code === "Minus") {
        e.preventDefault();
      }
      if (e.code === "Equal") {
        e.preventDefault();
      }
      if (e.code === "KeyE") {
        e.preventDefault();
      }
    }
  };

  const handleInputsTypeNumber = (e: {
    target: { value: string | any[]; maxLength: number };
  }) => {
    if (props.type === "number") {
      if (e.target.value.length > e.target.maxLength) {
        e.target.value = e.target.value.slice(0, e.target.maxLength);
      }
    }
  };

  function validate(e: any) {
    if (/^\s/.test(e.target?.value)) e.target.value = "";
  }

  return (
    <>
      <InputStyle
        {...props}
        ref={ref}
        dir="auto"
        pattern="/^\s/"
        onKeyPress={(e) => {
          preventMinus(e);
          e.charCode > 160 ? setKeyboardlng("ar") : setKeyboardlng("en");
        }}
        onInput={(e: {
          target: { value: string | any[]; maxLength: number };
        }) => {
          handleInputsTypeNumber(e);
          validate(e);
        }}
        lng={keyboardlng}
        language={i18n.language === "ar" ? "ar" : "en"}
        disabled={props.disabled}
      />
      {props.icon ? props.icon : ""}
    </>
  );
});

export interface CustomInputProps {
  name?: string;
  rules?: Record<string, any>;
  placeholder?: string;
  type?: string;
  status?: "error" | "warning";
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
}

export const CustomInput = ({
  type,
  placeholder,
  ...rest
}: CustomInputProps) => {
  return (
    <StyledAntdInput
      {...rest}
      bordered={false}
      type={type}
      placeholder={placeholder}
      onKeyDown={rest.onKeyDown}
    />
  );
};

export const CustomInputPassword = ({
  type,
  placeholder,
  ...rest
}: CustomInputProps) => {
  return (
    <StyledAntdInputPassword
      {...rest}
      bordered={false}
      type={type}
      placeholder={placeholder}
      onKeyDown={rest.onKeyDown}
    />
  );
};
