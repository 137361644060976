/** @format */

import React from "react";

import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const BarChart = ({ data, options }: any) => {
  return (
    <Bar
      data={data}
      options={
        options
          ? options
          : {
              responsive: true,
              interaction: {
                mode: "nearest",
              },
              plugins: {
                legend: {
                  position: "top",
                  display: false,
                  labels: {
                    font: {
                      size: 13,
                      family: "Famtree",
                      weight: "normal",
                    },
                    color: "#344247",
                    textAlign: "left",
                  },
                },
              },
            }
      }
    />
  );
};

export default BarChart;
