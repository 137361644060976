/** @format */

import React from 'react';
import { Trans } from 'react-i18next';

import { Container, CopyRightText } from './style';

const Footer = () => {
  return (
    <Container style={{ width: '100%' }} data-testid='footer'>
      <CopyRightText data-testid='footer-content'>
        <Trans>contact_us_on</Trans>
      </CopyRightText>
    </Container>
  );
};

export default Footer;
