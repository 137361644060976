/** @format */

import React, { useEffect } from 'react';

import { Error, Label } from 'pages/style';
import { Trans, useTranslation } from 'react-i18next';
import { Input } from 'common/Input';
import {
  FileContainer,
  Img,
  ImgContainer,
  Form,
  SubContainer,
  InputsContainer,
  PrefixSpan,
  ProfileSubContainer,
} from 'pages/User/style';
import { LoadingOutlined } from '@ant-design/icons';
import { Select } from 'common/Select';

import { Button } from 'common/Button';
import EditIcon from 'assets/svg/edit-icon';
import User from 'assets/svg/User';
import { FormProps } from 'infrastructure/interfaces/forms.interface';
import { useStore } from 'infrastructure/zustandStore/categories-store';
import { useMediaQuery } from 'react-responsive';

export const EditProfileForm: React.FC<FormProps> = ({
  errors,
  register,
  formState,
  onSubmit,
  handleImageUploading,
  showImage,
  userData,
  handleInputChange,
  loading,
  imageLoader,
  handleOnSelect,
}): JSX.Element => {
  const { i18n, t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 800 });

  const [categories, categoriesList] = useStore((state: any) => [
    state.categories,
    state.categoriesList,
  ]);

  useEffect(() => {
    categoriesList();
  }, []);

  return (
    <Form onSubmit={onSubmit} profile noValidate={true}>
      <SubContainer style={{ width: 'unset' }}>
        <ProfileSubContainer>
          <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
            <Trans>company_name</Trans>
          </Label>
          <Input
            name='name'
            type='text'
            width='register'
            shortName='true'
            value={userData.name}
            onChange={(e: Event) => handleInputChange(e)}
            ref={register({ required: true })}
            errorName={errors.name}
            profile
          />
          {errors?.name?.types?.required && (
            <Error>
              <Trans>required_error_message</Trans>
            </Error>
          )}
        </ProfileSubContainer>

        <FileContainer>
          <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
            <Trans>image</Trans>
          </Label>
          <label htmlFor='file'>
            {showImage ? (
              <div style={{ position: 'relative' }}>
                <Img src={showImage} alt='' />
                <EditIcon register='true' />
              </div>
            ) : (
              <User />
            )}
            {imageLoader && (
              <ImgContainer>
                <LoadingOutlined />
              </ImgContainer>
            )}
          </label>

          <input
            type='file'
            id='file'
            accept='.png, .jpg, .jpeg'
            style={{ display: 'none' }}
            name='image_url'
            onChange={(e) => handleImageUploading(e)}
          />
        </FileContainer>
      </SubContainer>

      <InputsContainer profile language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>mobileNum</Trans>
        </Label>
        <PrefixSpan lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          +966
        </PrefixSpan>
        <Input
          name='mobile'
          type='text'
          width='100'
          ref={register({ required: true, minLength: 8, pattern: /^[0-9]*$/ })}
          errorName={errors.mobile}
          value={userData.mobile}
          maxLength='9'
          onChange={(e: Event) => handleInputChange(e)}
          profile
        />
        {errors?.mobile?.types?.minLength && (
          <Error>
            <Trans>mobileNumـminLength_8</Trans>
          </Error>
        )}
        {errors?.mobile?.types?.pattern && (
          <Error>
            <Trans>numbers_only</Trans>
          </Error>
        )}
        {errors?.mobile?.types?.required && (
          <Error>
            <Trans>required_error_message</Trans>
          </Error>
        )}
      </InputsContainer>

      <InputsContainer profile language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>email</Trans>
        </Label>
        <Input
          name='email'
          type='text'
          width='100'
          value={userData.email}
          disabled
          profile
        />
      </InputsContainer>

      <InputsContainer profile language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>commercial_number</Trans>
        </Label>
        <Input
          name='commercial_number'
          type='text'
          width='100'
          value={userData.commercial_number}
          onChange={(e: Event) => handleInputChange(e)}
          profile
        />
      </InputsContainer>

      <InputsContainer profile language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>tax_number</Trans>
        </Label>
        <Input
          name='tax_number'
          type='text'
          width='100'
          value={userData.tax_number}
          onChange={(e: Event) => handleInputChange(e)}
          profile
        />
      </InputsContainer>

      <InputsContainer profile language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>address</Trans>
        </Label>
        <Input
          name='address'
          type='text'
          width='100'
          value={userData.address}
          onChange={(e: Event) => handleInputChange(e)}
          profile
        />
      </InputsContainer>
      <br />
      <InputsContainer
        language={i18n.language === 'ar' ? 'ar' : 'en'}
        profile
        style={{ width: isMobile ? '100%' : '38%' }}
      >
        <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>categories</Trans>
        </Label>

        <Select
          placeholder={t('category')}
          className='full-width'
          ref={register({
            required: true,
          })}
          mode='multiple'
          onChange={(e) => handleOnSelect && handleOnSelect(e)}
          defaultValue={userData.categories.map((category) => {
            return { value: category.id, label: category.name };
          })}
          options={categories.map((category: { id: number; name: string }) => {
            return { value: category.id, label: category.name };
          })}
        />
      </InputsContainer>
      <br />
      <InputsContainer
        language={i18n.language === 'ar' ? 'ar' : 'en'}
        profile
        style={{ width: isMobile ? '100%' : '39%' }}
      >
        <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Trans>website</Trans>(<Trans>optional</Trans>)
        </Label>
        <Input
          name='website'
          type='text'
          width='100'
          value={userData.website}
          onChange={(e: Event) => handleInputChange(e)}
          profile
        />
      </InputsContainer>

      <InputsContainer style={{ width: '40%', marginTop: '20px' }}>
        <Button
          disabled={formState.isSubmitting}
          size='large'
          onClick={onSubmit}
          loading={loading}
          style={{ width: '100%' }}
        >
          <Trans>save</Trans>
        </Button>
      </InputsContainer>
    </Form>
  );
};
