/** @format */

import React from "react";
import Details from "./Details";
import { CustomHeader } from "common/Header";
import Spinner from "common/LoadingIndecator";
import { useParams } from "react-router-dom";
import Unauthorized from "common/UnAuthorized";
import NotFound from "common/NotFound";
import { useQuery } from "react-query";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";
import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
const BranchDetails = () => {
  let { id } = useParams();

  const { data, isLoading } = useQuery(
    ["ManagerDetails"],
    () => fetchGetRequests(`api/company/v1/managers/${id}`),
    {
      keepPreviousData: false,
    }
  );
  const { t } = useTranslation();

  if (data?.error === 403) return <Unauthorized />;
  if (data?.error === 404) return <NotFound />;

  return isLoading ? (
    <Spinner size={"large"} tip={"loading"} />
  ) : data?.response.manager?.length !== 0 ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("tdree_details")}</title>
      </Helmet>
      <CustomHeader
        title={data?.response.manager?.name}
        path={"/manager"}
        editPath={`/manager/edit/${id}`}
      />
      <Details key={data?.response.manager.id} data={data?.response?.manager} />
    </>
  ) : null;
};

export default BranchDetails;
