/** @format */

import React, { useState } from "react";
import { Trans } from "react-i18next";
import {
  AnswersWrapper,
  ImageWrapper,
  StyledPlayerWrapper,
  StyledNotesButton,
  AttachmentWrapper,
  ChoicesAnswer,
  ExtraQuestionsWrapper,
  NotesWrapper,
} from "./style";
import { StyledAvatar } from "common/Avatar/style";
import { Label } from "pages/style";
import Avatar from "common/Avatar";
import { Player } from "common/Player";

const ExtraQuestions = ({
  question,
  index,
  language,
  show_extra,
  type,
}: any) => {
  const [notes, setNotes] = useState<any>({});
  const handleNotes = (index: number) => {
    setNotes({ ...notes, [index]: !notes[index] });
  };
  return (
    <ExtraQuestionsWrapper className={show_extra ? "extra_question" : ""}>
      {(question?.extras?.images?.length ||
        question?.extras?.videos?.length) && (
        <AttachmentWrapper>
          {
            <Label>
              <Trans>attachments</Trans>
            </Label>
          }
          {/* images  */}
          {question?.extras?.images?.length && (
            <AnswersWrapper>
              <StyledAvatar.Group
                maxCount={6}
                size="large"
                maxPopoverPlacement="bottom"
                style={{
                  direction: "rtl",
                  display: "flex",
                  flexDirection: "row",
                }}
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  cursor: "pointer",
                  marginRight: "-21px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {question.extras?.images?.map(
                  (image: { extra: string }, index: number) => {
                    return (
                      <ImageWrapper key={index} language={language}>
                        <Avatar
                          src={image.extra}
                          size={"large"}
                          shape={"square"}
                          preview={true}
                        />
                      </ImageWrapper>
                    );
                  }
                )}
              </StyledAvatar.Group>
            </AnswersWrapper>
          )}
          {/* videos  */}
          {question?.extras?.videos?.length && (
            <AnswersWrapper>
              {question.extras?.videos?.map(
                (video: { extra: string }, index: number) => {
                  return (
                    <StyledPlayerWrapper key={index} language={language}>
                      <Player key={index} url={video.extra} controls={true} />
                    </StyledPlayerWrapper>
                  );
                }
              )}
            </AnswersWrapper>
          )}
        </AttachmentWrapper>
      )}

      {question?.extras?.text?.length &&
        (type ? (
          <NotesWrapper>
            <Label>
              <Trans>notes</Trans>
            </Label>
            {question?.extras?.text?.map(
              (text: { extra: string }, index: number) => {
                return <ChoicesAnswer key={index}>{text.extra}</ChoicesAnswer>;
              }
            )}
          </NotesWrapper>
        ) : (
          <NotesWrapper>
            <StyledNotesButton onClick={() => handleNotes(index)}>
              <Trans>show_notes</Trans>
            </StyledNotesButton>

            {notes[index] &&
              question.extras?.text?.map(
                (text: { extra: string }, index: number) => {
                  return (
                    <ChoicesAnswer key={index}>{text.extra}</ChoicesAnswer>
                  );
                }
              )}
          </NotesWrapper>
        ))}
    </ExtraQuestionsWrapper>
  );
};

export default ExtraQuestions;
