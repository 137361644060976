/** @format */

import Avatar from "common/Avatar";
import { Player } from "common/Player";
import React, { useCallback } from "react";
import {
  Answers,
  AnswersWrapper,
  ImageWrapper,
  QuestionContainer,
  StyledPlayerWrapper,
} from "./style";

const RestOfAnswers = ({ question, index, language }: any) => {
  const loadTheRestOfTheAnswers = useCallback(
    (id: number, index: number) => {
      if (question[index]?.id === id && question[index].answersCount > 3) {
        return (
          <QuestionContainer language={language}>
            {question[index]?.questionType.id === 5 ||
            question[index]?.questionType.id === 4 ? (
              <AnswersWrapper>
                {question[index]?.answers
                  .slice(3, question[index]?.answers.length)
                  .map((answer: string, index: number) => {
                    return (
                      <StyledPlayerWrapper key={index} className="page-break">
                        <Player key={index} url={answer} controls={true} />
                      </StyledPlayerWrapper>
                    );
                  })}
              </AnswersWrapper>
            ) : question[index]?.questionType.id === 3 ? (
              <AnswersWrapper>
                {question[index]?.answers
                  .slice(3, question[index]?.answers.length)
                  .map((answer: string, index: number) => {
                    return (
                      <ImageWrapper
                        language={language}
                        key={index}
                        className="page-break"
                      >
                        <Avatar
                          src={answer}
                          size="large"
                          shape="square"
                          preview={false}
                        />
                      </ImageWrapper>
                    );
                  })}
              </AnswersWrapper>
            ) : (
              question[index]?.questionType.id !== 8 &&
              question[index]?.questionType.id !== 9 &&
              question[index]?.questionType.id !== 2 &&
              question[index]?.answers
                .slice(3, question[index]?.answers.length)
                .map((answer: Array<any>, index: number) => {
                  return (
                    <div key={index} className="page-break">
                      <Answers>{answer}</Answers>
                    </div>
                  );
                })
            )}
          </QuestionContainer>
        );
      }
    },
    [question]
  );

  return <>{loadTheRestOfTheAnswers(question, index)}</>;
};

export default RestOfAnswers;
