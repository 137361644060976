/** @format */

import BranchEmptyStateLogo from "assets/svg/BranchEmptyStateLogo";
import EvaluationTemplateEmptyLogo from "assets/svg/EvaluationTemplateEmptyLogo";
import JobsEmptyStateLogo from "assets/svg/JobsEmptyStateLogo";

import { Button } from "common/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Trans } from "react-i18next";

export const DashboardBranchesEmptyState = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <BranchEmptyStateLogo />
      <Title>
        <Trans>add_branch</Trans>
      </Title>
      <Pargraph>
        <Trans>create_your_branches</Trans>
      </Pargraph>
      <Button type="primary" size="large" onClick={() => navigate("/branches")}>
        +<Trans>new_branch</Trans>
      </Button>
    </Container>
  );
};

export const DashboardJobsEmptyState = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <JobsEmptyStateLogo />
      <Title>
        <Trans>add_job</Trans>
      </Title>
      <Pargraph>
        <Trans>create_your_jobs</Trans>
      </Pargraph>
      <Button type="primary" size="large" onClick={() => navigate("/jobs")}>
        +<Trans>new_job</Trans>
      </Button>
    </Container>
  );
};

export const DashboardEvaluationsEmptyState = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <EvaluationTemplateEmptyLogo />
      <Title>
        <Trans>add_evaluation_template</Trans>
      </Title>
      <Pargraph>
        <Trans>create_your_evaluation_template</Trans>
      </Pargraph>
      <Button
        type="primary"
        size="large"
        onClick={() => navigate("/evaluation-templates")}
      >
        +<Trans>add_new_template</Trans>
      </Button>
    </Container>
  );
};

export const Container = styled.div`
  padding: 26px 30px;
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 1px 1px 15px 2px rgba(48, 48, 48, 0.07);
  margin-bottom: 30px;
`;

export const Title = styled.p`
  font-family: Famtree;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #344247;
  margin: 26px 0 28px 0;
`;

export const Pargraph = styled.p`
  font-family: Famtree;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #344247;
  min-height: 80px;
`;
