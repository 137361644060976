/** @format */

import React, { FC } from "react";
import { placementType } from "../../infrastructure/interfaces/button.interface";
import { Dropdown as AntDDropdown } from "antd";

export type Props = {
  items: any;
  placement?: placementType;
  children?: any;
  className?: string;
  onClick?: (record: any) => void;
};

export const Dropdown: FC<Props> = ({
  items,
  placement,
  children,
  onClick,
}): JSX.Element => {
  return (
    <AntDDropdown
      trigger={["click"]}
      menu={items}
      placement={placement}
      data-testid="myDropdown"
      onOpenChange={onClick}
    >
      {children}
    </AntDDropdown>
  );
};

Dropdown.defaultProps = {
  placement: "bottom",
};
