/** @format */

import * as React from "react";
import { SVGProps } from "react";

const SmileysIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={68} height={72} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M33.658 52.207c7.28 0 9.082-9.028 13.94-13.71 5.094-4.913 9.02-14.636 0-22.154C38.575 8.825 50.387.211 35.894.211c-9.868 0-26.623 3.964-31.336 11.865C2.15 16.118.274 28.4.274 33.45c0 7.212 6.566 11.983 11.173 16.827 4.92 5.174 14.507 1.93 22.21 1.93z"
        fill="#E8F8EE"
      />
      <g
        stroke="#292D32"
        strokeWidth={3.6}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      >
        <path d="M14.833 28.983c0-7.25 2.9-10.15 10.15-10.15h8.7c7.25 0 10.15 2.9 10.15 10.15v9.184c-6.742.12-9.545 2.924-9.666 9.666h-9.184c-7.25 0-10.15-2.9-10.15-10.15" />
        <path d="M26.82 28.186a3.708 3.708 0 0 0-4.253.048m13.92-.048a3.708 3.708 0 0 0-4.253.048M29.72 41.599h-5.607a1.3 1.3 0 0 1-1.305-1.305 6.528 6.528 0 0 1 6.525-6.525c1.547 0 2.972.531 4.084 1.426m29.75 21.822c0 7.25-2.9 10.15-10.15 10.15h-8.7c-7.25 0-10.15-2.9-10.15-10.15v-9.184c.12-6.742 2.924-9.545 9.666-9.666h9.184c7.25 0 10.15 2.9 10.15 10.15" />
        <path d="M46.154 46.915a3.71 3.71 0 0 1-4.254-.048m13.92.048c-1.28.87-2.972.87-4.253-.048m2.319 6.235a1.3 1.3 0 0 1 1.305 1.305c0 3.6-2.924 6.525-6.525 6.525a6.529 6.529 0 0 1-6.525-6.525 1.3 1.3 0 0 1 1.305-1.305h10.44z" />
      </g>
    </g>
  </svg>
);

export default SmileysIcon;
