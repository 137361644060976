/** @format */

import React, { useEffect, useState } from "react";
import Details from "./Details";
import { CustomHeader } from "common/Header";
import Spinner from "common/LoadingIndecator";
import { useParams } from "react-router-dom";
import Unauthorized from "common/UnAuthorized";
import NotFound from "common/NotFound";
import { useQuery } from "react-query";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";
import { Helmet } from "react-helmet";
import { axiosApiInstance } from "infrastructure/utils/api";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { useTranslation } from "react-i18next";
const BranchDetails = () => {
  let { id } = useParams();

  const { data, isLoading } = useQuery(
    ["branchDetails"],
    () => fetchGetRequests(`api/company/v1/branches/${id}`),
    {
      keepPreviousData: false,
    }
  );
  const { t, i18n } = useTranslation();

  // fetch countries
  const { data: countries } = useQuery(
    ["Countries"],
    () => fetchGetRequests(`api/countries`),
    {
      keepPreviousData: false,
    }
  );
  const [country_name, setCountryName] = useState<string>("");
  // featch cities
  const [city_name, setCityName] = useState<string>("");
  const handleFetchCities = (id: number) => {
    axiosApiInstance
      .get(`api/countries/${id}/cities`)
      .then((res) => {
        res?.data?.cities?.map((city: any) => {
          if (city.id === data?.response?.branch?.city_id) {
            setCityName(city.name);
          }
        });
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          t("Error"),
          err.response.data.message
        );
      });
  };

  useEffect(() => {
    if (data?.response.branch) {
      handleFetchCities(data?.response.branch?.country_id);
    }
    countries &&
      countries?.response?.countries?.map((country: any) => {
        if (country.id === data?.response.branch?.country_id) {
          setCountryName(country.name);
        }
      });
    return;
  }, [data?.response.branch]);

  if (data?.error === 403) return <Unauthorized />;
  if (data?.error === 404) return <NotFound />;

  return isLoading ? (
    <Spinner size={"large"} tip={"loading"} />
  ) : data?.response.branch?.length !== 0 ? (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar"
            ? "تفاصيل الفرع -تدري"
            : "Branch details - Tdree"}
        </title>
      </Helmet>
      <CustomHeader
        title={data?.response.branch?.name}
        path={"/branches"}
        editPath={`/branches/edit/${id}`}
      />
      <Details
        key={data?.response.branch.id}
        data={{ ...data?.response.branch, city_name: city_name, country_name }}
      />
    </>
  ) : null;
};

export default BranchDetails;
