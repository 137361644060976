/** @format */

import { create } from 'zustand';
import { axiosApiInstance } from 'infrastructure/utils/api';
import hash from 'object-hash';

export const useStore = create((set: any) => ({
  evaluationDetailsData: {
    questions: [
      {
        options: [
          {
            option: 'جميع الاجابات لا تنطبق',
            points: 0,
          },
        ],
      },
    ],
  },
  loading: true,
  statusCode: '',
  changed: false,
  evaluationDetails: async (id: number) => {
    const response = await axiosApiInstance
      .get(`api/company/v1/evaluation-templates/${id}`)
      .then((res) => {
        localStorage.setItem(
          `draftEditForm.${id}`,
          JSON.stringify(res?.data?.template)
        );
        //@ts-ignore
        if (JSON.parse(localStorage.getItem(`draftEditForm.${id}`)) === null) {
          return set({
            evaluationDetailsData: res?.data?.template,
            loading: false,
          });
        }

        if (
          //@ts-ignore
          hash(JSON.parse(localStorage.getItem(`draftEditForm.${id}`))) !==
          hash(res?.data?.template)
        ) {
          set({
            evaluationDetailsData: JSON.parse(
              //@ts-ignore
              localStorage.getItem(`draftEditForm.${id}`)
            ),
            loading: false,
          });
        } else {
          set({
            evaluationDetailsData: res?.data?.template,
            loading: false,
          });
        }
      })
      .catch((error) => {
        set({ loading: false, statusCode: error?.response?.data?.statusCode });
      });

    return response;
  },
}));
