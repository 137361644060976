/** @format */

import React, { ChangeEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IconNoForms from 'assets/svg/icon-no-forms';
import { Button } from 'common/Button';
import EmptyTemplate from 'common/EmptyTemplate';
import {
  Pargraph,
  Span,
  Title,
  PayMultiContainer,
  SelectedItems,
  VerticalLine,
  SearchAndFilterWrapper,
} from './style';
import { useQuery } from 'react-query';
import { fetchGetRequestsWithPagination } from 'infrastructure/helpers/fetchServerRequests';
import { TableContainer, ButtonsContainer } from 'common/Table/style';
import SearchInput from 'common/Search';
import { List } from 'components/List';
import { Header, SectionFlex } from '../style';
import { Card } from 'common/Card';
import { List as AntDList } from 'antd';
import { Menu, Dropdown } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import Colors from 'infrastructure/utils/Colors';
import { Helmet } from 'react-helmet';
import {
  showNotification,
  NotificationStatus,
} from 'infrastructure/helpers/showNotifications';
import { axiosApiInstance } from 'infrastructure/utils/api';
import { createJobUseStore } from 'infrastructure/zustandStore/create-job-store';
import storage from 'infrastructure/utils/storage';

const Jobs = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const done = params.get('done');

  const page = 1;
  const [limit, setLimit] = useState<number>(10);
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const { data } = useQuery(
    ['jobsList', page, limit, searchInputValue],
    () =>
      fetchGetRequestsWithPagination(
        `api/company/v1/jobs?page=${page}&limit=${limit}&search=${searchInputValue}`
      ),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const handleSearchInputChange = (e: Event | any) => {
    setSearchInputValue(e.target?.value);
  };

  const onClick = ({ key }: any) => {
    setSearchInputValue(key);
  };

  const [selectedJobs, setSelectedJobs] = useState<any>([]);

  const handleSelectJob = (e: any, id: any) => {
    if (e.target.checked) {
      setSelectedJobs((selectedJobs: any) => [...selectedJobs, id]);
    } else {
      const newItems = selectedJobs.filter((item: number) => item !== id);
      setSelectedJobs(newItems);
    }
  };

  const onSubmitMultiJobs = () => {
    axiosApiInstance
      .post('api/company/v1/jobs/multi/pay', { job_id: selectedJobs })
      .then((res) => {
        createJobUseStore.setState({ res: res.data });
        storage.setItem('jobs_data', JSON.stringify(res.data));
        navigate('jobs/payment');
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          t('error'),
          err.response.data.message
        );
      });
  };

  const renderJobsCount = () => {
    switch (selectedJobs.length) {
      case 1:
        return (
          <Trans values={{ count: selectedJobs.length }}>
            one_job_selected
          </Trans>
        );
      case 2:
        return (
          <Trans values={{ count: selectedJobs.length }}>
            two_job_selected
          </Trans>
        );
      default:
        return (
          <Trans values={{ count: selectedJobs.length }}>
            selected_jobs_count
          </Trans>
        );
    }
  };

  const menu = (
    <Menu
      onClick={onClick}
      style={{
        fontFamily: 'Famtree',
        textAlign: i18n.language === 'ar' ? 'right' : 'left',
      }}
    >
      <Menu.Item key=' '>
        <Trans>display_all</Trans>
      </Menu.Item>
      <Menu.Item key='partially'>
        <Trans>partially</Trans>
      </Menu.Item>
      <Menu.Item key='fully'>
        <Trans>fully</Trans>
      </Menu.Item>
      <Menu.Item key='expired'>
        <Trans>expired</Trans>
      </Menu.Item>
      <Menu.Item key='pending'>
        <Trans>pending</Trans>
      </Menu.Item>
      <Menu.Item key='inProgress'>
        <Trans>in_progress</Trans>
      </Menu.Item>
      <Menu.Item key='done'>
        <Trans>to_done</Trans>
      </Menu.Item>
      <Menu.Item key='cancelled'>
        <Trans>cancelled</Trans>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    if (done) {
      showNotification(
        NotificationStatus.Success,
        t('done'),
        t('done_message')
      );
    }
  }, [searchInputValue]);

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {i18n.language === 'ar' ? 'قائمة المهام - تدري' : 'Jobs - Tdree'}
        </title>
      </Helmet>
      <Header language={i18n.language === 'ar' ? 'ar' : 'en'}>
        <Title>
          <Trans>jobs_list</Trans>
        </Title>
      </Header>
      {data?.jobs.length !== 0 && (
        <TableContainer language={i18n.language} className='jobs'>
          <ButtonsContainer
            jobs='true'
            language={i18n.language === 'ar' ? 'ar' : 'en'}
          >
            <SearchAndFilterWrapper>
              <SearchInput
                handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleSearchInputChange(e)
                }
                placeholder={t('search_placeholder')}
              />

              <Dropdown overlay={menu}>
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    color: Colors.dark_mint,
                    fontFamily: 'Famtree',
                    margin: '0 10px',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }}
                >
                  <FilterOutlined /> <Trans>display_as</Trans>
                </a>
              </Dropdown>
            </SearchAndFilterWrapper>
            <Button size={'large'} onClick={() => navigate('create')}>
              <Trans>new_job</Trans>
            </Button>
          </ButtonsContainer>
          <List
            type='jobsList'
            data={data?.jobs}
            paginationInfo={data?.pagination}
            setLimit={setLimit}
            setPage={() => {}}
            grid={{
              gutter: 8,
              xs: 1,
              sm: 1,
              md: 1,
              lg: 2,
              xl: 2,
              xxl: 2,
            }}
            renderItem={(item: any, index: number) => (
              <AntDList.Item key={index}>
                <Card
                  loading={!data?.jobs}
                  title={item.name}
                  id={item.id}
                  assigned={item.shoppersData.assigned}
                  status={item.status}
                  createdAt={item.createdAt}
                  startTime={item.startTime}
                  endTime={item.endTime}
                  branch={item.branch}
                  shoppersCount={item.shoppersData.count}
                  shoppersImages={item.shoppersData.shoppers}
                  finished={item.shoppersData.finished}
                  paymentStatus={item.paymentState.id}
                  onClick={() => {
                    navigate(`/jobs/${item.id}`);
                  }}
                  onPayClick={() => navigate(`/jobs/edit/${item.id}`)}
                  handleSelectJob={handleSelectJob}
                />
              </AntDList.Item>
            )}
          />
          {selectedJobs.length ? (
            <PayMultiContainer>
              <Button size={'large'} onClick={onSubmitMultiJobs}>
                <Trans>save-send-request</Trans>
              </Button>
              <VerticalLine></VerticalLine>
              <SelectedItems>{renderJobsCount()}</SelectedItems>
            </PayMultiContainer>
          ) : (
            ''
          )}
        </TableContainer>
      )}

      {searchInputValue.length !== 0 && data?.jobs.length === 0 ? (
        <TableContainer language={i18n.language} className='jobs'>
          <ButtonsContainer jobs='true'>
            <Button size={'large'} onClick={() => navigate('jobs/create')}>
              <Trans>new_job</Trans>
            </Button>

            <div>
              <Dropdown overlay={menu}>
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    color: Colors.dark_mint,
                    fontFamily: 'Famtree',
                    margin: '0 10px',
                    fontSize: '14px',
                  }}
                >
                  <Trans>display_as</Trans> <FilterOutlined />
                </a>
              </Dropdown>
              <SearchInput
                handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleSearchInputChange(e)
                }
                placeholder={t('search_for_new_job_placeholder')}
              />
            </div>
          </ButtonsContainer>
          <EmptyTemplate>
            <IconNoForms />
            <Pargraph>
              <Trans>there_are_no_matching_esults</Trans>
            </Pargraph>
            <SectionFlex language={i18n.language === 'ar' ? 'ar' : 'en'}>
              <Button onClick={() => navigate('/jobs/create')} size='large'>
                <Span>
                  <Trans>new_job</Trans>
                </Span>
              </Button>
              <Button size='large' onClick={() => setSearchInputValue('')}>
                <Span>
                  <Trans>back</Trans>
                </Span>
              </Button>
            </SectionFlex>
          </EmptyTemplate>
        </TableContainer>
      ) : (
        data?.jobs.length === 0 && (
          <EmptyTemplate>
            <IconNoForms />
            <Pargraph>
              <Trans>there_are_no_matching_esults</Trans>
            </Pargraph>

            <SectionFlex language={i18n.language}>
              <Button onClick={() => navigate('/jobs/create')} size='large'>
                <Span>
                  <Trans>new_job</Trans>
                </Span>
              </Button>
              <Button size='large' onClick={() => setSearchInputValue('')}>
                <Span>
                  <Trans>back</Trans>
                </Span>
              </Button>
            </SectionFlex>
          </EmptyTemplate>
        )
      )}
    </>
  );
};

export default React.memo(Jobs);
