/** @format */

import React from 'react';
import Form from './form';
import { useForm } from 'react-hook-form';
import { axiosApiInstance } from 'infrastructure/utils/api';
import { useNavigate } from 'react-router-dom';
import { RegisterData } from 'infrastructure/interfaces/forms.interface';
import { LeftContainer, RightContainer, Container } from '../style';
import TadreeComponent from 'assets/svg/tdree-component';
import {
  NotificationStatus,
  showNotification,
} from 'infrastructure/helpers/showNotifications';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import storage from 'infrastructure/utils/storage';

const { useState } = React;

const Register = () => {
  const [requestedImgUrl, setRequestedImgUrl] = useState<object | string | any>(
    {
      file: '',
    }
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showImage, setShowImage] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const [category, setCategory] = useState<Array<number>>([]);

  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const url = '/api/company/register';
  const { handleSubmit, register, errors, formState, watch, control } = useForm(
    {
      mode: 'onBlur',
      criteriaMode: 'all',
    }
  );

  const onSubmit = (userData: RegisterData) => {
    setIsLoading(true);
    const data = {
      name: userData.name,
      email: userData.email,
      password: userData.password,
      confirm_password: userData.confirm_password,
      mobile: '00966' + userData.mobile,
      website: userData.website,
      image_url: requestedImgUrl?.file,
      categories: category,
    };
    axiosApiInstance
      .post(url, data)
      .then((res) => {
        setIsLoading(false);
        storage.setItem('user_data', JSON.stringify(data));
        storage.setItem('user_image', res.data?.company?.image);
        navigate('/login', { state: 'registered' });
      })
      .catch((err) => {
        setIsLoading(false);
        showNotification(
          NotificationStatus.Error,
          'Error',
          err.response?.data.message
        );
      });
  };

  const handleImageUploading = (e: {
    target: { files: (string | Blob)[] };
    type: string;
  }) => {
    const image_url = '/api/file-upload';
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('entity', 'companies');
    formData.append('file_type', 'images');

    if (e.type === 'change') {
      setLoading(true);
    }

    axiosApiInstance
      .post(image_url, formData)
      .then((res) => {
        setLoading(false);
        setShowImage(res.data);
        setRequestedImgUrl(res.data);
      })
      .catch((err) => {
        setLoading(false);
        showNotification(
          NotificationStatus.Error,
          'Error',
          err.response.data.message
        );
      });
  };

  const handleOnSelect = (value) => {
    setCategory(value);
  };

  return (
    <>
      <Container>
        <Helmet>
          <meta charSet='utf-8' />
          <title> إنشاء حساب -تدري </title>
        </Helmet>

        <LeftContainer right={i18n.language === 'ar' || false}>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            errors={errors}
            register={register}
            formState={formState}
            watch={watch}
            loading={loading}
            handleImageUploading={handleImageUploading}
            showImage={showImage}
            isLoading={isLoading}
            control={control}
            handleOnSelect={handleOnSelect}
          />
        </LeftContainer>
        {!isMobile && (
          <RightContainer right={i18n.language === 'ar' || false}>
            <TadreeComponent />
          </RightContainer>
        )}
      </Container>
    </>
  );
};

export default Register;
