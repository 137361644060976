/** @format */

import React, { useState } from "react";
import Details from "components/QuestionsDetails/view";
import { CustomHeader } from "common/Header";
import Spinner from "common/LoadingIndecator";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { fetchGetRequests } from "infrastructure/helpers/fetchServerRequests";
import { Helmet } from "react-helmet";

const EvaluationDetails = () => {
  let { id } = useParams();
  const [updateTemplate, setUpdateTemplate] = useState(false);
  const { data, isLoading } = useQuery(
    ["evaluationTemplatesDetails", updateTemplate],
    () => fetchGetRequests(`api/company/v1/evaluation-templates/${id}`),
    {
      onSuccess: () => {
        setUpdateTemplate(false);
      },
      onError: () => {
        setUpdateTemplate(false);
      },
    }
  );

  return isLoading ? (
    <Spinner size={"large"} tip={"Loading..."} />
  ) : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> تفاصيل نماذج التقييم -تدري </title>
      </Helmet>
      <CustomHeader
        title={data?.response?.template?.name}
        path={"/evaluation-templates"}
        editPath={`/evaluation-templates/edit/${id}`}
        createdBy={data?.response?.template?.created_by}
      />
      <Details
        data={data?.response?.template}
        type="evaluation-template"
        setUpdateTemplate={setUpdateTemplate}
      />
    </>
  );
};

export default EvaluationDetails;
