/** @format */

import React, { useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { SearchWithPan, Input, Button } from "./style";
import CompassIcon from "../../assets/svg/iconn-my-location";
import pin from "../../assets/svg/icon-marker.svg";
import { locationStore } from "../../infrastructure/zustandStore/location-store";
import {
  NotificationStatus,
  showNotification,
} from "../../infrastructure/helpers/showNotifications";
import { useTranslation } from "react-i18next";
import axios from "axios";

const libraries: any = ["places"];
const googleMapsApiKey: string | any =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

type Props = {
  lat: number;
  long: number;
  time?: number | Date | undefined | any;
};

const MapContainer = ({ width, height, lat, lng, mode }: any) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
  });

  const mapContainerStyle = {
    height: height ? "50vh" : "60vh",
    width: width,
  };

  const [marker, setMarker] = React.useState<Props>({ lat: 0, long: 0 });
  const locationFn: any = locationStore((state) => state.locationFn);
  const address: any = locationStore((state) => state.address);
  const location: { lat: string; long: string } = locationStore(
    (state: Record<string | number | symbol, any>) => state.location
  );

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setMarker({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      },
      () => null
    );
    locationFn();
  }, []);

  const getAddress = (latlng: string) => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${googleMapsApiKey}&language=ar`
      )
      .then((res) => {
        let city;
        let country;
        let destrict;
        let streetNumber;
        for (
          let i = 0;
          i < res.data.results[0]?.address_components?.length;
          i++
        ) {
          for (
            let b = 0;
            b < res.data.results[0]?.address_components[i]?.types?.length;
            b++
          ) {
            if (
              res.data.results[0].address_components[i].types[b] ===
              "administrative_area_level_1"
            ) {
              city = res.data.results[0]?.address_components[i];
              break;
            }
            if (
              res.data.results[0].address_components[i].types[b] === "country"
            ) {
              country = res.data.results[0].address_components[i];
            }
            if (
              res.data.results[0].address_components[i].types[b] ===
              "street_number"
            ) {
              streetNumber = res.data.results[0].address_components[i];
              return streetNumber;
            }
            if (
              res.data.results[0].address_components[i].types[b] ===
              "sublocality"
            ) {
              destrict = res.data.results[0].address_components[i];
            }
            if (
              destrict === undefined &&
              res.data.results[0].address_components[i].types[b] ===
                "administrative_area_level_2"
            ) {
              destrict = res.data.results[0].address_components[i];
            }
          }
        }

        locationStore.setState({
          address: res.data?.results[0]?.formatted_address,
          district: destrict ? destrict.long_name : "",
          city: city ? city.long_name : "",
          country: country ? country.long_name : "",
        });
      });
  };

  const onMapClick = React.useCallback(
    (e: { latLng: { lat: () => any; lng: () => any } }) => {
      const latlng =
        parseFloat(e.latLng.lat()) + "," + parseFloat(e.latLng.lng());
      setMarker({
        lat: e.latLng.lat(),
        long: e.latLng.lng(),
        time: new Date(),
      });
      locationStore.setState({
        location: {
          ...location,
          lat: e.latLng.lat(),
          long: e.latLng.lng(),
        },
      });
      getAddress(latlng);
    },
    []
  );

  const mapRef: React.MutableRefObject<undefined | any> = React.useRef();
  const onMapLoad = React.useCallback((map: any) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }: any) => {
    const latlng = parseFloat(lat) + "," + parseFloat(lng);
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
    getAddress(latlng);

    locationStore.setState({
      location: {
        ...location,
        lat: lat,
        long: lng,
      },
    });
  }, []);

  if (loadError) return <>Error</>;
  if (!isLoaded) return <>Loading...</>;

  return (
    <div>
      {!mode && (
        <SearchWithPan>
          <Search panTo={panTo} setMarker={setMarker} address={address} />
          <Locate panTo={panTo} setMarker={setMarker} />
        </SearchWithPan>
      )}
      {/* @ts-ignore */}

      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={mode ? { lat, lng } : { lat: marker.lat, lng: marker.long }}
        options={options}
        /* @ts-ignore */
        onClick={mode ? () => {} : onMapClick}
        onLoad={onMapLoad}
      >
        {/* @ts-ignore */}

        <Marker
          key={`${marker.lat}-${marker.long}`}
          position={mode ? { lat, lng } : { lat: marker.lat, lng: marker.long }}
          icon={{
            url: pin,
          }}
        />
      </GoogleMap>
    </div>
  );
};
export default MapContainer;

function Locate({
  panTo,
  setMarker,
}: {
  panTo: ({ lat, lng }: any) => void;
  setMarker: any;
}) {
  return (
    <Button
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
            setMarker({
              lat: position.coords.latitude,
              long: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <CompassIcon />
    </Button>
  );
}

function Search({
  panTo,
  setMarker,
  address = "",
}: {
  panTo: ({ lat, lng }: any) => void;
  setMarker: any;
  address: string;
}) {
  const { t } = useTranslation();
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleInput = (e: { target: { value: string } }) => {
    setValue(e.target.value);
    locationStore.setState({
      address: e.target.value,
    });
  };

  const handleSelect = async (address: any) => {
    setValue(address, false);
    locationStore.setState({
      address: address,
      district: address.split(",", 2)[0] + "" + address.split(",", 2)[1],
      city: address.split(",", 3)[2],
    });
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
      setMarker({
        lat: lat,
        long: lng,
      });
      locationStore.setState({
        location: {
          // eslint-disable-next-line no-restricted-globals
          ...location,
          lat: lat,
          long: lng,
        },
      });
    } catch (error) {
      // @ts-ignore:next-line
      showNotification(NotificationStatus.Error, "Error", error);
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;
      return (
        <li
          key={place_id}
          onClick={() => handleSelect(suggestion.description)}
          style={{ cursor: "pointer" }}
          id={main_text}
          className={secondary_text}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div>
      <Input
        className="map-input"
        value={address}
        onChange={handleInput}
        disabled={!ready}
        placeholder={t("map_search_box_placeholder")}
        dir="true"
      />
      {status === "OK" && <ul>{renderSuggestions()}</ul>}
    </div>
  );
}
