/** @format */

import { create } from "zustand";
import { axiosApiInstance } from "infrastructure/utils/api";

export const QuestionTypesStore = create((set: any) => ({
  questionTypes: [],
  questionTypesList: async () => {
    try {
      const response = await axiosApiInstance.get("api/question-types");
      set({ questionTypes: response.data?.questionTypes });
    } catch (error) {}
  },
}));
