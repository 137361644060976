/** @format */

import * as React from "react";
import { SVGProps } from "react";

const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={17} height={17} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.625 14.11 6.007 9.492a1.407 1.407 0 0 1 0-1.984l4.618-4.618"
      stroke="#43C773"
      strokeWidth={1.05}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0,0"
      fill="none"
    />
  </svg>
);

export default ArrowIcon;
