/** @format */

import React, { ChangeEvent } from "react";
import { Trans, useTranslation } from "react-i18next";

import Tabs from "common/Tabs";

import TdreeTemplates from "./TdreeTemplates";
import AdminTemplates from "./AdminTemplates";
import { Title } from "./style";
import { Header } from "pages/style";
import { Container } from "pages/style";
import { ButtonsContainer } from "common/Table/style";
import SearchInput from "common/Search";
import { Button } from "common/Button";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const { useState } = React;

const EvaluationTemplates = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [searchInputValue, setSearchInputValue] = useState("");

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target?.value);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("evaluation_templates")}</title>
      </Helmet>
      <Header language={i18n.language === "ar" ? "ar" : "en"}>
        <Title>
          <Trans>evaluation_templates</Trans>
        </Title>
      </Header>
      <Container language={i18n.language === "ar" ? "ar" : "en"}>
        <ButtonsContainer
          type="template"
          language={i18n.language === "ar" ? "ar" : "en"}
        >
          <SearchInput
            handleChange={handleSearchInputChange}
            placeholder={t("search_placeholder")}
          />
          <Button
            size={"large"}
            onClick={() => navigate("/evaluation-templates/create")}
          >
            {t("new_template")}
          </Button>
        </ButtonsContainer>

        <Tabs
          language={i18n.language === "ar" ? "ar" : "en"}
          className="mainTabs"
          tabOneTitle={t("admin_evaluations")}
          tabTwoTitle={t("tdree_evaluations")}
          tabOneContent={
            <AdminTemplates
              searchResults={searchInputValue}
              setSearchInputValue={setSearchInputValue}
            />
          }
          tabTwoContent={
            <TdreeTemplates
              searchResults={searchInputValue}
              setSearchInputValue={setSearchInputValue}
            />
          }
        />
      </Container>
    </>
  );
};

export default EvaluationTemplates;
