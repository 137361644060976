/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "common/Table";
import { ColumnCell, MoreBtn, ColumnCellName } from "./style";
import { Trans, useTranslation } from "react-i18next";
import Tag from "common/Tag";
import Toolip from "common/Toolip";
import { SwitchBtn } from "common/Switch";
import { Dropdown } from "common/Dropdown";
import { timeStampToDate } from "infrastructure/helpers/timeStampToDate";
import { axiosApiInstance } from "infrastructure/utils/api";
import { ColumnsType } from "antd/lib/table";
import {
  NotificationStatus,
  showNotification,
} from "infrastructure/helpers/showNotifications";
import { EvaluationListProps } from "infrastructure/interfaces/evaluation-list.interface";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchGetRequestsWithPagination } from "infrastructure/helpers/fetchServerRequests";
import EditIcon from "assets/svg/edit-icon";
import DeleteIcon from "assets/svg/icon-delete";
import { Container, TablesWrapper } from "pages/style";
import { Helmet } from "react-helmet";
import { Menu, MenuProps } from "antd";
import Modal from "common/Modal";
import { StyledP, Container as MenuContainer } from "common/Menu/style";
import styled from "styled-components";

const AdminTemplates = ({ searchResults }: any) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [page, SetPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const { data, isLoading } = useQuery(
    ["evaluationAdminListData", page, searchResults],
    () =>
      fetchGetRequestsWithPagination(
        `api/company/v1/evaluation-templates?page=${page}?limit=10&search=${searchResults}`
      ),
    {
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDeatilsModalVisible, setDeatilsModalVisible] =
    useState<boolean>(false);
  const [row, setRow] = useState<{ status: { id: number }; id: number }>({
    status: { id: 0 },
    id: 0,
  });

  const [isDuplicateModalVisible, setIsDuplicateModalVisible] =
    useState<boolean>(false);

  const [, setChecked] = useState<boolean>(true);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showDetailsModal = () => {
    setDeatilsModalVisible(true);
  };

  const showDuplicateModal = () => {
    setIsDuplicateModalVisible(true);
  };

  const handleCancelDuplicateModal = () => {
    setIsDuplicateModalVisible(false);
  };

  const handleDeleteEvaluation = useMutation(
    (text: number) =>
      axiosApiInstance.delete(`api/company/v1/evaluation-templates/${text}`),
    {
      onMutate: async (text: number) => {
        // Snapshot the previous value
        const previousData =
          queryClient.getQueryData<any>("evaluationListData");

        // Optimistically update to the new value
        if (previousData) {
          const newList = data?.templates?.filter(
            (item: EvaluationListProps) => item.id !== text
          );

          queryClient.setQueryData<any>("evaluationListData", {
            ...previousData,
            templates: [...previousData.templates, newList],
          });
        }
        return previousData;
      },
      onError: (err: any) => {
        if (err.response.data.statusCode === 400) {
          showNotification(
            NotificationStatus.Error,
            " ",
            err.response.data.message
          );
        }
        queryClient.invalidateQueries("evaluationAdminListData");
        setIsModalVisible(false);
        setLoading(false);
      },
      onSuccess: () => {
        queryClient.invalidateQueries("evaluationAdminListData");
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
        setIsModalVisible(false);
        setLoading(false);
      },
      onSettled: () => {
        queryClient.invalidateQueries("evaluationListData");
      },
    }
  );

  const onDelete = (id) => {
    setLoading(true);
    handleDeleteEvaluation.mutate(id);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDetailsCancel = () => {
    setDeatilsModalVisible(false);
  };

  const handleOnChangeSwitchButton = (checked: boolean, value: any) => {
    axiosApiInstance
      .post("api/company/v1/evaluation-templates/state", {
        id: value.id,
        active: checked,
      })
      .then(() => {
        queryClient.invalidateQueries("evaluationAdminListData");
      })
      .catch((err) => {
        showNotification(
          NotificationStatus.Error,
          " ",
          err.response.data.message
        );
      });
  };

  const handlePagination = (page: number) => {
    SetPage(page);
  };

  const handleDuplicate = (id: number) => {
    axiosApiInstance
      .post("api/company/v1/evaluation-templates/duplicate", {
        template_id: id,
      })
      .then((res) => {
        setIsDuplicateModalVisible(false);
        showNotification(
          NotificationStatus.Success,
          t("done"),
          t("done_message")
        );
      })
      .catch((err) => {
        setIsDuplicateModalVisible(false);
        showNotification(
          NotificationStatus.Error,
          t("Error"),
          err.response.data.message
        );
      });
  };

  const handleMenuClick = (row: any) => {
    setRow(row);
  };

  const items: MenuProps["items"] = [
    {
      key: " 1",
      label: (
        <StyledMenu language={i18n.language === "ar" ? "ar" : "en"}>
          <MenuContainer
            onClick={showDuplicateModal}
            language={i18n.language === "ar" ? "ar" : "en"}
            minWidth="130pz"
          >
            {i18n.language === "ar" ? (
              <>
                <i className="fa fa-clone" />
                <a>
                  <Trans>duplicate</Trans>
                </a>
              </>
            ) : (
              <>
                <a>
                  <Trans>duplicate</Trans>
                </a>
                <i className="fa fa-clone" />
              </>
            )}
          </MenuContainer>
        </StyledMenu>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <MenuContainer
            onClick={
              row.status.id === 1
                ? () => navigate(`/evaluation-templates/edit/${row.id}`)
                : showDetailsModal
            }
            language={i18n.language === "ar" ? "ar" : "en"}
            minWidth="130pz"
          >
            {i18n.language === "ar" ? (
              <>
                <EditIcon />
                <a>
                  <Trans>edit</Trans>
                </a>
              </>
            ) : (
              <>
                <a>
                  <Trans>edit</Trans>
                </a>
                <EditIcon />
              </>
            )}
          </MenuContainer>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <MenuContainer
          onClick={showModal}
          language={i18n.language === "ar" ? "ar" : "en"}
          minWidth="130pz"
        >
          {i18n.language === "ar" ? (
            <>
              <DeleteIcon />
              <a>
                <Trans>delete</Trans>
              </a>
            </>
          ) : (
            <>
              <a>
                <Trans>delete</Trans>
              </a>
              <DeleteIcon />
            </>
          )}
        </MenuContainer>
      ),
    },
  ];

  const columns: ColumnsType<object> | undefined = [
    {
      title: t("template_num"),
      dataIndex: "id",
      key: "رقم النموذج",
      render: (text: string) => <ColumnCell>{text}</ColumnCell>,
    },
    {
      title: t("status"),
      key: "الحالة",
      render: (record: any) => (
        <ColumnCell>
          {record.status?.id === 1 ? (
            <Tag color="rgba(52, 66, 71, 0.1)">{record.status?.name}</Tag>
          ) : (
            <Tag color="blue">{record.status?.name}</Tag>
          )}
        </ColumnCell>
      ),
    },
    {
      title: t("template_name"),
      dataIndex: "name",
      key: "اسم النموذج",
      render: (text: string, record: any) => (
        <ColumnCellName
          onClick={() => navigate(`/evaluation-templates/${record.id}`)}
        >
          {text}
        </ColumnCellName>
      ),
    },
    {
      title: t("created_at"),
      dataIndex: "created_at",
      key: "تاريخ الإنشاء",
      render: (text: string) => (
        <ColumnCell>{timeStampToDate(text)}</ColumnCell>
      ),
      // defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => {
        return (
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
      },
    },
    {
      title: t("jobs"),
      key: "jobs",
      render: (record: any) => (
        <ColumnCell>
          <Toolip count={record.jobs} />
        </ColumnCell>
      ),
    },
    {
      title: t("on/off"),
      key: "on/off",
      dataIndex: "active",
      render: (record: any, value: any) => (
        <SwitchBtn
          onChange={(checked: boolean) => {
            setChecked(checked);
            handleOnChangeSwitchButton(checked, value);
          }}
          defaultChecked={record}
        />
      ),
    },
    {
      title: " ",
      key: "action",
      render: (record) => {
        return (
          <>
            <Dropdown items={{ items }} onClick={() => handleMenuClick(record)}>
              <MoreBtn
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                ...
              </MoreBtn>
            </Dropdown>
            <Modal
              title={<StyledP>{<Trans>duplicate</Trans>}</StyledP>}
              visible={isDuplicateModalVisible}
              onOk={() => handleDuplicate(row.id)}
              onCancel={handleCancelDuplicateModal}
            >
              {<Trans>duplicate_message</Trans>}
            </Modal>
            <Modal
              title={<StyledP></StyledP>}
              visible={isDeatilsModalVisible}
              onCancel={handleDetailsCancel}
              hideOk={true}
            >
              <div style={{ textAlign: "center" }}>
                <h3>
                  <Trans>can_not_edit_case</Trans>
                </h3>
              </div>
            </Modal>
            <Modal
              title={<StyledP>{<Trans>delete_Evaluation</Trans>}</StyledP>}
              visible={isModalVisible}
              onOk={() => onDelete(row.id)}
              onCancel={handleCancel}
              loading={loading}
            >
              <Trans>evaluation_template_modal_delete_message</Trans>
            </Modal>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {i18n.language === "ar"
            ? " نماذج تقييم شركة -تدري "
            : "Company Templates - Tdree"}
        </title>
      </Helmet>
      <Container>
        <TablesWrapper>
          <CustomTable
            columns={columns}
            data={data?.templates}
            loading={isLoading}
            pagination={{
              position: [i18n.language === "ar" ? "bottomRight" : "bottomLeft"],
              total: searchResults?.pagination?.total || data?.pagination.total,
              defaultCurrent:
                searchResults?.pagination?.total ||
                data?.pagination.current_page ||
                1,
              onChange: (page: number) => handlePagination(page),
            }}
          />
        </TablesWrapper>
      </Container>
    </>
  );
};

export default AdminTemplates;

//@ts-ignore
export const StyledMenu = styled(Menu)`
  &.ant-dropdown-menu {
    width: 140px !important;
    box-shadow: 3px 3px 5px 6px #ccc;
    direction: ${(props: { language?: string }) =>
      props.language === "ar" ? "ltr" : "rtl"};
  }
`;
