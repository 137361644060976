import React from "react";
import { Category, CategoryWrapper } from "./style";

const EvaluationCategory = ({ category }: any) => {
  return (
    <CategoryWrapper>
      <Category>{category} #</Category>
    </CategoryWrapper>
  );
};

export default EvaluationCategory;
