/** @format */
import { Divider } from "antd";
import Logo from "assets/svg/logo";
import { timeStampToDate } from "infrastructure/helpers/timeStampToDate";
import Colors from "infrastructure/utils/Colors";
import { axiosApiInstance } from "infrastructure/utils/api";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  BillContainer,
  HeaderWrapper,
  LogoWrappper,
  Pargraph,
  ContentWrapper,
  CompanyName,
  Tr,
  Th,
  Td,
  Payments,
  Payment,
  SmallPargraph,
  Footer,
  Table,
} from "../Invoices/style";

const WalletQr = () => {
  const { id }: any = useParams();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    axiosApiInstance.get(`api/transaction/wallet/${id}`).then((response) => {
      setData(response.data.transaction);
    });
  }, []);

  return (
    data && (
      <div
        style={{
          width: "550px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          height: "100%",
        }}
      >
        <BillContainer>
          <HeaderWrapper>
            <LogoWrappper>
              <Logo />
            </LogoWrappper>
            <div>
              <Pargraph
                fontWeight="normal"
                color={Colors.dark_mint}
                fontSize="14px"
              >
                <Trans>wallet_charge_invoice</Trans>
              </Pargraph>
            </div>
          </HeaderWrapper>
          <HeaderWrapper>
            <LogoWrappper>
              <Pargraph color="#000" fontWeight="bold" fontSize="14px">
                <Trans>tdree</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                <Trans>tdree_company_name</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                <Trans>saudi_arabia_riyadh</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                www.tdree.com
              </Pargraph>
            </LogoWrappper>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Pargraph color="#000" fontSize="10px">
                <Trans>commercial_number</Trans>
              </Pargraph>
              <Pargraph color="#000" fontSize="10px">
                {"1010487662"}
              </Pargraph>
              <Pargraph color="#000" fontSize="10px">
                <Trans>tax_number</Trans>
              </Pargraph>
              <Pargraph color="#000" fontSize="10px">
                <span>310769921800003</span>
              </Pargraph>
            </div>
          </HeaderWrapper>
          <Divider
            style={{
              backgroundColor: Colors.dark_mint,
              border: "4px solid #44c874",
            }}
          />
          <ContentWrapper>
            <CompanyName>
              <Pargraph fontSize="10px" color="#000">
                <Trans>invoice_to</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                {data.company.name}
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                {data.company.address}
              </Pargraph>
            </CompanyName>

            <CompanyName>
              <Pargraph fontSize="10px" color="#000">
                <Trans>commercial_number</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                {data.company.commercial_number}
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                <Trans>tax_number</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                {data.company.tax_number}
              </Pargraph>
            </CompanyName>
          </ContentWrapper>

          <Divider
            style={{
              backgroundColor: Colors.dark_mint,
              border: "4px solid #44c874",
            }}
          />

          <ContentWrapper>
            <CompanyName>
              <Pargraph fontSize="10px" color="#000">
                <Trans>wallet_invoice_purpose</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                <Trans>wallet_pdf_purpose</Trans>
              </Pargraph>
            </CompanyName>

            <CompanyName>
              <Pargraph fontSize="10px" color="#000">
                <Trans>wallet_invoice_number</Trans>
              </Pargraph>

              <Pargraph fontSize="10px" color="#000">
                {data.id}
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                <Trans>wallet_createdat</Trans>
              </Pargraph>
              <Pargraph fontSize="10px" color="#000">
                {timeStampToDate(data.createdAt)}
              </Pargraph>
            </CompanyName>
          </ContentWrapper>

          <Table>
            <tbody>
              <Tr>
                <Th>
                  <Trans>description</Trans>
                </Th>

                <Th>
                  <Trans>total_amounnt_without_vats</Trans>
                </Th>
                <Th>
                  <Trans>vat_percentage</Trans>
                </Th>

                <Th>
                  <Trans>vat_amount</Trans>
                </Th>

                <Th>
                  <Trans
                    values={{
                      vatPercentage: data?.vat_percentage,
                    }}
                  >
                    total_amount
                  </Trans>
                </Th>
              </Tr>

              <Tr>
                <Td>
                  <Pargraph
                    fontSize="10px"
                    color="#000"
                    style={{ margin: "0" }}
                  >
                    <Trans>wallet_descriptionn</Trans>
                  </Pargraph>
                </Td>

                <Td>{data.amount}</Td>

                <Td>{data.vat_percentage}</Td>

                <Td>{data.vat_amount}</Td>
                <Td>{data.total_amount}</Td>
              </Tr>
            </tbody>
          </Table>

          <ContentWrapper>
            <Payments>
              <Payment>
                <SmallPargraph>
                  <Trans>wallet_total</Trans>
                </SmallPargraph>
                <SmallPargraph>{data.amount}</SmallPargraph>
              </Payment>

              <Payment>
                <SmallPargraph>
                  <Trans
                    values={{
                      vatPercentage: data?.vat_percentage,
                    }}
                  >
                    vat
                  </Trans>
                </SmallPargraph>
                <SmallPargraph>{data.vat_amount}</SmallPargraph>
              </Payment>

              <Payment>
                <SmallPargraph>
                  <Trans
                    values={{
                      vatPercentage: data?.vat_percentage,
                    }}
                  >
                    wallet_total_amount
                  </Trans>
                </SmallPargraph>
                <SmallPargraph>{data.total_amount}</SmallPargraph>
              </Payment>
            </Payments>
          </ContentWrapper>
          <Footer>
            <Pargraph fontSize="10px" color="#000">
              <Trans>view_balance_consumption_details</Trans>
            </Pargraph>
            <Pargraph fontSize="10px" color="#000">
              <Trans>thank_you</Trans>
            </Pargraph>
          </Footer>
        </BillContainer>
      </div>
    )
  );
};

export default WalletQr;
