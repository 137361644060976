/** @format */
//@ts-nocheck
import React, { useRef, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Empty } from 'common/Empty';
import { QuestionContainer } from 'pages/EvaluationTemplate/EvaluationDetails/style';
import {
  Question,
  ShoppersDataContainer,
  PageSummaryContainer,
  SummaryWrapper,
  BranchNameWrapper,
} from '../style';
import { useParams } from 'react-router-dom';
import { axiosApiInstance } from 'infrastructure/utils/api';
import { mockedArr } from 'infrastructure/helpers/questionTypeList';
import { timeStampToDate } from 'infrastructure/helpers/timeStampToDate';
import PrintPDF from 'components/ReactPdf';
import PdfCoverr from 'components/ReactPdf/cover';
import EvaluationCategory from 'components/Summary/EvaluationCategroy';
import SummaryPointsReview from 'components/Summary/summaryPointsReview';
import QuestionAnswer from 'components/Summary/QuestionAnswer';
import AnswerCount from 'components/Summary/AnswerCount';
import AnswerChart from 'components/Summary/AnswerChart';
import RestOfAnswers from 'components/Summary/RestOfAnswers';
import InfiniteScroll from 'react-infinite-scroll-component';
import ExtraQuestions from 'components/Summary/ExtraQuestions';
import { useQueries } from 'react-query';
import { fetchGetRequests } from 'infrastructure/helpers/fetchServerRequests';
import { useStore } from 'infrastructure/zustandStore/evaluation-template-store';
import { jobSummaryQuestionsUseStore } from 'infrastructure/zustandStore/job-summary-questions-store';
import { List, Skeleton } from 'antd';
import { Label } from 'components/ReactPdf/style';

export const ShoppersSummary = ({
  templateId,
  branch,
  startTime,
  endTime,
  shoppersCount,
  pdf_file,
}: {
  templateId: number;
  branch: { name: string };
  startTime: string;
  endTime: string;
  shoppersCount: number;
  pdf_file?: any;
}) => {
  const { id }: { id?: string | number | undefined } = useParams();
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState<any>(null);

  const [template, setTemplates] = useStore((state: any) => [
    state.template,
    state.setTemplates,
  ]);

  const [jobQuestion, setJobQuestions] = jobSummaryQuestionsUseStore(
    (state: any) => [state.jobQuestion, state.setJobQuestions]
  );

  const [{ data: jobTemplates }, { data: jobSummary }] = useQueries([
    {
      queryKey: ['templates'],
      queryFn: () =>
        fetchGetRequests(`api/company/v2/evaluation-templates/${templateId}`),
    },
    {
      queryKey: ['jobSummary'],
      queryFn: () => fetchGetRequests(`api/company/v2/jobs/${id}/summary`),
    },
  ]);

  const componentRef: React.MutableRefObject<undefined> | any = useRef();

  const handleExportExcel = () => {
    return axiosApiInstance
      .get(`api/company/v1/jobs/export/${id}`, { responseType: 'blob' })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  useEffect(() => {
    if (jobTemplates) {
      setTemplates(jobTemplates?.response?.template);
    }
  }, [jobTemplates]);

  const loadMoreData = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const response = await axiosApiInstance.get(
        `api/company/v2/jobs/${id}/summary/questions/?page=${page}`
      );

      setItems((prevItems) => [...prevItems, ...response.data.data.questions]);
      setJobQuestions(response.data.data.questions);
      setPagination(response.data.data.pagination);
      setLoading(false);

      if (page <= response.data.data.pagination.total_pages) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMoreData();
  }, []);

  // eslint-disable-next-line
  let scale;

  items.length &&
    items.map((question: any, index: number) => {
      scale = {
        min: question.scale?.min,
        step: question.scale?.step,
        max: question.scale?.max,
      };

      function move(input: any[], from: any, to: any) {
        let numberOfDeletedElm = 1;

        const elm = input.splice(from, numberOfDeletedElm)[0];

        numberOfDeletedElm = 0;

        input.splice(to, numberOfDeletedElm, elm);
      }

      if (question?.options) {
        const lastElement = question?.options[question?.options?.length - 1];

        const lastIndex = question?.options.findIndex(
          (el) => el === lastElement
        );
        const indexOf = question?.options.findIndex((object) => {
          return object.option === 'جميع الاجابات لا تنطبق';
        });
        move(question?.options, indexOf, lastIndex);
      }
    });

  return (
    <PageSummaryContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
      {jobQuestion?.questions?.length > 0 ? (
        <InfiniteScroll
          style={{ height: '100%', overflowX: 'hidden' }}
          dataLength={items?.length || 0}
          next={loadMoreData}
          hasMore={pagination?.has_more || false}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          scrollableTarget='scrollableDiv'
        >
          <PrintPDF handleExportExcel={handleExportExcel} pdf_file={pdf_file}>
            <>
              <PdfCoverr
                template_name={template?.data?.name}
                description={template?.data?.description}
                branch_name={branch?.name}
                start_time={timeStampToDate(startTime)}
                end_time={timeStampToDate(endTime)}
                shoppers_number={shoppersCount}
                questions_number={template?.data?.questions?.length}
                focus_of_evaluation={template?.data?.categories.map(
                  (category: any, index: any) => (
                    <EvaluationCategory key={index} category={category} />
                  )
                )}
                summary_point_type={template?.data?.type === 'points'}
                show_summary={true}
                pageHeight='65vh'
                percentage={
                  jobSummary?.response.jobSummary.branchRate?.percentage
                }
                questionCategories={
                  jobSummary?.response.jobSummary?.questionCategories
                }
              />
              <SummaryWrapper>
                {branch.name && (
                  <BranchNameWrapper>
                    <Label>
                      <Trans>branch</Trans>
                    </Label>
                    {branch.name}
                  </BranchNameWrapper>
                )}
                {template?.data?.type === 'points' && (
                  <SummaryPointsReview
                    percentage={
                      jobSummary?.response.jobSummary.branchRate?.percentage
                    }
                    questionCategories={
                      jobSummary?.response.jobSummary?.questionCategories
                    }
                  />
                )}
              </SummaryWrapper>
              {/* shoppers data  */}
              <ShoppersDataContainer
                ref={componentRef}
                language={i18n.language === 'ar' ? 'ar' : 'en'}
              >
                <List
                  dataSource={items}
                  grid={{
                    gutter: 8,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                  }}
                  renderItem={(item: any, index: number) => (
                    <List.Item key={index}>
                      <div className='page-break' key={index}>
                        <QuestionContainer
                          language={i18n.language === 'ar' ? 'ar' : 'en'}
                        >
                          {/* category  */}
                          {item.category && (
                            <EvaluationCategory category={item?.category} />
                          )}
                          {/* question with answer */}
                          <QuestionAnswer
                            type={template?.data?.type}
                            question={item}
                            language={i18n.language === 'ar' ? 'ar' : 'en'}
                            index={index}
                            mockedArr={mockedArr}
                          />
                          <AnswerCount
                            totalShoppersCount={item?.totalShoppersCount}
                            answeredShoppersCount={item?.answeredShoppersCount}
                          />
                          <AnswerChart
                            question={item}
                            language={i18n.language === 'ar' ? 'ar' : 'en'}
                          />
                        </QuestionContainer>

                        {item.questionType?.id === 8 ||
                          item.questionType?.id === 9 ||
                          (item.questionType?.id === 2 && (
                            <RestOfAnswers
                              question={item}
                              language={i18n.language === 'ar' ? 'ar' : 'en'}
                              index={index}
                            />
                          ))}

                        {item.extras && (
                          <QuestionContainer
                            language={i18n.language === 'ar' ? 'ar' : 'en'}
                          >
                            <ExtraQuestions
                              question={item}
                              language={i18n.language === 'ar' ? 'ar' : 'en'}
                              index={index}
                            />
                          </QuestionContainer>
                        )}
                      </div>
                    </List.Item>
                  )}
                />
              </ShoppersDataContainer>
            </>
          </PrintPDF>
        </InfiniteScroll>
      ) : (
        <Empty
          description={
            <Question>
              <Trans>no_shoppers_data</Trans>
            </Question>
          }
        />
      )}
    </PageSummaryContainer>
  );
};
