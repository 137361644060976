/** @format */

import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { timeStampToDate } from "../../infrastructure/helpers/timeStampToDate";
import {
  StyledCard,
  Title,
  Status,
  TimeSpan,
  TimesContainer,
  Label,
  TimesSubContainer,
  ShoppersContainer,
  NoShoppersLabel,
  StyledTitle,
  SmallCardStyle,
  ShopperDetailsRow,
  Information,
  ShoppersTitle,
  ShoppersAddress,
  ShoppersStatusAndIdRow,
  ShoppersStatus,
  StyledLink,
  PaymentFailerSpan,
  Header,
  TitleWrapper,
  JobID,
} from "./style";
import { Avatar as AntDAvatar } from "antd";
import Avatar from "../Avatar";
import { Progress } from "../Progress";
import { Button } from "../Button";
import Colors from "infrastructure/utils/Colors";
import { Checkbox } from "common/Checkbox";

export interface Props {
  title: string;
  isActive?: boolean;
  status: {
    id: number;
    name: string;
  };
  id?: number;
  loading?: boolean;
  paymentStatus?: number;
  createdAt?: string | undefined;
  branch?: string | undefined;
  shoppersCount?: number | undefined;
  shoppersImages?: Array<{ id: number; image: string }>;
  finished?: number | undefined;
  onClick?: () => void;
  address?: string;
  rating?: number;
  image?: string;
  jobId?: number | undefined;
  className?: string | any;
  startTime?: string;
  endTime?: string;
  onPayClick?: () => void;
  assigned?: any;
  handleSelectJob?: (event: any, id: any) => void;
}

export const Card = (props: Props) => {
  const { i18n } = useTranslation();
  const { id, handleSelectJob } = props;
  return (
    <StyledCard language={i18n.language === "ar" ? "ar" : "en"}>
      <StyledTitle>
        <Header>
          <TitleWrapper>
            {props.paymentStatus === 1 && props.status.id === 1 && (
              <Checkbox
                onChange={(e: any) => handleSelectJob && handleSelectJob(e, id)}
              />
            )}
            <Title>
              <StyledLink data-testid="title-id" onClick={props.onClick}>
                {props.title}{" "}
              </StyledLink>
            </Title>
            <Status status={props.status?.id} data-testid="status-name">
              {" "}
              {props.status?.name}
            </Status>
          </TitleWrapper>
          <JobID data-testid="id">#{props?.id}</JobID>
        </Header>
        {props.paymentStatus === 1 && props.status.id !== 8 && (
          <Button onClick={props.onPayClick}>
            <Trans>payment_status_label</Trans>
          </Button>
        )}
        {props.paymentStatus === 3 && (
          <>
            <PaymentFailerSpan>
              <Trans>pay_failed</Trans>
            </PaymentFailerSpan>
            <Button onClick={props.onPayClick}>
              <Trans>re_pay</Trans>
            </Button>
          </>
        )}
      </StyledTitle>
      <TimeSpan createdAt>
        {props.createdAt !== undefined && timeStampToDate(props.createdAt)}
      </TimeSpan>
      <TimesContainer>
        <TimesSubContainer>
          <Label>
            <Trans>start_job</Trans>
          </Label>
          <TimeSpan>
            {props.startTime !== undefined && timeStampToDate(props.startTime)}
          </TimeSpan>
        </TimesSubContainer>
        <TimesSubContainer>
          <Label>
            <Trans>end_job</Trans>
          </Label>
          <TimeSpan>
            {props.endTime !== undefined && timeStampToDate(props.endTime)}
          </TimeSpan>
        </TimesSubContainer>

        <TimesSubContainer>
          <Label>
            <Trans>evaluation_place</Trans>
          </Label>
          <TimeSpan data-testid="branch">{props.branch}</TimeSpan>
        </TimesSubContainer>
      </TimesContainer>
      {props.shoppersCount !== undefined && props.shoppersCount > 0 ? (
        <ShoppersContainer>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            data-testid="images"
          >
            <Label>
              <Trans>shoppers</Trans> ({props.shoppersCount})
            </Label>
            <AntDAvatar.Group size="default">
              {props.shoppersImages !== undefined &&
                props.shoppersImages.map((image) => {
                  return (
                    <Avatar
                      size={"default"}
                      shape={"circle"}
                      src={image.image}
                      key={image.id}
                    />
                  );
                })}
            </AntDAvatar.Group>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <Label>
              <Trans>assigned</Trans>
            </Label>
            <span style={{ textAlign: "center" }} data-testid="assigned">
              {" "}
              {props.assigned}{" "}
            </span>
          </div>

          <div>
            <Label>
              ({props.shoppersCount}/{props.finished})
              <Trans>finished_evaluation</Trans>
            </Label>
            <Progress
              percent={
                props.finished !== undefined &&
                props.shoppersCount !== undefined &&
                (100 * props.finished) / props.shoppersCount
              }
              size={"small"}
              showInfo={false}
            />
          </div>
        </ShoppersContainer>
      ) : (
        <NoShoppersLabel data-testid="empty-shoppers">
          <Trans>no_shoppers_yet</Trans>
        </NoShoppersLabel>
      )}
    </StyledCard>
  );
};

export const SmallCard = (props: Props) => {
  const { i18n } = useTranslation();
  return (
    <SmallCardStyle
      onClick={props.onClick}
      className={props.className}
      language={i18n.language === "ar" ? "ar" : "en"}
      style={{
        backgroundColor: props.isActive ? Colors.light_gray : Colors.white,
        borderColor: props.isActive ? Colors.dark_mint : Colors.light_gray,
        cursor: "pointer",
      }}
      data-testid="small-card"
    >
      <ShopperDetailsRow>
        <Avatar
          src={props.image}
          shape={"circle"}
          size={"default"}
          preview={false}
        />
        <Information language={i18n.language === "ar" ? "ar" : "en"}>
          <ShoppersTitle
            language={i18n.language === "ar" ? "ar" : "en"}
            data-testid="title-id"
          >
            {props.title}
          </ShoppersTitle>
          <ShoppersAddress
            language={i18n.language === "ar" ? "ar" : "en"}
            data-testid="address-id"
          >
            {props.address}
          </ShoppersAddress>
          <ShoppersAddress
            language={i18n.language === "ar" ? "ar" : "en"}
          ></ShoppersAddress>
        </Information>
      </ShopperDetailsRow>

      <ShoppersStatusAndIdRow language={i18n.language === "ar" ? "ar" : "en"}>
        <JobID data-testid="job-id">#{props.jobId}</JobID>
        <ShoppersStatus status={props.status?.id} data-testid="status">
          {props.status?.name}
        </ShoppersStatus>
      </ShoppersStatusAndIdRow>
    </SmallCardStyle>
  );
};
