/** @format */

import * as React from "react";

const TaskIconResponsive = () => (
  <svg width={37} height={36} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M16.923 27c3.65 0 3.775-4.175 6.21-6.53 2.555-2.472 5.606-3.982 7.3-7.524 1.695-3.543 3.94-7.25-.583-11.032-4.523-3.783-9.607-.79-13.403-.79-4.948 0-12.304.388-14.667 4.363C.571 7.52 0 11.845 0 14.385c0 3.628 2.55 7.053 4.86 9.49C7.327 26.478 13.06 27 16.923 27z"
        fill="#E8F8EE"
      />
      <g
        stroke="#292D32"
        strokeWidth={2.016}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0,0"
      >
        <path d="M21.244 15.446h7.113m-15.229 0 1.016 1.02 3.049-3.059m4.051 11.555h7.113m-15.229 0 1.016 1.02 3.049-3.06" />
        <path d="M7.194 20.979v2.787c0 6.797 2.71 9.515 9.484 9.515h8.129c6.774 0 9.483-2.718 9.483-9.515v-8.157c0-6.796-2.71-9.515-9.483-9.515h-8.129c-6.774 0-9.484 2.718-9.484 9.515" />
      </g>
    </g>
  </svg>
);

export default TaskIconResponsive;
