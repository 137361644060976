/** @format */

import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Input } from 'common/Input';
import Modal from 'common/Modal';
import { FormProps } from 'infrastructure/interfaces/forms.interface';

import { Button } from 'common/Button';
import {
  SubTitle,
  Title,
  TitleContainer,
  LinkStyle,
  SubContainer,
  InputsContainer,
  Form as StyledForm,
  PrefixSpan,
  Img,
  ImgContainer,
  FileContainer,
  TermsTitle,
  RegiaterBottomContainer,
} from '../style';
import { LoadingOutlined } from '@ant-design/icons';

import { Error, Label } from '../../style';
import User from 'assets/svg/User';
import EditIcon from 'assets/svg/edit-icon';
import Footer from 'common/Footer';
import InvisibleIcon from 'assets/svg/icon-invisible';
import VisibleIcon from 'assets/svg/icon-visible';
import TermsAndConditions from 'pages/Static/termsAndConditions';
import { Select as AntDSelect, Typography } from 'antd';
import { Select } from 'common/Select';
import { useStore } from 'infrastructure/zustandStore/categories-store';

const { useState, useRef } = React;
const { Option } = AntDSelect;
const { Text } = Typography;

const Form: React.FC<FormProps> = ({
  errors,
  register,
  formState,
  watch,
  onSubmit,
  loading,
  handleImageUploading,
  showImage,
  isLoading,
  handleOnSelect,
}): JSX.Element => {
  const { i18n, t } = useTranslation();
  const password = useRef({});
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  password.current = watch('password', '');

  const [categories, categoriesList] = useStore((state: any) => [
    state.categories,
    state.categoriesList,
  ]);

  useEffect(() => {
    categoriesList();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'initial',
        width: '100%',
        direction: i18n.language === 'en' ? 'ltr' : 'rtl',
      }}
    >
      <StyledForm onSubmit={onSubmit}>
        <TitleContainer>
          <Title>
            <Trans>create_account</Trans>
          </Title>
          <SubTitle>
            <Trans>do_you_have_an_account</Trans>
            <LinkStyle
              to={{ pathname: '/login' }}
              language={i18n.language === 'ar' ? 'ar' : 'en'}
            >
              <Trans>loginـnow</Trans>
            </LinkStyle>
          </SubTitle>
        </TitleContainer>
        <SubContainer>
          <FileContainer>
            <Label>
              <Trans>image</Trans>
            </Label>
            <label htmlFor='file'>
              {showImage ? (
                <>
                  <Img src={showImage?.fullUrl} alt='' />

                  <EditIcon register='true' />
                </>
              ) : (
                <>
                  <User />
                </>
              )}
              {loading && (
                <ImgContainer>
                  <LoadingOutlined />
                </ImgContainer>
              )}
            </label>

            <input
              type='file'
              id='file'
              accept='.png, .jpg, .jpeg'
              style={{ display: 'none' }}
              name='image'
              onChange={(e) => handleImageUploading(e)}
            />
          </FileContainer>
          <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
            <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
              <Trans>company_name</Trans>
            </Label>
            <Input
              name='name'
              type='text'
              dir='true'
              shortName='true'
              errorName={errors.name}
              ref={register({ required: true, minLength: 2 })}
              width='register'
            />
            {errors?.name?.types?.required && (
              <Error>
                <Trans>required_error_message</Trans>
              </Error>
            )}
            {errors?.name?.types?.minLength && (
              <Error>
                <Trans>firstNameـminLength_2</Trans>
              </Error>
            )}
          </InputsContainer>
        </SubContainer>

        <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
            <Trans>email</Trans>
          </Label>
          <Input
            name='email'
            type='text'
            dir='true'
            errorName={errors.email}
            ref={register({
              required: true,
              pattern:
                /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
            })}
            width='login'
          />

          {errors?.email?.types?.required && (
            <Error>
              <Trans>email_required</Trans>
            </Error>
          )}
          {errors?.email?.types?.pattern && (
            <Error>
              <Trans>patternـisـnotـcorrect</Trans>
            </Error>
          )}
        </InputsContainer>
        <br />

        <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
            <Trans>password</Trans>
          </Label>

          <Input
            name='password'
            type={passwordShown ? 'text' : 'password'}
            errorName={errors.password}
            ref={register({ required: true, minLength: 8 })}
            width='login'
          />
          {errors?.password?.types?.required && (
            <Error>
              <Trans>password_required</Trans>
            </Error>
          )}
          {errors?.password?.types?.minLength && (
            <Error>
              <Trans>passwordـminLength_8</Trans>
            </Error>
          )}
          {passwordShown ? (
            <VisibleIcon
              onClick={togglePasswordVisiblity}
              className='register'
              lang={i18n.language === 'ar' ? 'ar' : 'en'}
            />
          ) : (
            <InvisibleIcon
              onClick={togglePasswordVisiblity}
              className='register'
              lang={i18n.language === 'ar' ? 'ar' : 'en'}
            />
          )}
        </InputsContainer>
        <br />

        <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
            <Trans>confirm_password</Trans>
          </Label>

          <Input
            name='confirm_password'
            type={confirmPasswordShown ? 'text' : 'password'}
            errorName={errors.confirm_password}
            ref={register({
              required: true,
              validate: (value: string) =>
                value === password.current || 'The passwords do not match',
            })}
            width='login'
          />
          {errors?.confirm_password?.types?.required && (
            <Error>
              <Trans>password_required</Trans>
            </Error>
          )}
          {errors.confirm_password && (
            <Error>
              <Trans>the_passwords_do_not_match</Trans>
            </Error>
          )}
          {confirmPasswordShown ? (
            <VisibleIcon
              onClick={toggleConfirmPasswordVisiblity}
              className='register'
              lang={i18n.language === 'ar' ? 'ar' : 'en'}
            />
          ) : (
            <InvisibleIcon
              onClick={toggleConfirmPasswordVisiblity}
              className='register'
              lang={i18n.language === 'ar' ? 'ar' : 'en'}
            />
          )}
        </InputsContainer>
        <br />

        <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
            <Trans>mobileNum</Trans>
          </Label>

          <PrefixSpan lang={i18n.language === 'ar' ? 'ar' : 'en'}>
            +966
          </PrefixSpan>
          <Input
            name='mobile'
            type='number'
            countryCode
            errorName={errors.mobile}
            ref={register({
              required: true,
              pattern: /^[0-9]*$/,
            })}
            maxLength='9'
            width='login'
            onWheel={(e: any) => e.target.blur()}
          />
          {errors?.mobile?.types?.required && (
            <Error>
              <Trans>mobileNum_required</Trans>
            </Error>
          )}
          {errors?.mobile?.types?.pattern && (
            <Error>
              <Trans>numbers_only</Trans>
            </Error>
          )}
        </InputsContainer>
        <br />

        <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
            <Trans>categories</Trans>
          </Label>

          <Select
            placeholder={t('category')}
            className='full-width'
            ref={register({
              required: true,
            })}
            mode='multiple'
            onChange={(e) => handleOnSelect && handleOnSelect(e)}
          >
            {categories.map((category: { id: number; name: string }) => (
              <Option key={category.id} value={category.id} name='categories'>
                <Text>{category.name}</Text>
              </Option>
            ))}
          </Select>
        </InputsContainer>

        <InputsContainer language={i18n.language === 'ar' ? 'ar' : 'en'}>
          <br />
          <Label lang={i18n.language === 'ar' ? 'ar' : 'en'}>
            <Trans>website</Trans> (<Trans>optional</Trans>)
          </Label>
          <Input
            name='website'
            type='url'
            width={'login'}
            errorName={errors.website}
            ref={register({
              pattern:
                /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
            })}
          />
          {errors?.website?.types?.pattern && (
            <Error>
              <Trans>website_pattern</Trans>
            </Error>
          )}
        </InputsContainer>

        <RegiaterBottomContainer>
          <Button
            disabled={formState.isSubmitting}
            size='large'
            onClick={onSubmit}
            loading={isLoading}
          >
            <Trans>create_account</Trans>
          </Button>
          <TermsTitle>
            <Trans>by_creating_an_account_you_agree_to</Trans>
            <a style={{ cursor: 'pointer' }} onClick={showModal}>
              <Trans>cancellation_policy</Trans>
            </a>
          </TermsTitle>
        </RegiaterBottomContainer>

        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={handleCancel}
          title={
            <TermsTitle>
              <Trans>terms_and_conditions</Trans>
            </TermsTitle>
          }
          text={<Trans>ok</Trans>}
          display
          width={1000}
        >
          <TermsAndConditions page />
        </Modal>
      </StyledForm>
      <Footer />
    </div>
  );
};
export default Form;
