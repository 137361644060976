import * as React from "react";
import { SVGProps } from "react";

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    style={{
      cursor: "pointer",
    }}
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 7h18M3 12h18M3 17h18"
      stroke="#344247"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeDasharray="0,0"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export default MenuIcon;
