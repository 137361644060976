import styled from "styled-components";
import Colors from "infrastructure/utils/Colors";

export const Container = styled.div``;

export const Head = styled.p`
  font-size: 20px;
  color: ${Colors.charcoal_grey};
  font-weight: bold;
  font-family: Famtree;
  line-height: normal;
  text-align: end;
  padding-top: 29px;
`;

export const Card = styled.div`
  background-color: white;
  margin: 32px 0 0 30px;
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  height: 744px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 83%;
`;

export const Pargraph = styled.div`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 18px;
  font-weight: normal;
  margin: 18px 0 34px;
`;

export const Span = styled.span`
  color: white;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
`;

export const P = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 30px 43px 98px 0px;
`;

export const Label = styled.label`
  color: ${Colors.charcoal_grey_54};
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
  margin: 0 auto;
`;

export const Hr = styled.div`
  width: 923px;
  color: "#dddddd";
  margin: 0 auto;
  height: 2px;
`;

export const SubP = styled.p`
  font-family: Famtree;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  color: ${Colors.charcoal_grey_54};
  text-align: center;
`;

export const ParagraphContainer = styled.div`
  margin: 0px auto;
  width: 923px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SupContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  margin: 0 auto;
`;

export const QuestionsHeadContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: flex-end;
`;

export const QuestionBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 947px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 23px 16px 18px 17px;
  align-items: flex-start;
`;

export const SwitchLabel = styled.div`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  margin-left: 41px;
  margin-right: 14px;
`;

export const SelectLabel = styled.div`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  color: ${Colors.charcoal_grey};
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  line-height: 51px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export const OptionValue = styled.p`
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  color: ${Colors.charcoal_grey};
  margin-right: 14px;
  margin-bottom: 0px;
  margin-top: 6px;
`;

export const QuestionTypeScale = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MultiChoiceContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const Icons = styled.div`
  position: absolute;
  min-width: 50px;
  text-align: center;
`;

export const MultiChoiceQuestionsContainer = styled.div`
  display: flex;
  flex-direction: coulmn;
`;

export const PContainer = styled.div`
  width: 1275px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 23px 16px 18px 17px;
  align-items: flex-end;
`;

export const ColumnCell = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  color: ${Colors.charcoal_grey};
`;

export const ColumnCellName = styled.div`
  font-family: Famtree;
  font-size: 12px;
  font-weight: normal;
  display: flex;
  cursor: pointer;
  color: ${Colors.charcoal_grey};
`;

export const MoreBtn = styled.div`
  font-family: Famtree;
  font-size: 36px;
  line-height: 0;
  margin-top: -10px;
  letter-spacing: -2px;
  cursor: pointer;
`;

export const Title = styled.p`
  color: ${Colors.charcoal_grey};
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  line-height: normal;
  text-align: end;
  margin: 29px 0px 15px 0px;
`;

export const LabelImag = styled.div`
  width: 175px;
  height: 48px;
  border-radius: 8px;
  border: solid 1.5px #44c874;
  display: block;
  cursor: pointer;
  color: #44c874;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  position: relative;
  margin: 0 10px;
  @media (max-width: 767px) {
    width: 96%;
    margin: 0 0 17px 0;
  }

  ${(props: { language?: string }) =>
    props.language === "ar"
      ? '::before{content:"اضافه اكسل";}'
      : '::before{content:"Upload excel";}'}

  ::before {
    position: absolute;
    top: 55.5%;
    left: 48%;
    transform: translate(-50%, -50%);
  }
`;

export const LeftButtonWarpper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    align-items: unset;
  }
`;

export const MobileHeader = styled.div`
  background-color: #fff;
  margin: 15px 15px 25px 15px;
  padding: 5px;
`;

export const SearchWrapper = styled.div`
  background-color: #fff;
`;

export const CardsContainer = styled.div`
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 30px 0 rgba(48, 48, 48, 0.01);
  direction: ${(props: { language: string }) =>
    props.language === "ar" ? "rtl" : "ltr"};

  .ant-pagination .ant-pagination-item {
    background: ${Colors.light_gray};
    border-color: ${Colors.light_gray};
    border-radius: 8px;
    color: ${Colors.dark_mint};
    width: 28px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .ant-pagination-item a {
    color: ${Colors.dark_mint};
  }

  .ant-pagination-item-active a {
    color: white !important;
  }

  .ant-pagination-item-active {
    background: ${Colors.dark_mint} !important;
    border-color: ${Colors.dark_mint} !important;
  }

  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    background: rgba(52, 66, 71, 0.1) !important;
    border-color: rgba(52, 66, 71, 0.1) !important;
    color: #344247 !important ;
    border-radius: 8px;
  }

  .ant-pagination-item-link {
    color: ${Colors.dark_mint};
  }

  .ant-spin-container {
    height: 100%;
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: ${Colors.light_gray};
  }

  .ant-table-pagination.ant-pagination {
    direction: ltr;
  }

  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th,
  .ant-table.ant-table-middle tfoot > tr > td {
    padding: 9px 8px;
  }

  .ant-pagination-options-size-changer {
    display: none;
  }
`;

export const BranchesContainer = styled.div`
  .ant-modal-body {
    padding: 44px 20px;
  }

  .ant-pagination .ant-pagination-item {
    background: ${Colors.light_gray};
    border-color: ${Colors.light_gray};
    border-radius: 8px;
    color: ${Colors.dark_mint};
    width: 28px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .ant-pagination-item a {
    color: ${Colors.dark_mint};
  }

  .ant-pagination-item-active a {
    color: white !important;
  }

  .ant-pagination-item-active {
    background: ${Colors.dark_mint} !important;
    border-color: ${Colors.dark_mint} !important;
  }

  .ant-pagination.mini .ant-pagination-prev,
  .ant-pagination.mini .ant-pagination-next {
    background: rgba(52, 66, 71, 0.1) !important;
    border-color: rgba(52, 66, 71, 0.1) !important;
    color: #344247 !important ;
    border-radius: 8px;
  }

  .ant-pagination-item-link {
    color: ${Colors.dark_mint};
  }

  .ant-spin-container {
    height: 100%;
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus .ant-pagination-item-link {
    color: ${Colors.light_gray};
  }

  .ant-table-pagination.ant-pagination {
    direction: ltr;
  }

  .ant-table.ant-table-middle .ant-table-title,
  .ant-table.ant-table-middle .ant-table-footer,
  .ant-table.ant-table-middle .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-tbody > tr > td,
  .ant-table.ant-table-middle tfoot > tr > th,
  .ant-table.ant-table-middle tfoot > tr > td {
    padding: 9px 8px;
  }

  .ant-pagination-options-size-changer {
    display: none;
  }
`;

export const MobileMoreBtn = styled.div`
  font-size: 36px;
  margin-top: -10px;
  cursor: pointer;
  color: #817a7a;
`;

export const CardItem = styled.div`
  padding: 10px 30px;
  border: 1px solid #ccc;
  margin: 0px 15px 15px 15px;
  border-radius: 5px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.p`
  margin-bottom: 0;
  font-family: Famtree;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`;

export const RatingWrapper = styled.p`
  margin-bottom: 5px;
`;
