/** @format */

import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";
import { Input as AntDInput } from "antd";
//@ts-ignore
export const InputStyle = styled.input`
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (max-width: 800px) {
    width: 100%;
  }

  width: ${(props: any) =>
    props.width === "formWidth"
      ? "700px"
      : props.width === "optionsWidth"
      ? "25%"
      : props.width === "searchWidth"
      ? "365px"
      : props.width === "questionWidth"
      ? "679px"
      : props.width === "login"
      ? "450px"
      : props.width === "register"
      ? "270px"
      : props.width === "jobs"
      ? "342px"
      : props.width === "price"
      ? "210px"
      : props.width === "payment"
      ? "350px"
      : props.width === "extraItem"
      ? "485px"
      : props.width === "exp"
      ? "70px"
      : props.width === "full_width"
      ? "100%"
      : props.width === "width_80"
      ? "80%"
      : props.width === "points_w"
      ? "19%"
      : "100%"};
  height: 50px;
  padding: ${(props) =>
    props.width === "price" || props.width === "login"
      ? "16px 10px 16px 25px"
      : props.width === "exp" || props.width === "optionsWidth"
      ? "16px 10px 16px 15px"
      : props.dir === "true"
      ? "16px 10px 16px 85px"
      : "16px 10px 16px 25px"};
  padding-left: ${(props: { language: string; name: string; id: string }) =>
    (props.name === "password" && props.language === "ar") ||
    (props.name === "confirm_password" && props.language === "ar") ||
    (props.name === "new_password" && props.language === "ar") ||
    (props.name === "current_password" && props.language === "ar")
      ? "50px"
      : props.name === "mobile"
      ? "65px"
      : props.id === "options" && "85px"};
  margin-bottom: ${(props: any) =>
    props.login ? "25px" : props.width === "formWidth" ? "unset" : "15px"};
  border: 1px solid;
  background-color: ${(props: any) =>
    props.errorName ? Colors.lipstick_5 : Colors.black_4};
  border-radius: 10px;
  margin: 13px 0;
  border-color: ${(props: any) =>
    props.errorName ? Colors.lipstick : Colors.black_4};
  :focus {
    outline: none !important;
    border: solid 0.1px
      ${(props: any) =>
        props.errorName ? Colors.lipstick : Colors.charcoal_grey};
  }
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
  text-align: ${(props: any) => props.lng === "ar" && "right"};
  ::placeholder {
    font-family: Famtree;
    font-size: 11px;
    color: ${Colors.charcoal_grey_38};
    text-align: ${(props) =>
      props.placeholder === "SAR" || props.placeholder === "cvv"
        ? "left"
        : props.language === "en"
        ? "left"
        : "right"};
  }
  direction: ${(props) => (props.language === "ar" ? "rtl" : "ltr")};

  @media (max-width: 800px) {
    width: ${(props) =>
      props.width === "points_w"
        ? "19%"
        : props.width === "width_80"
        ? "80%"
        : "100%"};
  }
`;

export const P = styled.p`
  position: absolute;
  margin-left: 108px;
  margin-top: -50px;
`;

export const StyledAntdInput = styled(AntDInput)`
  height: 50px;
  padding: 16px 10px 16px 25px;
  background-color: ${Colors.black_4};
  border: 1px solid ${Colors.black_4};
  border-radius: 10px;
  margin: 13px 0;
  :focus {
    outline: none !important;
    border: solid 0.1px ${Colors.black_4};
    background-color: ${Colors.black_4};
  }

  :hover {
    background-color: ${Colors.black_4};
    border: solid 0.1px ${Colors.black_4};
  }

  &.error {
    border: 1px solid ${Colors.darkish_pink};
    background-color: ${Colors.lipstick_5};
  }

  &.ant-input-status-error {
    border: 1px solid ${Colors.darkish_pink};
    background-color: ${Colors.lipstick_5};
  }
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
`;

export const StyledAntdInputPassword = styled(AntDInput.Password)`
  height: 50px;
  padding: 16px 10px 16px 25px;
  background-color: ${Colors.black_4};
  border: 1px solid ${Colors.black_4};
  border-radius: 10px;
  margin: 13px 0;
  :focus {
    outline: none !important;
    border: solid 0.1px ${Colors.black_4};
    background-color: ${Colors.black_4};
  }

  :hover {
    background-color: ${Colors.black_4};
    border: solid 0.1px ${Colors.black_4};
  }

  &.ant-input-affix-wrapper-status-error {
    border: 1px solid ${Colors.darkish_pink};
    background-color: ${Colors.lipstick_5};

    :hover {
      border: 1px solid ${Colors.darkish_pink};
      background-color: ${Colors.lipstick_5};
    }
  }
  font-family: Famtree;
  font-size: 14px;
  font-weight: normal;
`;
