/** @format */

import * as React from "react";
import { SVGProps } from "react";

const InfoCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={24} xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      stroke="#04CFEA"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="0,0"
      fill="none"
      fillRule="evenodd"
    >
      <path
        d="M22 12c0-5.5-4.5-10-10-10S2 6.5 2 12s4.5 10 10 10 10-4.5 10-10zm-10 5v-5"
        strokeWidth={1.5}
      />
      <path strokeWidth={2} d="M11.995 9h.1" />
    </g>
  </svg>
);

export default InfoCircleIcon;
