import { Menu } from "antd";
import styled from "styled-components";
import Colors from "../../infrastructure/utils/Colors";

//@ts-ignore
export const StyledMenu = styled(Menu)`
  &.ant-menu {
    width: 140px !important;
    box-shadow: 3px 3px 5px 6px #ccc;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 150px;
  min-width: ${(props: { minWidth?: any; language?: string }) =>
    props.minWidth ? props.minWidth : "80px"};
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  a {
    font-family: Famtree;
    color: ${Colors.charcoal_grey};
    font-size: 14px;
    line-height: 26px;
    @media (max-width: 767px) {
      margin: 0 20px;
    }
  }
  div {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  direction: ${(props: { language?: any }) =>
    props.language === "ar" ? "rtl" : "ltr"};
  @media (max-width: 767px) {
    justify-content: unset;
    margin-top: 5px;
  }
`;

export const StyledP = styled.p`
  font-family: Famtree;
  font-size: 20px;
  font-weight: bold;
  color: ${Colors.charcoal_grey};
  margin-bottom: 5px;
`;
