/** @format */

import { create } from "zustand";
import { axiosApiInstance } from "infrastructure/utils/api";
import storage from "infrastructure/utils/storage";

export const useStore = create((set: any) => ({
  userProfileData: {
    name: "",
    mobile: "",
    website: "",
    image_url: "",
    email: "",
    commercial_number: "",
    tax_number: "",
    address: "",
    categories: [],
  },
  isLoading: false,
  userData: async () => {
    try {
      const response = await axiosApiInstance.get("api/company/v1/profile");

      const data = {
        name: response.data.company.name,
        mobile: response.data.company.mobile,
        website: response.data.company.website,
        image_url: response.data.company.image,
        email: response.data.company.email,
        commercial_number: response.data.company.commercial_number,
        tax_number: response.data.company.tax_number,
        address: response.data.company.address,
        categories: response.data.company.categories,
      };
      set({
        userProfileData: data,
        isLoading: false,
      });

      storage.setItem("profile_data", JSON.stringify(data));
      storage.setItem("user_image", data.image_url);
    } catch (error) {}
  },
}));
