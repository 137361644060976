/** @format */

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CustomHeader } from 'common/Header';
import Tabs from 'common/Tabs';
import ChargeWithBankAccount from './ChargeWithBankAccount';
import ChargeWithCredit from './ChargeWithCredit';
import { TabsContainer } from 'pages/User/style';
import { Helmet } from 'react-helmet';
import { ChargeContainer } from '../style';

export const Charge = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>
          {i18n.language === 'ar' ? 'المحفظة - تدري' : 'Wallet - Tdree'}
        </title>
      </Helmet>
      <ChargeContainer>
        <CustomHeader
          title={<Trans>recharge_balance</Trans>}
          path={'/home'}
          type='withoutLeftContainer'
        />
        <TabsContainer>
          <Tabs
            tabOneTitle={t('charge_with_credit')}
            tabTwoTitle={t('charge_with_bank_account')}
            tabOneContent={<ChargeWithCredit />}
            tabTwoContent={<ChargeWithBankAccount />}
            className='changePassword'
          />
        </TabsContainer>
      </ChargeContainer>
    </>
  );
};
